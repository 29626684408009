﻿import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { FundingOrgRowContainerComponent } from './funding-org-row-container.component';
import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';

interface FundingOrgsRowRendererComponentProps extends ICellRendererParams {
    data: IFundingOrganization;
    onRemoveRow?: (fundingOrganization: IFundingOrganization) => void;
    canEdit?: boolean;
    baseFormField?: string;
}

export const FundingOrgsRowRenderer = ({ data, node, api, onRemoveRow, canEdit, baseFormField }: FundingOrgsRowRendererComponentProps) => {
    const handleRemoveFundingOrg = (fundingOrg: IFundingOrganization) => {
        onRemoveRow(fundingOrg);
    };

    return (
        <FundingOrgRowContainerComponent
            fundingOrganization={data}
            handleRemoveFundingOrg={handleRemoveFundingOrg}
            onRemoveRow={onRemoveRow}
            rowIndex={node.rowIndex}
            canEdit={canEdit}
            baseFormField={baseFormField}
        />
    );
};
