export const AssetsConstant = {
    ACADEMIC_DATA_SHARING_GRAPHIC: '/assets/Academic_Data_Sharing_Graphic.png',
    AVATAR_ICON: '/assets/Ribbon_AvatarIcon_Inverse.svg',
    avatarInverseIcon: '/assets/Ribbon_AvatarIcon_Inverse.svg',
    VIVLI_LOGO: '/assets/Logo@2x.png',
    VIVLI_LOGO2: '/assets/vivli_logo2.png',
    VIVLI_AMR_LOGO: '/assets/vivli_amr_logo.png',
    VIVLI_AMR_RGB_LOGO: '/assets/Vivli_AMR_rgb.jpg',
    VIVLI_BACKGROUND: '/assets/background.jpg',
    VIVLI_BACKGROUND_PEOPLE: '/assets/background.jpg',
    PLUS_SYMBOL: '/assets/UI_PlusSymbol.svg',
    DASHBOARD_ICON_ACTIVE: '/assets/LeftNav_DashboardIcon-Active.svg',
    DASHBOARD_ICON_INACTIVE: '/assets/LeftNav_DashboardIcon-Inactive.svg',
    DATA_REQUESTS_ICON_ACTIVE: '/assets/LeftNav_DataRequestsIcon-Active.svg',
    DATA_REQUESTS_ICON_INACTIVE: '/assets/LeftNav_DataRequestsIcon-Inactive.svg',
    INVITATIONS_ICON_ACTIVE: '/assets/LeftNav_InvitationsIcon-Active.svg',
    INVITATIONS_ICON_INACTIVE: '/assets/LeftNav_InvitationsIcon-Inactive.svg',
    OFFICEBUILDING_ICON_ACTIVE: '/assets/LeftNav_OfficeBuildingIconSmall-Active.png',
    OFFICEBUILDING_ICON_INACTIVE: '/assets/LeftNav_OfficeBuildingIconSmall-Inactive.png',
    ORGANIZATIONS_ICON_ACTIVE: '/assets/LeftNav_OrganizationsIcon-Active.svg',
    ORGANIZATIONS_ICON_INACTIVE: '/assets/LeftNav_OrganizationsIcon-Inactive.svg',
    REQUESTS_ICON_ACTIVE: '/assets/LeftNav_RequestsIcon-Active.svg',
    REQUESTS_ICON_INACTIVE: '/assets/LeftNav_RequestsIcon-Inactive.svg',
    VM_ICON_ACTIVE: '/assets/LeftNav_VMIcon-Active.svg',
    VM_ICON_INACTIVE: '/assets/LeftNav_VMIcon-Inactive.svg',
    TRIALS_ICON_ACTIVE: '/assets/LeftNav_StudiesIcon-Active.svg',
    TRIALS_ICON_INACTIVE: '/assets/LeftNav_StudiesIcon-Inactive.svg',
    INFO_ICON: '/assets/Icons_InfoICon.svg',
    questionMarkIcon: '/assets/Icons_InfoICon.svg',
    INFO_BANG: '/assets/Icons_InfoBang.svg',
    FIELD_DROPDOWN_ARROW: '/assets/Icons_FieldDropdownArrow.svg',
    CLOSE_X_WHITE: '/assets/Icon_Close_X_White.svg',
    CLOSE_X_BLACK: '/assets/Icon_Close_X_Black.svg',
    PICO_CHECKMARK: '/assets/pico_checkmark@2x.png',
    PICO_CHECKMARK_BLACK: '/assets/pico_checkmark_black@2x.png',
    RED_X_ICON: '/assets/Icons_RedXIcon.svg',
    YELLOW_X_ICON: '/assets/Icons_YellowXIcon.svg',
    GREEN_CHECK_MARK: '/assets/Icons_GreenCheckMark.svg',
    WHITE_CHECK_MARK: '/assets/Icons_CheckMark.svg',
    BLACK_RIGHT_ARROW: '/assets/chevron_right_black.svg',
    WHITE_RIGHT_ARROW: '/assets/chevron_right_white.svg',
    REMOVE_RESEARCHER: '/assets/ICons_DeleteSectionSymbol.svg',
    WHITE_MAGNIFYING_GLASS: '/assets/Icon_White_Magnifying_Glass.png',
    DOWN_CHEVRON_WHITE: '/assets/Down_Chevron_White@2x.png',
    DOWN_CHEVRON_BLACK: '/assets/Down_Chevron_Black.svg',
    TERMS_UP_ARROW: '/assets/Icons_ParentTermsLevelUpArrow.svg',
    TERMS_DOWN_ARROW: '/assets/Icons_ChildrenTermsLevelDownArrow.svg',
    RIBBON_MY_REQUESTS: '/assets/Ribbon_MyRequestsIcon.svg',
    NAV_VM_ICON: '/assets/Nav_VM_Icon.svg',
    SIGN_WARNING: '/assets/Sign_Warning.svg',
    VM_BEING_PROVISIONED: '/assets/VM_Being_Provisioned.svg',
    VM_NOT_RUNNING: '/assets/VM_Not_Running.svg',
    VM_RUNNING: '/assets/VM_Running.svg',
    VM_INACTIVE_ICON: '/assets/VM-Inactive_Icon.svg',
    VM_CHECK_MARK: '/assets/Check_Mark.svg',
    SEARCH_HELP_IMAGE1: '/assets/search_help_text_image001.png',
    SEARCH_HELP_IMAGE2: '/assets/search_help_text_image002.png',
    SEARCH_HELP_IMAGE3: '/assets/search_help_text_image003.png',
    SEARCH_HELP_IMAGE4: '/assets/search_help_text_image004.png',
    SEARCH_HELP_IMAGE5: '/assets/search_help_text_image005.png',
    SEARCH_HELP_IMAGE6: '/assets/search_help_text_image006.png',
    LEFT_CARET: '/assets/leftCaret.png',
    RIGHT_CARET: '/assets/rightCaret.png',
    DOWNLOAD_ARROW: '/assets/Icons_Download.svg',
    DOWNLOAD_ARROW_WHITE: '/assets/Icons_Download_White.svg',
    BANNER_BACKGROUND: '/assets/COVID_Banner_blank.jpg',
    avatarIcon: '/assets/Ribbon_AvatarIcon.svg',
    downArrowWhiteIcon: '/assets/Ribbon_DownArrow_White.svg',
    downArrowDarkIcon: '/assets/Ribbon_DownArrow_Dark.svg',
    vivliTempLogo: '/assets/VivliTempLogo.png',
    editIconActive: '/assets/UI_EditSymbol-Active.svg',
    editIconInactive: '/assets/UI_EditSymbol-Inactive.svg',
};
