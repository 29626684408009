﻿import React, { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { LoadIndicatorComponent, PlanningTableComponent } from '@vivli/shared/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { DuaExecutionComponent } from './dua-execution.component';
import { useDuaDataHook } from '@vivli/features/data-requests/infrastructure/hook';

const mainContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
    backgroundColor: Color.LIGHT_GRAY,
};

const planningStyle: CSSProperties = {
    display: 'flex',
    gap: '20px',
    width: '70%',
};

const loadingContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

const loadingStyle: CSSProperties = {
    ...Styles.CENTERED_IN_BLOCK,
    zIndex: 100,
    minWidth: 'initial',
};

export const DuaComponent = ({ isOrgAdmin }) => {
    const { isVivliAdmin } = useActiveUser();

    const {
        organizationsOptions,
        templateOptions,
        getDefaultTemplateCode,
        handleAddRow,
        handleDeleteRow,
        handleUpdateRow,
        organizations,
        planningTableData,
    } = useDuaDataHook();

    return (
        <div style={mainContainerStyle}>
            {!organizations ? (
                <div style={loadingContainerStyle}>
                    <div style={loadingStyle}>
                        <LoadIndicatorComponent />
                    </div>
                </div>
            ) : (
                <>
                    {isVivliAdmin && (
                        <>
                            <h2>Planning</h2>
                            <div style={planningStyle}>
                                <PlanningTableComponent
                                    templateOptions={templateOptions}
                                    handleAddRow={handleAddRow}
                                    planningTableData={planningTableData}
                                    handleDeleteRow={handleDeleteRow}
                                    handleUpdateRow={handleUpdateRow}
                                    getDefaultTemplateCode={getDefaultTemplateCode}
                                    organizationsOptions={organizationsOptions}
                                />
                            </div>
                        </>
                    )}

                    <h2>Execution</h2>
                    <DuaExecutionComponent isOrgAdmin={isOrgAdmin} />
                </>
            )}
        </div>
    );
};
