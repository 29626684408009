﻿import React from 'react';
import { ChatFeature } from '@vivli/features/chat/feature';

interface StudyDatasetChatComponentProps {
    chatChannelId: string;
}

export const StudyDatasetChatComponent = ({ chatChannelId }: StudyDatasetChatComponentProps) => {
    return (
        <div>
            <ChatFeature channelId={chatChannelId} connectOnFirstSend={true} />
        </div>
    );
};
