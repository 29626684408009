﻿import React, { CSSProperties } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { DTIAMRCommonConst } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    maxHeight: window.innerHeight * 0.8,
    overflowX: 'hidden',
    overflowY: 'auto',
};
const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-around',
};
const buttonStyle: CSSProperties = {
    backgroundColor: '#005475',
    height: '48px',
    margin: '31px 14px 10px 10px',
};

interface QuickSearchComponentProps {
    currentId: string;

    executeSearch(id: string, sponsors?: string[]);
}

export const EmbeddedQuickSearchComponent = ({ currentId, executeSearch }: QuickSearchComponentProps) => {
    const handleClick = () => {
        executeSearch(currentId);
    };

    return (
        <div style={containerStyle}>
            <div style={buttonContainerStyle}>
                <ButtonComponent
                    onClick={handleClick}
                    style={buttonStyle}
                    className={'embedded_quickSearch_lookup'}
                    dataId={DTIAMRCommonConst.RequestStudyButton}
                >
                    Prepare to Request Vivli Study
                </ButtonComponent>
            </div>
        </div>
    );
};
