import React, { CSSProperties, ReactNode } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { HintPopoverComponent } from '../hint-popover.component';
import { BadgeComponent } from '../badge.component';
import { DTITab } from '@vivli/shared/infrastructure/constants';

export interface TabComponentProps {
    isSelected?: boolean;
    isSelectedByIndex?: boolean;
    title?: string;
    style?: CSSProperties;
    onClick?: () => void;
    hint?: string;
    count?: number;
    children?: ReactNode;
    disabled?: boolean;
}

const containerStyle = (hint: string, selected: boolean, style: CSSProperties): CSSProperties =>
    ({
        position: 'relative',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        paddingLeft: hint ? Size.PADDING * 2 : Size.PADDING,
        paddingRight: Size.PADDING,
        marginRight: Size.PADDING * 2,
        color: selected ? Color.TEXT_DARK_BLUE : Color.TEXT_LIGHT_BLUE,
        fontSize: Size.FontSize.Large,
        fontWeight: selected ? Size.FontWeight.Bold : Size.FontWeight.Regular,
        transition: `opacity ${Size.HOVER_TRANSITION_TIME}s`,
        ':hover': { opacity: !selected && Size.HOVER_OPACITY },
        ...style,
    } as CSSProperties);

const hintContainerStyle: CSSProperties = {
    position: 'absolute',
    top: 20,
    left: 3,
};

const badgeStyle = (count: number): CSSProperties => ({
    backgroundColor: Color.TEXT_LIGHT_BLUE,
    paddingBottom: count === 0 ? 0 : 3,
    paddingLeft: count === 0 ? 0 : 3,
    paddingRight: count === 0 ? 0 : 3,
    paddingTop: count === 0 ? 0 : 3,
    color: count === 0 ? 'rgba(0,0,0,0)' : 'white',
    position: 'relative',
    bottom: -25,
    right: -15,
    height: count === 0 ? 0 : 'initial',
    zIndex: 2,
});

const selectedStyle = (selected: boolean): CSSProperties => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    height: 2,
    backgroundColor: selected ? Color.TEXT_DARK_BLUE : null,
});

const titleStyle = (disabled: boolean): CSSProperties => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    color: disabled ? '#808080ab' : 'unset',
});

export const TabButtonComponent = ({
    isSelected,
    isSelectedByIndex,
    title,
    onClick,
    style,
    children,
    hint,
    count,
    disabled,
}: TabComponentProps) => {
    const selected = isSelected ? true : !!isSelectedByIndex;
    const tabTitle = DTITab.TabItem(title ? title.replace(/[^\w]/gi, '_') : 'empty');

    const handleOnClick = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    return (
        <div style={containerStyle(hint, selected, style)} onClick={handleOnClick} className={tabTitle} data-test-id={tabTitle}>
            <div style={hintContainerStyle}>
                <HintPopoverComponent hintText={hint} maxWidth={window.innerWidth - 2 * Size.PADDING} />
            </div>
            <div style={titleStyle(disabled)}>{title}</div>
            {count > 0 && <BadgeComponent style={badgeStyle(count)} value={count} />}
            <div style={selectedStyle(selected)} />
        </div>
    );
};
