import React, { CSSProperties } from 'react';
import Tippy from '@tippyjs/react';

interface AdminHeaderTitleProps {
    requestNumber: number;
    pi?: string;
    status: string;
    title?: string;
    myDataRequestsAdminHeader?: boolean;
}

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
};

const titleStyle: CSSProperties = {
    fontWeight: 'bold',
};

export const AdminHeaderDetailTitleComponent = ({ requestNumber, pi, status, title, myDataRequestsAdminHeader }: AdminHeaderTitleProps) => {
    const titleRenderer = () => (
        <div style={containerStyle}>
            <span>
                <span style={titleStyle}>Request:</span> {requestNumber},
                {myDataRequestsAdminHeader ? (
                    <>
                        <span style={titleStyle}> PI:</span> {pi}
                    </>
                ) : (
                    <>
                        {' '}
                        <span style={titleStyle}> Title:</span> {title}{' '}
                    </>
                )}
            </span>
            <span>
                <span style={titleStyle}>Status:</span> {status}
            </span>
        </div>
    );

    return (
        <Tippy content={titleRenderer()} allowHTML={true} followCursor="horizontal">
            {titleRenderer()}
        </Tippy>
    );
};
