// Add new translation key values to amr-translations.constant,
// default-translations.constant and translation-string.enum.
// This allows you to use enum vals to look up constants easily
// To retrieve values, get a function from the useTranslation hook,
// as follows:
// const {t} = useTranslation();
// then pass the key to the function, e.g.
// const translatedString = t(TranslationStringEnum.welcomeMessage)

export const DefaultTranslationsConstant: any = {
    isDefault: 'yas',
    picoButton: 'PICO Beta',
    studies: 'Studies',
    study: 'study',
    Vivli: 'Vivli',
    welcomeMessage:
        'This is your view of Vivli at a glance.' +
        '<br><br>' +
        'Here you can view your organizational memberships and roles,\n' +
        'any pending requests that require your approval, as well as any studies awaiting\n' +
        'Data Package upload from your organization. You can also generate metrics for\n' +
        "data requests involving your organization's studies.\n" +
        '<br><br>' +
        'If you have any questions, please contact Vivli Support.' +
        '<br><br>' +
        'Thanks!',
    studyID: 'Study ID',
    studyType: 'IPD',
    fileUploadInstructions: 'studies',
    update: 'Upload study Data Package below',
    title: 'Study Title',
    popUpWelcomeMessage:
        'Welcome to the Vivli platform, which offers an easy way to share, archive and ' +
        'request anonymized data from completed clinical trials across a wide range of ' +
        'stakeholders from academia, government, non-profits and foundations, biotech and ' +
        'pharmaceutical companies.' +
        '<br><br>' +
        '<strong>Share your studies</strong>: Vivli is here to make it as easy as possible to share your studies. ' +
        'If you have one study to submit for sharing, click ' +
        //Translates to: '<a href="https://@.../study-submission">here</a>.'
        '<3>here</3>. ' +
        'For more information, see our webpage on ' +
        //Translates to: '<a href="https://vivli.org/resources/sharedata/">how to share data</a>.'
        '<4>how to share data</4>.' +
        '<br><br>' +
        '<strong>Request studies</strong>: You can search for studies on the Search page under this popup and then request them on the platform. ' +
        'For more information see our webpage on ' +
        //Translates to: '<a href="https://vivli.org/resources/requestdata/">how to request data</a>.'
        '<5>how to request data</5>.' +
        '<br><br>' +
        '<strong>Search for studies:</strong> You can use the Search page under this popup to enter the name of a condition, a ' +
        'treatment, an outcome, or the ID of a study you want to request.  If you enter more than one term, ' +
        'it will find studies that contain any of the terms.  You can override this by combining terms with ' +
        'an AND (e.g. asthma AND fluticasone) or, if the exact order is important, putting the terms in quotes ' +
        '(e.g. “multiple sclerosis”).  The number of matches will display on the bottom of the screen – click ' +
        'this number to display the list of studies.' +
        '<br><br>' +
        'More detailed guidance on search and all aspects of using the Vivli Platform is on the ' +
        '<6>Resources Menu</6>. ' +
        'You can also contact us at ' +
        //Translates to: '<a href="mailto:support@vivli.org">support@vivli.org</a>.'
        '<0>support@vivli.org</0>.' +
        '<br><br>' +
        'If you cannot find a study on this Search page and you would like to request it from one of our members you can use the ' +
        //Translates to: '<a href="https://vivli.org/members/enquiries-about-studies-not-listed-on-the-vivli-platform/">Enquiry Form</a> '
        '<1>Enquiry Form</1> ' +
        'to ask about study data availability.',
    chatHelperText:
        "The chat with an organization's name will only be seen by members of that organization.  As with the Open Chat, the organization chat is only for a specific data request." +
        '<br/><br/>' +
        'Contributors chat will be seen by all contributors of data to this request and Vivli' +
        'Requesters Chat will be seen by the requester of the data and Vivli',
    howToGuideLink: 'https://vivli.org/resources/resources/',
    privacyLink: 'https://vivli.org/privacy/',
    cookiePolicyLink: 'https://vivli.org/vivli-cookie-policy/',
    eeaDisclosurePolicyLink: 'https://vivli.org/vivli-eea-disclosure/',
    contactUsLink: 'https://vivli.org/contact/',
    duaValidatedStatusString: 'DUA Validated and Awaiting Data Package Upload',
    vmResearchEnvironmentConfig:
        '<strong>Academic license for SAS.</strong><br/>' +
        'Alternative pricing applies for industry users of SAS, email ' +
        '<0>support@vivli.org</0>' +
        ' for details.',
    pathway2SubmitConfirmText:
        'You are about to submit a data request containing studies whose data package or approval come from a Vivli Platform Provider. These studies must be requested through their Platform Provider. Once you submit this request, it can be changed only by contacting Vivli. Press OK if you are sure you are ready to submit the request now.',
    IPD: 'IPD (Anonymized Individual Participant-level Data)',
    accessDataPackagesIPD: 'anonymized Individual Participant Data (IPD)',
    accessDPResearchEnvironment: 'placing the data into a secure research environment or (when allowed)',
};
