﻿import { ColDef, GridApi } from 'ag-grid-community';
import React, { useRef } from 'react';
import { FundingOrgsRowRenderer } from './funding-orgs-row-renderer.component';
import { GridComponent } from '@vivli/shared/components';
import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';

interface FundingOrgsGridComponentProps {
    fundingOrgs: IFundingOrganization[];
    handleFunderChange?: (fundingOrganizations: IFundingOrganization[]) => void;
    handleRemoveRow?: (fundingOrganization: IFundingOrganization) => void;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    asyncUpdateKey?: string;
    dataRefreshing?: boolean;
    onChange?: () => void;
    canEdit?: boolean;
    isListingRequest?: boolean;
}

export const FundingOrgsGridComponent = ({
    fundingOrgs,
    handleFunderChange,
    handleRemoveRow,
    onGridReady,
    emptyMessage,
    hideFloatingFilter,
    hideHeader,
    autoHeight,
    dataRefreshing,
    canEdit,
    onChange,
    isListingRequest,
}: FundingOrgsGridComponentProps) => {
    const gridApiRef = useRef<GridApi>();

    const handleOnGridReady = (gridApi: GridApi) => {
        onGridReady && onGridReady(gridApi);
        gridApiRef.current = gridApi;
    };

    const columnDefs: ColDef[] = [
        {
            field: 'fundingOrganization',
            headerName: 'Funding Organization',
            filter: true,
            sortable: true,
            cellRenderer: 'FundingOrgsRow',
            cellRendererParams: {
                onFunderChange: handleFunderChange,
                onRemoveRow: handleRemoveRow,
                canEdit: canEdit,
                baseFormField: isListingRequest ? 'datasets[0]' : null,
            },
        },
    ];

    const customComponents = [
        {
            name: 'FundingOrgsRow',
            component: FundingOrgsRowRenderer,
        },
    ];

    return (
        <GridComponent
            rowData={fundingOrgs}
            fullWidthColumns={true}
            onGridReady={handleOnGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            hideHeader={hideHeader}
            autoHeight={autoHeight}
            customComponents={customComponents}
            rowHeight={100}
            asyncUpdateKey={'rorId'}
            dataRefreshing={dataRefreshing}
            flushPrevious={true}
        />
    );
};
