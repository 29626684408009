﻿import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { ColDef, GridApi } from 'ag-grid-community';
import { GridPageComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { IVirtualMachine } from '@vivli/features/virtual-machine/infrastructure/interface';
import { first } from 'rxjs/operators';
import { useVirtualMachineService } from '@vivli/features/virtual-machine/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { ManageVmModalComponent } from '@vivli/features/virtual-machine/components';

const centeredStyle: CSSProperties = {
    textAlign: 'center',
};

export const ManageVmsFeature = () => {
    const [vms, setVms] = useState<IVirtualMachine[]>(null);
    const [isLoading, setIsLoading] = useState(false);
    const gridRef = useRef<GridApi>();
    const virtualMachineService = useVirtualMachineService();
    const modalService = useModalService();

    const columnDefs: ColDef[] = [
        {
            field: 'dataRequestDoi',
            headerName: 'Data Request Id',
            sortable: true,
            filter: true,
            initialSort: 'asc',
            cellStyle: centeredStyle,
        },
        {
            field: 'dataRequestTitle',
            headerName: 'Title',
            sortable: true,
            filter: true,
        },
        {
            field: 'provisionedByEmail',
            headerName: 'Requester Email',
            sortable: true,
            filter: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            filter: true,
            cellStyle: centeredStyle,
        },
        {
            field: 'daysSinceLastLogin',
            headerName: 'Days Since Last Login',
            sortable: true,
            filter: false,
            cellStyle: centeredStyle,
        },
        {
            field: 'daysSinceLastStart',
            headerName: 'Runtime (Days)',
            sortable: true,
            filter: false,
            cellStyle: centeredStyle,
        },
    ];

    const handleRowClick = (data) => {
        const modalId = modalService.custom(
            <ManageVmModalComponent
                vmId={data.id}
                onCancel={() => {
                    modalService.dismiss(modalId);
                }}
            />
        );
    };

    const handleError = (e) => {
        modalService.error(e);
        setIsLoading(false);
    };

    const getVms = () => {
        setIsLoading(true);
        virtualMachineService
            .getVms(false)
            .pipe(first())
            .subscribe((vms) => {
                setIsLoading(false);
                setVms(vms);
            }, handleError);
    };

    useEffect(() => {
        getVms();
    }, []);

    if (!vms) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <GridPageComponent
            title="Research Environment Management"
            columnDefs={columnDefs}
            rowData={vms}
            onRowClick={handleRowClick}
            exportTitle="Research Environment Management"
            asyncUpdateKey="id"
            dataRefreshing={isLoading}
            onGridReady={(gridApi) => (gridRef.current = gridApi)}
            fullWidthColumns={true}
        />
    );
};
