﻿export enum AmrAnalysisPurposeEnum {
    AmrTrendsForCountryRegion = 'AmrTrendsForCountryRegion',
    AmrTrendsForPathogen = 'AmrTrendsForPathogen',
    AmrTrendsForSpecificResistanceMechanism = 'AmrTrendsForSpecificResistanceMechanism',
    AmrTrendsForInfectionTypeOrIndication = 'AmrTrendsForInfectionTypeOrIndication',
    AmrTrendsOverDifferentYears = 'AmrTrendsOverDifferentYears',
    PkPd = 'PkPd',
    InformAntimicrobialStewardship = 'InformAntimicrobialStewardship',
    Other = 'Other',
}
