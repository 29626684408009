import React from 'react';
import { AdvancedSelectComponent, AdvancedSubHeaderComponent } from '@vivli/shared/components';
import { amrFieldTitleStyle, inputGroupBreakStyle } from '../../search-filters.styles';
import { FilterGroupComponent } from '../../filter-group.component';
import { useAmrSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';

export const FirstFilterGroupComponent = () => {
    const { amrFilterValues } = useSearchContext();
    const {
        antimicrobialSelectedOptions,
        setAntimicrobialSelectedOptions,
        organismSelectedOptions,
        setOrganismSelectedOptions,
        sampleSelectedOptions,
        setSampleSelectedOptions,
    } = useAmrSearchOptionsContext();

    const antimicrobialOptions = amrFilterValues?.antimicrobials.map((l) => ({ value: l.value, label: l.value }));
    const organismOptions = amrFilterValues?.organisms.map((l) => ({ value: l.value, label: l.value }));
    const sampleOptions = amrFilterValues?.sampleTimes.map((l) => ({ value: l.value, label: l.value }));

    return (
        <FilterGroupComponent>
            <div style={inputGroupBreakStyle}>
                <AdvancedSubHeaderComponent title={'Antimicrobials'} style={amrFieldTitleStyle} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setAntimicrobialSelectedOptions}
                    options={antimicrobialOptions}
                    selectedOptions={antimicrobialSelectedOptions}
                />
            </div>

            <div style={inputGroupBreakStyle}>
                <AdvancedSubHeaderComponent title={'Organisms'} style={amrFieldTitleStyle} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setOrganismSelectedOptions}
                    options={organismOptions}
                    selectedOptions={organismSelectedOptions}
                />
            </div>

            <div style={inputGroupBreakStyle}>
                <AdvancedSubHeaderComponent title={'Time of Sample'} style={amrFieldTitleStyle} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setSampleSelectedOptions}
                    options={sampleOptions}
                    selectedOptions={sampleSelectedOptions}
                />
            </div>
        </FilterGroupComponent>
    );
};
