﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { NotificationServiceContext } from '@vivli/shared/features/notification/infrastructure/context';
import { INotificationService } from '@vivli/shared/features/notification/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { NotificationApiService } from '@vivli/shared/features/notification/infrastructure/service';

export const NotificationServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new NotificationApiService(apiOptions);

    const provider: INotificationService = {
        ...api,
    };

    return <NotificationServiceContext.Provider value={provider}>{children}</NotificationServiceContext.Provider>;
};
