import React, { CSSProperties } from 'react';
import { ICheckboxMenuItem, IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseControlledFormFieldComponent } from './base-controlled-form-field.component';
import { CheckboxListComponent } from '../input/checkbox-list.component';

interface CheckboxListFormFieldComponentProps extends IFormField {
    checkedListItems?: ICheckboxMenuItem[];
    checkboxGroupNames?: any;
    groupStyle?: CSSProperties;
    fieldStyle?: CSSProperties;
    name: string;
}

export const CheckboxListFormFieldComponent = (props: CheckboxListFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <CheckboxListComponent {...props} />
        </BaseControlledFormFieldComponent>
    );
};
