export enum ResultsRequestStatusEnum {
    None = 'None',
    NotStarted = 'NotStarted',
    Draft = 'Draft',
    RetrievingResults = 'RetrievingResults',
    RetrievingResultsFailed = 'RetrievingResultsFailed',
    HeuristicsPassed = 'HeuristicsPassed',
    HeuristicsRequireReview = 'HeuristicsRequireReview',
    Approved = 'Approved',
    Disapproved = 'Disapproved',
    Submitted = 'Submitted',
}
