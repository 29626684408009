﻿export enum DatasetTabEnum {
    DatasetDetails = 'Dataset Details',
    Documents = 'Dataset Documents',
    AdminDetails = 'Administrative Details',
    Download = 'Download',
    Usage = 'Usage',
    StudyDetails = 'Study Details',
    StudyDocuments = 'Study Documents',
    StudyDownload = 'Download Data Package',
    ResearchTeam = 'Research Team',
    View = 'View Data Package',
    StudyView = 'View Data Package',
}
