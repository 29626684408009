import { Observable } from 'rxjs';
import { IAmrFilterValues, IAzureFiltersSearchService, ICtFilterValues } from '@vivli/features/search/infrastructure/interface';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { AzureSearchBaseService } from './azure-search-base.service';

export class AzureFiltersSearchService extends AzureSearchBaseService implements IAzureFiltersSearchService {
    public amrFacets = [
        'antimicrobials,count:200,sort:value',
        'groupingsOfResistancePatterns,count:50,sort:value',
        'organisms,count:1500,sort:value',
        'specimenSources,count:200,sort:value',
        'sampleTimes',
        'countries,count:300,sort:value',
        'regions',
        'yearsDataCollected,count:50',
        'sponsorName,count:500,sort:value',
    ];

    public ctFacets = [
        'studyDesign',
        'locationsOfStudySites,count:300,sort:value',
        'sponsorType',
        'contributorType',
        'sponsorName,count:500,sort:value',
        'studyType',
        'actualEnrollment,interval:100',
        'funder,count:500',
        'orgName,count:500',
    ];

    getFilterValues<T>(facets: string[], assignedAppType: AssignedAppTypeEnum = AssignedAppTypeEnum.Default): Observable<T> {
        return this._searchClient.getFacets<T>({
            facets,
            filter: `assignedAppType eq '${assignedAppType}'`,
        });
    }

    getAmrFilterValues(): Observable<IAmrFilterValues> {
        return this.getFilterValues<IAmrFilterValues>(this.amrFacets, AssignedAppTypeEnum.Amr);
    }

    getCtFilterValues(): Observable<ICtFilterValues> {
        return this.getFilterValues<ICtFilterValues>(this.ctFacets);
    }
}
