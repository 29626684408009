import { IOrganizationInvitation } from '@vivli/features/organizations/infrastructure/interface';
import { DataRequestPermissionsService } from '@vivli/features/data-requests/infrastructure/service';
import React, { CSSProperties } from 'react';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { Color, Size } from '@vivli/shared/theme';
import { ButtonComponent } from '@vivli/shared/components';
import { useOrganizationDetailsContext } from '@vivli/features/organizations/infrastructure/context';

const rowInviteStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    backgroundColor: Color.WHITE,
    height: Size.HEIGHT_FOR_ROW,
    boxShadow: Size.BOX_SHADOW_EXTRA_LIGHT,
    marginBottom: Size.FIELD_SEPARATION,
};
const fontStyle: CSSProperties = {
    fontWeight: Size.FontWeight.SemiBold,
};
const paddingStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
};
const adminTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Medium,
    color: Color.DARK_GRAY,
};
const divStyle: CSSProperties = {
    ...adminTextStyle,
    display: 'flex',
    alignItems: 'center',
};
const innerDivStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};

interface OrgInviteRowComponentProps {
    invitation: IOrganizationInvitation;
}

export const OrgInviteRowComponent = ({ invitation }: OrgInviteRowComponentProps) => {
    const user = useActiveUser();
    const { organization, deleteInvite, isSaving } = useOrganizationDetailsContext();
    const showDeleteButton =
        user && (user.isVivliAdmin || DataRequestPermissionsService.isOrgMember(user, organization.id, (om) => om.isOrgAdmin));

    return (
        <div style={rowInviteStyle}>
            <span style={fontStyle}>
                {invitation.email}
                {invitation.orgMembership.isOrgAdmin && <span> | Org Admin</span>}
                {invitation.orgMembership.isIRPApprover && <span> | IRP/Reviewer</span>}
                {invitation.orgMembership.isDataProvider && <span> | Data Contributor</span>}
                {invitation.orgMembership.isAnnotator && <span> | Curator</span>}
                {invitation.orgMembership.isHead && <span> | Head Curator</span>}
            </span>
            <div style={paddingStyle}>
                <div style={divStyle}>
                    <div style={innerDivStyle}>
                        {showDeleteButton && (
                            <ButtonComponent onClick={() => deleteInvite(invitation)} isLoading={isSaving} className={'orgInvite_delete'}>
                                Delete Invitation
                            </ButtonComponent>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
