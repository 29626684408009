import React, { CSSProperties, useEffect, useState } from 'react';
import {
    IDataRequest,
    IDataRequestInvitationResponse,
    IResearchTeamMemberDetail,
} from '@vivli/features/data-requests/infrastructure/interface';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { Observable, of } from 'rxjs';
import { usePrioritySort } from '@vivli/shared/infrastructure/hook';
import { ResearchTeamListComponent } from '../../research-team/research-team-list.component';
import { Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`,
    height: '100%',
};

export const DataRequestResearchTeamComponent = () => {
    const [researchTeamMembers, setResearchTeamMembers] = useState<IResearchTeamMemberDetail[]>(null);
    const [teamMembersLoading, setTeamMembersLoading] = useState<string[]>([]);

    const { dataRequest, updateDataRequest } = useDataRequestContext();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();
    const user = useActiveUser();
    const teamMemberSort = usePrioritySort(['isLeadResearcher', 'isAdmin', 'isStatistician'], 'firstName');
    const modal = useModalService();

    const handleAddError = (error: string) => {
        modal.error(error);
    };

    const setTeamMemberLoadingStatus = (teamMemberId: string, status: boolean) => {
        const teamMembers = [...teamMembersLoading.filter((id) => id !== teamMemberId)];
        if (status) {
            setTeamMembersLoading([...teamMembers, teamMemberId]);
        } else {
            setTeamMembersLoading([...teamMembers]);
        }
    };

    const inviteToVm = (updatedTeamMember: IResearchTeamMemberDetail): Observable<IDataRequestInvitationResponse> => {
        if (updatedTeamMember.inviteToVirtualMachine) {
            return dataRequestsService.inviteToVm({
                email: updatedTeamMember.email,
                InvitedById: user.userId,
                dataRequestId: dataRequest.id,
                teamMemberId: updatedTeamMember.teamMemberId,
            });
        }

        return of(null);
    };

    const handleAddEditCompleted = (updatedDataRequest: IDataRequest) => {
        updateResearchTeamMembers(updatedDataRequest);
        updateDataRequest(updatedDataRequest);
    };

    const handleInviteToVm = (teamMember: IResearchTeamMemberDetail, dr: IDataRequest) => {
        setTeamMemberLoadingStatus(teamMember.teamMemberId, true);
        inviteToVm(teamMember)
            .pipe(
                first(),
                finalize(() => {
                    setTeamMemberLoadingStatus(teamMember.teamMemberId, false);
                })
            )
            .subscribe(
                (resp) => {
                    let updatedRequest = dr;

                    // if the invitation is successful it will have the latest data, override the previous result
                    if (resp) {
                        updatedRequest = resp.dataRequest;
                    }
                    handleAddEditCompleted(updatedRequest);
                },
                (error) => {
                    modalService.error(error);
                    updateDataRequest(dr);
                }
            );
    };

    const removeStatisticianIfNeeded = (teamMember: IResearchTeamMemberDetail) => {
        const currentStatistician = researchTeamMembers.find((rtm) => rtm.isStatistician);
        if (currentStatistician && teamMember.isStatistician && currentStatistician.teamMemberId !== teamMember.teamMemberId) {
            return dataRequestsService.editResearchTeamMember(dataRequest.id, {
                ...currentStatistician,
                isStatistician: false,
            });
        }

        return of(null);
    };

    const handleOnSave = (updatedTeamMember: IResearchTeamMemberDetail) => {
        setTeamMemberLoadingStatus(updatedTeamMember.teamMemberId, true);
        removeStatisticianIfNeeded(updatedTeamMember).subscribe(() => {
            if (updatedTeamMember.teamMemberId) {
                dataRequestsService.editResearchTeamMember(dataRequest.id, updatedTeamMember).subscribe((dr) => {
                    handleInviteToVm(updatedTeamMember, dr);
                });
            }
        });
    };

    const handleOnAdd = (teamMember: IResearchTeamMemberDetail) => {
        dataRequestsService.addResearchTeamMember(dataRequest.id, teamMember).subscribe(({ teamMemberId, dataRequest }) => {
            const newTeamMember = { ...teamMember, teamMemberId };
            handleInviteToVm(newTeamMember, dataRequest);
        }, handleAddError);
    };

    const updateResearchTeamMembers = (dataRequest: IDataRequest, updateDr = true) => {
        const orderedMembers = teamMemberSort<IResearchTeamMemberDetail>(dataRequest.researchTeam.researchTeamDetails);

        setResearchTeamMembers(orderedMembers);

        if (updateDr) {
            updateDataRequest(dataRequest);
        }
    };

    const handleOnRemove = (teamMemberToRemove: IResearchTeamMemberDetail) => {
        setTeamMemberLoadingStatus(teamMemberToRemove.teamMemberId, true);
        dataRequestsService
            .removeResearchTeamMember(dataRequest.id, teamMemberToRemove.teamMemberId)
            .pipe(first())
            .subscribe(
                (dr) => {
                    handleAddEditCompleted(dr);
                },
                () => {
                    setTeamMemberLoadingStatus(teamMemberToRemove.teamMemberId, false);
                }
            );
    };

    useEffect(() => {
        if (!dataRequest) {
            return;
        }

        updateResearchTeamMembers(dataRequest, false);
    }, [dataRequest]);

    return (
        <div style={containerStyle}>
            <ResearchTeamListComponent
                dataRequest={dataRequest}
                researchTeamMembers={researchTeamMembers}
                onRemove={handleOnRemove}
                onAdd={handleOnAdd}
                onSave={handleOnSave}
                onChange={(dataRequest) => updateResearchTeamMembers(dataRequest)}
                teamMembersLoading={teamMembersLoading}
            />
        </div>
    );
};
