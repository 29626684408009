import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = {
    marginTop: Size.PADDING,
    fontSize: Size.FontSize.Larger + 5,
    fontWeight: Size.FontWeight.Regular,
    color: Color.BLACK,
    textAlign: 'center',
};

const linkStyle: CSSProperties = { color: Color.TEXT_LIGHT_BLUE };

interface ResearchHelpLinkComponent {
    isNewVm?: boolean;
}

export const ResearchHelpLinkComponent = ({ isNewVm }: ResearchHelpLinkComponent) => {
    const link = ' https://vivli.org/how-to-access-data-from-vivli-use-the-secure-research-environment';

    return (
        <div style={containerStyle}>
            Information on using the Research Environment is available{' '}
            <a style={linkStyle} target="_blank" href={link}>
                here
            </a>
            .
        </div>
    );
};
