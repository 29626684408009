import {
    IAcceptInviteResponse,
    IOrganization,
    IOrganizationInvitation,
    IOrganizationMember,
    IOrganizationsApiService,
} from '@vivli/features/organizations/infrastructure/interface';
import { RestApi } from '@vivli/core/infrastructure/rest';
import { Observable } from 'rxjs';
import { IOrganizationChannel } from '@vivli/features/chat/infrastructure/interface';
import { IOrganizationRoles } from '@vivli/shared/infrastructure/interface';

export class OrganizationsApiService extends RestApi implements IOrganizationsApiService {
    getOrganization = (organizationId: string): Observable<IOrganization> => {
        return this.handleGet<IOrganization>(`/orgs/${organizationId}`);
    };

    getOrganizations = (): Observable<IOrganization[]> => {
        return this.handleGet<IOrganization[]>('/orgs');
    };

    getOrganizationMembers = (organizationId: string, includeDisabled?: boolean): Observable<IOrganizationMember[]> => {
        return this.handleGet<IOrganizationMember[]>(`/orgs/${organizationId}/members${includeDisabled && '/disabled'}`);
    };

    getIrpApproverOrganizations = (): Observable<IOrganization[]> => {
        return this.handleGet<IOrganization[]>('/orgs/irpApprovers');
    };

    getAnnotatorOrganizations = (): Observable<IOrganization[]> => {
        return this.handleGet<IOrganization[]>('/orgs/annotators');
    };

    getQaReviewerOrganizations = (): Observable<IOrganization[]> => {
        return this.handleGet<IOrganization[]>('/orgs/qaReviewers');
    };

    addOrganization = (organization: IOrganization, isAmr: boolean): Observable<IOrganization> => {
        return this.handlePost(`/orgs/${isAmr}`, organization);
    };

    updateOrganization = (organization: IOrganization, isAmr: boolean): Observable<IOrganization> => {
        return this.handlePut(`/orgs/${isAmr}`, organization);
    };

    acceptOrganizationInvite = (organizationId: string): Observable<IAcceptInviteResponse> => {
        return this.handlePost<IAcceptInviteResponse>(`/orgs/${organizationId}/invitations/accept`);
    };

    inviteOrganizationMember = (
        organizationId: string,
        invite: IOrganizationInvitation,
        isAmr: boolean
    ): Observable<IOrganizationInvitation> => {
        return this.handlePost<IOrganizationInvitation>(`/orgs/${organizationId}/invitations/${isAmr}`, invite);
    };

    uninviteOrganizationMember = (organizationId: string, memberEmail: string): Observable<void> => {
        return this.handleDelete(`/orgs/${organizationId}/invitations?email=${encodeURIComponent(memberEmail)}`);
    };

    getOrganizationInvites = (organizationId: string): Observable<IOrganizationInvitation[]> => {
        return this.handleGet<IOrganizationInvitation[]>(`/orgs/${organizationId}/invitations/`);
    };

    setOrganizationMemberRights = (
        organizationId: string,
        memberUserId: string,
        memberEmail: string,
        memberRights: IOrganizationRoles
    ): Observable<IOrganizationMember> => {
        return this.handlePost<IOrganizationMember>(
            `/orgs/${organizationId}/members/${memberUserId}?email=${encodeURIComponent(memberEmail)}`,
            memberRights
        );
    };

    removeOrganizationMember = (organizationId: string, memberUserId: string): Observable<void> => {
        return this.handleDelete(`/orgs/${organizationId}/members/${memberUserId}`);
    };

    getDraftBulkUploadId = (organizationId: string, isAmr: boolean): Observable<string> => {
        return this.handlePost<string>(`/orgs/${organizationId}/uploadPackage/${isAmr}`, organizationId);
    };

    getOrganizationChannels = (dataRequestId: string): Observable<IOrganizationChannel[]> => {
        return this.handleGet<IOrganizationChannel[]>(`/dataRequests/${dataRequestId}/myorgchannels`);
    };

    joinMember = (): Observable<string[]> => {
        return this.handleGet<string[]>('/orgs/joinMember');
    };
}
