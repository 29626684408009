import React, { CSSProperties, ReactNode } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';

const containerStyle: CSSProperties = {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '10px 0',
};

const paragraphStyle: CSSProperties = {
    lineHeight: 1.2,
    wordBreak: 'break-word',
    margin: 0,
    marginBottom: '3px',
};

interface GridCellDateTimeComponentProps extends ICellRendererParams {}

//note: For use with metrics dates specified as: m/d/yyyy h:mm:ss
// - could be extended to handle other date formats
export const GridCellDateTimeComponent = ({ value }: GridCellDateTimeComponentProps): ReactNode => {
    //note this is going to show LOCAL time for the USER
    const formattedDate = value && moment(value).local().format('M/D/YYYY h:mm:ss a');

    return (
        <div style={containerStyle} className="scrolly">
            {formattedDate
                ?.toString()
                ?.split('\n')
                ?.map((paragraph, i) => (
                    <p key={i} style={paragraphStyle}>
                        {paragraph}
                    </p>
                ))}
        </div>
    );
};
