import { ChatFeature } from './chat.feature';
import React, { useRef } from 'react';
import { IChannelApi, IChatApiMessage, IChatMessage } from '@vivli/features/chat/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { first } from 'rxjs/operators';
import { ChatDataPackageFeature } from '@vivli/shared/features/data-package/feature';

interface ChannelDisplayComponentProps {
    channelId: string;
    isActive: boolean;
    onMessageReceived: (message: IChatApiMessage) => any;
}

export const ChannelDisplayFeature = ({ channelId, isActive, onMessageReceived }: ChannelDisplayComponentProps) => {
    const channelRef = useRef<IChannelApi>();
    const activeUser = useActiveUser();

    const handleFileMessage = (text: string) => {
        if (!channelRef.current) {
            return;
        }

        const message: IChatMessage = {
            text,
            userId: activeUser.userId,
            sent: new Date().toString(),
            systemMessage: true,
        };

        channelRef.current.send(message, true).pipe(first()).subscribe();
    };

    return (
        <div
            key={channelId}
            style={{
                display: 'flex',
                width: '100%',
                visibility: isActive ? 'visible' : 'hidden',
                justifyContent: 'space-between',
                position: 'absolute',
                zIndex: isActive ? 1 : 0,
                height: isActive ? 'initial' : 0,
            }}
        >
            <div style={{ width: '60%' }}>
                <ChatFeature
                    channelId={isActive ? channelId : null}
                    onStart={(channel) => (channelRef.current = channel)}
                    onMessageReceived={onMessageReceived}
                    connectOnFirstSend={false}
                />
            </div>
            <div style={{ width: '40%', marginLeft: '10px' }}>
                <ChatDataPackageFeature
                    chatChannelId={channelId}
                    allowUpload={true}
                    showFileWarning={true}
                    showChatUploadFileWarning={true}
                    onFileAdded={({ name }) => handleFileMessage(`File Uploaded: ${name}`)}
                    onFileRemoved={({ name }) => handleFileMessage(`File Deleted: ${name}`)}
                />
            </div>
        </div>
    );
};
