import React, { ChangeEvent, CSSProperties, forwardRef, useRef } from 'react';
import { Size } from '@vivli/shared/theme';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { BaseInputFieldComponent } from './base-input-field.component';
import mergeRefs from 'react-merge-refs';

interface RadioComponentProps extends IFormField {
    checked?: boolean;
    defaultChecked?: boolean;
    isInherited?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioComponent = forwardRef((props: RadioComponentProps, ref) => {
    const inputRef = useRef<HTMLInputElement>();
    const { checked, defaultChecked, onChange, style, label, isInherited = false, readonly } = props;

    const containerStyle: CSSProperties = {
        padding: Size.PADDING,

        ...style,
    };

    const handleLabelClick = () => {
        if (!inputRef.current) {
            return;
        }

        inputRef.current.click();
    };

    const buildInput = (props) => (
        <input {...props} ref={mergeRefs([inputRef, ref])} type="radio" onChange={onChange} disabled={readonly} />
    );

    // prefer controlled value if it exists, then default
    const inputDisplay = checked !== undefined ? buildInput({ checked }) : buildInput({ defaultChecked });

    const fieldDisplay = (
        <div style={containerStyle}>
            {inputDisplay}
            <label onClick={handleLabelClick}>{label}</label>
        </div>
    );

    if (isInherited) {
        return fieldDisplay;
    }

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.Radio} inputRef={inputRef}>
            {fieldDisplay}
        </BaseInputFieldComponent>
    );
});
