﻿export abstract class DatasetFormatService {
    static sortAndValidateList(stringList: string[]): any {
        if (stringList.length == 0) {
            return '';
        }
        const sorted = stringList.sort();
        return sorted;
    }

    public static getYearsCollectedString(yearsCollected: string[]): string {
        if (yearsCollected.length === 0) {
            return 'N/A';
        }
        const lastIndex = yearsCollected.length - 1;
        const firstYear = yearsCollected[0];
        const lastYear = yearsCollected[lastIndex];
        return `${firstYear} - ${lastYear}`;
    }

    //alphabetizes and formats list for vertical display by a simple
    //(non-list) control
    public static getStringsForVerticalDisplay(stringList: string[]) {
        const sorted = this.sortAndValidateList(stringList);
        if (sorted === '') {
            return sorted;
        }
        const formattedString = sorted
            .map((a) => a + '\n')
            .join()
            .replace(/,/g, '');
        return formattedString;
    }

    //alphabetizes and formats list for comma-delimited display
    //this method will return an array that will display with
    // comma separators and default spacing in a text field
    public static getStringsForCommaDelimitedDisplay(stringList: string[]) {
        const sorted = this.sortAndValidateList(stringList);
        if (sorted === '' || sorted.len === 1) {
            return sorted;
        }
        let formatted = sorted.map((a) => a + ',  ');
        const index = formatted.length - 1;
        let last = formatted[index];
        last = last.replace(/,\s*$/, '');
        formatted.splice(index, 1, last);
        return formatted;
    }

    //to display list items as a delimited string in a text area,
    // with a delimiter you specify between items, use this method
    public static getStringForCommaDelimitedDisplay(stringList: string[], delimiter: string): string {
        let returnStrings;
        returnStrings = this.getStringsForCommaDelimitedDisplay(stringList);
        let returnString = '';
        if (returnStrings && returnStrings.length > 0) {
            returnString = returnStrings.join(delimiter);
        }
        return returnString;
    }
}
