import React, { createContext, useContext } from 'react';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { IUser } from '@vivli/shared/infrastructure/interface';

interface IDatasetContext {
    study: IStudy;
    requestedStudyDownloadableIpdPackageId: string;
    showDownload: boolean;
    user: IUser;
    assignedAppType: AssignedAppTypeEnum;
    isLanding: boolean;
}

export const DatasetContext = createContext<IDatasetContext>(null);

export const useDatasetContext = () => useContext(DatasetContext);
