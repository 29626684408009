﻿import React, { CSSProperties, useState } from 'react';
import { Popover } from 'react-tiny-popover';

export type PopoutTargetRenderer = (isOpen?: boolean) => React.ReactElement<HTMLDivElement>;
export type PopoutPopoutRenderer = (style: React.CSSProperties, onItemSelected: () => void) => React.ReactElement<HTMLDivElement>;

interface PopoutMenuComponentProps {
    isOpen?: boolean;
    setIsOpen?: (isOpen) => void;
    onClickOutside?: (event?: any) => void;
    style?: React.CSSProperties;
    popoutRenderer: PopoutPopoutRenderer;
    targetRenderer: PopoutTargetRenderer;
    disableReposition?: boolean;
}

export const PopoutMenuComponent = ({
    isOpen,
    setIsOpen,
    onClickOutside,
    style,
    popoutRenderer,
    targetRenderer,
    disableReposition,
}: PopoutMenuComponentProps) => {
    const [isOpening, setIsOpening] = useState(false);

    const innerDivStyle: CSSProperties = {
        position: 'relative',
        cursor: 'pointer',
        ...style,
    };

    const onItemSelected = () => {
        setIsOpen(false);
    };

    const handleClickOutside = () => {
        if (!isOpening) {
            onClickOutside && onClickOutside();
        } else {
            setIsOpen(true);
            setIsOpening(false);
        }
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
        if (isOpen) {
            setIsOpening(false);
        } else {
            setIsOpening(true);
        }
    };

    return (
        <Popover
            isOpen={isOpen}
            containerStyle={{
                zIndex: '3',
                boxShadow: style?.boxShadow ? style?.boxShadow : null,
            }}
            reposition={disableReposition}
            content={({ childRect: { width }, popoverRect: { height }, nudgedTop }) =>
                popoutRenderer(
                    {
                        height: nudgedTop !== 0 ? height + nudgedTop : 'inherit',
                        minWidth: width,
                        overflowY: nudgedTop !== 0 ? 'scroll' : 'inherit',
                    },
                    onItemSelected
                ) || <div />
            }
            positions={['bottom', 'top']}
            align={'start'}
            padding={0}
            onClickOutside={handleClickOutside}
        >
            <div style={innerDivStyle}>
                <div style={{ width: '100%', height: '100%' }} onClick={handleClick}>
                    {targetRenderer(isOpen)}
                </div>
            </div>
        </Popover>
    );
};
