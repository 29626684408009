import React from 'react';
import { IDropdownMenuItem, IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseControlledFormFieldComponent } from './base-controlled-form-field.component';
import { DropdownFieldComponent } from '../input/dropdown-field.component';

interface DropdownFormFieldComponentProps extends IFormField {
    selectText?: string;
    items: IDropdownMenuItem[];
    name: string;
    objectKey?: string;
    valueKey?: string;
    widerHint?: boolean;
}

export const DropdownFormFieldComponent = (props: DropdownFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <DropdownFieldComponent {...props} />
        </BaseControlledFormFieldComponent>
    );
};
