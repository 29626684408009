﻿import React, { ReactNode, useEffect, useState } from 'react';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useRequestedStudyPermissions } from '@vivli/features/studies/infrastructure/hook';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { first } from 'rxjs/operators';
import { DatasetContext } from '@vivli/features/datasets/infrastructure/context';

interface DatasetContextWrapperProps {
    children: ReactNode;
    study: IStudy;
    requestedStudyDownloadableIpdPackageId: string;
    isLanding: boolean;
    dataRequestId?: string;
}

export const DatasetContextWrapper = ({
    children,
    study,
    requestedStudyDownloadableIpdPackageId,
    isLanding,
    dataRequestId,
}: DatasetContextWrapperProps) => {
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const dataRequestsService = useDataRequestsService();
    const modal = useModalService();
    const assignedAppType = useAssignedAppType();
    const user = useActiveUser();
    const { userCanDownloadIpd } = useRequestedStudyPermissions();

    const getRequestedStudy = (dataRequest: IDataRequest) => {
        return dataRequest?.requestedStudies?.find((rs) => rs.studyId === study.id);
    };

    const handleError = (error: string) => {
        modal.error(error);
    };

    const provider = {
        study: study,
        requestedStudyDownloadableIpdPackageId: requestedStudyDownloadableIpdPackageId,
        showDownload: showDownload,
        user: user,
        assignedAppType: assignedAppType,
        isLanding: isLanding,
    };

    useEffect(() => {
        if (requestedStudyDownloadableIpdPackageId && dataRequestId && study) {
            dataRequestsService
                .getDataRequest(dataRequestId)
                .pipe(first())
                .subscribe((dr) => {
                    const requestedStudy = getRequestedStudy(dr);
                    const downloadable = userCanDownloadIpd(requestedStudy, dr);
                    setShowDownload(downloadable);
                }, handleError);
        }
    }, [requestedStudyDownloadableIpdPackageId, dataRequestId, study]);

    return <DatasetContext.Provider value={provider}>{children}</DatasetContext.Provider>;
};
