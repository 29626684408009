export function useQueryParamsHook(): any {
    const params = new URLSearchParams(window.location.search);

    const result = {};

    params.forEach((value, key) => {
        result[key] = value;
    });

    return result;
}
