import React, { CSSProperties } from 'react';
import { ButtonComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { IRequestedStudy } from '@vivli/features/data-requests/infrastructure/interface';
import { useAdminConsoleContext } from '@vivli/features/admin-console/infrastructure/context';

const gridButtonStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
};
const marginRightBrnStyle = {
    marginRight: 10,
    marginBottom: 10,
};
export const RequestedStudyStatusButtonsRendererComponent = ({ data }: { data: IRequestedStudy }) => {
    const context = useAdminConsoleContext();
    const btnClick = (study: IRequestedStudy, action: string) => {
        context.handleAction(action, study.requestId, study.studyId, action == 'CopyRequestedFilesFromStudy');
    };

    return (
        <div>
            {context.isLoading && <LoadIndicatorComponent />}
            {!context.isLoading && (
                <div style={gridButtonStyle}>
                    <ButtonComponent onClick={() => btnClick(data, 'ResetRequestedStudyToNew')} style={marginRightBrnStyle}>
                        Reset Status to New
                    </ButtonComponent>
                    <ButtonComponent onClick={() => btnClick(data, 'ResetRequestedStudyToIPDProvided')} style={marginRightBrnStyle}>
                        Reset Status to IPDProvided
                    </ButtonComponent>
                    <ButtonComponent onClick={() => btnClick(data, 'ResetRequestedStudyLinkToIPD')} style={marginRightBrnStyle}>
                        Reset link to study latest IPD
                    </ButtonComponent>
                    <ButtonComponent onClick={() => btnClick(data, 'RemoveRequestedStudyFiles')} style={marginRightBrnStyle}>
                        Remove Requested Study Files
                    </ButtonComponent>
                    <ButtonComponent onClick={() => btnClick(data, 'CopyRequestedFilesFromStudy')} style={marginRightBrnStyle}>
                        Copy Requested Files from Study
                    </ButtonComponent>
                </div>
            )}
        </div>
    );
};
