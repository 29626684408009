﻿import React, { CSSProperties } from 'react';
import { AdminHeaderButtonComponent, innerFormContainerStyle, rightHeaderContainerStyle, submitBtnStyle } from '@vivli/shared/components';
import { useAdminStudyPermissions, useAdminStudyStatusChange } from '@vivli/features/studies/infrastructure/hook';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { Size } from '@vivli/shared/theme';
import { StudyApproveRejectButtonComponent } from '../study-approve-reject-button.component';
import { useFormContext } from 'react-hook-form';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const actionButtonStyle: CSSProperties = {
    ...submitBtnStyle,
    backgroundImage: 'none',
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    marginRight: Size.PADDING,
};

export const AdminStudyHeaderContainerComponent = () => {
    const {
        study,
        studyIpdPackageSubmitted,
        isSavingStudy,
        handleFormSave,
        handleSetupForNewIpdVersion,
        handleCreateDoiForIpdPackage,
        handleDeleteIpdPackage,
        handleApproveStudy,
        handleRejectStudy,
        handleRemovePosting,
    } = useStudyContext();
    const formApi = useFormContext<IStudy>();
    const adminStudyRules = useAdminStudyStatusChange(study);
    const permissions = useAdminStudyPermissions(study, studyIpdPackageSubmitted);
    const approval = adminStudyRules.getApprovalMessage();
    const rejection = adminStudyRules.getRejectionMessage();

    return (
        <div style={innerFormContainerStyle}>
            <div style={rightHeaderContainerStyle}>
                {permissions.userIsVivliAdmin() && studyIpdPackageSubmitted && (
                    <AdminHeaderButtonComponent
                        title={'Remove Stored Data Package'}
                        onClick={() => handleDeleteIpdPackage()}
                        isLoading={isSavingStudy}
                        style={actionButtonStyle}
                        dataId={DTICommonConst.RemoveButton}
                    />
                )}
                {permissions.userIsOrgDataProvider() && studyIpdPackageSubmitted && (
                    <AdminHeaderButtonComponent
                        title={'Create DOI For New Data Version'}
                        onClick={() => handleCreateDoiForIpdPackage()}
                        isLoading={isSavingStudy}
                        style={actionButtonStyle}
                        dataId={DTICommonConst.AddButton}
                    />
                )}
                {studyIpdPackageSubmitted && (
                    <AdminHeaderButtonComponent
                        title={'Prepare For New Data Version'}
                        onClick={() => handleSetupForNewIpdVersion()}
                        isLoading={isSavingStudy}
                        style={actionButtonStyle}
                        dataId={DTICommonConst.HeaderApprovalButton('PrepareNewData')}
                    />
                )}
                {permissions.userCanRemovePosting() && (
                    <AdminHeaderButtonComponent
                        title={'Remove Posting'}
                        onClick={() => handleRemovePosting()}
                        isLoading={isSavingStudy}
                        isDisabled={false}
                        style={actionButtonStyle}
                        dataId={DTICommonConst.CancelButton}
                    />
                )}
                {
                    <StudyApproveRejectButtonComponent
                        onRejectStudyClick={() => handleRejectStudy()}
                        onApproveStudyClick={() => handleApproveStudy()}
                        approveLabel={approval}
                        rejectLabel={rejection}
                        showApprove={approval !== null}
                        showReject={rejection !== null}
                        isLoading={isSavingStudy}
                        isDisabled={formApi.formState.isDirty || !formApi.formState.isValid} //approve only uses this
                    />
                }
                {permissions.userCanSave() && (
                    <AdminHeaderButtonComponent
                        title={'Save'}
                        onClick={() => handleFormSave(formApi)}
                        isLoading={isSavingStudy}
                        isDisabled={!formApi.formState.isDirty || !formApi.formState.isValid}
                        style={actionButtonStyle}
                        dataId={DTICommonConst.SaveButton}
                    />
                )}
            </div>
        </div>
    );
};
