import React from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useNavigate } from 'react-router-dom';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

export const EditDataRequestButtonComponent = () => {
    const navigate = useNavigate();
    const { dataRequest, isUpdatingStatus, setIsAdminEditing } = useDataRequestContext();
    const modalService = useModalService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const activeUser = useActiveUser();

    const handleEditClick = () => {
        const linkTab = !isAmr ? 'researchProposal' : activeUser.isVivliAdmin ? 'requestprintview' : 'request';
        modalService.confirm('Proceeding will take you to the edit form for this data request. Proceed?', {
            title: 'Confirm Override?',
            onConfirm: () => {
                navigate(`/myDataRequestDetails/Draft/${dataRequest.id}/${linkTab}`, {
                    state: { detail: activeUser.isVivliAdmin },
                });
            },
        });
    };

    return (
        <ButtonComponent
            dataId={DTICommonConst.EditButton}
            style={{ marginLeft: 10 }}
            onClick={handleEditClick}
            disabled={isUpdatingStatus}
        >
            Edit Data Request
        </ButtonComponent>
    );
};
