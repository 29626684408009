export function useCsvExportHook() {
    const exportRows = (filename: string, rows: any[], headers?: string[]) => {
        if (!rows || !rows.length) {
            return;
        }

        const separator = ',';
        const keys = Object.keys(rows[0]);
        const csvContent =
            (headers ? headers : keys).join(separator) +
            '\n' +
            rows
                .map((row) => {
                    return keys
                        .map((k) => {
                            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                            cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');
                            if (cell.search(/("|,|\n)/g) >= 0) {
                                cell = `"${cell}"`;
                            }
                            return cell;
                        })
                        .join(separator);
                })
                .join('\n');

        saveStringToFile(csvContent, filename);
    };

    const saveStringToFile = (stringContent: string, fileName: string) => {
        const blob = new Blob([stringContent], { type: 'text/csv;charset=utf-8;' });

        // handle typescript issue with not understanding window.navigator still exists
        const navigator = window.navigator as any;
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const url = URL.createObjectURL(blob);
            downloadFile(url, fileName);
        }
    };

    return {
        exportRows,
        saveStringToFile,
    };
}

export function downloadFile(url: string, fileName: string) {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        //link is created and inserted into the DOM
        //to avoid flickering happening if we just use window.open...
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
