﻿import React, { CSSProperties } from 'react';
import { CheckboxComponent, GridComponent } from '@vivli/shared/components';
import { IOrganization, IOrganizationMember } from '@vivli/features/organizations/infrastructure/interface';
import { ColDef } from 'ag-grid-community';
import { NameRowRendererComponent } from './name-row-renderer.component';
import { OrgMemberRowRenderComponent } from './org-member-row-render.component';
import { OrgMemberCheckBoxRendererComponent } from './org-member-checkBox-renderer.component';

interface OrgMembersGridComponentProps {
    currentOrgMembers?: IOrganizationMember[];
    organization?: IOrganization;
}

const checkboxStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
};
export const OrgMembersGridComponent = ({ currentOrgMembers, organization }: OrgMembersGridComponentProps) => {
    const columnDefs: ColDef[] = [
        {
            field: 'displayName',
            headerName: 'Name',
            sortable: true,
            filter: false,
            initialSort: 'asc',
            cellRenderer: 'NameRow',
            flex: 2,
        },
        {
            field: 'isOrgAdmin',
            headerName: 'Org Admin',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        },
    ];

    if (organization.isAnnotator) {
        columnDefs.push({
            field: 'isAnnotator',
            headerName: 'Curator',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    if (organization.isQaReviewer) {
        columnDefs.push({
            field: 'isQaReviewer',
            headerName: 'QA Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    if (organization.isDataProvider) {
        columnDefs.push({
            field: 'isDataProvider',
            headerName: 'Data Contributor',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    if (organization.isIRPApprover) {
        columnDefs.push({
            field: 'isIRPApprover',
            headerName: 'IRP / Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    if (organization.isAnnotator) {
        columnDefs.push({
            field: 'isHead',
            headerName: 'Head Curator',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    if (organization.isQaReviewer) {
        columnDefs.push({
            field: 'isHead',
            headerName: 'Head QA Reviewer',
            sortable: false,
            filter: false,
            cellRenderer: 'OrgMemberRow',
            cellRendererParams: {
                orgId: organization.id,
            },
            flex: 1,
        });
    }
    columnDefs.push({
        field: 'isEnabled',
        headerName: 'Org Membership',
        sortable: false,
        filter: false,
        cellRenderer: 'OrgMemberRow',
        cellRendererParams: {
            orgId: organization.id,
        },
        flex: 1,
    });
    columnDefs.push({
        field: 'disabled',
        headerName: 'Enabled',
        sortable: false,
        filter: false,
        cellStyle: checkboxStyle,
        cellRenderer: 'Enabled',
        cellRendererParams: {
            orgId: organization.id,
        },
        flex: 1,
    });
    columnDefs.push({
        field: 'notifyAsOrgAdmin',
        headerName: 'Email Notification',
        sortable: false,
        filter: false,
        cellStyle: checkboxStyle,
        cellRenderer: 'Email Notification',
        cellRendererParams: {
            orgId: organization.id,
        },
        flex: 1,
    });

    const CheckBox = ({ data }) => {
        return <CheckboxComponent readonly={true} checked={!data.disabled} />;
    };

    const customComponents = [
        {
            name: 'NameRow',
            component: NameRowRendererComponent,
        },
        {
            name: 'OrgMemberRow',
            component: OrgMemberRowRenderComponent,
        },
        {
            name: 'Enabled',
            component: CheckBox,
        },
        {
            name: 'Email Notification',
            component: OrgMemberCheckBoxRendererComponent,
        },
    ];
    return (
        <GridComponent
            rowData={currentOrgMembers}
            columns={columnDefs}
            autoHeight={true}
            hideFloatingFilter={true}
            rowHeight={50}
            customComponents={customComponents}
        />
    );
};
