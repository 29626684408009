﻿import { ICitation, IResearcherIdentifier } from '@vivli/shared/features/citations/infrastructure/interface';
import { Size, Styles } from '@vivli/shared/theme';
import { DeleteButtonComponent, TextFieldComponent } from '@vivli/shared/components';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DTIFormFields } from '@vivli/shared/infrastructure/constants';

const genericStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    marginBottom: Size.FIELD_SEPARATION,
};

const twoAcrossStyleNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 50%',
    maxWidth: '49%',
};

const threeAcrossStyleNoBox: CSSProperties = {
    ...genericStyle,
    flex: '1 1 33%',
    maxWidth: '32%',
};

const threeAcrossStyleNoBoxLower: CSSProperties = {
    ...threeAcrossStyleNoBox,
    marginTop: Size.FIELD_SEPARATION * 2,
};

interface CitationAuthorsComponentProps {
    author: IResearcherIdentifier;
    citation: ICitation;
    index: number;
    onRemoveAuthor: (index: number) => void;
}

export const CitationAuthorsComponent = ({ author, citation, index, onRemoveAuthor }: CitationAuthorsComponentProps) => {
    const modal = useModalService();
    const [newAuthor, setNewAuthor] = useState<IResearcherIdentifier>(author);
    const [newCitation, setNewCitation] = useState<ICitation>(citation);
    const isDoi = citation.doi !== null && citation.doi !== undefined && citation.doi !== '';
    const styleToUse = isDoi ? twoAcrossStyleNoBox : threeAcrossStyleNoBox;

    const handleNewGivenName = (val) => {
        setNewAuthor({ ...newAuthor, givenName: val });
        newCitation.authors[index].givenName = val;
        setNewCitation(newCitation);
    };

    const handleNewFamilyName = (val) => {
        setNewAuthor({ ...newAuthor, familyName: val });
        newCitation.authors[index].familyName = val;
        setNewCitation(newCitation);
    };

    const handleOnRemoveAuthor = (index) => {
        onRemoveAuthor(index);
    };

    const handleRemoveButtonClick = (e) => {
        e.stopPropagation();
        const message = `Are you sure you want to remove the author ${newCitation.authors[index].givenName} ${newCitation.authors[index].familyName} from the citation?`;
        const confirmModal = modal.confirm(message, {
            onConfirm: () => {
                modal.dismiss(confirmModal);
                handleOnRemoveAuthor(index);
            },
            onCancel: () => {},
        });
    };

    useEffect(() => {
        setNewAuthor({ ...newAuthor, familyName: '', givenName: '' });
    }, [author]);

    return (
        <div style={Styles.FORM_ROW}>
            <TextFieldComponent
                value={author.givenName}
                name="givenName"
                style={styleToUse}
                label={'Author Given Name'}
                onChange={(e) => handleNewGivenName(e.target.value)}
                dataId={DTIFormFields.AuthorsName('Given')}
            />

            <TextFieldComponent
                value={author.familyName}
                name="familyName"
                style={styleToUse}
                label={'Author Family Name'}
                onChange={(e) => handleNewFamilyName(e.target.value)}
                dataId={DTIFormFields.AuthorsName('Family')}
            />
            {!isDoi && <DeleteButtonComponent style={threeAcrossStyleNoBoxLower} onClick={(e) => handleRemoveButtonClick(e)} />}
        </div>
    );
};
