﻿import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { first } from 'rxjs/operators';
import {
    IDataRequest,
    IDuaDetails,
    IDuaDocuments,
    IResearchTeam,
    IVirtualMachineAccessInvitation,
} from '@vivli/features/data-requests/infrastructure/interface';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useTranslation } from 'react-i18next';
import { IVirtualMachine } from '@vivli/features/virtual-machine/infrastructure/interface';
import { DataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { useVirtualMachineService } from '@vivli/features/virtual-machine/infrastructure/context';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { DataRequestStatusEnum, RequestedStudyTypeEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { UseFormReturn } from 'react-hook-form';
import { AssignedAppTypeEnum, TranslationKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useNavigate, useParams } from 'react-router-dom';
import { useInitializeResearchTeamHook } from '@vivli/features/data-requests/infrastructure/hook';
import { findDuplicates } from '@vivli/shared/infrastructure/hook';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { IStatusUpdate } from '@vivli/shared/infrastructure/interface';

interface DataRequestContextWrapperProps {
    children: ReactNode;
    dataRequest: IDataRequest;
}

export const DataRequestContextWrapper = ({ children, dataRequest }: DataRequestContextWrapperProps) => {
    const { organizations } = useOrganizations();
    const { requestStatus } = useParams();
    const navigate = useNavigate();
    const user = useActiveUser();
    const { t } = useTranslation();
    const { transformDataRequest } = useInitializeResearchTeamHook();

    const [dataRequestState, setDataRequest] = useState<IDataRequest>(dataRequest);
    const [showApprovalButtons, setShowApprovalButtons] = useState<boolean>(false);
    const [virtualMachine, setVirtualMachine] = useState<IVirtualMachine>();
    const [showResearchResults, setShowResearchResults] = useState(false);
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
    const [isSavingDataRequest, setIsSavingDataRequest] = useState(false);
    const [isSubmittingDataRequest, setIsSubmittingDataRequest] = useState(false);
    const [statusUpdateSignal, setStatusUpdateSignal] = useState(false);
    const [isAdminEditing, setIsAdminEditing] = useState(false);
    const [planningTableData, setPlanningTableData] = useState([]);
    const [duaDetails, setDuaDetails] = useState<IDuaDetails>();
    const [duaDocuments, setDuaDocuments] = useState<IDuaDocuments[]>([]);
    const [statusUpdateState, setStatusUpdateState] = useState<IStatusUpdate>();

    const appType = useAssignedAppType();
    const isAmr = appType === AssignedAppTypeEnum.Amr;

    const ownsDataRequest = () => {
        return dataRequest.userId === user.userId;
    };

    const dataRequestsService = useDataRequestsService();
    const virtualMachineService = useVirtualMachineService();
    const modalService = useModalService();
    const toastService = useToastService();
    const isSavingRef = useRef(false);

    const updateShowResearchResults = () => {
        const showResearchResultsState =
            (requestStatus === 'InProgress' ||
                dataRequestState?.status === DataRequestStatusEnum.PartiallyFulfilled ||
                dataRequestState?.status === DataRequestStatusEnum.Fulfilled ||
                requestStatus === 'Fulfilled' ||
                requestStatus === 'Active') &&
            !!virtualMachine;

        setShowResearchResults(showResearchResultsState);
    };

    const updateShowApprovalButtons = () => {
        if (!dataRequestState) {
            return;
        }

        const isVivliAdmin = user.isVivliAdmin;
        const isIrpApprover =
            user.orgMemberships.find(
                (om) => om.isIRPApprover && om.isOrgAdmin && dataRequest.requestedStudies.some((a) => a.approvalBodyOrgId == om.orgId)
            ) !== undefined;
        const isDataProvider =
            user.orgMemberships.find(
                (om) => om.isDataProvider && om.isOrgAdmin && dataRequest.requestedStudies.some((a) => a.organizationId == om.orgId)
            ) !== undefined;

        switch (dataRequestState.status) {
            case DataRequestStatusEnum.AwaitingIRPApproval:
            case DataRequestStatusEnum.RejectedByIRP:
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
                setShowApprovalButtons(isIrpApprover);
                break;
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
                setShowApprovalButtons(isDataProvider);
                break;
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.RejectedByVivli:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAReturnedToRequester:
            case DataRequestStatusEnum.Approved:
                setShowApprovalButtons(isVivliAdmin);
                break;
            default:
                setShowApprovalButtons(false);
                break;
        }
    };

    //Methods for data-request form components

    const updateIsSaving = (status: boolean) => {
        isSavingRef.current = status;
        setIsSavingDataRequest(status);
    };

    const handleError = (error: string) => {
        const cleanError = error.replace(/\\r|\\n|"/g, ' ').trim();
        modalService.error(cleanError);
        updateIsSaving(false);
        setIsSubmittingDataRequest(false);
    };

    const handleDataRequestFormUpdate = (formApi: UseFormReturn<IDataRequest, object>, updatedDataRequest: IDataRequest) => {
        updateIsSaving(false);
        formApi.reset(updatedDataRequest, { keepErrors: true, keepIsValid: true });
        setDataRequest(updatedDataRequest);
    };

    const handleDataPackageCreation = (formApi: UseFormReturn<IDataRequest, object>) => {
        if (!dataRequest.metaDataDataPackageId) {
            dataRequestsService
                .createDataPackageForRequest(dataRequest.id)
                .pipe(first())
                .subscribe((updatedDataRequest) => {
                    handleDataRequestFormUpdate(formApi, {
                        ...updatedDataRequest,
                        researchTeam: dataRequest.researchTeam, // data package creation nulls out research team
                        researchProposal: dataRequest.researchProposal, // data package creation nulls out research team
                    });
                });
        }
    };

    const submitDataRequest = (updatedDataRequest: IDataRequest) => {
        setIsSubmittingDataRequest(true);
        dataRequestsService
            .submitDataRequest(updatedDataRequest)
            .pipe(first())
            .subscribe(() => {
                navigate('/myDataRequests/active');
            }, handleError);
    };

    const handleConfirmStep = (formApi: UseFormReturn<IDataRequest, object>, updatedDataRequest: IDataRequest) => {
        if (updatedDataRequest.requestedStudies.some((a) => a.requestedStudyType === RequestedStudyTypeEnum.External)) {
            modalService.confirm(t(TranslationKeyEnum.pathway2SubmitConfirmText), {
                title: 'Confirm Data Request is Ready to Submit',
                onConfirm: () => {
                    submitDataRequest(updatedDataRequest);
                },
                onCancel: () => {
                    handleDataRequestFormUpdate(formApi, updatedDataRequest);
                },
            });
        } else {
            submitDataRequest(updatedDataRequest);
        }
    };

    const handleDataRequestSaved = (
        formApi: UseFormReturn<IDataRequest, object>,
        updatedDataRequest: IDataRequest,
        submitRequest: boolean
    ) => {
        toastService.success(`${updatedDataRequest.requestTitle} successfully saved`);

        if (submitRequest) {
            handleConfirmStep(formApi, updatedDataRequest);
        } else {
            handleDataRequestFormUpdate(formApi, updatedDataRequest);
        }
    };

    const getInvitations = (invitationsDataRequest: IDataRequest): IVirtualMachineAccessInvitation[] => {
        // grab only the people we need to invite
        // do not send invites to anyone that already has been invited or anyone who already has access
        return invitationsDataRequest.researchTeam.researchTeamDetails
            .filter((x) => x.inviteToVirtualMachine && !x.hasBeenInvitedToVMAccess && !x.hasAccessToVirtualMachineResource)
            .map((tm) => ({
                dataRequestId: invitationsDataRequest.id,
                email: tm.email,
                InvitedById: user.userId,
                teamMemberId: tm.teamMemberId,
            }));
    };

    const handleInvites = (
        formApi: UseFormReturn<IDataRequest, object>,
        updatedDataRequest: IDataRequest,
        invitationsDataRequest: IDataRequest,
        submitRequest: boolean
    ) => {
        // grab only the people we need to invite
        // do not send invites to anyone that already has been invited or anyone who already has access
        const invitations = getInvitations(invitationsDataRequest);

        if (invitations?.length > 0) {
            dataRequestsService
                .bulkInviteTeamMembers(updatedDataRequest.id, invitations)
                .pipe(first())
                .subscribe(
                    (resp) => {
                        handleDataRequestSaved(formApi, resp.dataRequest, submitRequest);
                    },
                    (error) => {
                        modalService.error(error);

                        if (invitations.length > 1) {
                            // pull down the latest data request instance in this case
                            // the data request could have been modified prior to the fail since bulk invite can potentially
                            // have multiple invites / data request updates, and on error it does not return the latest DR
                            dataRequestsService
                                .getDataRequest(updatedDataRequest.id)
                                .pipe(first())
                                .subscribe((resultDataRequest) => handleDataRequestSaved(formApi, resultDataRequest, submitRequest));
                        } else {
                            handleDataRequestSaved(formApi, updatedDataRequest, submitRequest);
                        }
                    }
                );
        } else {
            handleDataRequestSaved(formApi, updatedDataRequest, submitRequest);
        }
    };

    const handleFormSave = (formApi: UseFormReturn<IDataRequest, object>, submitRequest = false) => {
        // already in the process of a form save, don't do any additional saves (yet)
        // also ignore if no fields have changed
        if (isSavingRef.current) {
            return;
        }

        formApi.handleSubmit(
            (formValues) => handleFormApiSave(formApi, formValues, submitRequest),
            () => {
                const formValues = formApi.getValues();
                handleFormApiSave(formApi, formValues, submitRequest);
            }
        )();
    };

    function checkResearchTeamForDuplicates(researchTeam: IResearchTeam) {
        const teamMembers = researchTeam.researchTeamDetails;
        const teamMemberEmails = teamMembers.map((tm) => tm.email.toLowerCase());
        const dupEmails = findDuplicates(teamMemberEmails);
        const teamMemberOrcidIds = teamMembers.filter((tm) => tm.orcidId).map((tm) => tm.orcidId.toLowerCase());
        const dupOrcidIds = findDuplicates(teamMemberOrcidIds);
        if (dupEmails.length > 0 || dupOrcidIds.length > 0) {
            let message =
                'Each user email address or ORCID iD can appear in the research team only once - please delete any duplicate users.  If a user will serve as both the Lead Researcher and Statistician, use the "Lead Researcher is also Statistician Researcher" checkbox to indicate this.' +
                `The following team members have duplicate emails or ORCID iDs: ${dupEmails.join(',')} ${dupOrcidIds.join(',')}`;
            modalService.error(message);
            return false;
        }
        return true;
    }

    const handleFormApiSave = (formApi: UseFormReturn<IDataRequest, object>, formValues: IDataRequest, submitRequest: boolean) => {
        let updatedDataRequest: IDataRequest = { ...formValues };
        const isDirty = Object.keys(formApi.formState.dirtyFields)?.length > 0;

        if (isDirty) {
            // only do another request update if needed (aka data has changed)
            updateIsSaving(true);
            //check Research team for duplicates
            if (submitRequest && !isAmr && !checkResearchTeamForDuplicates(updatedDataRequest.researchTeam)) {
                //save, but not submit
                handleFormApiSave(formApi, formValues, false);
                return;
            }

            // null out the additional properties that are not being used
            // they cause json parsing issues when sent to the server for the lead and statistician
            updatedDataRequest = {
                ...updatedDataRequest,
                researchTeam: {
                    ...updatedDataRequest.researchTeam,
                    leadResearcherTeamMemberDetail: null,
                    statisticianTeamMemberDetail: null,
                },
            };

            dataRequestsService
                .updateDataRequest(updatedDataRequest)
                .pipe(first())
                .subscribe((resultDataRequest) => {
                    handleInvites(formApi, resultDataRequest, updatedDataRequest, submitRequest);
                }, handleError);
        } else if (submitRequest) {
            handleConfirmStep(formApi, updatedDataRequest);
        }
    };

    const handelDuaSave = () => {
        setIsSavingDataRequest(true);
        const updatedDataRequest = {
            ...dataRequestState,
            duaDetail: {
                ...duaDetails,
                contributorTemplates: planningTableData,
                duaDocuments: duaDocuments,
            },
            statusUpdate: {
                ...statusUpdateState,
            },
        };
        dataRequestsService.updateDataRequest(updatedDataRequest).subscribe(
            (resultDataRequest) => {
                toastService.success(`DUA Details successfully saved`);
                setDataRequest(resultDataRequest);
                setIsSavingDataRequest(false);
            },
            (err) => {
                modalService.error(`An error occurred with your operation. Please try again or contact Vivli support.`);
                setIsSavingDataRequest(false);
            }
        );
    };

    const handleEditTitleClick = (formApi: UseFormReturn<IDataRequest, object>) => {
        modalService.prompt('Enter a new title for your research data request', formApi.getValues('requestTitle'), {
            title: 'Research Data Request Title',
            promptLabel: 'Title',
            cancelText: 'Cancel',
            confirmText: 'Save',
            onConfirm: (newTitle) => {
                let title = formApi.getValues('requestTitle');
                if (newTitle?.comment) {
                    title = newTitle.comment;
                }
                formApi.setValue('requestTitle', title, { shouldDirty: true });
                handleFormSave(formApi);
            },
        });
    };

    const getResetToDraftVisibility = () => {
        switch (dataRequestState.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.RejectedByVivli:
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.AwaitingIRPApproval:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
            case DataRequestStatusEnum.RejectedByIRP:
            case DataRequestStatusEnum.Approved:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.DUAReturnedToRequester:
                if (user.isVivliAdmin) {
                    return true;
                }
                break;
            case DataRequestStatusEnum.Cancelled:
                return true;
            case DataRequestStatusEnum.Fulfilled:
            case DataRequestStatusEnum.PartiallyFulfilled:
                if (!virtualMachine && user.isVivliAdmin) {
                    return true;
                }
                break;
            case DataRequestStatusEnum.Draft:
            case DataRequestStatusEnum.Archived:
            default:
                return false;
        }
    };
    //End section

    const provider = {
        dataRequest: dataRequestState,
        updateDataRequest: (dataRequest) => {
            const updatedDataRequest = transformDataRequest(dataRequest);
            setDataRequest(updatedDataRequest);
        },
        showApprovalButtons,
        setShowApprovalButtons,
        organizations,
        virtualMachine,
        showResearchResults,
        isUpdatingStatus,
        setIsUpdatingStatus,
        handleEditTitleClick,
        isSavingDataRequest,
        isSubmittingDataRequest,
        handleFormSave,
        handleDataPackageCreation,
        getResetToDraftVisibility,
        updateIsSaving,
        isAdminEditing,
        setIsAdminEditing,
        ownsDataRequest,
        statusUpdateSignal,
        setStatusUpdateSignal,
        handelDuaSave,
        planningTableData,
        setPlanningTableData,
        duaDetails,
        setDuaDetails,
        statusUpdateState,
        setStatusUpdateState,
        duaDocuments,
        setDuaDocuments,
    };

    useEffect(() => {
        if (!dataRequestState) {
            return;
        }

        updateShowResearchResults();
        updateShowApprovalButtons();
    }, [dataRequestState]);

    useEffect(() => {
        if (!virtualMachine && dataRequest) {
            virtualMachineService.getDataRequestVm(dataRequest.id).pipe(first()).subscribe(setVirtualMachine, modalService.error);
        }
    }, [dataRequest]);

    useEffect(() => {
        updateShowResearchResults();
    }, [virtualMachine]);

    useEffect(() => {
        if (dataRequestState?.duaDetail?.contributorTemplates) {
            setPlanningTableData(dataRequestState.duaDetail.contributorTemplates);
            setDuaDetails(dataRequestState.duaDetail);
        }
        dataRequestState.duaDetail?.duaDocuments && setDuaDocuments(dataRequestState.duaDetail.duaDocuments);
        dataRequestState.statusUpdate && setStatusUpdateState(dataRequestState.statusUpdate);
    }, [dataRequestState]);

    return <DataRequestContext.Provider value={provider}>{children}</DataRequestContext.Provider>;
};
