import React, { CSSProperties, useEffect, useState } from 'react';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { first } from 'rxjs/operators';

const containerStyle: CSSProperties = { textAlign: 'center' };
const titleStyle: CSSProperties = { justifyContent: 'center' };

export const StudiesProvidedComponent = () => {
    const { dataRequest } = useDataRequestContext();
    const { getNumberOfStudiesProvided } = useDataRequestsService();
    const allStudies = dataRequest?.requestedStudies?.length;

    const [providedStudies, setProvidedStudies] = useState<number>();

    const title = `Data Package available for ${providedStudies} of ${allStudies} studies in the Data Request`;

    useEffect(() => {
        const sub = getNumberOfStudiesProvided(dataRequest.id).pipe(first()).subscribe(setProvidedStudies);

        return () => {
            sub.unsubscribe();
        };
    }, []);

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title={title} style={titleStyle} />
        </div>
    );
};
