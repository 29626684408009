import React, { CSSProperties, useState } from 'react';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { ButtonComponent, FieldHeaderComponent } from '@vivli/shared/components';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useResultRequestService } from '@vivli/features/results-request/infrastructure/context';
import { first } from 'rxjs/operators';
import { DownloadOnlyDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = { marginTop: Size.INSET_SIZE };

const subContainerStyle: CSSProperties = {
    padding: '20px',
    borderStyle: 'solid',
    borderWidth: '1px',
    margin: '20px 0',
};

const headerStyle: CSSProperties = {
    paddingBlock: '10px',
};

const noFilesStyle: CSSProperties = {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
};

const denyButtonStyle: CSSProperties = {
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.RED_X_ICON})`,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderTop: '1px solid',
    borderColor: Color.ERROR_RED,
    boxShadow: 'none',
    color: Color.ERROR_RED,
    marginRight: 10,
    maxHeight: 40,
    paddingLeft: 30,
};

const approveHeaderStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
};

interface ResultRequestApprovalComponentProps {
    resultRequest: IResultRequest;
    dataRequestId: string;
    onResultApproved?: (result: IResultRequest) => void;
    onResultDisapproved?: (result: IResultRequest) => void;
}

export const ResultRequestApprovalComponent = ({
    resultRequest,
    dataRequestId,
    onResultApproved,
    onResultDisapproved,
}: ResultRequestApprovalComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const modalService = useModalService();
    const resultRequestService = useResultRequestService();

    const handleDenyConfirm = (comment?: string) => {
        if (!comment || comment.length <= 0) {
            modalService.error('Rejection Comment is Required');
            return;
        }

        setIsLoading(true);
        resultRequestService
            .disapproveResultRequest(dataRequestId, resultRequest.id, comment)
            .pipe(first())
            .subscribe(
                (result) => {
                    onResultDisapproved && onResultDisapproved(result);
                    modalService.message('Request Denied.', {
                        showContinueButton: true,
                    });
                    setIsLoading(false);
                },
                (error) => {
                    modalService.error(error);
                    setIsLoading(false);
                }
            );
    };

    const handleDenyRequest = () => {
        modalService.confirm('Provide a comment to describe why you are denying this request.', {
            title: 'Deny Request',
            confirmText: 'Ok',
            cancelText: 'Cancel',
            showPrompt: true,
            promptLabel: 'Rejection Comment',
            onConfirm: ({ comment }) => handleDenyConfirm(comment),
        });
    };

    const handleApproveConfirm = () => {
        setIsLoading(true);
        resultRequestService
            .approveResultRequest(dataRequestId, resultRequest.id)
            .pipe(first())
            .subscribe(
                (result) => {
                    onResultApproved && onResultApproved(result);
                    modalService.message('Request Approved.', {
                        showContinueButton: true,
                    });
                },
                modalService.error,
                () => {
                    setIsLoading(false);
                }
            );
    };

    const handleApproveRequest = () => {
        modalService.confirm('Click OK to approve this request. This action cannot be undone.', {
            title: 'Approve Request',
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: handleApproveConfirm,
        });
    };

    return (
        <div style={containerStyle} className={'scrolly'}>
            <div style={subContainerStyle}>
                <div style={approveHeaderStyle}>
                    <span style={headerStyle}>Approve Results Request</span>
                    <div style={buttonContainerStyle}>
                        <ButtonComponent style={denyButtonStyle} onClick={handleDenyRequest} isLoading={isLoading}>
                            Deny Request
                        </ButtonComponent>

                        <ButtonComponent
                            style={Styles.Button.BUTTON_DARKER_GREEN_CHECK}
                            onClick={handleApproveRequest}
                            isLoading={isLoading}
                        >
                            Approve Request
                        </ButtonComponent>
                    </div>
                </div>

                <FieldHeaderComponent title="HEURISTIC RESULTS" />

                <ul style={{ margin: 0 }}>
                    {resultRequest.heuristicResults
                        .filter((r) => r.heuristicResultReason)
                        .map((result, i) => (
                            <li key={resultRequest.id + i} style={{ color: Color.VIVLI_DARK_BLUE }}>
                                {result.heuristicResultReason} - {result.additionalInformation}
                            </li>
                        ))}
                </ul>
            </div>

            <div style={{ color: Color.VIVLI_DARK_BLUE }}>
                Please review the following files to ensure that IPD has not been included. If you are confident that the data does not
                contain IPD from source data sets, approve the request by clicking the correct button above. Otherwise you may reject the
                request.4
            </div>

            {resultRequest.resultsDataPackageId ? (
                <DownloadOnlyDataPackageFeature dataPackageId={resultRequest.resultsDataPackageId} />
            ) : (
                <div style={noFilesStyle}>
                    <FieldHeaderComponent title={'NO FILES IN PACKAGE'} />
                </div>
            )}
        </div>
    );
};
