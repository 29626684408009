﻿import { useEffect, useState } from 'react';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { first } from 'rxjs/operators';

export function useStudy(studyId: string) {
    const [study, setStudy] = useState<IStudy>();

    const studiesService = useStudiesService();

    useEffect(() => {
        if (!studyId) {
            return;
        }

        studiesService.getStudy(studyId).pipe(first()).subscribe(setStudy);
    }, [studyId]);

    return {
        study,
        setStudy,
    };
}
