﻿import React, { CSSProperties } from 'react';

interface AdminStudyDatasetUsedInComponentProps {
    statusMessage?: string;
    dataUploaded?: boolean;
    usedInList?: string;
}

export const AdminStudyDatasetUsedInComponent = ({ statusMessage, dataUploaded, usedInList }: AdminStudyDatasetUsedInComponentProps) => {
    const usedInStyle: CSSProperties = {
        fontWeight: 'bold',
        paddingTop: 4,
    };

    const uploadedText = dataUploaded ? 'YES' : 'NO';
    const noRequestsText = usedInList ? 'See Requests Tab' : 'None';

    return (
        <div>
            <div style={usedInStyle}>
                <div>Current Status: {statusMessage}</div>
                <div>Data Available for Sharing: {uploadedText}</div>
                <div>Requests: {noRequestsText}</div>
            </div>
        </div>
    );
};
