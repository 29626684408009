﻿import { EmptyListPlaceholderComponent, HistoryListComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { useStudy } from '@vivli/features/studies/infrastructure/hook';
import { AuditTrailContextualComponent } from '@vivli/features/admin-console/components';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const placeholderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
};

const auditButtonStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: '60px',
    paddingBottom: '20px',
};

interface StudyDatasetHistoryComponentProps {
    hideReviewers?: false;
    studyId: string;
    historyStyle?: CSSProperties;
    historyItemStyle?: CSSProperties;
}

export const StudyDatasetHistoryComponent = ({
    hideReviewers,
    studyId,
    historyStyle,
    historyItemStyle,
}: StudyDatasetHistoryComponentProps) => {
    const { study } = useStudy(studyId);

    if (!study) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <>
            <div style={auditButtonStyle}>
                <AuditTrailContextualComponent
                    type="Study"
                    id={study.sponsorProtocolId}
                    internalId={study.id}
                ></AuditTrailContextualComponent>
            </div>
            {study.studyHistoryEntries?.length > 0 ? (
                <HistoryListComponent
                    historyEntries={study.studyHistoryEntries}
                    hideReviewers={hideReviewers}
                    historyStyle={historyStyle}
                    historyItemStyle={historyItemStyle}
                />
            ) : (
                <EmptyListPlaceholderComponent
                    optionalStyles={placeholderStyle}
                    imageHeight={'100%'}
                    image={AssetsConstant.TRIALS_ICON_INACTIVE}
                    text={'There is no history for this study.'}
                />
            )}
        </>
    );
};
