import React, { CSSProperties } from 'react';

const tabStyle = (active?: boolean): CSSProperties => ({
    opacity: active ? 1 : 0.5,
    cursor: 'pointer',
    width: 275,
    fontSize: 20,
});

const textStyle: CSSProperties = {
    paddingBottom: '5px',
    borderStyle: 'solid',
    borderWidth: '0px 0px 4px 0px',
};

export const SearchTabComponent = ({ activeTab, setActiveTab, tabIndex, tabText }) => (
    <div style={tabStyle(activeTab === tabIndex)} onClick={() => setActiveTab(tabIndex)}>
        <div style={textStyle}>{tabText}</div>
    </div>
);
