import React, { CSSProperties, useEffect, useRef, useState } from 'react';

const containerStyle: CSSProperties = {
    width: '100%',
};

interface EllipsisTextComponentProps {
    text: string;
    bypassTruncate?: boolean;
    widthPerCharacter?: number;
}

export const EllipsisTextComponent = ({ text = '', bypassTruncate, widthPerCharacter = 10 }: EllipsisTextComponentProps) => {
    const containerRef = useRef<HTMLDivElement>();
    const activeTextRef = useRef<string>(text);
    const [ellipsisText, setEllipsisText] = useState<string>(text);
    const [activeText, setActiveText] = useState<string>(text);
    const updateEllipsisText = () => {
        if (!containerRef.current || !activeTextRef.current) {
            return;
        }
        let ellipsisText = activeTextRef.current;
        const divWidth = containerRef.current.clientWidth;
        const allowedWidth = divWidth - 20;
        const allowedCharacters = Math.floor(allowedWidth / widthPerCharacter);
        const titleCharacters = ellipsisText.length;
        if (titleCharacters > allowedCharacters) {
            ellipsisText = `${ellipsisText.substr(0, allowedCharacters)}...`;
        }
        setEllipsisText(ellipsisText);
    };
    const handleEllipsisTimeoutUpdate = () => {
        setTimeout(() => {
            updateEllipsisText();
        });
    };
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        handleEllipsisTimeoutUpdate();
        window.addEventListener('resize', () => handleEllipsisTimeoutUpdate());
        return () => {
            window.removeEventListener('resize', () => handleEllipsisTimeoutUpdate());
        };
    }, []);
    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        activeTextRef.current = text;
        setActiveText(text);
        handleEllipsisTimeoutUpdate();
    }, [text]);
    return (
        <div style={containerStyle} ref={containerRef} title={activeText}>
            {bypassTruncate ? activeText : ellipsisText}
        </div>
    );
};
