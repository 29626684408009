import { useEffect, useState } from 'react';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { filter, finalize } from 'rxjs/operators';
import { IReportItem, IReportSpec } from '@vivli/shared/infrastructure/interface';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import moment from 'moment';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useQueryParamsHook } from '@vivli/shared/infrastructure/hook';

export function useReportItems() {
    const user = useActiveUser();
    const [reportItems, setReportItems] = useState<IReportItem[]>();
    const [isLoadingReportItems, setIsLoadingReportItems] = useState(false);
    const [reportSpecs, setReportSpecs] = useState<IReportSpec[]>();
    const [vivliAdminReport, setVivliAdminReport] = useState(false);
    const vivliConfig = useVivliConfig();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();
    const queryParms = useQueryParamsHook();

    const handleFailure = (err: string) => {
        modalService.error(`An error occurred. Please try again or contact Vivli support. Message: ${err}`);
        setIsLoadingReportItems(false);
    };
    const refreshItemsForSpecificReport = (
        dataType: string,
        specificReportSpecCode: string,
        dateToUse?: Date,
        contributor?: string,
        queryString?: string
    ) => {
        const actualDateToUse = dateToUse ? prepareDateString(dateToUse) : null;

        setIsLoadingReportItems(true);
        dataRequestsService
            .getReport(dataType, actualDateToUse, specificReportSpecCode, contributor, queryString)
            .pipe(
                filter((report) => report !== null),
                finalize(() => setIsLoadingReportItems(false))
            )
            .subscribe(setNewReportItems, handleFailure);
    };

    const setNewReportItems = (reportItems: IReportItem[]): IReportItem[] => {
        setReportItems([...reportItems]);
        return reportItems;
    };

    const prepareDateString = (dateToPrepare: Date) => {
        const utc = moment.utc(dateToPrepare);
        const utcDate = utc.format('YYYYMMDD');
        return utcDate;
    };

    const getDataRequestUrlForReportItem = (dataRequestReportItem: IReportItem) => {
        let urlStatus = 'None';  // needed for DuaDetail report link to work
        switch (dataRequestReportItem.status) {
            case DataRequestStatusEnum.Draft:
                urlStatus = 'Draft';
                break;
            case DataRequestStatusEnum.Approved:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.DUAReturnedToRequester:
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.PartiallyFulfilled:
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.AwaitingIRPApproval:
                urlStatus = 'Active';
                break;
            case DataRequestStatusEnum.Fulfilled:
                urlStatus = 'Fulfilled';
                break;
            case DataRequestStatusEnum.RejectedByVivli:
            case DataRequestStatusEnum.RejectedByIRP:
            case DataRequestStatusEnum.RejectedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByDataProvider:
            case DataRequestStatusEnum.RevisionsRequestedByIRP:
                urlStatus = 'NotApproved';
                break;
            case DataRequestStatusEnum.Cancelled:
                urlStatus = 'Cancelled';
                break;
            case DataRequestStatusEnum.Archived:
                urlStatus = 'Archived';
                break;
        }
        //note use of .realId here; we replaced the actual id with a random guid so realId contains
        //the actual id - see IReportItem for more info
        return `/adminDataRequests/${urlStatus}/${dataRequestReportItem.realId}/StatusUpdate`;
    };

    useEffect(() => {
        if (!queryParms['reportType']) {
            setIsLoadingReportItems(true);
            //get data for default report
            const reportItemsSub = dataRequestsService
                .getReport(null, null, null)
                .pipe(
                    filter((report) => report !== null),
                    finalize(() => setIsLoadingReportItems(false))
                )
                .subscribe(setReportItems, handleFailure);

            return () => {
                reportItemsSub.unsubscribe();
            };
        }
    }, []);

    useEffect(() => {
        if (vivliConfig?.reportSpecs) {
            const isAdmin = user?.isVivliAdmin;
            setVivliAdminReport(isAdmin);
            const thisUserSpecs = isAdmin
                ? vivliConfig.reportSpecs.filter((a) => a.userType === 'VivliAdmin')
                : vivliConfig.reportSpecs.filter((a) => a.userType === 'OrgAdmin');
            setReportSpecs(thisUserSpecs);
        }
    }, [vivliConfig]);

    return {
        reportItems,
        isLoadingReportItems,
        reportSpecs,
        refreshItemsForSpecificReport,
        vivliAdminReport,
        getDataRequestUrlForReportItem,
    };
}
