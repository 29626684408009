import { Observable } from 'rxjs';
import { RestApi } from '@vivli/core/infrastructure/rest';
import {
    IDataPackage,
    IDataPackageApiService,
    IDataPackageFileDescriptor,
} from '@vivli/shared/features/data-package/infrastructure/interface';

export class DataPackageApiService extends RestApi implements IDataPackageApiService {
    getDataPackage = (dataPackageId: string, anonymous?: boolean): Observable<IDataPackage> => {
        return this.handleGet<IDataPackage>(`/dataPackages/${dataPackageId}`, { bypassAuth: anonymous });
    };

    getStudyDocumentsDataPackage = (dataPackageId: string, ipdDataPackageId: string): Observable<IDataPackage> => {
        return this.handleGet<IDataPackage>(`/dataPackages/${dataPackageId}/combined/${ipdDataPackageId}`);
    };

    getForAnonymousUser = (dataPackageDoi: string): Observable<IDataPackage> => {
        return this.handleGet<IDataPackage>(`/dataPackages/${dataPackageDoi}/metadata`);
    };

    getDataPackageSubmitStatus = (dataPackageId: string): Observable<boolean> => {
        return this.handleGet<boolean>(`/dataPackages/${dataPackageId}/submitted`);
    };

    updateDataPackage = (dataPackage: IDataPackage): Observable<IDataPackage> => {
        return this.handlePut<IDataPackage>(`/dataPackages/true`, dataPackage);
    };

    deleteDataPackage = (dataPackageId: string) => {
        return this.handleDelete(`/datapackages/${dataPackageId}`);
    };

    addUpdateFile = (dataPackageId: string, fileDescriptor: IDataPackageFileDescriptor): Observable<IDataPackage> => {
        return this.handlePut<IDataPackage>(`/datapackages/${dataPackageId}/file`, fileDescriptor);
    };

    createDoi = (dataPackageId: string): Observable<string> => {
        return this.handlePut<string>(`/dataPackages/${dataPackageId}/doicreation`, null);
    };

    getDataPackageFileStatus = (dataPackageId: string): Observable<string> => {
        return this.handleGet<string>(`/dataPackages/${dataPackageId}/fileCheck`);
    };
}
