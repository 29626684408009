import React, { ReactElement } from 'react';

interface ContextInjectorComponent {
    contextWrappers: ReactElement[];
    children?: ReactElement;
}

export const ContextInjectorComponent = ({ contextWrappers, children }: ContextInjectorComponent) => {
    // internal array for wrappers
    const wrappers = [...contextWrappers];

    // remove first array item and assign it to a const (this also updates the original array)
    const currentWrapper = wrappers.shift();

    // recursive check, add additional wrappers as children if they exist
    if (wrappers.length > 0) {
        const childElement = React.cloneElement(<ContextInjectorComponent contextWrappers={wrappers} />, null, children);

        return React.cloneElement(currentWrapper, null, childElement);
    }

    return React.cloneElement(currentWrapper, null, children);
};
