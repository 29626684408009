﻿import React from 'react';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseControlledFormFieldComponent } from './base-controlled-form-field.component';
import { MultiEntryInputTextFieldComponent } from '../input/multi-entry-input-text-field.component';

interface MultiEntryInputTextFormFieldComponentProps extends IFormField {
    selectText?: string;
    name: string;
    objectKey?: string;
    title?: string;
    label?: string;
    inputTextArray?: string[];
    value?: string[];
    defaultValue?: string[];
    readonly?: boolean;
}

export const MultiEntryInputTextFormFieldComponent = (props: MultiEntryInputTextFormFieldComponentProps) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <MultiEntryInputTextFieldComponent {...props} />
        </BaseControlledFormFieldComponent>
    );
};
