﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { StudiesSearchApiService } from '@vivli/features/studies/infrastructure/service';
import { IStudiesSearchService } from '@vivli/features/studies/infrastructure/interface';
import React from 'react';
import { StudiesSearchServiceContext } from '@vivli/features/studies/infrastructure/context';

export const StudiesSearchServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const config = useConfigService();
    const api = new StudiesSearchApiService(apiOptions, config);

    const provider: IStudiesSearchService = {
        ...api,
    };

    return <StudiesSearchServiceContext.Provider value={provider}>{children}</StudiesSearchServiceContext.Provider>;
};
