import { IDataRequestSummary, IDataRequestsByType, IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { ApprovalStatusEnum, DataRequestStatusEnum, StudyRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { DataRequestPermissionsService } from './data-request-permissions.service';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

export type DataRequestType = 'draft' | 'active' | 'fulfilled' | 'notApproved' | 'archived' | 'cancelled' | 'awaitingUserAction';

export abstract class DataRequestFilterService {
    public static hasBeenSubmittedFilter = (dr: IDataRequestSummary | IDataRequest) => dr.dataRequestDoi;
    public static withdrawnFilter = (dr) => dr.status === DataRequestStatusEnum.Cancelled;
    public static archivedFilter = (dr) => dr.status === DataRequestStatusEnum.Archived;
    public static notApprovedFilter = (dr) =>
        dr.status === DataRequestStatusEnum.RejectedByVivli ||
        dr.status === DataRequestStatusEnum.RejectedByIRP ||
        dr.status === DataRequestStatusEnum.RejectedByDataProvider ||
        dr.status === DataRequestStatusEnum.RevisionsRequestedByDataProvider ||
        dr.status === DataRequestStatusEnum.RevisionsRequestedByIRP;
    public static fulfilledFilter = (dr) => dr.status === DataRequestStatusEnum.Fulfilled;
    public static activeFilter = (dr: IDataRequestSummary | IDataRequest) =>
        dr.status === DataRequestStatusEnum.Approved ||
        dr.status === DataRequestStatusEnum.AwaitingDUAValidation ||
        dr.status === DataRequestStatusEnum.DUAReturnedToRequester ||
        dr.status === DataRequestStatusEnum.DUAValidated ||
        dr.status === DataRequestStatusEnum.PartiallyFulfilled ||
        dr.status === DataRequestStatusEnum.SubmittedToVivli ||
        dr.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
        dr.status === DataRequestStatusEnum.AwaitingIRPApproval ||
        dr.status === DataRequestStatusEnum.Fulfilled ||
        (dr.status === DataRequestStatusEnum.Draft && DataRequestFilterService.hasBeenSubmittedFilter(dr));
    public static draftFilter = (dr) => dr.status === DataRequestStatusEnum.Draft && !DataRequestFilterService.hasBeenSubmittedFilter(dr);
    public static awaitingUserActionFilter = (user: IUser) => (dr) => {
        switch (dr.status) {
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            case DataRequestStatusEnum.Approved:
                return user.isVivliAdmin;
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return dr.requiredDataProviderApprovals.some(
                    (dpa) =>
                        dpa.status === ApprovalStatusEnum.None &&
                        DataRequestPermissionsService.isOrgMember(
                            user,
                            dpa.orgId,
                            (om) => om.isDataProvider || (om.isOrgAdmin && !user.isVivliAdmin)
                        )
                );
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return dr.requiredIRPApprovals.some(
                    (dpa) =>
                        dpa.status === ApprovalStatusEnum.None &&
                        DataRequestPermissionsService.isOrgMember(
                            user,
                            dpa.orgId,
                            (om) => om.isIRPApprover || (om.isOrgAdmin && !user.isVivliAdmin)
                        )
                );
            case DataRequestStatusEnum.DUAValidated:
            case DataRequestStatusEnum.PartiallyFulfilled:
                //only user actions awaited at these statuses are for
                // DRs that I need to upload data for
                const awaitingIpdIpdOrgIds = dr.requestedStudies
                    .filter((s) => s.studyRequestStatus === StudyRequestStatusEnum.New)
                    .map((a) => a.organizationId);
                let awaitingMeToUploadIpd = awaitingIpdIpdOrgIds?.some((o) =>
                    DataRequestPermissionsService.isOrgMember(user, o, (om) => om.isDataProvider)
                );

                //in Amr mode, Vivli Admins should also see drs awaiting IPD upload
                const appType = useAssignedAppType();
                if (appType === AssignedAppTypeEnum.Amr && awaitingIpdIpdOrgIds?.length > 0 && user.isVivliAdmin) {
                    awaitingMeToUploadIpd = true;
                }
                return awaitingMeToUploadIpd;
            default:
                return false;
        }
    };

    public static getFilterByType(type: DataRequestType, user?: IUser) {
        switch (type) {
            case 'archived':
                return this.archivedFilter;
            case 'cancelled':
                return this.withdrawnFilter;
            case 'draft':
                return this.draftFilter;
            case 'fulfilled':
                return this.fulfilledFilter;
            case 'active':
                return this.activeFilter;
            case 'notApproved':
                return this.notApprovedFilter;
            case 'awaitingUserAction':
                return this.awaitingUserActionFilter(user);
        }
    }

    public static filterRequestsByType(dataRequests: IDataRequestSummary[], type: DataRequestType, user?: IUser) {
        return dataRequests.filter(this.getFilterByType(type, user));
    }

    public static filterAllRequestsByType(dataRequests: IDataRequestSummary[], user?: IUser): IDataRequestsByType {
        const draftRequests = dataRequests.filter(this.draftFilter);
        const active = dataRequests.filter(this.activeFilter);
        const notApproved = dataRequests.filter(this.notApprovedFilter);
        const withdrawn = dataRequests.filter(this.withdrawnFilter);
        const archived = dataRequests.filter(this.archivedFilter);

        let result: IDataRequestsByType = {
            draftRequests,
            active,
            notApproved,
            withdrawn,
            archived,
        };

        if (user) {
            const awaitingUserAction = dataRequests.filter(this.awaitingUserActionFilter(user));

            result = {
                ...result,
                awaitingUserAction,
            };
        }

        return result;
    }

    public static filterAwaitingUserActionRequests(dataRequests: IDataRequestSummary[], user: IUser): IDataRequestSummary[] {
        return this.filterRequestsByType(dataRequests, 'awaitingUserAction', user);
    }

    public static filterAllRequestsByAssignedAppType(dataRequests: IDataRequest[]): IDataRequest[] {
        const appType = useAssignedAppType();
        let filteredDataRequests: IDataRequest[];
        if (appType === AssignedAppTypeEnum.Amr) {
            filteredDataRequests = dataRequests.filter((a) => a.antimicrobialDetail !== null);
        } else {
            filteredDataRequests = dataRequests.filter((a) => !a.antimicrobialDetail || a.antimicrobialDetail === 'Undefined');
        }

        return filteredDataRequests;
    }
}
