﻿import React, { CSSProperties } from 'react';
import { AdminHeaderTitleComponent, GoBackLinkComponent, leftHeaderContainerStyle } from '@vivli/shared/components';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { ListingRequestStatusEnum } from '@vivli/features/listing-request/infrastructure/enum';

const titleContainerStyle: CSSProperties = {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 30,
    flex: 1,
    height: 35,
};
const statusStyle: CSSProperties = {
    fontWeight: 'bold',
};
const titleStyle: CSSProperties = {
    fontSize: 20,
    paddingRight: 0,
    marginRight: 0,
};

export const ListingRequestLeftHeaderComponent = () => {
    const { listingRequest } = useListingRequestContext();
    const title = listingRequest?.datasets[0]?.studyTitle || '';
    const status = ListingRequestStatusEnum[listingRequest?.status];

    return (
        <div style={leftHeaderContainerStyle}>
            <GoBackLinkComponent />
            <div style={titleContainerStyle}>
                <AdminHeaderTitleComponent title={title} truncateText={true} widthPerCharacter={10} style={titleStyle} />
                Status: <span style={statusStyle}>{status}</span>
            </div>
        </div>
    );
};
