import React, { useEffect, useState } from 'react';
import { DataPackageBaseComponent, DataPackageComponent, DataPackageContext } from '@vivli/shared/features/data-package/components';
import { useChatService } from '@vivli/features/chat/infrastructure/context';
import { IDataPackageFileDescriptor } from '@vivli/shared/features/data-package/infrastructure/interface';
import { LoggerService } from '@vivli/shared/infrastructure/service';

interface ChatDataPackageFeatureProps {
    chatChannelId: string;
    allowUpload: boolean;
    showFileWarning: boolean;
    showChatUploadFileWarning: boolean;
    onFileAdded?: (file: IDataPackageFileDescriptor) => void;
    onFileRemoved?: (file: IDataPackageFileDescriptor) => void;
}

export const ChatDataPackageFeature = ({
    chatChannelId,
    allowUpload,
    onFileAdded,
    showFileWarning,
    showChatUploadFileWarning,
    onFileRemoved,
}: ChatDataPackageFeatureProps) => {
    const [dataPackageId, setDataPackageId] = useState(null);
    const chatService = useChatService();

    useEffect(() => {
        if (!chatChannelId) {
            return;
        }
        //retrieve the data only when chat is active
        const sub = chatService.getChatChannel(chatChannelId).subscribe((result) => {
            if (result) {
                LoggerService.info(`Files are retrieved for the channel ${chatChannelId}.`);
                setDataPackageId(result.channel.attachmentsDataPackageId);
            }
        });

        return () => {
            sub.unsubscribe();
        };
    }, [chatChannelId]);

    if (!chatChannelId) {
        return <div>{!chatChannelId && <span>This chat package is restricted or is unavailable.</span>}</div>;
    }
    return (
        <DataPackageBaseComponent dataPackageId={dataPackageId}>
            <DataPackageContext.Consumer>
                {(dataPackage) => (
                    <DataPackageComponent
                        dataPackage={dataPackage}
                        readOnly={false}
                        allowDownload={true}
                        allowUpload={allowUpload}
                        allowDelete={true}
                        allowSubmit={false}
                        hideFileType={true}
                        showDateTime={false}
                        onFileAdded={onFileAdded}
                        onFileRemoved={onFileRemoved}
                        miniature={true}
                        showChatUploadFileWarning={showChatUploadFileWarning}
                        sortFilesByDate={true}
                        showFileWarning={showFileWarning}
                        expandFilenames={true}
                        showDeleteDialog={true}
                        availableTypes={['Study Protocol', 'CSR', 'Other', 'Unknown']}
                    />
                )}
            </DataPackageContext.Consumer>
        </DataPackageBaseComponent>
    );
};
