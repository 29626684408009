﻿import React, { CSSProperties, useState } from 'react';
import {
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    ButtonComponent,
    GoBackLinkComponent,
    leftHeaderContainerStyle,
} from '@vivli/shared/components';
import { StudyLookupComponent } from './study-lookup.component';
import { Size, Styles } from '@vivli/shared/theme';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { first } from 'rxjs/operators';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { StudySampleComponent } from './study-sample.component';
import { useNavigate } from 'react-router-dom';

const containerStyle: CSSProperties = {
    height: '100%',
    overflow: 'auto',
};
const innerContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingTop: Size.PADDING,
    flex: 'auto',
};

export const SubmitNewStudyComponent = () => {
    const studiesService = useStudiesService();
    const modalService = useModalService();
    const navigate = useNavigate();
    const [nctValue, setNctValue] = useState<string>('');
    const [orgValue, setOrgValue] = useState<{ orgId: string; orgName: string; orgCode: string }>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [sampleStudy, setSampleStudy] = useState(null);

    const handleSearchError = (e) => {
        modalService.error(`Error looking up study by NCT ID - ${e}`);
        setIsLoading(false);
    };
    const handleError = (e) => {
        modalService.error(`Error creating study - ${e}`);
        setIsLoading(false);
    };

    const handleNctSearch = (nct) => {
        if (!/^NCT\d{8}$/gi.test(nct)) {
            return;
        }

        setIsLoading(true);
        studiesService
            .getSampleStudy(nct)
            .pipe(first())
            .subscribe((sample) => {
                setSampleStudy(sample);
                setIsLoading(false);
            }, handleSearchError);
    };

    const handleContinueClick = () => {
        setIsLoading(true);
        const sample = { ...sampleStudy, orgId: orgValue.orgId, orgName: orgValue.orgName, orgCode: orgValue.orgCode };
        studiesService
            .addStudy(sample, true)
            .pipe(first())
            .subscribe((newStudy) => {
                setIsLoading(false);
                modalService.message('Draft study created.  You may now upload study protocol or CSR documents.', {
                    showBackground: false,
                    showLogo: true,
                    showContinueButton: true,
                });
                navigate(`/admin/studies/submitStudy/${newStudy.id}/details`);
            }, handleError);
    };

    return (
        <div style={containerStyle} className="scrolly">
            <div>
                <AdminHeaderComponent>
                    <div style={{ flexDirection: 'column' }}>
                        <div style={{ ...leftHeaderContainerStyle, paddingBottom: 10 }}>
                            <GoBackLinkComponent />
                        </div>
                        <AdminHeaderTitleComponent title={'Submit New Study'} />
                    </div>
                </AdminHeaderComponent>
            </div>
            <div style={innerContainerStyle}>
                <StudyLookupComponent
                    nctValue={nctValue}
                    setNctValue={setNctValue}
                    handleNctSearch={handleNctSearch}
                    orgValue={orgValue}
                    setOrgValue={setOrgValue}
                />

                {sampleStudy && <StudySampleComponent sampleStudy={sampleStudy} />}

                <ButtonComponent
                    isLoading={isLoading}
                    style={Styles.Button.BUTTON_VIVLI_BLUE}
                    disabled={!nctValue || !orgValue || !sampleStudy}
                    onClick={handleContinueClick}
                >
                    Continue
                </ButtonComponent>
            </div>
        </div>
    );
};
