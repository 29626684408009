﻿import React from 'react';
import { PrintButtonComponent } from '../data-request/data-request-header/components/print-button.component';
import { AmrPrintButtonComponent } from '../amr-data-request/amr-print-button.component';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

export const PrintButtonContainerComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    if (!isAmr) {
        return <PrintButtonComponent />;
    }

    return <AmrPrintButtonComponent />;
};
