﻿import { useCtSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';
import { inputGroupBreakStyle } from '../../search-filters.styles';
import { AdvancedSelectComponent, AdvancedSubHeaderComponent } from '@vivli/shared/components';
import { FilterGroupComponent } from '../../filter-group.component';
import React from 'react';

export const FunderInfoFilterGroupComponent = () => {
    const { ctFilterValues } = useSearchContext();
    const { funderSelectedOptions, setFunderSelectedOptions, contributorSelectedOptions, setContributorSelectedOptions } =
        useCtSearchOptionsContext();

    const funderOptions = ctFilterValues?.funder.map((l) => ({ value: l.value, label: l.value }));
    const contributorOptions = ctFilterValues?.orgName.map((l) => ({ value: l.value, label: l.value }));

    return (
        <FilterGroupComponent title={'SPONSOR INFORMATION'} noBottomPad={true}>
            <div style={inputGroupBreakStyle}>
                <AdvancedSubHeaderComponent title={'FUNDER'} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setFunderSelectedOptions}
                    options={funderOptions}
                    selectedOptions={funderSelectedOptions}
                />
            </div>

            <div>
                <AdvancedSubHeaderComponent title={'CONTRIBUTOR'} />
                <AdvancedSelectComponent
                    isMulti={true}
                    isSearchable={true}
                    onChange={setContributorSelectedOptions}
                    options={contributorOptions}
                    selectedOptions={contributorSelectedOptions}
                />
            </div>
        </FilterGroupComponent>
    );
};
