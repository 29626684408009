import React, { CSSProperties, HTMLProps } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { LoadIndicatorComponent } from './load-indicator/load-indicator.component';

export interface LinkButtonComponentProps extends HTMLProps<HTMLDivElement> {
    disabled?: boolean;
    isLoading?: boolean;
    title?: string;
    dataId?: string;
    wrapText?: boolean;
    style?: CSSProperties;
    children?: any;
}

const defaultButtonStyle = {
    color: Color.TEXT_DARK_BLUE,
    fontWeight: Size.FontWeight.Bold,
    backgroundColor: Color.TRANSPARENT,
    fontSize: Size.FontSize.Large,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
    textDecoration: 'underline',
};

const loadIndicatorStyle: CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

export const LinkButtonComponent = ({
    style,
    disabled,
    isLoading,
    children,
    title,
    dataId,
    wrapText,
    ...optionalProps
}: LinkButtonComponentProps) => {
    const buttonStyle: any = {
        ...defaultButtonStyle,
        cursor: disabled || isLoading ? 'default' : 'pointer',
        pointerEvents: disabled || isLoading ? 'none' : null,
        opacity: disabled || isLoading ? Size.HOVER_OPACITY : 1,
        ...style,
    };

    const wrapStyle = wrapText ? 'normal' : 'nowrap';

    return (
        <div title={title ? title : ''} style={buttonStyle} data-test-id={dataId} {...optionalProps}>
            {isLoading && <LoadIndicatorComponent style={loadIndicatorStyle} />}
            <span style={{ visibility: isLoading ? 'hidden' : 'visible', whiteSpace: wrapStyle }}>{children}</span>
        </div>
    );
};
