﻿export enum OutcomeGroupNames {
    InformPatient = 'Inform Patient Care Decisions',
    AlgorithmsCode = 'Algorithms / Code',
    ClinicalGuidelines = 'Clinical Guidelines',
    FutureTrials = 'Designing Future Trials, Trial Protocol',
    FundingGrants = 'Funding Application / Grants',
    Tools = 'Tools',
    Other = 'Other',
}

export const OutcomesOptions = [
    { value: 'InformPatient', label: 'Inform Patient Care Decisions', groupName: OutcomeGroupNames.InformPatient },
    { value: 'AiAlgorithm', label: 'A.I. algorithm', groupName: OutcomeGroupNames.AlgorithmsCode },
    {
        value: 'AlgorithmForPredictingTreatmentResponse',
        label: 'Algorithm for predicting treatment response',
        groupName: OutcomeGroupNames.AlgorithmsCode,
    },
    { value: 'Code', label: 'Code', groupName: OutcomeGroupNames.AlgorithmsCode },
    { value: 'MachineLearning', label: 'Machine Learning', groupName: OutcomeGroupNames.AlgorithmsCode },

    { value: 'ClinicalGuidelines', label: 'Clinical guidelines', groupName: OutcomeGroupNames.ClinicalGuidelines },

    { value: 'ClinicalTrialDesign', label: 'Clinical trial design', groupName: OutcomeGroupNames.FutureTrials },
    {
        value: 'ClinicalTrialPatientSelection',
        label: 'Clinical trial patient selection / recruitment',
        groupName: OutcomeGroupNames.FutureTrials,
    },
    {
        value: 'OptimizationOfClinicalTrialParameters',
        label: 'Optimization of clinical trial parameters',
        groupName: OutcomeGroupNames.FutureTrials,
    },

    { value: 'FundingApplication', label: 'Funding application / grants', groupName: OutcomeGroupNames.FundingGrants },
    { value: 'NihGrant', label: 'NIH grant', groupName: OutcomeGroupNames.FundingGrants },

    { value: 'ClinicalTools', label: 'Clinical tools', groupName: OutcomeGroupNames.Tools },
    {
        value: 'QualificationOfClinicalOutcomeTools',
        label: 'Qualification of clinical outcome tools',
        groupName: OutcomeGroupNames.Tools,
    },
    { value: 'StatisticalTools', label: 'Statistical tools', groupName: OutcomeGroupNames.Tools },
    { value: 'WebBasedTools', label: 'Web-based tools', groupName: OutcomeGroupNames.Tools },

    { value: 'Other', label: 'Other', groupName: OutcomeGroupNames.Other },
];
