﻿import React, { CSSProperties } from 'react';
import { AdminHeaderButtonComponent, btnStyle } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { AssetsConstant, DTICommonConst } from '@vivli/shared/infrastructure/constants';

const approveRejectButtonsContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: Size.PADDING,
    marginRight: Size.PADDING,
};

const btnStyleApproveReject: CSSProperties = {
    ...btnStyle,
    marginRight: Size.PADDING,
    height: 40,
    minWidth: 0,
    paddingLeft: 40,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: Color.WHITE,
};

const btnStyleApprove: CSSProperties = {
    ...btnStyleApproveReject,
    backgroundImage: `url(${AssetsConstant.GREEN_CHECK_MARK})`,
    color: Color.VIVLI_DARKER_GREEN,
    border: `1px solid ${Color.VIVLI_DARKER_GREEN}`,
};

const btnStyleReject: CSSProperties = {
    ...btnStyleApproveReject,
    backgroundImage: `url(${AssetsConstant.RED_X_ICON})`,
    color: Color.VIVLI_RED,
    border: `1px solid ${Color.VIVLI_RED}`,
};

const btnStyleSoftReject: CSSProperties = {
    ...btnStyleApproveReject,
    backgroundImage: `url(${AssetsConstant.YELLOW_X_ICON})`,
    color: Color.VIVLI_YELLOW,
    border: `1px solid ${Color.VIVLI_YELLOW}`,
};

interface StudyApproveRejectButtonComponentProps {
    onClick?: (e) => void;
    onApproveStudyClick?: () => void;
    onRejectStudyClick?: () => void;
    onSoftRejectStudyClick?: () => void;
    isLoading?: boolean;
    title?: string;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    showApprove?: boolean;
    showReject?: boolean;
    showSoftReject?: boolean;
    approveLabel?: string;
    rejectLabel?: string;
    softRejectLabel?: string;
    approvePrompt?: string;
    rejectPrompt?: string;
}

export const StudyApproveRejectButtonComponent = ({
    isLoading,
    showApprove,
    showReject,
    showSoftReject,
    approveLabel,
    rejectLabel,
    softRejectLabel,
    onApproveStudyClick,
    onRejectStudyClick,
    onSoftRejectStudyClick,
    isDisabled,
}: StudyApproveRejectButtonComponentProps) => {
    return (
        <div style={approveRejectButtonsContainerStyle}>
            {showReject && (
                <AdminHeaderButtonComponent
                    title={rejectLabel}
                    onClick={onRejectStudyClick}
                    isLoading={isLoading}
                    style={btnStyleReject}
                    dataId={DTICommonConst.RemoveButton}
                />
            )}
            {showSoftReject && (
                <AdminHeaderButtonComponent
                    title={softRejectLabel}
                    onClick={onSoftRejectStudyClick}
                    isLoading={isLoading}
                    style={btnStyleSoftReject}
                    dataId={DTICommonConst.SoftRejectButton}
                />
            )}
            {showApprove && (
                <AdminHeaderButtonComponent
                    title={approveLabel}
                    onClick={onApproveStudyClick}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    style={btnStyleApprove}
                    dataId={DTICommonConst.ApproveButton}
                />
            )}
        </div>
    );
};
