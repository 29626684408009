﻿import { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';

export const wrapperStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    backgroundColor: Color.VIVLI_DARK_BLUE,
    color: Color.WHITE,
    minWidth: 300,
    borderRadius: 15,
};
export const innerWrapperStyle: CSSProperties = {
    padding: 15,
    fontSize: Size.FontSize.Medium,
};
export const containerStyle: CSSProperties = {
    display: 'flex',
    backgroundColor: 'rgb(233, 234, 239)',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    color: 'black',
    overflowY: 'scroll',
    height: 600,
    width: 1007,
    flexDirection: 'column',
};
export const innerContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
};
export const buttonContainerStyle: CSSProperties = {
    margin: 10,
};
export const headerDivStyle: CSSProperties = {
    padding: '15px 20px',
    borderBottom: '1px solid white',
    fontSize: Size.FontSize.Larger,
    alignContent: 'center',
};
export const flexRelativeStyle: CSSProperties = {
    display: 'flex',
    position: 'relative',
};
export const flexRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
};
export const flexRowMarginStyle: CSSProperties = {
    ...flexRowStyle,
    marginRight: 20,
};
export const flexRowEvenStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: 10,
};
export const cellStyle: CSSProperties = {
    fontSize: 20,
    padding: 10,
};
export const flexColStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};
export const fullFlexColStyle: CSSProperties = {
    ...flexColStyle,
    width: '100%',
};
export const yellowButtonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_YELLOW,
};
export const blueButtonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_BLUE,
};
export const redButtonStyle: CSSProperties = {
    backgroundColor: Color.VIVLI_RED,
};
export const hiddenStyle: CSSProperties = {
    display: 'none',
};
