﻿import { AdvancedSelectComponent, AdvancedSubHeaderComponent, MultiEntryTextFieldComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { ISelectOption } from '@vivli/features/search/infrastructure/interface';

const metricsExportStyle: CSSProperties = {
    margin: '5px',
    display: 'flex',
    gap: '10px',
    width: '95%',
};

export const MetricsReportFilterComponent = ({ setDataRequestIds, handleContributorChanged }) => {
    const { organizations } = useOrganizations();
    const allOption = { value: 'all', label: '---' } as ISelectOption;

    const alphabeticalSort = (a, b) => {
        return a.title < b.title ? -1 : 1;
    };
    let organizationsOptions = organizations
        ?.map(
            (org) =>
                ({
                    label: org.name,
                    value: org.id,
                } as ISelectOption)
        )
        .sort(alphabeticalSort);

    organizationsOptions?.unshift(allOption);

    return (
        <>
            <div style={{ width: '100%' }}>
                <div style={{ marginLeft: '1%', fontWeight: 'bold' }}>Metrics Export Filters</div>
                <div style={metricsExportStyle}>
                    <div style={{ width: '45%' }}>
                        <AdvancedSubHeaderComponent title={'Data Contributor (Select to Refresh Metrics)'} />
                        <AdvancedSelectComponent
                            targetStyle={{ height: '46px', backgroundColor: 'white' }}
                            isMulti={false}
                            isSearchable={true}
                            sort={'asc'}
                            onChange={handleContributorChanged}
                            options={organizationsOptions}
                        />
                    </div>

                    <MultiEntryTextFieldComponent
                        textDivStyle={{ height: '68px', width: '55%' }}
                        label={'Data Request IDs (Press Enter to Add)'}
                        onChange={setDataRequestIds}
                    />
                </div>
            </div>
        </>
    );
};
