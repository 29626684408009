import { CSSProperties } from 'react';
import { IExternalLink } from '@vivli/shared/infrastructure/interface';
import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import joi from 'joi';
import { ButtonComponent } from '../buttons';
import { FieldHeaderComponent } from '../field-header.component';
import { TextFormFieldComponent } from '../form-field/text-form-field.component';
import { DTIAMRCommonConst, DTIFormFields } from '@vivli/shared/infrastructure/constants';

const containerStyle: CSSProperties = {
    padding: '40px',
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    rowGap: '10px',
    minWidth: '500px',
};

const buttonContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    columnGap: '10px',
};

interface IDataPackageLinkComponent {
    onSave: (externalLink: IExternalLink) => void;
    onCancel: () => void;
    externalLink?: IExternalLink;
    existingLinks?: IExternalLink[];
    title: string;
}

export const ExternalLinkComponent = ({ onCancel, onSave, externalLink, existingLinks, title }: IDataPackageLinkComponent) => {
    const formApi = useForm({
        mode: 'onSubmit',
        resolver: joiResolver(
            joi.object({
                title: joi
                    .string()
                    .required()
                    .label('Title')
                    .custom((value, helper) => {
                        if (existingLinks?.some((el) => el.title === value)) {
                            return helper.message({
                                custom: 'Title already exists, please choose a different title.',
                            });
                        }

                        return value;
                    }),
                uri: joi.string().uri().required().label('Uri').messages({
                    'string.uri': '{{#label}} must be valid: (http://www.sitename.com).',
                }),
            })
        ),
        defaultValues: externalLink || { title: '', uri: '' },
        reValidateMode: 'onChange',
    });

    const handleOnSave = () => {
        formApi.trigger().then((isValid) => {
            if (isValid) {
                formApi.handleSubmit(onSave)();
            }
        });
    };

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title={title} />
            <FormProvider {...formApi}>
                <TextFormFieldComponent dataId={DTIFormFields.FormTitle} focusOnLoad={true} label="Title" name={'title'} />
                <TextFormFieldComponent dataId={DTIFormFields.FormURL} label="URI" name={'uri'} />
            </FormProvider>
            <div style={buttonContainerStyle}>
                <ButtonComponent dataId={DTIAMRCommonConst.SaveButton} onClick={handleOnSave}>
                    Save
                </ButtonComponent>
                <ButtonComponent dataId={DTIAMRCommonConst.CancelButton} onClick={onCancel}>
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    );
};
