import React from 'react';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import {
    AdminHeaderComponent,
    AdminHeaderTitleComponent,
    GridComponent,
    TabButtonComponent,
    TabButtonsComponent,
} from '@vivli/shared/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ColDef } from 'ag-grid-community';
import { Styles } from '@vivli/shared/theme';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { useNavigate } from 'react-router-dom';

const columnDefs: ColDef[] = [
    {
        field: 'name',
        headerName: 'Organization',
        sortable: true,
        filter: true,
        initialSort: 'asc',
    },
];

export const OrganizationsFeature = () => {
    const user = useActiveUser();
    const { organizations, isLoadingOrganizations } = useOrganizations();
    const navigate = useNavigate();

    const handleRowClick = (organization: IOrganization) => {
        navigate(`/admin/orgs/${organization.id}`);
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <AdminHeaderComponent>
                <AdminHeaderTitleComponent title="My Organizations" />
            </AdminHeaderComponent>
            <div style={{ padding: '0 40px', height: '100%' }}>
                {user.isVivliAdmin && (
                    <div style={{ ...Styles.CENTERED_FLEX, width: '100%' }}>
                        <TabButtonsComponent
                            outerStyle={{ width: '100%' }}
                            commandImage={AssetsConstant.PLUS_SYMBOL}
                            commandTitle={'Add Organization'}
                            commandOnClick={() => navigate('/admin/orgs/add')}
                        >
                            <TabButtonComponent title={'All Organizations'} isSelected={true} />
                        </TabButtonsComponent>
                    </div>
                )}
                <GridComponent
                    rowData={organizations}
                    columns={columnDefs}
                    onRowClick={handleRowClick}
                    fullWidthColumns={true}
                    style={{
                        marginTop: 0,
                        cursor: 'pointer',
                        height: user.isVivliAdmin ? '70%' : '80%',
                    }}
                    hideHeader={true}
                    emptyMessage="No Organizations Found"
                    asyncUpdateKey="id"
                    dataRefreshing={isLoadingOrganizations}
                />
            </div>
        </div>
    );
};
