﻿import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import React from 'react';
import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { FundingOrgsGridComponent } from './funding-orgs-grid.component';

interface FundingOrgsContainerComponentProps {
    fundingOrgs?: IFundingOrganization[];
    handleRemoveRow?: (fundingOrganization: IFundingOrganization) => void;
    handleFunderChange?: (fundingOrganizations: IFundingOrganization[]) => void;
    canEdit?: boolean;
    isListingRequest?: boolean;
}

export const FundingOrgsContainerComponent = ({
    fundingOrgs,
    handleRemoveRow,
    handleFunderChange,
    canEdit,
    isListingRequest,
}: FundingOrgsContainerComponentProps) => {
    if (!fundingOrgs) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div>
            <FundingOrgsGridComponent
                fundingOrgs={fundingOrgs}
                handleRemoveRow={handleRemoveRow}
                handleFunderChange={handleFunderChange}
                emptyMessage={'No funding organizations found'}
                hideFloatingFilter={true}
                hideHeader={true}
                autoHeight={true}
                dataRefreshing={false}
                canEdit={canEdit}
                isListingRequest={isListingRequest}
            />
        </div>
    );
};
