import React, { CSSProperties, useRef, useState } from 'react';

import { useActiveUser } from '@vivli/core/infrastructure/context';
import { LoadIndicatorComponent } from '@vivli/shared/components';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { ResultRequestComponent, ResultRequestsGridComponent } from '@vivli/features/results-request/components';
import { Color, Styles } from '@vivli/shared/theme';
import { ResultRequestRoutesEnum } from '@vivli/features/results-request/infrastructure/enum';
import { useDataRequest } from '@vivli/features/data-requests/infrastructure/hook';
import { useResultRequests } from '@vivli/features/results-request/infrastructure/hook';
import { useAuthService } from '@vivli/core/authentication';
import { useNavigate } from 'react-router-dom';
import { LoginPromptComponent } from '@vivli/features/authentication/components';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Color.LIGHT_GRAY,
    alignItems: 'center',
};

const loadingContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
};

const bodyContainerStyle: CSSProperties = {
    width: '90%',
    marginTop: 20,
};

export const ResultRequestsFeature = ({ requestId }: { requestId: string }) => {
    const [resultRequest, setResultRequest] = useState<IResultRequest>(null);
    const baseUrlRef = useRef(window.location.pathname);
    const navigate = useNavigate();

    const activeUser = useActiveUser();
    const { dataRequest } = useDataRequest(requestId);
    const { resultRequests, setResultRequests } = useResultRequests(requestId);

    const authService = useAuthService();

    const updateRequestArray = (request: IResultRequest) => {
        const filteredRequests = resultRequests.filter((rr) => rr.id !== request.id);
        setResultRequests([...filteredRequests, request]);
    };

    const handleRequestChanged = (request: IResultRequest) => {
        setResultRequest(request);
        updateRequestArray(request);
    };

    const handleRequestClick = (request: IResultRequest) => {
        navigate(baseUrlRef.current + ResultRequestRoutesEnum.ResultRequest);
        setResultRequest(request);
    };

    const handleGridReady = (request: IResultRequest) => {
        setResultRequest(request);
    };

    if (!activeUser) {
        return (
            <LoginPromptComponent login={authService.login}>
                <div style={{ ...Styles.CENTERED_FLEX, marginBottom: 50 }}>
                    <h2>Please log in to continue...</h2>
                </div>
            </LoginPromptComponent>
        );
    }

    if (!resultRequests || !dataRequest) {
        return (
            <div style={loadingContainerStyle}>
                <LoadIndicatorComponent size={LoadIndicatorSizeEnum.Large} />
            </div>
        );
    }

    return (
        <div style={containerStyle}>
            <div style={bodyContainerStyle}>
                <p>
                    To request export of research results, connect to the research environment, click on the Export Results icon on the
                    desktop. In the browser window, log in with your email address and Vivli Platform password. Use Drag and Drop to drop
                    the requested files onto the "Drop files here" control, then click submit when they are complete. When the results have
                    been reviewed and approved, you will return back here to download the results. Reminder - exporting individual
                    participant data is not allowed.
                </p>
                <ResultRequestsGridComponent
                    resultRequests={resultRequests}
                    onRequestClick={handleRequestClick}
                    onGridReady={handleGridReady}
                />

                {resultRequest && (
                    <ResultRequestComponent
                        resultRequest={resultRequest}
                        dataRequest={dataRequest}
                        onRequestChanged={handleRequestChanged}
                    />
                )}
            </div>
        </div>
    );
};
