import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import React, { useState } from 'react';
import { AdminConsoleLookupIdContext } from '@vivli/features/admin-console/infrastructure/context';

export const AdminConsoleLookupIDContextWrapper = ({ children }: IContextWrapper) => {
    const [vmLookupId, setVmLookupId] = useState('');
    const [studyLookupId, setStudyLookupId] = useState('');
    const [dataRequestLookupId, setDataRequestLookupId] = useState('');
    const provider = {
        studyLookupId,
        setStudyLookupId,
        setVmLookupId,
        vmLookupId,
        dataRequestLookupId,
        setDataRequestLookupId,
    };

    return <AdminConsoleLookupIdContext.Provider value={provider}>{children}</AdminConsoleLookupIdContext.Provider>;
};
