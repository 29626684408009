import React, { CSSProperties, useState } from 'react';
import { ButtonComponent, CheckboxComponent, TextFieldComponent } from '@vivli/shared/components';
import { finalize, first } from 'rxjs/operators';
import {
    useAdminConsoleContext,
    useAdminConsoleLookupIdContext,
    useAdminConsoleService,
} from '@vivli/features/admin-console/infrastructure/context';
import { ITemplateStudyLookUp } from '@vivli/features/admin-console/infrastructure/interface';
import { useModalService } from '@vivli/shared/infrastructure/context';

/* eslint-disable-next-line */

const marginLeftStyle = {
    marginLeft: 10,
};
const fontBoldStyle = {
    fontWeight: '700',
};
const templateSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '70%',
};
const statusSectionStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '75%',
};
const checkboxStyle: CSSProperties = {
    flexDirection: 'row-reverse',
};

const buttonStyle: CSSProperties = {
    marginLeft: 15,
};
const inlineAlignStyle = {
    display: 'flex',
    justifyContent: 'space-around',
};
const marginLeftSponsorStyle = {
    marginLeft: '12%',
};

export function AdminTemplateStudyComponent() {
    const [templateBody, setTemplateBody] = useState(false);
    const adminConsoleService = useAdminConsoleService();
    const [studyData, setStudyData] = useState<ITemplateStudyLookUp>();
    const modalService = useModalService();
    const adminConsoleContext = useAdminConsoleContext();
    const { studyLookupId, setStudyLookupId } = useAdminConsoleLookupIdContext();
    const [isLoading, setIsLoading] = useState(false);
    const [hasIPDSelected, setHasIPDSelected] = useState(false);

    const handleInputChangeTsRequest = (e) => {
        const value = e.currentTarget.value;
        setStudyLookupId(value);
    };

    const handleLookUpStudyClick = (e) => {
        e.stopPropagation();
        setIsLoading(true);
        adminConsoleService
            .getTemplateStudy(studyLookupId)
            .pipe(
                first(),
                finalize(() => setIsLoading(false))
            )
            .subscribe((lookUpData) => {
                if (lookUpData.item1) {
                    setStudyData(lookUpData);
                    setTemplateBody(true);
                } else {
                    modalService.error('Study not found.');
                }
            }, modalService.error);
    };

    const handleTemplateStudyActions = (action: string) => {
        adminConsoleContext.handleAction(action, studyData.item1.id, null, false);
    };

    const handleTemplateStudyIpdActions = (action: string) => {
        adminConsoleContext.handleAction(action, studyData.item1.id, studyData.item1.hasSubmittedIpd.toString(), false);
    };

    const handleCbChange = (e) => {
        {
            studyData.item1.hasSubmittedIpd = !studyData.item1.hasSubmittedIpd;
            handleTemplateStudyIpdActions('ChangeHasSubmittedIpd');
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLookUpStudyClick(e);
        }
    };

    return (
        <div>
            <div style={templateSectionStyle}>
                <span style={fontBoldStyle}>Template Study - NCT or Sponsor ID:</span>
                <TextFieldComponent
                    label={'Template Study'}
                    onKeyDown={handleKeyDown}
                    value={studyLookupId}
                    onChange={(e) => handleInputChangeTsRequest(e)}
                    style={marginLeftStyle}
                />
                <ButtonComponent style={buttonStyle} onClick={handleLookUpStudyClick} isLoading={isLoading} disabled={isLoading}>
                    Lookup Study
                </ButtonComponent>
            </div>
            {templateBody && studyData.item1 && (
                <div>
                    <p style={inlineAlignStyle}>
                        <span>Title:</span> <span style={marginLeftStyle}>{studyData.item1.studyTitle}</span>
                    </p>
                    <p>Data Contributor: {studyData.item1.orgName}</p>
                    <p>
                        <span>NCTId: {studyData.item1.nctId}</span>
                        <span style={marginLeftSponsorStyle}> Sponsor Id: {studyData.item1.sponsorProtocolId}</span>
                    </p>
                    <div style={statusSectionStyle}>
                        {<span>Current IPD Status: {studyData.item2}</span>}
                        <ButtonComponent onClick={() => handleTemplateStudyActions('ResetIPDToDraft')}>Reset IPD to Draft</ButtonComponent>
                        <ButtonComponent onClick={() => handleTemplateStudyActions('RemoveStudyFiles')}>Remove Files</ButtonComponent>
                        <ButtonComponent onClick={() => handleTemplateStudyActions('ResetIPDProvider')}>Reset IPD Provider</ButtonComponent>
                    </div>
                    <p>
                        <div style={checkboxStyle}>
                            <CheckboxComponent
                                label={'Has submitted IPD (changing this will immediately update the Study)'}
                                checked={studyData.item1.hasSubmittedIpd}
                                onChange={handleCbChange}
                            />

                            {/*                         <ButtonComponent onClick={() => handleTemplateStudyActions('ChangeHasSubmittedIpd')}>
                             Change value of "Has Submitted IPD" in the Study
                         </ButtonComponent>*/}
                        </div>
                    </p>
                </div>
            )}
        </div>
    );
}
