import {
    IApprovalDescriptor,
    IDataRequest,
    IRequestedStudy,
    IResearchTeamMemberDetail,
} from '@vivli/features/data-requests/infrastructure/interface';
import { ApprovalStatusEnum, DataRequestStatusEnum, StudyRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IOrganizationRoles, IUser } from '@vivli/shared/infrastructure/interface';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';

export abstract class DataRequestPermissionsService {
    public static isOrgMember(user: IUser, orgId: string, predicate?: (o: IOrganizationRoles) => boolean) {
        return user?.orgMemberships?.some((o) => o.orgId === orgId && predicate(o));
    }

    public static canUpload(request: IDataRequest, study: IRequestedStudy, user: IUser): boolean {
        if (!request) {
            return false;
        }

        //check for Vivli Admin that happens to have contributor rights
        //by mistake for non-AMR request, don't allow upload
        if (user.isVivliAdmin && !request.antimicrobialDetail) {
            return false;
        }

        if (
            (request.status === DataRequestStatusEnum.DUAValidated || request.status === DataRequestStatusEnum.PartiallyFulfilled) &&
            study.studyRequestStatus === StudyRequestStatusEnum.New
        ) {
            return this.isOrgMember(user, study.organizationId, (om) => om.isDataProvider || om.isOrgAdmin);
        }

        return false;
    }

    public static canReject(request: IDataRequest, user: IUser): boolean {
        if (user.isVivliAdmin) {
            return true;
        }

        return this.awaitingApproval(request, user);
    }

    public static canSoftReject(request: IDataRequest, user?: IUser): boolean {
        switch (request.status) {
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return this.canApprove(request, user);
            case DataRequestStatusEnum.SubmittedToVivli:
            case DataRequestStatusEnum.AwaitingDUAValidation:
            default:
                return false;
        }
    }

    public static canApprove(request: IDataRequest, user?: IUser): boolean {
        if (user?.isVivliAdmin) {
            switch (request.status) {
                case DataRequestStatusEnum.SubmittedToVivli:
                case DataRequestStatusEnum.AwaitingDataProviderApproval:
                case DataRequestStatusEnum.AwaitingIRPApproval:
                case DataRequestStatusEnum.AwaitingDUAValidation:
                case DataRequestStatusEnum.DUAReturnedToRequester:
                    return true;
            }
        }

        return this.awaitingApproval(request, user);
    }

    public static awaitingApproval(request: IDataRequest, user?: IUser) {
        switch (request.status) {
            case DataRequestStatusEnum.AwaitingDataProviderApproval:
                return (
                    user &&
                    request.requiredDataProviderApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None &&
                            this.isOrgMember(user, dpa.orgId, (om) => om.isDataProvider || om.isOrgAdmin)
                    )
                );
            case DataRequestStatusEnum.AwaitingIRPApproval:
                return (
                    user &&
                    request.requiredIRPApprovals.some(
                        (dpa: IApprovalDescriptor) =>
                            dpa.status === ApprovalStatusEnum.None && this.isOrgMember(user, dpa.orgId, (om) => om.isOrgAdmin)
                    )
                );
        }
        return false;
    }

    public static shouldHideReviewers(request: IDataRequest, user: IUser, orgs: IOrganization[], forPrint = false): boolean {
        // get org ids where user is a data provider or irp approver
        // don't return the org if the user is an admin, since they are still allowed to see reviewers
        const userOrgIds = user.orgMemberships
            .filter((x) => x.isDataProvider && ((forPrint && x.isOrgAdmin) || !x.isOrgAdmin))
            .map((x) => x.orgId);
        const irpOrgIds = user.orgMemberships
            .filter((x) => x.isIRPApprover && ((forPrint && x.isOrgAdmin) || !x.isOrgAdmin))
            .map((x) => x.orgId);

        // get org ids and approver org ids from request
        const studyOrgIds = request.requestedStudies.map((x) => x.organizationId);
        const approverOrgIds = request.requestedStudies.map((x) => x.approvalBodyOrgId);

        // cross reference ids to see if the user has studies in this request
        const dataProviderOrgIds = userOrgIds.filter((x) => studyOrgIds.some((s) => s === x));
        const irpApproverOrgIds = irpOrgIds.filter((x) => approverOrgIds.some((s) => s === x));

        // concat ids for ease of use
        const orgIds = [...dataProviderOrgIds, ...irpApproverOrgIds];

        // filter orgs based the returned org ids, check of any of them have hide reviewers checked
        // if any have it checked then use it (least permission possible)
        return orgs.filter((org) => orgIds.some((orgId) => orgId === org.id)).some((org) => org.blindReviewers);
    }

    public static canEditTeamMember(teamMember: IResearchTeamMemberDetail): boolean {
        return !teamMember.isLeadResearcher && !teamMember.isStatistician;
    }

    public static canEditEmailField(user: IUser, teamMember: IResearchTeamMemberDetail): boolean {
        if (user?.isVivliAdmin) {
            return true;
        }

        return this.canEditTeamMember(teamMember) && !teamMember.hasAccessToVirtualMachineResource && !teamMember.hasBeenInvitedToVMAccess;
    }

    public static canEditNonEmailFields(user: IUser, teamMember: IResearchTeamMemberDetail): boolean {
        if (user?.isVivliAdmin) {
            return true;
        }

        return this.canEditTeamMember(teamMember);
    }
}
