import React from 'react';
import { VmFailureComponent } from '../vm-failure.component';
import { BlockMessageComponent } from '../block-message.component';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';

export const VmStartingFailedViewComponent = () => {
    const { startVm } = useResearchEnvironmentContext();

    return (
        <BlockMessageComponent message={'Failed to Start Research Environment'}>
            <VmFailureComponent title={'Retry Starting Research Environment'} onClick={startVm} />
        </BlockMessageComponent>
    );
};
