﻿import React, { CSSProperties, useEffect } from 'react';
import { useAdminStudyPermissions, useStudyWithUserCheck, useSubmitStudyForm } from '@vivli/features/studies/infrastructure/hook';
import { FormProvider } from 'react-hook-form';
import { SubmitStudyHeaderComponent } from './submit-study-header.component';
import { useParams } from 'react-router';
import { SubmitStudyTabsComponent } from './submit-study-tabs.component';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';

const containerStyle: CSSProperties = {
    height: '100%',
};
const bodyContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 2,
    height: '80%',
    overflow: 'auto',
};

export const SubmitStudyFormComponent = () => {
    const { studyId } = useParams();
    const { study } = useStudyContext();
    const { studyIpdDataPackageSubmitted } = useStudyWithUserCheck(studyId);
    const { userCanDelete, userCanApprove, userCanSave } = useAdminStudyPermissions(study, studyIpdDataPackageSubmitted);
    const formApi = useSubmitStudyForm(study);

    useEffect(() => {
        // force initial validation
        formApi.trigger();
    }, []);

    useEffect(() => {
        formApi.reset(study, { keepErrors: false, keepIsValid: false, keepDirty: false });
    }, [study]);

    if (!study) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <FormProvider {...formApi}>
            <div style={containerStyle}>
                <div>
                    <SubmitStudyHeaderComponent
                        studyId={studyId}
                        userCanDelete={userCanDelete}
                        userCanApprove={userCanApprove}
                        userCanSave={userCanSave}
                    />
                </div>
                <div style={bodyContainerStyle} className="scrolly">
                    <SubmitStudyTabsComponent study={study} />
                </div>
            </div>
        </FormProvider>
    );
};
