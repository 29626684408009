﻿import React from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

interface ChatDescriptionComponentProps {
    activeChannelName: string;
    activeChannelId: string;
}

export const ChatDescriptionComponent = ({ activeChannelName, activeChannelId }: ChatDescriptionComponentProps) => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const isOpenChat = activeChannelName === 'Open Chat';
    const isContributorsChat = activeChannelName === 'Contributors';

    const getDisplay = () => {
        let chatText = '';
        if (!activeChannelId) {
            return chatText;
        }
        if (isOpenChat) {
            chatText = 'Communicate with stakeholders involved in this data request.';
        }
        if (isContributorsChat) {
            chatText = isAmr
                ? 'Communicate with all data contributors and Vivli, but not the requester.'
                : 'Communicate with all data contributors and Vivli, but not the requester or IRPs.';
        }

        return chatText;
    };

    return <span>{getDisplay()}</span>;
};
