﻿import React from 'react';
import { useDatasetContext } from '@vivli/features/datasets/infrastructure/context';
import { DownloadOnlyDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Size, Styles } from '@vivli/shared/theme';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { useActiveUser } from '@vivli/core/infrastructure/context';

export const DatasetViewComponent = () => {
    const { study, requestedStudyDownloadableIpdPackageId } = useDatasetContext();
    const ipdToUse = requestedStudyDownloadableIpdPackageId ?? study.ipdDataPackageId;
    const showDownloadAdmin = useActiveUser().isVivliAdmin;
    return (
        <div>
            {
                <>
                    <FieldHeaderComponent style={Styles.FORM_ROW} title="VIEWABLE DATA PACKAGE" />
                    <div style={Styles.FORM_ROW}>
                        <div style={{ flex: 'auto', marginBottom: 20, paddingLeft: Size.PADDING }}>
                            <DownloadOnlyDataPackageFeature
                                dataPackageId={ipdToUse}
                                datasetMetaDataDoi={study.studyIPDDataPackageDoi}
                                studyOrgId={study.orgId}
                                disallowDownload={showDownloadAdmin}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
