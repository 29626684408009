import React, { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { IFormField } from '@vivli/shared/infrastructure/interface';

const valueStyle: CSSProperties = {
    paddingTop: '4px',
    border: 'none',
    fontSize: 16,
    fontWeight: Size.FontWeight.Regular,
    color: Color.DARK_GRAY,
    flex: '1 1 auto',
    resize: 'none',
    borderWidth: 'initial',
    borderStyle: 'none',
    borderColor: 'initial',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    width: '100%',
};

const fieldStyle: CSSProperties = {
    minHeight: '27px',
};

interface LinkFieldComponentProps extends IFormField {
    linkTitle: string;
    linkContent: string;
    linkStyle?: CSSProperties;
}

export const LinkFieldComponent = forwardRef((props: LinkFieldComponentProps, ref) => {
    const textInputRef = useRef<HTMLInputElement>();
    const { tooltip, focusOnLoad, linkTitle, linkContent, linkStyle } = props;

    const handleInitialFocus = () => {
        if (focusOnLoad && textInputRef.current) {
            textInputRef.current.focus();
        }
    };

    useEffect(() => {
        handleInitialFocus();
    }, []);

    const display = (
        <div style={{ ...valueStyle, ...linkStyle, cursor: 'default' }} title={linkTitle}>
            <Tippy content={linkContent} disabled={!tooltip} appendTo="parent" followCursor={true} plugins={[followCursor]}>
                <div>
                    <a href={linkContent} target="_blank">
                        {linkTitle}
                    </a>
                </div>
            </Tippy>
        </div>
    );

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.Text} inputRef={textInputRef}>
            <div style={fieldStyle}>{display}</div>
        </BaseInputFieldComponent>
    );
});
