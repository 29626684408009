import React, { CSSProperties } from 'react';
import { ProvisionViewComponent } from '@vivli/features/virtual-machine/components';
import { StudiesProvidedComponent } from './studies-provided.component';
import { ResearchHelpLinkComponent } from './research-help-link.component';

const containerStyle: CSSProperties = { display: 'flex', flexDirection: 'column' };

const textStyle: CSSProperties = { padding: '5px 0px', textAlign: 'center' };

export const ProvisionVirtualMachineComponent = () => {
    return (
        <div style={containerStyle}>
            <StudiesProvidedComponent />

            <div style={textStyle}>Once the machine is started, the request cannot be sent back for revision</div>

            <ResearchHelpLinkComponent isNewVm={true} />

            <ProvisionViewComponent />
        </div>
    );
};
