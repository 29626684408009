import { useEffect, useState } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { first } from 'rxjs/operators';

/*for use getting data request by Doi suitable for anonymous public access - fields that
are not supposed to be public won't be provided.
 */

export function useDataRequestAnonymously(dataRequestDoi: string) {
    const dataRequestService = useDataRequestsService();
    const [dataRequest, setDataRequest] = useState<IDataRequest>();

    const handleDataRequest = (dataRequest: IDataRequest) => {
        setDataRequest(dataRequest);
    };

    useEffect(() => {
        if (!dataRequestDoi) {
            return;
        }

        const sub = dataRequestService.getDataRequestAnonymouslyByDoi(dataRequestDoi).pipe(first()).subscribe(setDataRequest);

        return () => {
            sub.unsubscribe();
        };
    }, [dataRequestDoi]);

    return {
        dataRequest,
        setDataRequest,
        handleDataRequest,
    };
}
