﻿import { IApiWrapper } from '@vivli/core/infrastructure/interface';
import { AnonmyousApiService } from '@vivli/core/infrastructure/service';
import React from 'react';
import { AnonymousApiServiceContext } from '@vivli/core/infrastructure/context';

export const AnonymousApiServiceContextWrapper = ({ children, options }: IApiWrapper) => {
    const provider = new AnonmyousApiService(options);

    return provider && <AnonymousApiServiceContext.Provider value={provider}>{children}</AnonymousApiServiceContext.Provider>;
};
