import { DataRequestFilterService } from '@vivli/features/data-requests/infrastructure/service';
import { IDataRequestSummary } from '@vivli/features/data-requests/infrastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useEffect, useState } from 'react';

export function useAwaitingUserActionDataRequests(dataRequests: IDataRequestSummary[]) {
    const [requests, setRequests] = useState<IDataRequestSummary[]>();

    const user = useActiveUser();

    useEffect(() => {
        if (!dataRequests) {
            return;
        }

        const awaitingUserActionRequests = DataRequestFilterService.filterAwaitingUserActionRequests(dataRequests, user);
        setRequests(awaitingUserActionRequests);
    }, [dataRequests]);

    return requests;
}
