import React, { CSSProperties, ReactElement, useEffect } from 'react';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useActiveUser, useConfigService } from '@vivli/core/infrastructure/context';
import { ContextInjectorComponent, WelcomeModalComponent } from '@vivli/shared/components';
import {
    SearchBannerComponent,
    SearchFiltersComponent,
    SearchResultsBarComponent,
    SearchTabsComponent,
} from '@vivli/features/search/components';
import { useSearchContext } from '@vivli/features/search/infrastructure/context';
import { SearchTabEnum } from '@vivli/features/search/infrastructure/enum';
import {
    AmrSearchOptionsContextWrapper,
    CtSearchOptionsContextWrapper,
    PicoSearchOptionsContextWrapper,
} from '@vivli/features/search/infrastructure/wrappers';
import { StorageService } from '@vivli/shared/infrastructure/service';
import { LocalStorageKeyEnum } from '@vivli/shared/infrastructure/enum';
import { useDisplayBannerHook } from '@vivli/features/search/infrastructure/hook';
import { useAuthService } from '@vivli/core/authentication';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { LoginPromptComponent } from '@vivli/features/authentication/components';

const contextWrappers: ReactElement[] = [
    <AmrSearchOptionsContextWrapper />,
    <CtSearchOptionsContextWrapper />,
    <PicoSearchOptionsContextWrapper />,
];

export const SearchFeature = () => {
    const modalService = useModalService();
    const { viewRequestResultsOnly } = useConfigService();
    const activeUser = useActiveUser();
    const authService = useAuthService();
    const config = useConfigService();
    const { toggleIsShowingResults, searchResultCount, activeTab } = useSearchContext();
    const { shouldDisplayBanner } = useDisplayBannerHook();

    const handleWelcomePopup = () => {
        const showWelcomePopup = StorageService.getItem<boolean>(LocalStorageKeyEnum.ShowWelcomePopup);

        // show the welcome popup for the VIVLI (not request results app) if the user hasn't turned it
        // off and isn't logged in
        if (showWelcomePopup !== false && !viewRequestResultsOnly && !activeUser) {
            const modalId = modalService.custom(<WelcomeModalComponent onClose={() => modalService.dismiss(modalId)} />);
        }
    };

    useEffect(() => {
        handleWelcomePopup();
    }, []);

    const containerStyle: CSSProperties = {
        overflow: 'hidden',
        backgroundImage: `url(${AssetsConstant.VIVLI_BACKGROUND})`,
        display: 'grid',
        position: 'relative',
        gridTemplateRows: shouldDisplayBanner() ? 'auto minmax(200px, 1fr) 70px' : 'minmax(200px, 1fr) 70px',
        height: '100%',
    };

    const showLoginPrompt = !activeUser && config?.loginRequiredForSearch;

    const searchContainerStyle: CSSProperties = {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0 80px',
        height: activeTab !== SearchTabEnum.SecondTab ? '100%' : 'initial',
        gridTemplateRows: '90px 1fr',
        gridTemplateColumns: '100%',
        transitionProperty: 'all',
        transitionDuration: '1s',
        transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
    };

    if (showLoginPrompt) {
        return <LoginPromptComponent login={authService.login} />;
    }

    return (
        <ContextInjectorComponent contextWrappers={contextWrappers}>
            <div style={containerStyle} id="search-feature-container">
                <SearchBannerComponent />

                <div style={searchContainerStyle}>
                    <SearchTabsComponent />
                    <SearchFiltersComponent />
                </div>

                <SearchResultsBarComponent count={searchResultCount} onClick={toggleIsShowingResults} />
            </div>
        </ContextInjectorComponent>
    );
};
