import { IPubSubService } from '@vivli/shared/infrastructure/interface';
import { Realtime } from 'ably';
import { LoggerService } from '@vivli/shared/infrastructure/service';

export class PubSubService implements IPubSubService {
    private ably: Realtime;
    private env: string;
    private apiBase: string;

    public constructor(envName: string, apiBase: string) {
        this.apiBase = apiBase;
        this.env = envName;
        if (window.location.hostname === 'localhost') {
            this.env = 'Development';
        }
    }

    private initializeIfNotYetDone = () => {
        if (!this.ably) {
            this.ably = new Realtime({ authUrl: `${this.apiBase}api/VivliConfig/pubsub/auth/VivliPlatform` });
            // Connect to Ably
            this.ably.connection.on('connected', () => {
                LoggerService.info('Connected to Ably!');
            });
        }
    };
    public SubscribeToChannel = (channelName: string, callback: (message: any) => void) => {
        const channel = this.InitializeChannel(channelName);
        if (this.ably.connection.state === 'connecting') {
            setTimeout(() => {
                channel.subscribe(callback);
            }, 1000);
        } else if (this.ably.connection.state === 'connected') {
            // Subscribe to the channel
            channel.subscribe(callback);
        } else {
            LoggerService.info('Ably connection error, status: ' + this.ably.connection.state);
        }
    };

    public UnsubscribeFromChannel = (channelName: string) => {
        const channel = this.InitializeChannel(channelName);

        // unsubscribe from the channel
        channel.unsubscribe();
    };

    public SubscribeToEvent = (channelName: string, eventName: string, callback: (message: any) => void) => {
        const channel = this.InitializeChannel(channelName);
        if (this.ably.connection.state === 'connecting') {
            setTimeout(() => {
                channel.subscribe(eventName, callback);
            }, 1000);
        } else if (this.ably.connection.state === 'connected') {
            // Subscribe to the channel
            channel.subscribe(eventName, callback);
        } else {
            LoggerService.info('Ably connection error, status: ' + this.ably.connection.state);
        }
    };

    public CloseConnection = () => {
        if (!this.ably?.connection) return;
        // Register connection close callback
        this.ably.connection.on('closed', () => {
            LoggerService.info('Closed the connection to Ably.');
        });

        // Close the connection without waiting to receive messages
        this.ably.connection.close();
    };

    private InitializeChannel = (channelName: string) => {
        this.initializeIfNotYetDone();
        channelName = this.PrependChannelName(channelName);
        // Get the channel to connect to
        return this.ably.channels.get(channelName);
    };

    private PrependChannelName = (channelName: string): string => {
        if (channelName.startsWith(`${this.env}:`)) return channelName;
        return `${this.env}:${channelName}`;
    };
}
