import { createContext, useContext } from 'react';
import { IProvisionAdminOptions } from '../../../interface/src/lib/provision-admin-options';
import { ILoggingAreas } from '@vivli/features/admin-console/infrastructure/interface';

interface IAdminConsoleContext {
    handleAction: (action: string, id: string, data: string, isAsync: boolean) => void;
    isLoading: boolean;
    isUpdating: boolean;
    provisionVm?: (id: string, options: IProvisionAdminOptions, callback: () => void) => void;
    loggingData?: ILoggingAreas[];
    getLoggingDetails?: () => void;
    handleLoggingDropdownChange?: (index: number, value: string) => void;
    handleLoggingUpdate?: (updatedValues: ILoggingAreas[]) => void;
}

export const AdminConsoleContext = createContext<IAdminConsoleContext>(null);

export const useAdminConsoleContext = () => useContext(AdminConsoleContext);
