﻿import React, { CSSProperties } from 'react';
import { ButtonComponent, CopyrightComponent } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { IAcceptInviteResponse } from '@vivli/features/organizations/infrastructure/interface';
import { useNavigate } from 'react-router-dom';

const acceptedStyle: CSSProperties = {
    display: 'inline-block',
    paddingBottom: '25px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '25px',
};

const center500Style: CSSProperties = {
    textAlign: 'center',
    width: 500,
};

interface AcceptedOrgInviteComponentProps {
    acceptedInvitation: IAcceptInviteResponse;
}

export const AcceptedOrgInviteComponent = ({ acceptedInvitation }: AcceptedOrgInviteComponentProps) => {
    const modalService = useModalService();
    const navigate = useNavigate();

    const handleContinue = async () => {
        // go to orgs route...
        modalService.hide();
        if (acceptedInvitation?.invitation?.orgMembership?.isOrgAdmin) {
            navigate(`/admin/orgs/${acceptedInvitation?.invitation?.orgMembership?.orgId}`);
        } else {
            navigate('/');
        }
    };

    return (
        <div style={center500Style}>
            <div style={acceptedStyle}>
                <h2>Welcome!</h2>
                <br />
                You are now a member of {acceptedInvitation && acceptedInvitation.organizationName} <br />
                <br />
                {acceptedInvitation?.invitation?.redirectUri && (
                    <ButtonComponent
                        style={{ ...Styles.Button.BUTTON_VIVLI_BLUE }}
                        onClick={() => {
                            window.location.href = acceptedInvitation.invitation.redirectUri;
                        }}
                    >
                        Continue
                    </ButtonComponent>
                )}
                {acceptedInvitation && (!acceptedInvitation.invitation || !acceptedInvitation.invitation.redirectUri) && (
                    <ButtonComponent style={{ ...Styles.Button.BUTTON_VIVLI_BLUE }} onClick={handleContinue}>
                        Continue Home
                    </ButtonComponent>
                )}
                <br />
                <br />
                <br />
                <CopyrightComponent />
            </div>
        </div>
    );
};
