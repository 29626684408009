import React from 'react';
import { GridComponent } from '@vivli/shared/components';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { StudyStatusEnum } from '@vivli/features/studies/infrastructure/enum';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ColDef, GridApi } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useNavigate } from 'react-router-dom';

interface StudiesGridComponentProps {
    studies: IStudy[];
    type: string;
    onGridReady?: (gridApi: GridApi) => void;
    emptyMessage?: string;
    hideFloatingFilter?: boolean;
    hideHeader?: boolean;
    autoHeight?: boolean;
    dataRefreshing?: boolean;
}

export const StudiesGridComponent = ({
    studies,
    onGridReady,
    type,
    emptyMessage,
    hideFloatingFilter,
    hideHeader,
    autoHeight,
    dataRefreshing,
}: StudiesGridComponentProps) => {
    const user = useActiveUser();
    const navigate = useNavigate();
    const assignedAppType = useAssignedAppType();

    const { t, i18n } = useTranslation();

    const isHidden = () => {
        if (assignedAppType === AssignedAppTypeEnum.Amr) {
            return true;
        }

        return false;
    };

    const columnDefs: ColDef[] = [
        {
            field: 'studyTitle',
            headerName: 'Title',
            filter: true,
            sortable: true,
            tooltipField: 'studyTitle',
            headerTooltip: 'Title',
            minWidth: 500,
        },
        {
            field: 'studyStatusDisplay',
            headerName: 'Status',
            filter: true,
            sortable: true,
            headerTooltip: 'Status',
            minWidth: 200,
        },
        {
            field: 'dateDisplay',
            headerName: type,
            headerTooltip: type,
            filter: true,
            sortable: true,
        },
        {
            field: 'sponsorProtocolId',
            headerName: 'Sponsor ID',
            headerTooltip: 'Sponsor ID',
            filter: true,
            sortable: true,
        },
        {
            field: 'nctId',
            headerName: 'NCTID',
            headerTooltip: 'NCTID',
            filter: true,
            sortable: true,
            hide: isHidden(),
        },
        {
            field: 'hasSubmittedIpdString',
            headerName: 'IPD',
            headerTooltip: t('studyType'),
            filter: true,
            sortable: true,
            maxWidth: 100,
            cellStyle: { textAlign: 'center' },
        },
        {
            field: 'studyMetadataDoi',
            headerName: 'Primary DOI',
            headerTooltip: 'Primary DOI',
            filter: false,
            sortable: false,
            hide: true,
        },
    ];

    if (user.isVivliAdmin) {
        columnDefs.push({
            field: 'orgName',
            headerName: 'Contributor',
            headerTooltip: 'Contributor',
            filter: true,
            sortable: true,
            tooltipField: 'orgName',
            minWidth: 150,
        });
    }

    const handleRowClick = (study: IStudy) => {
        // Choice of page to open on this click depends on study status
        // AND (during curation phase) whether user is a legit annotator.
        // If legit annotator, always go to annotation even if
        // user is also a data provider. (Annotation page allows
        // users to switch to study page if need be).
        const userCanAnnotate =
            user.isVivliAdmin || user.orgMemberships.some((a) => a.isQaReviewer) || user.orgMemberships.some((a) => a.isAnnotator);

        switch (study.status) {
            case StudyStatusEnum.Draft:
                navigate(`/admin/studies/submitStudy/${study.id}/details`);
                break;
            case StudyStatusEnum.AwaitingCuration:
            case StudyStatusEnum.CurationInProgress:
            case StudyStatusEnum.CurationInternalReview:
            case StudyStatusEnum.AwaitingCurationQaReview:
            case StudyStatusEnum.CurationQaReviewInProgress:
            case StudyStatusEnum.CurationRevisionsRequired:
            case StudyStatusEnum.CurationBlocked:
                if (userCanAnnotate) {
                    navigate(`/admin/studies/annotateStudy/${study.id}/json`);
                    break;
                }
                navigate(`/admin/studies/${study.id}/admin-study-feature`);
                break;
            default:
                navigate(`/admin/studies/${study.id}/admin-study-feature`);
                break;
        }
    };

    const asyncUpdatePredicate = (oldVal: IStudy, newVal: IStudy) => {
        return oldVal.status !== newVal.status;
    };

    return (
        <GridComponent
            rowData={studies}
            fullWidthColumns={true}
            onRowClick={handleRowClick}
            onGridReady={onGridReady}
            columns={columnDefs}
            emptyMessage={emptyMessage}
            hideFloatingFilter={hideFloatingFilter}
            hideHeader={hideHeader}
            autoHeight={autoHeight}
            pagination={true}
            asyncUpdateKey="id"
            asyncUpdatePredicate={asyncUpdatePredicate}
            dataRefreshing={dataRefreshing}
        />
    );
};
