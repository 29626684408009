import { DirDataTypeEnum, DirStatusEnum, DirStudyNotAvailableResponseEnum } from '@vivli/features/enquiry/infastructure/enum';
import { IFacetItem } from '@vivli/features/search/infrastructure/interface';
import { IKeyValue } from '@vivli/shared/infrastructure/interface';

export const NotAvailableReasonOptions = {
    [DirStudyNotAvailableResponseEnum.None]: 'None',
    [DirStudyNotAvailableResponseEnum.NotCompleted]: 'Study Completion Date criteria is not yet met',
    [DirStudyNotAvailableResponseEnum.Prohibited]: 'Data Sharing Prohibited by Consent, Legal, Regulatory, or Contractual Constraints',
    [DirStudyNotAvailableResponseEnum.NotAuthorized]: 'Indications have not received market authorization',
    [DirStudyNotAvailableResponseEnum.InsufficientData]:
        'Likelihood of re-identification of patients given small number of patients and/or involves a rare disease',
    [DirStudyNotAvailableResponseEnum.NotResponsible]: 'Not responsible for Data Sharing',
    [DirStudyNotAvailableResponseEnum.Other]: 'Other (See Discussion)',
};
export const DirStatusOptions = {
    [DirStatusEnum.None]: 'None',
    [DirStatusEnum.New]: 'New',
    [DirStatusEnum.RequesterCommented]: 'Response from requester',
    [DirStatusEnum.DCCommented]: 'Response from data contributor',
    [DirStatusEnum.VivliCommented]: 'Response from Vivli',
    [DirStatusEnum.AvailableUnlisted]: 'Eligible for Request as an unlisted study',
    [DirStatusEnum.AvailableListed]: 'Study is Listed',
    [DirStatusEnum.NotAvailable]: 'Not Available',
};
export const DataTypeOptions: IKeyValue[] = [
    { key: DirDataTypeEnum.None.toString(), value: 'None' },
    { key: DirDataTypeEnum.ClinicalDocuments.toString(), value: 'Clinical Documents' },
    { key: DirDataTypeEnum.ParticipantData.toString(), value: 'ParticipantData' },
    { key: DirDataTypeEnum.SummaryData.toString(), value: 'SummaryData' },
];

export const DirDropDownConstants = {
    NotAvailableReasonOptions,
    DataTypeOptions,
    DirStatusOptions,
};
