﻿import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { DuaDocumentStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';

export const useDuaDocumentTableDataHook = () => {
    const { duaDocuments, setDuaDocuments } = useDataRequestContext();
    const vivliConfig = useVivliConfig();

    const alphabeticalSort = (a, b) => {
        return a.title < b.title ? -1 : 1;
    };

    const templateOptions = vivliConfig?.duaDocumentTypeItems
        ?.map(
            (item) =>
                ({
                    title: item.displayName,
                    value: item.code,
                } as IDropdownMenuItem)
        )
        .sort(alphabeticalSort);

    const handleUpdateRow = (index, field, value) => {
        const updatedData = duaDocuments.map((item, idx) => {
            switch (field) {
                case 'forPrincipalInvestigatorOrLeadResearcher':
                    return { ...item, forPrincipalInvestigatorOrLeadResearcher: value && idx === index };
                case 'duaDocumentType':
                    const duaDocument = vivliConfig?.duaDocumentTypeItems?.find((item) => item.code === value);
                    return { ...item, [field]: idx === index ? duaDocument : item[field] };
                default:
                    return { ...item, [field]: idx === index ? value : item[field] };
            }
        });

        setDuaDocuments(updatedData);
    };

    const handleAddRow = () => {
        setDuaDocuments([
            ...duaDocuments,
            {
                rorId: '',
                institutionName: '',
                duaDocumentType: {},
                executionDate: '',
                expirationDate: '',
                master: false,
                forPrincipalInvestigatorOrLeadResearcher: false,
                status: DuaDocumentStatusEnum.No_Status_Yet,
                duaDocumentNotes: '',
            },
        ]);
    };

    const handleDeleteRow = (index) => {
        const newData = [...duaDocuments];
        newData.splice(index, 1);
        setDuaDocuments(newData);
    };

    const handleDuaDateChange = (index, field, value) => {
        const updatedDuaDocuments = [...duaDocuments];
        const updatedValue = value !== null ? new Date(value) : null;
        updatedDuaDocuments[index] = {
            ...updatedDuaDocuments[index],
            [field]: updatedValue,
        };
        setDuaDocuments(updatedDuaDocuments);
    };

    return {
        templateOptions,
        handleUpdateRow,
        handleAddRow,
        handleDeleteRow,
        handleDuaDateChange,
        duaDocuments,
    };
};
