import React from 'react';
import { ButtonComponent } from '../buttons/button.component';

interface AdminHeaderButtonProps {
    onClick?: (e) => void;
    isLoading?: boolean;
    title: string;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
    dataId?: string;
}

export const AdminHeaderButtonComponent = ({ onClick, isLoading, title, isDisabled, style, className, dataId }: AdminHeaderButtonProps) => (
    <ButtonComponent
        onClick={onClick}
        isLoading={isLoading}
        style={{
            ...style,
        }}
        disabled={isDisabled}
        className={className}
        dataId={dataId}
    >
        {title}
    </ButtonComponent>
);
