import React, { CSSProperties } from 'react';
import { FieldHeaderComponent, InfoPopoverComponent, TextAreaFormFieldComponent } from '@vivli/shared/components';
import { StatisticalAnalysisPlanHelpComponent } from './statistical-analysis-plan-help.component';
import { DataRequestHintsConstant, DTIStatisticalAnalysisPlanForm } from '@vivli/shared/infrastructure/constants';
import { Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: Size.INSET_SIZE,
};

const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: Size.PADDING,
    position: 'relative',
};

const infoPopupStyle: CSSProperties = {
    minHeight: '30px',
    justifyContent: 'flex-end',
};

export const StatisticalAnalysisPlanFormComponent = () => {
    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title="GENERAL" />
            <InfoPopoverComponent content={<StatisticalAnalysisPlanHelpComponent />} size={20} style={infoPopupStyle} />
            <div style={bodyStyle}>
                <TextAreaFormFieldComponent
                    name={'statisticalAnalysisPlan.description'}
                    label={'Describe how you will analyze the requested clinical study data'}
                    style={{ marginBottom: '20px' }}
                    dataId={DTIStatisticalAnalysisPlanForm.StatisticalAnalysisPlanDescriptionTextBox}
                />
                <TextAreaFormFieldComponent
                    name={'statisticalAnalysisPlan.analysisLocation'}
                    label={DataRequestHintsConstant.statisticalAnalysisPlan.analysisLocation}
                />
            </div>
        </div>
    );
};
