import React, { CSSProperties, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Size } from '@vivli/shared/theme';
import { ButtonComponent, TextAreaFormFieldComponent, TextFormFieldComponent } from '@vivli/shared/components';
import { useSafetyConcernForm } from '@vivli/features/data-requests/infrastructure/hook';
import {
    useDataRequestContext,
    useDataRequestsService,
    useSafetyConcernsContext,
} from '@vivli/features/data-requests/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { ISafetyConcernReport } from '@vivli/features/data-requests/infrastructure/interface';

const fieldContainerStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    minHeight: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
};

const buttonContainerStyle: CSSProperties = {
    marginTop: Size.FIELD_SEPARATION,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40px',
};


const message =
    <div style={{display: "flex", gap: "10px", flexDirection: "column"}}>
        <div>The safety concern screen is provided so that you can alert the contributor of one or more of the studies
            you are analyzing to the possibility of a safety concern with the treatment that was studied. Accordingly,
            submitting a safety concern generates an urgent alert to the contributors of data for your analysis. If you
            are asking a question, or providing feedback that is not safety-related to Vivli or to any of the data
            contributors, please use the Chat for that.
        </div>
        <div>Is the message you are about to submit a concern about the safety of a treatment?</div>
    </div>
export const ReportSafetyConcernFormComponent = () => {
    const activeUser = useActiveUser();

    const safetyDefaultValues: ISafetyConcernReport = {
        name: activeUser.name,
        email: activeUser.email,
        phoneNumber: '',
        safetyConcernDescription: '',
    };

    const { dataRequest } = useDataRequestContext();
    const formApi = useSafetyConcernForm(safetyDefaultValues);
    const dataRequestsService = useDataRequestsService();
    const toastService = useToastService();
    const modalService = useModalService();
    const { addSafetyConcern } = useSafetyConcernsContext();
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleOnClick = () => {
        modalService.confirm(message, {
            confirmText: 'Yes',
            cancelText: 'No',
            onConfirm: () => handleSubmit()
        })
    }
    const handleSubmit = () => {
        formApi.handleSubmit(({ name, email, phoneNumber, safetyConcernDescription }) => {
            const safetyConcernSubmitter = {
                name,
                email,
                phoneNumber,
                userId: activeUser.userId,
            };

            setIsSubmitting(true);
            dataRequestsService
                .postSafetyConcern(dataRequest.id, {
                    safetyConcernSubmitter,
                    safetyConcernDescription,
                })
                .pipe(
                    first(),
                    finalize(() => {
                        setIsSubmitting(false);
                    })
                )
                .subscribe(
                    () => {
                        toastService.success('Successfully Submitted your Safety Concern');
                        formApi.reset();
                        formApi.trigger();
                        addSafetyConcern({
                            dataRequestId: dataRequest.id,
                            dataRequestTitle: dataRequest.requestTitle,
                            safetyConcernSubmitter,
                            submittedDateTime: new Date(),
                            safetyConcernDescription,
                        });
                    },
                    (e) => {
                        var cleanError = e.replace(/\\r|\\n|"/g, ' ').trim();
                        modalService.error(
                            'An error occured submitting safety concern.  Please try again or contact Vivli support. Message: ' + cleanError
                        );
                    }
                );
        })();
    };

    useEffect(() => {
        formApi.trigger();
    }, []);

    return (
        <FormProvider {...formApi}>
            <div>
                <div style={{ marginTop: Size.FIELD_SEPARATION }}>
                    <h4>
                        Supply your contact information and safety concern description below, then click 'Submit Safety Concern' to
                        continue.
                    </h4>
                </div>

                <div style={fieldContainerStyle}>
                    <TextFormFieldComponent name="name" label="Name" />
                </div>

                <div style={fieldContainerStyle}>
                    <TextFormFieldComponent name="email" label="Email Address" style={{ width: '48%', flexGrow: 0 }} />

                    <TextFormFieldComponent name="phoneNumber" label="Phone Number" style={{ width: '48%', flexGrow: 0 }} />
                </div>

                <div style={fieldContainerStyle}>
                    <TextAreaFormFieldComponent name="safetyConcernDescription" label="Describe the Safety Concern" />
                </div>


                <div style={buttonContainerStyle}>
                    <ButtonComponent
                        disabled={Object.keys(formApi.formState.errors)?.length > 0}
                        isLoading={isSubmitting}
                        onClick={handleOnClick}
                    >
                        Submit Safety Concern
                    </ButtonComponent>
                </div>
            </div>
        </FormProvider>
    );
};
