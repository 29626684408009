import React, { CSSProperties, ReactNode, useState } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { ButtonComponent } from '../buttons/button.component';
import { MenuItemComponent } from '../menu-item.component';
import { SlideDown } from 'react-slidedown';

interface TabsComponentProps {
    innerStyle?: React.CSSProperties;
    outerStyle?: React.CSSProperties;
    outerClassName?: string;
    innerClassName?: string;
    children?: any;
    selectedIndex?: number;
    commandTitle?: string;
    commandOnClick?: () => void;
    commandImage?: string;
    commandOptions?: { title: string; onClick?: () => void }[];
    marginBottom?: number;
    rightHeaderContent?: ReactNode;
}

const tabsBodyStyle = (commandTitle: string, innerStyle: CSSProperties) =>
    ({
        position: 'relative',
        backgroundColor: Color.WHITE,
        display: 'flex',
        alignItems: 'center',
        boxShadow: Size.BOX_SHADOW_EXTRA_LIGHT,
        height: Size.HEIGHT_FOR_ROW - Size.ROW_BOTTOM_MARGIN,
        borderTopRightRadius: commandTitle != null ? 6 : 0,
        borderBottomRightRadius: commandTitle != null ? 6 : 0,
        '@media print': {
            display: 'none',
        },
        ...innerStyle,
    } as React.CSSProperties);

export const TabButtonsComponent = ({
    innerStyle,
    outerStyle,
    outerClassName,
    innerClassName,
    children,
    commandTitle,
    commandOnClick,
    commandImage,
    commandOptions,
    marginBottom,
    rightHeaderContent,
}: TabsComponentProps) => {
    const [slideDownOpen, setSlideDownOpen] = useState(false);
    const tabsClassName = 'tabButton_' + (commandTitle ? commandTitle.replace(/\s/gi, '_') : 'empty');

    const handleCommandClick = () => {
        commandOnClick && commandOnClick();
        commandOptions && setSlideDownOpen(!slideDownOpen);
    };

    return (
        <div
            className={outerClassName}
            style={{
                height: Size.HEIGHT_FOR_ROW - Size.ROW_BOTTOM_MARGIN,
                marginBottom: marginBottom === undefined ? Size.SMALL_INSET_SIZE : marginBottom,
                ...outerStyle,
            }}
        >
            <div className={innerClassName} style={tabsBodyStyle(commandTitle, innerStyle)}>
                {children}
                {rightHeaderContent}
                {commandTitle && (
                    <ButtonComponent onClick={handleCommandClick} style={Styles.Button.TAB_BUTTON} className={tabsClassName}>
                        <img src={commandImage} style={{ marginRight: Size.PADDING }} />
                        {commandTitle}
                    </ButtonComponent>
                )}
                {commandOptions && (
                    <SlideDown className={'pure-menu dropdown-slidedown'}>
                        {slideDownOpen && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {commandOptions.map((opt, i) => (
                                    <MenuItemComponent
                                        key={i}
                                        title={opt.title}
                                        dataId={opt.title.replace(' ', '_')}
                                        onClick={() => {
                                            opt.onClick && opt.onClick();
                                            setSlideDownOpen(false);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </SlideDown>
                )}
            </div>
        </div>
    );
};
