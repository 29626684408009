﻿import { useDataRequest } from '@vivli/features/data-requests/infrastructure/hook';
import { DataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import React from 'react';
import { DataRequestFormComponent } from '@vivli/features/data-requests/components';
import { useParams } from 'react-router';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { DataRequestContextWrapper, StatusUpdateContextWrapper } from '@vivli/features/data-requests/infrastructure/wrappers';
import { NotificationContextWrapper } from '@vivli/shared/features/notification/infrastructure/wrappers';

export const DataRequestDraftFeature = () => {
    const { requestId } = useParams();
    const { dataRequest } = useDataRequest(requestId);

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <>
            <DataRequestContextWrapper dataRequest={dataRequest}>
                <DataRequestContext.Consumer>
                    {({ organizations }) => {
                        if (!organizations) {
                            return <LoadIndicatorCenteredComponent />;
                        }
                        return (
                            <StatusUpdateContextWrapper>
                                <NotificationContextWrapper>
                                    <DataRequestFormComponent />
                                </NotificationContextWrapper>
                            </StatusUpdateContextWrapper>
                        );
                    }}
                </DataRequestContext.Consumer>
            </DataRequestContextWrapper>
        </>
    );
};
