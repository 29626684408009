import React from 'react';
import { OrgDetailsFormComponent } from '@vivli/features/organizations/components';
import { OrganizationDetailsContextWrapper } from '@vivli/features/organizations/infrastructure/wrappers';

export const OrganizationDetailsFeature = () => {
    return (
        <OrganizationDetailsContextWrapper>
            <OrgDetailsFormComponent />
        </OrganizationDetailsContextWrapper>
    );
};
