﻿import joi, { ObjectSchema } from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';
import { IInvestigator } from '@vivli/features/studies/infrastructure/interface';
import { orcidInputSchema } from '@vivli/shared/infrastructure/schema';

export const studyPiFormSchema: ObjectSchema<IInvestigator> = joi
    .object({
        oRCIDId: orcidInputSchema('ORCID iD', false),
    })
    .options(JoiValidationOptionsConstant);

export function useStudyPiFormHook(principalInvestigator: IInvestigator, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'onChange') {
    return useForm({
        mode,
        resolver: joiResolver(studyPiFormSchema.options(JoiValidationOptionsConstant)),
        defaultValues: principalInvestigator,
        reValidateMode: 'onChange',
    });
}
