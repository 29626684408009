﻿import React from 'react';
import {
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    LinkFieldComponent,
    TextFormFieldComponent,
    threeAcrossStyle,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import { useOrganizations } from '@vivli/features/organizations/infrastructure/hook';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useMapListToFormHook } from '@vivli/shared/infrastructure/hook';
import { useFormContext } from 'react-hook-form';
import { DTICommonConst, DTICTFormFields, DTIDropdownMenu } from '@vivli/shared/infrastructure/constants';
import { useDoiTransform } from '@vivli/features/studies/infrastructure/hook';

interface AdminCommonSubmissionDetailsComponentProps {
    user?: IUser;
    study?: IStudy;
}

//common elements for vivli admins, submission info, both
//amr and ct
export const AdminCommonSubmissionDetailsComponent = ({ user, study }: AdminCommonSubmissionDetailsComponentProps) => {
    const alphabeticalSort = (a, b) => {
        return a.title < b.title ? -1 : 1;
    };
    const getListOptions = (list: IOrganization[]) => {
        return list?.map(({ id, name }) => ({ value: id, title: name })).sort(alphabeticalSort);
    };

    const userIsReadOnly = !user.isVivliAdmin;
    const formApi = useFormContext();
    const { organizations, irpApproverOrgs, qaReviewerOrgs, annotatorOrgs } = useOrganizations();
    const orgCodeItems = getListOptions(organizations);
    const irpApproverOrgItems = getListOptions(irpApproverOrgs);
    const annotatorOrgItems = getListOptions(annotatorOrgs);
    const qaReviewerOrgItems = getListOptions(qaReviewerOrgs);
    const showLinkDoi = study.studyMetadataDoi?.indexOf('DOINotCreated') < 0;
    const { transformDoiUrl } = useDoiTransform();

    //set name + code values for org (data contributor org)
    useMapListToFormHook({
        formFieldName: 'orgId',
        formFieldToUpdate: 'orgCode',
        listOfObjects: orgCodeItems,
        formApi: formApi,
    });

    useMapListToFormHook({
        formFieldName: 'orgId',
        formFieldToUpdate: 'orgName',
        listOfObjects: orgCodeItems,
        formApi: formApi,
    });

    //set name value for irp approver org
    useMapListToFormHook({
        formFieldName: 'irpApproverOrgId',
        formFieldToUpdate: 'irpApproverOrgName',
        listOfObjects: irpApproverOrgItems,
        formApi: formApi,
    });

    //set name value for annotator org
    useMapListToFormHook({
        formFieldName: 'annotatorOrgId', //name from form field
        formFieldToUpdate: 'annotatorOrgName',
        listOfObjects: annotatorOrgItems,
        formApi: formApi,
    });

    //set name value for qa reviewer org
    useMapListToFormHook({
        formFieldName: 'qaReviewerOrgId', //name from form field
        formFieldToUpdate: 'qaReviewerOrgName',
        listOfObjects: qaReviewerOrgItems,
        formApi: formApi,
    });

    return (
        <div style={{ width: '100%' }}>
            <FieldHeaderComponent title={'SUBMISSION DETAILS'} />
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent
                    name="status"
                    label="Status of Submission"
                    style={threeAcrossStyle}
                    readonly={true}
                    dataId={DTICTFormFields.Status}
                />
                {showLinkDoi ? (
                    <LinkFieldComponent
                        label="Vivli DOI"
                        linkContent={transformDoiUrl(study.studyMetadataDoi) /*11301*/}
                        linkTitle={study.studyMetadataDoi}
                        style={threeAcrossStyle}
                        dataId={DTICommonConst.HeaderApprovalButton('VivliDoi')}
                    />
                ) : (
                    <TextFormFieldComponent
                        name={`studyMetadataDoi`}
                        label="Vivli DOI"
                        style={threeAcrossStyle}
                        readonly={true}
                        dataId={DTICTFormFields.StudyDoi}
                    />
                )}

                <TextFormFieldComponent
                    name="alternateDoi"
                    label="Alternate DOI"
                    style={threeAcrossStyle}
                    readonly={userIsReadOnly}
                    dataId={DTICTFormFields.AlternateDoi}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={`orgId`}
                    label={'Submission Org Codes (Name)'}
                    items={orgCodeItems}
                    style={twoAcrossStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('OrgId')}
                />
                <DropdownFormFieldComponent
                    name={`irpOrgId`}
                    label={'Approving Organization Name'}
                    items={irpApproverOrgItems}
                    style={twoAcrossStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('IrpOrgId')}
                />
            </div>
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={`annotatorOrgId`}
                    label={'Curation Organization'}
                    items={annotatorOrgItems}
                    style={twoAcrossStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('AnnotatorOrgId')}
                />
                <DropdownFormFieldComponent
                    name={`qaReviewerOrgId`}
                    label={'Curation QA Reviewer Organization'}
                    items={qaReviewerOrgItems}
                    style={twoAcrossStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('QaReviewerOrgId')}
                />
            </div>
        </div>
    );
};
