﻿import React from 'react';
import { Styles } from '@vivli/shared/theme';
import { FieldHeaderComponent, fieldHeaderStyle } from '@vivli/shared/components';

//Todo : come back in future and make this it's own component
const getConditions = (conditions) => {
    if (conditions && conditions.length > 0) {
        conditions.map((c, i) => {
            const isOredCondition = c && c !== null && c.indexOf('-OR-') !== -1;
            if (isOredCondition) {
                c.value = c && c.replace('-OR-', '');
            }
            return (
                <li key={i}>
                    {c}
                    {isOredCondition && (
                        <ul style={Styles.NON_BULLET_LIST}>
                            <li>OR</li>
                        </ul>
                    )}
                </li>
            );
        });
    }
    return <></>;
};

export const PicoPopulationComponent = ({ study }) => {
    const showMinAge = study?.participantEligibility?.minimumAge?.ageNum && study?.participantEligibility?.minimumAge?.timeUnit;
    const minAge = showMinAge
        ? study.participantEligibility.minimumAge.ageNum + ' ' + study.participantEligibility.minimumAge.timeUnit
        : '';
    const showMaxAge = study?.participantEligibility?.maximumAge?.ageNum && study?.participantEligibility?.maximumAge.timeUnit;
    const maxAge = showMaxAge
        ? study.participantEligibility.maximumAge.ageNum + ' ' + study.participantEligibility.maximumAge.timeUnit
        : '';

    return (
        <>
            <FieldHeaderComponent title={'POPULATION '} style={fieldHeaderStyle} />
            <div>
                <table style={Styles.FULL_WIDTH_TABLE}>
                    <tbody>
                        <tr>
                            <th style={Styles.TABLE_HEADER}>{'Sex'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Minimum Age'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Maximum Age'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Accepts Healthy Volunteers?'}</th>
                            <th style={Styles.TABLE_HEADER}>{'Main Eligibility Criteria'}</th>
                        </tr>
                        <tr>
                            <td>{study?.participantEligibility?.sex ?? ''}</td>
                            <td>{minAge}</td>
                            <td>{maxAge}</td>
                            <td>{study?.participantEligibility?.acceptsHealthyVolunteers ?? ''}</td>
                            <td>{getConditions(study?.conditions)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
