import React, { createContext, CSSProperties, MutableRefObject, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Color } from '@vivli/shared/theme';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import { InfoPopoverComponent } from '../info-popover.component';
import { EllipsisTextComponent } from '../ellipsis-text.component';

const containerStyle = (divStyle): CSSProperties => ({
    backgroundColor: 'white',
    borderBottom: '2px solid white',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0 3px 3px -3px',
    ...divStyle,
});

const checkboxStyle: CSSProperties = {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderBottom: 'none',
    flexDirection: 'row',
    flexGrow: 0,
};

const labelFieldStyle: CSSProperties = {
    userSelect: 'none',
    padding: '4px 15px',
    color: 'rgb(96, 104, 111)',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
};

const labelContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    flexDirection: 'row-reverse',
};

const countLabelContainerStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'rgb(96, 104, 111)',
    fontSize: '15px',
};

export const BaseFormFieldContext = createContext<MutableRefObject<HTMLInputElement>>(null);

export const useBaseFormFieldContext = () => useContext(BaseFormFieldContext);

interface BaseFormFieldComponentProps {
    label?: string;
    style?: CSSProperties;
    hint?: ReactNode;
    type: FormFieldTypeEnum;
    labelStyle?: CSSProperties;
    divStyle?: CSSProperties;
    error?: string;
    hidePopoverError?: boolean;
    widerHint?: boolean;
    truncateLabel?: boolean;
    children?: ReactElement;
    inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLDivElement>;
    showCharacterCount?: {
        currentCount: number;
        max: number;
    };
}

export const BaseInputFieldComponent = ({
    style,
    label,
    hint,
    type,
    error,
    hidePopoverError,
    truncateLabel,
    labelStyle,
    divStyle,
    children,
    inputRef,
    showCharacterCount,
    widerHint,
}: BaseFormFieldComponentProps) => {
    const [isFocused, setIsFocused] = useState(false);

    const getFieldColor = (isLabel = false) => {
        let color = isLabel ? 'rgb(96, 104, 111)' : style?.backgroundColor || 'white';

        if (error) {
            color = Color.ERROR_RED;
        } else if (isFocused) {
            color = Color.VIVLI_LIGHT_BLUE;
        }

        return color;
    };

    const getLabelStyle = (): CSSProperties => {
        const color = getFieldColor(true);

        return {
            ...labelFieldStyle,
            color,
            ...labelStyle,
        };
    };

    const getContainerStyle = (): CSSProperties => {
        const color = getFieldColor();

        let result: CSSProperties = {
            ...containerStyle(divStyle),
            borderBottomColor: `${color}`,
        };

        if (type === FormFieldTypeEnum.Checkbox) {
            result = {
                ...result,
                ...checkboxStyle,
            };
        }

        return {
            ...result,
            ...style,
        };
    };

    const isErrorVisible = () => {
        return !hidePopoverError && !!error && (isFocused || type === FormFieldTypeEnum.CheckboxList);
    };

    useEffect(() => {
        // handle cases where the field is readonly and doesn't have a ref
        if (!inputRef.current) {
            return;
        }

        inputRef.current.onfocus = () => {
            setIsFocused(true);
        };

        inputRef.current.onblur = () => {
            setIsFocused(false);
        };
    }, []);

    return (
        <div style={getContainerStyle()}>
            <div style={countLabelContainerStyle}>
                {label !== undefined && (
                    <div
                        style={labelContainerStyle}
                        title={label}
                        onClick={() => {
                            inputRef.current?.click();
                        }}
                    >
                        {hint && (
                            <div style={{ padding: '0 5px' }}>
                                <InfoPopoverComponent widerHint={widerHint} content={hint} size={15} />
                            </div>
                        )}
                        <label style={getLabelStyle()}>
                            <EllipsisTextComponent text={label} bypassTruncate={!truncateLabel} widthPerCharacter={7} />
                        </label>
                    </div>
                )}
                {showCharacterCount && (
                    <div style={{ margin: '0 15px', width: '220px' }}>
                        <span style={{ marginRight: '10px' }}>Character Count: </span>
                        {showCharacterCount.currentCount}/{showCharacterCount.max}
                    </div>
                )}
            </div>
            <Tippy
                visible={isErrorVisible()}
                content={error}
                placement="top"
                className="tippy-error"
                offset={[0, 35]}
                appendTo={'parent'}
                zIndex={100}
            >
                {children}
            </Tippy>
        </div>
    );
};
