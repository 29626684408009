import { useEffect } from 'react';

export function useBooleanFilterHook({
    key,
    selected,
    onChange,
}: {
    key: string;
    selected: boolean;
    onChange: (key: string, selected: boolean) => void;
}) {
    useEffect(() => {
        onChange(key, selected);
    }, [selected]);
}
