﻿export enum StudyDatasetAdminCtAmrTabEnum {
    StudyDetails = 'Study Details',
    DatasetDetails = 'Dataset Details',
    AdministrativeDetails = 'Administrative Details',
    AmrDocuments = 'Dataset Documents',
    StudyDocuments = 'Study Documents',
    ResearchTeam = 'Research Team',
    History = 'History',
    Json = 'JSON',
    DataPackage = 'Data Package',
    Chat = 'Chat',
    Usage = 'Usage',
    Requests = 'Requests',
}
