import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { EllipsisTextComponent } from '../ellipsis-text.component';

interface AdminHeaderTitleProps {
    title: string;
    style?: CSSProperties;
    truncateText?: boolean;
    widthPerCharacter?: number;
}

const titleStyle: CSSProperties = {
    fontSize: 30,
    fontWeight: Size.FontWeight.XLight,
    color: Color.DARK_GRAY,
    lineHeight: '27px',
    paddingRight: Size.PADDING,
    wordWrap: 'break-word',
    width: '100%',
    marginRight: 5,
    paddingBottom: '10px',
};

export const AdminHeaderTitleComponent = ({ title, style, truncateText, widthPerCharacter = 12 }: AdminHeaderTitleProps) => (
    <span style={{ ...titleStyle, ...style }}>
        {truncateText && title ? <EllipsisTextComponent text={title} widthPerCharacter={widthPerCharacter} /> : title}
    </span>
);
