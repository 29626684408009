import React, { CSSProperties } from 'react';
import { AdminHeaderTitleComponent } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { ResultRequestDataPackageFeature } from '@vivli/shared/features/data-package/feature';

const explanationTextStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    color: Color.DARK_GRAY,
    padding: '20px 0',
};

const containerStyle: CSSProperties = {
    height: '100%',
    width: '100%',
    fontFamily: 'sans-serif',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
};

interface NewResultRequestComponentProps {
    dataPackageId: string;
    resultsRequestId: string;
    dataRequestId: string;
    onPackageSubmitted: () => void;
}

export const NewResultRequestComponent = ({
    dataPackageId,
    resultsRequestId,
    onPackageSubmitted,
    dataRequestId,
}: NewResultRequestComponentProps) => {
    return (
        <div style={containerStyle}>
            <div>
                <AdminHeaderTitleComponent title={`Requesting export of research results`} />
            </div>

            <div style={explanationTextStyle}>
                <p>
                    To request export of research results, use Drag and Drop to drop the requested files onto the "Drop files here" control,
                    then click submit when they are complete. If you have more than 6 files or if you have sub-folders, please zip the files
                    using 7-zip (Right-click on the folder and choose 7-zip -{'>'} Add to foldername.zip).
                </p>
                <p>
                    When the results have been reviewed and approved, you will connect to the main Vivli Platform to download the results.
                    Reminder - exporting individual participant data is not allowed.
                </p>
            </div>
            {dataPackageId && (
                <ResultRequestDataPackageFeature
                    dataPackageId={dataPackageId}
                    dataRequestId={dataRequestId}
                    resultRequestId={resultsRequestId}
                    onPackageSubmitted={onPackageSubmitted}
                />
            )}
        </div>
    );
};
