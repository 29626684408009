import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import {
    useAdminConsoleContext,
    useAdminConsoleLookupIdContext,
    useAdminConsoleService,
} from '@vivli/features/admin-console/infrastructure/context';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { finalize, first } from 'rxjs/operators';
import {
    ButtonComponent,
    CheckboxComponent,
    DatePickerComponent,
    DropdownFieldComponent,
    LoadIndicatorComponent,
    TextFieldComponent,
} from '@vivli/shared/components';
import { IProvisionAdminOptions } from '@vivli/features/admin-console/infrastructure/interface';
import moment from 'moment';
import { VMSizeEnum, VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { useCopyToClipboardHook, useVmPubSubHook } from '@vivli/shared/infrastructure/hook';
import { IVirtualMachine, IVirtualMachineUser } from '@vivli/features/virtual-machine/infrastructure/interface';
import { VmUserDetailComponent } from './vm-user-details.component';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';

const dropdownButtonStyle: CSSProperties = {
    marginLeft: '10px',
};

const marginLeftStyle: CSSProperties = {
    marginLeft: 10,
};
const marginBottom5Style: CSSProperties = {
    marginBottom: '5px',
};
const fontBoldStyle: CSSProperties = {
    fontWeight: '700',
};
const templateSectionStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '40% auto',
    width: '75%',
    marginBottom: '5px',
};
const lookupSectionStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '55%',
    marginBottom: '20px',
};

const dropdownSectionStyle: CSSProperties = {
    margin: '15px 0',
    display: 'grid',
    gridTemplateColumns: '200px 350px 200px',
};

const statusSectionStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    width: '80%',
    marginBottom: '20px',
};
const buttonStyle: CSSProperties = {
    marginLeft: 15,
};
const marginLeft50Style: CSSProperties = {
    marginLeft: 50,
};
const templateBodyStyle: CSSProperties = {
    overflow: 'auto',
};

export function AdminVirtualMachineComponent() {
    const dropDownResetValue = Object.values(VmStatusEnum).map((value) => ({ title: value, value: value }));

    const useLookUpRef = useRef<any>();
    const modalService = useModalService();
    const adminConsoleService = useAdminConsoleService();
    const [isLoading, setIsLoading] = useState(false);
    const [dataRequest, setDataRequest] = useState<IDataRequest>();
    const [virtualMachine, setVirtualMachine] = useState<IVirtualMachine>();
    const [deprovisionedDate, setDeprovisionedDate] = useState<Date>();
    const [selectedStatus, setSelectedStatus] = useState<string>(null);
    const [userVmDetails, setUserVmDetails] = useState<IVirtualMachineUser[]>([]);
    const [researchVmType, setResearchVmType] = useState([]);
    const [selectedVmSize, setSelectedVmSize] = useState<string>();
    const [sasSelected, setSasSelected] = useState(false);
    const [stataSelected, setStataSelected] = useState(false);
    const [operationStatus, setOperationStatus] = useState<string>();
    const vivliConfig = useVivliConfig();
    const context = useAdminConsoleContext();
    const toastService = useToastService();
    const { copyToClipboard } = useCopyToClipboardHook();
    const { vmLookupId, setVmLookupId } = useAdminConsoleLookupIdContext();

    const btnClick = (action: string, isAsync = false) => {
        vmPubSub.subscribeToVmStatusChanged();
        vmPubSub.subscribeToVmMessages(updateProcessingMessages);
        context.handleAction(action, virtualMachine.id, null, isAsync);
    };
    const handleError = (error) => {
        modalService.error(error);
    };

    const handleInputChangeVmRequest = (e) => {
        const value = e.currentTarget.value;
        setVmLookupId(value);
    };
    const handleLookUpClick = (e) => {
        e.stopPropagation();
        const requestId = vmLookupId.padStart(8, '0');
        if (virtualMachine && virtualMachine.dataRequestDoi != requestId) {
            vmPubSub.CloseChannel();
            setOperationStatus(null);
        }
        updateVMStatus();
    };

    const updateVMStatus = () => {
        setIsLoading(true);
        const requestId = vmLookupId.padStart(8, '0');
        adminConsoleService
            .getVMData(requestId)
            .pipe(
                first(),
                finalize(() => setIsLoading(false))
            )
            .subscribe((data) => {
                setDataRequest(data.item2);
                let virtualMachine = { ...data.item1 };
                if (!data.item1) {
                    virtualMachine = {
                        ...virtualMachine,
                        id: '',
                        status: VmStatusEnum.vmStatusNotCreated,
                        size: VMSizeEnum.None,
                        licenseSAS: false,
                        licenseSTATA: false,
                        deProvisionedDateTime: null,
                        deletedOnDateTime: null,
                    };
                }
                addVmUserDetails(data);
                setVirtualMachine(virtualMachine);
            }, handleError);
    };
    const vmPubSub = useVmPubSubHook(virtualMachine, updateVMStatus);

    const addVmUserDetails = (details) => {
        if (!details.item1) {
            setUserVmDetails([]);
            return;
        }
        const authorizedUsersDetails = details.item1.authorizedUsers;
        const researchTeamDetails = details.item2.researchTeam.researchTeamDetails;

        const userDetails = authorizedUsersDetails
            .filter((authedUser) => researchTeamDetails.some((rtd) => rtd.userId === authedUser.id))
            .map((authedUser) => {
                const teamMember = researchTeamDetails.find((teamMember) => teamMember.userId === authedUser.id);
                return {
                    email: teamMember.email,
                    id: authedUser.id,
                    name: teamMember.firstName + ' ' + teamMember.lastName,
                    username: authedUser.username,
                };
            });
        setUserVmDetails(userDetails);
    };
    const leadResearcherName = () => {
        return (
            <span>
                {' '}
                {dataRequest.researchTeam.leadResearcherTeamMemberDetail.firstName}
                {dataRequest.researchTeam.leadResearcherTeamMemberDetail.lastName},{' '}
                {dataRequest.researchTeam.leadResearcherTeamMemberDetail.email}
            </span>
        );
    };

    const dateLookUp = (date: Date | string) => {
        return <span>{!date || date === 'Not Set' ? 'Not Set' : moment(date).format('YYYY/DD/MM')}</span>;
    };

    const handleVMProvisioning = () => {
        //options are hardcoded for now
        const VmOptions: IProvisionAdminOptions = {
            licenseSAS: sasSelected,
            licenseSTATA: stataSelected,
            size: selectedVmSize,
            comment: '',
        };
        context.provisionVm(dataRequest.id, VmOptions, () => vmPubSub.startVmPolling());
    };

    const handleStatusReset = (e) => {
        e.stopPropagation();
        context.handleAction('ResetVmStatus', virtualMachine.id, selectedStatus, false);
    };
    const handleSetDeprovisionDate = (e) => {
        e.stopPropagation();
        context.handleAction('SetDeprovisionedDate', virtualMachine.id, deprovisionedDate.toDateString(), false);
    };

    const handleCopyToClipboardClick = () => {
        const parsedLog = virtualMachine.provisioningLog.join('\r\n');
        copyToClipboard(parsedLog).then(() => {
            toastService.success('provisioning Log copied to clipboard.');
        });
    };
    const updateProcessingMessages = (message: any) => {
        if (message?.data) {
            setOperationStatus(message.data);
        } else {
            setOperationStatus(null);
        }
    };

    useEffect(() => {
        vmPubSub.initiatePubSubIfNeeded(true, updateProcessingMessages); //initiate subscription for certain statuses
    }, [virtualMachine]);

    useEffect(() => {
        if (!vivliConfig) {
            return;
        }
        const vmSizeList = Object.values(vivliConfig.virtualMachineTypes).map((value) => ({
            title: value.title,
            value: value.vmSize,
        }));
        setResearchVmType(vmSizeList);
        return () => {
            vmPubSub.closeConnections();
        };
    }, [vivliConfig]);
    return (
        <div>
            <div style={lookupSectionStyle}>
                <span style={fontBoldStyle}>VM Request ID:</span>
                <TextFieldComponent
                    label={'VM Request Id'}
                    value={vmLookupId}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && handleLookUpClick(e);
                    }}
                    style={marginLeftStyle}
                    onChange={(e) => handleInputChangeVmRequest(e)}
                />
                <ButtonComponent style={buttonStyle} onClick={handleLookUpClick} isLoading={isLoading} disabled={isLoading}>
                    Lookup VM
                </ButtonComponent>
            </div>

            {virtualMachine && (
                <div style={templateBodyStyle}>
                    <div style={templateSectionStyle}>
                        <div>
                            <span style={fontBoldStyle}>Title:</span> {dataRequest.requestTitle}
                        </div>
                        <div>
                            <span style={fontBoldStyle}>VM Size:</span> {virtualMachine.size}
                        </div>
                    </div>
                    <div style={templateSectionStyle}>
                        <div>
                            <span style={fontBoldStyle}>Lead Researcher:</span> {leadResearcherName()}
                        </div>
                        <div>
                            <span style={fontBoldStyle}>SAS:</span> {virtualMachine.licenseSAS ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div style={templateSectionStyle}>
                        <div>
                            <span style={fontBoldStyle}>Current Status:</span> {virtualMachine.status}
                        </div>
                        <div>
                            <span style={fontBoldStyle}>STATA:</span> {virtualMachine.licenseSTATA ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div style={marginBottom5Style}>
                        <span style={fontBoldStyle}>Deprovisioned Date:</span>{' '}
                        {dateLookUp(virtualMachine.deProvisionedDateTime || 'Not Set')}
                    </div>
                    <div style={marginBottom5Style}>
                        <span style={fontBoldStyle}>Deleted Date:</span> {dateLookUp(virtualMachine.deletedOnDateTime || 'Not Set')}
                    </div>
                    <div>
                        <span style={fontBoldStyle}>Operation Status:</span> {operationStatus || 'Not Set'}
                    </div>

                    {isLoading && (
                        <div style={statusSectionStyle}>
                            <LoadIndicatorComponent />
                        </div>
                    )}

                    {!isLoading && virtualMachine && (
                        <div>
                            <div style={dropdownSectionStyle}>
                                <div>Status to set</div>
                                <DropdownFieldComponent items={dropDownResetValue} label={'Status to set'} onChange={setSelectedStatus} />
                                <ButtonComponent onClick={handleStatusReset} style={dropdownButtonStyle}>
                                    Reset Status
                                </ButtonComponent>
                            </div>
                            <div style={dropdownSectionStyle}>
                                <div>Deprovisioned Date to set</div>
                                <DatePickerComponent
                                    ref={useLookUpRef}
                                    label={'Deprovisioned Date'}
                                    onChange={setDeprovisionedDate}
                                    placeholderText={'MM/DD/YY'}
                                    value={deprovisionedDate?.toDateString()}
                                />
                                <ButtonComponent style={dropdownButtonStyle} onClick={handleSetDeprovisionDate}>
                                    Set Deprovisioned Date
                                </ButtonComponent>
                            </div>
                            <div style={statusSectionStyle}>
                                <ButtonComponent onClick={() => btnClick('DeleteVmRecord')}>Delete VM Record</ButtonComponent>
                                <ButtonComponent style={marginLeft50Style} onClick={handleCopyToClipboardClick}>
                                    Copy Provisioning Log to Clipboard
                                </ButtonComponent>
                            </div>
                            <div style={statusSectionStyle}>
                                <ButtonComponent onClick={() => btnClick('StartVm', true)}>Start</ButtonComponent>
                                <ButtonComponent style={marginLeft50Style} onClick={() => btnClick('StopVm', true)}>
                                    Stop
                                </ButtonComponent>
                                <ButtonComponent style={marginLeft50Style} onClick={() => btnClick('DeprovisionVm', true)}>
                                    Deprovision
                                </ButtonComponent>
                                <ButtonComponent style={marginLeft50Style} onClick={() => btnClick('RetryProvisioning', true)}>
                                    Retry Provisioning
                                </ButtonComponent>
                                <ButtonComponent style={marginLeft50Style} onClick={() => btnClick('AddDataPackages', true)}>
                                    Add Data Packages
                                </ButtonComponent>
                            </div>
                            <div style={statusSectionStyle}>
                                <ButtonComponent onClick={() => handleVMProvisioning()}>Provision VM</ButtonComponent>
                                <DropdownFieldComponent
                                    style={marginLeft50Style}
                                    items={researchVmType}
                                    label={'Select to provision'}
                                    onChange={setSelectedVmSize}
                                />
                                <CheckboxComponent
                                    label={'SAS'}
                                    checked={sasSelected}
                                    onChange={(e) => setSasSelected(e.currentTarget.checked)}
                                />
                                <CheckboxComponent
                                    label={'Stata'}
                                    checked={stataSelected}
                                    onChange={(e) => setStataSelected(e.currentTarget.checked)}
                                />
                            </div>
                            <VmUserDetailComponent vmUserDetails={userVmDetails}></VmUserDetailComponent>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
