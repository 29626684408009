﻿import React, { ReactNode, useEffect, useState } from 'react';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { OrganizationDetailsContext, useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { IOrganization, IOrganizationInvitation, IOrganizationMember } from '@vivli/features/organizations/infrastructure/interface';
import { first } from 'rxjs/operators';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentMembersHook } from '@vivli/features/organizations/infrastructure/hook';
import { IOrganizationRoles, IUser } from '@vivli/shared/infrastructure/interface';

interface OrganizationDetailsContextWrapperProps {
    children: ReactNode;
}

export const OrganizationDetailsContextWrapper = ({ children }: OrganizationDetailsContextWrapperProps) => {
    const { orgId } = useParams();
    const modalService = useModalService();
    const toastService = useToastService();
    const orgsService = useOrganizationsService();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const navigate = useNavigate();
    const [organization, setOrganization] = useState<IOrganization>();
    const [invitations, setInvitations] = useState<IOrganizationInvitation[]>();
    const [invitationsCount, setInvitationsCount] = useState(invitations?.length || 0);
    const [isLoading, setIsLoading] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const { currentMembers, setCurrentMembers, getCurrentMembers } = useCurrentMembersHook(orgId);
    const { setOrganizationMemberRights } = useOrganizationsService();
    const [updatedUser, setUpdatedUser] = useState<IOrganizationMember>();
    const handleError = (e, toast?: string) => {
        setIsLoading(false);
        setIsSaving(false);
        const message = toast ? toast + e : e;
        modalService.error(message);
    };

    const getOrganization = (orgId: string) => {
        setIsLoading(true);
        orgsService
            .getOrganization(orgId)
            .pipe(first())
            .subscribe((org) => {
                setOrganization(org);
                setIsLoading(false);
            });
    };

    const getOrganizationInvites = (orgId: string) => {
        orgsService.getOrganizationInvites(orgId).pipe(first()).subscribe(setInvitations);
    };

    const inviteOrgMember = (orgInvite: IOrganizationInvitation) => {
        setIsSaving(true);
        orgsService
            .inviteOrganizationMember(organization.id, orgInvite, isAmr)
            .pipe(first())
            .subscribe(() => {
                toastService.success(`${orgInvite.email} successfully added.`);
                const newCount = invitationsCount + 1;
                setInvitationsCount(newCount);
                setIsSaving(false);
                getCurrentMembers();
            }, handleError);
    };

    const deleteInvite = (invitation: IOrganizationInvitation) => {
        setIsSaving(true);
        orgsService
            .uninviteOrganizationMember(organization.id, invitation.email)
            .pipe(first())
            .subscribe(() => {
                toastService.success(`${invitation.email} successfully uninvited.`);
                const newCount = invitationsCount - 1;
                setInvitationsCount(newCount);
                setIsSaving(false);
            }, handleError);
    };

    const handleSave = (updatedOrg: IOrganization) => {
        setIsSaving(true);
        if (updatedOrg.id) {
            handleUpdateOrg(updatedOrg);
        } else {
            handleAddOrg(updatedOrg);
        }
    };

    const handleUpdateOrg = (updatedOrg: IOrganization) => {
        orgsService
            .updateOrganization(updatedOrg, isAmr)
            .pipe(first())
            .subscribe(
                (savedOrg) => {
                    setOrganization(savedOrg);
                    toastService.success('Organization saved successfully.');
                    setIsSaving(false);
                },
                (e) => {
                    handleError(e, 'There was a problem saving this Organization: ');
                }
            );
    };

    const handleAddOrg = (updatedOrg: IOrganization) => {
        orgsService
            .addOrganization(updatedOrg, isAmr)
            .pipe(first())
            .subscribe(
                (newOrg) => {
                    toastService.success(
                        'Organization created successfully.  You may now make additional changes to ' + 'your newly created org.'
                    );
                    setIsSaving(false);
                    navigate(`/admin/orgs/${newOrg.id}`);
                },
                (e) => {
                    handleError(e, 'There was a problem saving this Organization: ');
                }
            );
    };

    const handleRoleUpdate = (
        orgId: string,
        fieldName: string,
        userToUpdate: IUser,
        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        const currentOrgRoles = userToUpdate.orgMemberships.find((m) => m.orgId === orgId);
        let currentRoleValue = currentOrgRoles[fieldName];

        const payload: IOrganizationRoles = {};
        payload[fieldName] = !currentRoleValue;
        setOrganizationMemberRights(orgId, userToUpdate.id, userToUpdate.email, payload).subscribe(
            (result) => {
                if (result) {
                    // @ts-ignore
                    setUpdatedUser(result);
                    toastService.success(`Successfully updated organization member rights for ${userToUpdate.displayName}`);
                }
                setIsLoading && setIsLoading(false);
            },
            (e) => {
                setIsLoading && setIsLoading(false);
                toastService.error(`Error occured while updating organization member rights ${e}`);
            }
        );
    };

    const provider = {
        organization,
        invitations,
        invitationsCount,
        handleSave,
        isSaving,
        inviteOrgMember,
        deleteInvite,
        currentMembers,
        setCurrentMembers,
        handleRoleUpdate,
        updatedUser,
        isLoading,
    };

    useEffect(() => {
        if (orgId && orgId !== 'add') {
            getOrganization(orgId);
        }
    }, [orgId]);

    useEffect(() => {
        if (orgId) {
            getOrganizationInvites(orgId);
        }
    }, [invitationsCount]);

    if (isLoading) {
        return <LoadIndicatorCenteredComponent />;
    }

    return <OrganizationDetailsContext.Provider value={provider}>{children}</OrganizationDetailsContext.Provider>;
};
