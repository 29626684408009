﻿import { IFundingOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { RorSearchContainerComponent } from '@vivli/features/organizations/components';

interface RorFundersSearchComponentProps {
    fundingOrganizations: IFundingOrganization[];
    handleFunderChange?: (updatedFunders: IFundingOrganization[]) => void;
}

export const RorFundersSearchComponent = ({ handleFunderChange, fundingOrganizations }: RorFundersSearchComponentProps) => {
    const searchText = 'SEARCH ROR TO ADD FUNDING ORGANIZATION';

    const handleResult = (funderId, matches) => {
        const match = matches.find((r) => r.id === funderId);
        const parentRelationship = match.relationships.find((r) => r.type === 'Parent');
        const updatedFunder: IFundingOrganization = {
            rorId: match.id,
            rorName: match.name,
            grantId: '',
            parentRorId: parentRelationship ? parentRelationship.id : null,
            parentRorName: parentRelationship ? parentRelationship.label : '',
        };

        handleFunderChange([...fundingOrganizations, updatedFunder]);
    };

    return <RorSearchContainerComponent searchText={searchText} handleResult={handleResult} />;
};
