﻿import React from 'react';
import {
    FieldHeaderComponent,
    MultiEntryInputTextFormFieldComponent,
    TextAreaFormFieldComponent,
    twoAcrossStyle,
} from '@vivli/shared/components';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { Styles } from '@vivli/shared/theme';
import { DTICTFormFields } from '@vivli/shared/infrastructure/constants';

interface CollaboratorsAndSecondaryIdsComponentProps {
    study: IStudy;
    isVivliAdmin: boolean;
}

export const CollaboratorsAndSecondaryIdsComponent = ({ study, isVivliAdmin }: CollaboratorsAndSecondaryIdsComponentProps) => {
    const collaborators = study?.collaborators?.map((a) => ` ${a.agency}`).toString();
    const labelText = isVivliAdmin ? 'Secondary IDs (press Enter after typing new ID)' : 'Secondary IDs';

    return (
        <div style={{ width: '100%' }}>
            <FieldHeaderComponent title={'COLLABORATORS AND SECONDARY IDS'} />
            <div style={Styles.FORM_ROW}>
                <TextAreaFormFieldComponent
                    name={'collaborators'}
                    label={'Collaborators'}
                    style={twoAcrossStyle}
                    value={collaborators}
                    readonly={true}
                    rows={2}
                    dataId={DTICTFormFields.Collaborators}
                />
                <MultiEntryInputTextFormFieldComponent
                    label={labelText}
                    inputTextArray={study.secondaryIds}
                    style={twoAcrossStyle}
                    readonly={!isVivliAdmin}
                    name={'secondaryIds'}
                    dataId={DTICTFormFields.SecondaryId}
                />
            </div>
        </div>
    );
};
