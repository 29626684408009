import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

interface TranslationComponentProps {
    style?: CSSProperties;
    children: string;
}

export const TranslationComponent = ({ children, style }: TranslationComponentProps) => {
    const { t, i18n } = useTranslation();

    return <span style={style}>{t(children)}</span>;
};
