import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { IDataPackage } from '@vivli/shared/features/data-package/infrastructure/interface';
import { useDataPackageService } from '@vivli/shared/features/data-package/infrastructure/context';
import { first } from 'rxjs/operators';
import { DataPackageLoaderComponent } from './data-package-loader.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { useModalService } from '@vivli/shared/infrastructure/context';

interface DataPackageBaseComponentProps {
    dataPackageId: string;
    //data package id for study IPD package
    secondaryDataPackageId?: string;
    onPackageAvailable?: (dataPackage: IDataPackage) => void;
    //the number for forcing files list to refresh
    filesRefresh?: number;
    children: ReactNode;
}

export const DataPackageContext = createContext<IDataPackage>(null);

export const DataPackageBaseComponent = ({
    dataPackageId,
    secondaryDataPackageId,
    onPackageAvailable,
    filesRefresh,
    children,
}: DataPackageBaseComponentProps) => {
    const [dataPackage, setDataPackage] = useState<IDataPackage>();
    const modalService = useModalService();

    const dataPackageService = useDataPackageService();
    const onPackageRetrieved = (dataPackage) => {
        setDataPackage(dataPackage);
        onPackageAvailable && onPackageAvailable(dataPackage);
    };

    function handleError(error) {
        modalService.error(`Cannot get the Data Package. Error received: ${error}`);
    }

    useEffect(() => {
        if (!dataPackageId) {
            return;
        }
        let sub: Subscription;

        if (secondaryDataPackageId) {
            //get combined data package for study documents and IPD shared files
            sub = dataPackageService
                .getStudyDocumentsDataPackage(dataPackageId, secondaryDataPackageId)
                .pipe(first())
                .subscribe(onPackageRetrieved, handleError);
        } else {
            sub = dataPackageService.getDataPackage(dataPackageId).pipe(first()).subscribe(onPackageRetrieved, handleError);
        }

        return () => {
            sub.unsubscribe();
        };
    }, [dataPackageId, filesRefresh]);

    if (!dataPackage) {
        return <DataPackageLoaderComponent />;
    }

    return <DataPackageContext.Provider value={dataPackage}>{children}</DataPackageContext.Provider>;
};
