import React, { CSSProperties, useEffect, useState } from 'react';
import { DropdownFormFieldComponent, TextAreaFormFieldComponent, TextFormFieldComponent } from '@vivli/shared/components';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { EnquiryStatusEnum } from '@vivli/features/enquiry/infastructure/enum';
import { useFormContext } from 'react-hook-form';
import { IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { useActiveUser } from '@vivli/core/infrastructure/context';

const flexStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
};
const twoColumnGrid: CSSProperties = {
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '25px',
};
const paddingTop20: CSSProperties = {
    paddingTop: '20px',
};
const width70: CSSProperties = {
    width: '70%',
};
export const EnquiryMainContainerComponent = () => {
    const [countryItems, setCountryItems] = useState<IDropdownMenuItem[]>();
    const vivliConfig = useVivliConfig();
    const user = useActiveUser();
    const formApi = useFormContext<IEnquiry>();
    const enquiry = formApi.watch();
    const isReadOnlyRequester = enquiry.status !== EnquiryStatusEnum.Draft && enquiry.requesterId === user.userId;
    const isReadOnlyRequesterName =
        user.isVivliAdmin || (enquiry.status === EnquiryStatusEnum.Draft && enquiry.requesterId === user.userId);

    useEffect(() => {
        if (vivliConfig?.countries?.length > 0) {
            const countryItems = vivliConfig.countries.map(({ name, code, numberSites }) => ({
                value: { name, code, numberSites },
                title: name,
            }));
            setCountryItems(countryItems);
        }
    }, [vivliConfig]);
    return (
        <div style={paddingTop20}>
            <div style={twoColumnGrid}>
                <div style={flexStyle}>
                    <TextFormFieldComponent name={'requesterEmail'} label={'Requester Email'} readonly={true}></TextFormFieldComponent>
                </div>
                <div style={flexStyle}>
                    <TextFormFieldComponent
                        name={'requesterName'}
                        label={'Requester Name'}
                        readonly={!isReadOnlyRequesterName}
                    ></TextFormFieldComponent>
                </div>
            </div>
            <div style={{ ...twoColumnGrid, ...paddingTop20 }}>
                <TextFormFieldComponent
                    name={'requesterOrganization'}
                    label={'Your Institution'}
                    readonly={isReadOnlyRequester}
                ></TextFormFieldComponent>

                <DropdownFormFieldComponent
                    name={'requesterCountry'}
                    label={'Country'}
                    items={countryItems}
                    valueKey={`name`}
                    readonly={isReadOnlyRequester}
                ></DropdownFormFieldComponent>
            </div>
            <div style={{ ...twoColumnGrid, ...paddingTop20 }}>
                <TextAreaFormFieldComponent
                    name={'enquiryPurpose'}
                    label={'Purpose'}
                    rows={3}
                    readonly={isReadOnlyRequester}
                ></TextAreaFormFieldComponent>

                <div>
                    The{' '}
                    <a target="_blank" href="http://vivli.org/members/ourmembers">
                        Vivli Members Page
                    </a>{' '}
                    provides information on each member and their policy for sharing datasets
                </div>
            </div>
        </div>
    );
};
