import React, { CSSProperties, useEffect } from 'react';
import { AdvancedSelectComponent, AdvancedSubHeaderComponent } from '@vivli/shared/components';
import { FilterGroupComponent } from '../../filter-group.component';
import { useAmrSearchOptionsContext, useSearchContext } from '@vivli/features/search/infrastructure/context';
import { IFacetItem, ISelectOption } from '@vivli/features/search/infrastructure/interface';
import { amrFieldTitleStyle } from '../../search-filters.styles';
import { YearAdvancedSelectComponent } from '../year-advanced-select.component';

const yearRangeColumnStyleContainer: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    columnGap: '10px',
};

const yearRangeVerticalStyleContainer: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
    rowGap: '10px',
};

interface FourthFilterGroupComponentProps {
    menuPlacement?: 'auto' | 'bottom' | 'top';
    vertical?: boolean;
}

export const FourthFilterGroupComponent = ({ menuPlacement, vertical = false }: FourthFilterGroupComponentProps) => {
    const { amrFilterValues } = useSearchContext();
    const { yearsDataCollectedFrom, setYearsDataCollectedFrom, setEarliestYear } = useAmrSearchOptionsContext();

    const sortFunction = (a: IFacetItem, b: IFacetItem) => {
        return a.value.localeCompare(b.value);
    };

    const yearOptions: ISelectOption[] =
        amrFilterValues?.yearsDataCollected?.sort(sortFunction).map((year) => ({
            value: year.value,
            label: year.value,
        })) || [];

    const yearOptionsReversed = [...yearOptions].reverse();

    useEffect(() => {
        const earliestYearX = amrFilterValues?.yearsDataCollected[0].value;
        setEarliestYear(earliestYearX);
    }, [yearOptions]);

    return (
        <FilterGroupComponent title={'Years Data Collected'} innerStyle={{ height: '100%' }} noBottomPad={true}>
            <div style={vertical ? yearRangeVerticalStyleContainer : yearRangeColumnStyleContainer}>
                <div>
                    <AdvancedSubHeaderComponent title={'From'} style={amrFieldTitleStyle} />
                    <AdvancedSelectComponent
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        onChange={setYearsDataCollectedFrom}
                        options={yearOptions}
                        selectedOptions={yearsDataCollectedFrom}
                        menuPlacement={menuPlacement}
                    />
                </div>

                <div>
                    <AdvancedSubHeaderComponent title={'To'} style={amrFieldTitleStyle} />
                    <YearAdvancedSelectComponent menuPlacement={menuPlacement} options={yearOptionsReversed} />
                </div>
            </div>
        </FilterGroupComponent>
    );
};
