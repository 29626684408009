import React, { CSSProperties, useState } from 'react';
import { PicoColorEnum } from '@vivli/features/search/infrastructure/enum';
import { PicoColorBlockComponent } from './pico-color-block.component';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import { usePicoSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { IPicoSearchTerm } from '@vivli/features/search/infrastructure/interface';

const containerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto auto',
};

const picoSelectorStyle: CSSProperties = {
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '5px',
};

const picoSelectorInnerStyle: CSSProperties = {
    overflow: 'hidden',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: '25% 25% 25% 25%',
};

const conditionContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    padding: '5px',
    fontSize: '0.7em',
    color: 'black',
};

const conditionTitleStyle: CSSProperties = {
    fontWeight: 'bold',
};

interface PicoTypeModalComponent {
    picoTerm: IPicoSearchTerm;
}

export const PicoTypeModalComponent = ({ picoTerm }: PicoTypeModalComponent) => {
    const [selectedType, setSelectedType] = useState<PicoTypeEnum>(null);
    const { updatePicoTermType } = usePicoSearchOptionsContext();

    const updateSelectedType = (type: PicoTypeEnum) => {
        setSelectedType(type);
        updatePicoTermType(picoTerm.key, type);
    };

    return (
        <div style={containerStyle}>
            <div style={conditionContainerStyle}>
                <div style={conditionTitleStyle}>Condition</div>
                <div>Used in {picoTerm?.document?.usesInStudyMetadata || '0'} studies</div>
            </div>
            <div style={picoSelectorStyle}>
                <div style={picoSelectorInnerStyle}>
                    <PicoColorBlockComponent
                        color={PicoColorEnum.P}
                        title={'P'}
                        onClick={() => updateSelectedType(PicoTypeEnum.P)}
                        selected={selectedType === PicoTypeEnum.P}
                    />
                    <PicoColorBlockComponent
                        color={PicoColorEnum.I}
                        title={'I'}
                        onClick={() => updateSelectedType(PicoTypeEnum.I)}
                        selected={selectedType === PicoTypeEnum.I}
                    />
                    <PicoColorBlockComponent
                        color={PicoColorEnum.C}
                        title={'C'}
                        onClick={() => updateSelectedType(PicoTypeEnum.C)}
                        selected={selectedType === PicoTypeEnum.C}
                    />
                    <PicoColorBlockComponent
                        color={PicoColorEnum.O}
                        title={'O'}
                        onClick={() => updateSelectedType(PicoTypeEnum.O)}
                        selected={selectedType === PicoTypeEnum.O}
                    />
                </div>
            </div>
        </div>
    );
};
