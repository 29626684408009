﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { AmrAnalysisPurposeEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { ICheckboxMenuItem } from '@vivli/shared/infrastructure/interface';
import { useAmrAnalysisPurposeTextMap } from '@vivli/features/data-requests/infrastructure/hook';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    CheckboxListFormFieldComponent,
    FieldHeaderComponent,
    InfoPopoverComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';

export const AmrPurposeOfAnalysisComponent = () => {
    const formApi = useFormContext();
    const [showOtherDescriptionField, setShowOtherDescriptionField] = useState(null);
    const [showOtherInitialized, setShowOtherInitialized] = useState(false);
    const fieldName = 'antimicrobialDetail.amrAnalysisDetails.purposeListOtherDescription';
    const amrAnalysisPurposeMap = useAmrAnalysisPurposeTextMap();
    const amrPurposeOfAnalysis = useWatch({ name: 'antimicrobialDetail.amrAnalysisDetails.purposeList' });

    const createCheckboxMenuItem = (purpose: AmrAnalysisPurposeEnum): ICheckboxMenuItem => {
        const currentValues = formApi.getValues('antimicrobialDetail.amrAnalysisDetails.purposeList') as string[];
        return {
            title: amrAnalysisPurposeMap(purpose),
            value: purpose,
            checked: currentValues?.some((x) => x === purpose),
        };
    };
    const checkboxMenuItems = Object.values(AmrAnalysisPurposeEnum).map(createCheckboxMenuItem);
    const purposeOfAnalysisHint = (
        <div>
            <div>Choose from the available categories – select all that apply:</div>
            <ul>
                {checkboxMenuItems
                    .filter((i) => i.value !== AmrAnalysisPurposeEnum.Other)
                    .map((item) => (
                        <li key={item.value}>{item.title}</li>
                    ))}
            </ul>
        </div>
    );

    const getOtherFieldStyle = (): CSSProperties => {
        if (showOtherDescriptionField) {
            return {};
        }
        return {
            height: 0,
            visibility: 'hidden',
            overflow: 'hidden',
        };
    };

    useEffect(() => {
        if (!amrPurposeOfAnalysis) {
            return;
        }
        const showDescField = amrPurposeOfAnalysis.some((p) => p === AmrAnalysisPurposeEnum.Other);
        setShowOtherDescriptionField(showDescField);
    }, [amrPurposeOfAnalysis]);

    useEffect(() => {
        if (showOtherDescriptionField === null) {
            return;
        }
        if (!showOtherInitialized) {
            setShowOtherInitialized(true);
            return;
        }

        if (showOtherDescriptionField) {
            formApi.trigger(fieldName, { shouldFocus: true });
        } else {
            formApi.setValue(fieldName, '', { shouldValidate: true });
        }
    }, [showOtherDescriptionField]);

    return (
        <>
            <FieldHeaderComponent
                title="Purpose(s) of Analysis"
                rightAccessory={<InfoPopoverComponent content={purposeOfAnalysisHint} size={20} />}
            />
            <CheckboxListFormFieldComponent
                name={'antimicrobialDetail.amrAnalysisDetails.purposeList'}
                checkedListItems={checkboxMenuItems}
                truncateLabel={false}
            />
            <div style={getOtherFieldStyle()}>
                <TextFormFieldComponent
                    name={'antimicrobialDetail.amrAnalysisDetails.purposeListOtherDescription'}
                    label={'Please provide further details'}
                />
            </div>
        </>
    );
};
