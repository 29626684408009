﻿import { useEffect, useState } from 'react';
import { ICitation } from '@vivli/shared/features/citations/infrastructure/interface';
import { CitationsContext, useCitationsService } from '@vivli/shared/features/citations/infrastructure/context';
import { first } from 'rxjs/operators';
import { useModalService, useToastService } from '@vivli/shared/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';

interface CitationsContextWrapperProps {
    children: React.ReactNode;
    objectId: string;
    isStudy: boolean;
    doUpdate: boolean;
}

const defaultCitation: ICitation = {
    doi: '',
    title: '',
    authors: [{ givenName: '', familyName: '' }],
    yearPublished: '',
    monthPublished: '',
    publisher: '',
    journal: '',
    volume: '',
    number: '',
    pages: '',
    addedByVivli: true,
    dataRequestIds: [],
    url: '',
};

/*object id  is the document id of the data request or study that "owns" the citation*/
export const CitationsContextWrapper = ({ children, objectId, isStudy, doUpdate }: CitationsContextWrapperProps) => {
    const user = useActiveUser();
    const citationsService = useCitationsService(false);
    const modalService = useModalService();
    const toastService = useToastService();
    const [citation, setCitation] = useState<ICitation>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [citations, setCitations] = useState<ICitation[]>(null);

    function setDefaultCitation() {
        setCitation({ ...defaultCitation });
    }

    function handleCreateError(err: string) {
        setIsLoading(false);
        modalService.error(`There was an error creating the citation. Please check the DOI and try again: ${err}`);
    }

    function handleCreateFromObjectError(err: string) {
        setIsLoading(false);
        modalService.error(`There was an error creating the citation. Please check the fields and try again: ${err}`);
    }

    function handleCreateCitationSuccess(citation: ICitation) {
        setDefaultCitation();
        setIsLoading(false);
        setCitations([...citations, citation]);
        toastService.success('Citation created successfully; please review the details');
    }

    const handleUpdateCitationSuccess = (citation: ICitation) => {
        citationsService
            .getCitationsForObject(objectId, isStudy, false)
            .pipe(first())
            .subscribe({
                next: (data) => {
                    setCitations(data);
                    setIsLoading(false);
                    toastService.success('Citation updated successfully');
                },
                error: handleUpdateError,
            });
    };

    function handleUpdateError(err: string) {
        setIsLoading(false);
        modalService.error(`There was an error updating the citation. Please check the fields and try again: ${err}`);
    }

    //add a new citation and on success set citation to default (empty)
    //citation so another citation can be added.
    const addCitation = (citationDoi: string, objectId: string, isStudy: boolean, dataRequestDoiStem: string, citation: ICitation) => {
        setIsLoading(true);
        if (citationDoi) {
            citationsService.createCitationFromDoi(citationDoi, objectId, dataRequestDoiStem).pipe(first()).subscribe({
                next: handleCreateCitationSuccess,
                error: handleCreateError,
            });
        } else {
            const dataRequestId = objectId;
            citationsService.createCitationFromObject(citation, dataRequestId, dataRequestDoiStem).pipe(first()).subscribe({
                next: handleCreateCitationSuccess,
                error: handleCreateFromObjectError,
            });
        }
    };

    const updateCitation = (citation: ICitation, dataRequestDoiStem: string) => {
        setIsLoading(true);
        citationsService.updateCitation(citation, citation.id, dataRequestDoiStem).pipe(first()).subscribe({
            next: handleUpdateCitationSuccess,
            error: handleUpdateError,
        });
    };

    const removeCitation = (id: string, dataRequestDoiStem: string) => {
        citationsService
            .deleteCitation(id, objectId, dataRequestDoiStem)
            .pipe(first())
            .subscribe(
                () => {
                    const newCitations = citations.filter((c) => c.id !== id);
                    setCitations(newCitations);
                    toastService.success(`Citation deleted successfully from Data Request ${dataRequestDoiStem}`);
                },
                (err) => {
                    modalService.error(`There was an error deleting the citation: ${err}`);
                }
            );
    };

    const canDeleteOrEdit = () => {
        if (!user) {
            return false;
        }
        return user.isVivliAdmin;
    };

    const getCitationsError = () => {
        setIsLoading(false);
        setCitations([]); // set citations to empty so the grid shows there are none found
        modalService.error(
            'An error was encountered while fetching citations.  Please report the ID of the study to Vivli at support@vivli.org'
        );
    };

    useEffect(() => {
        if (!objectId) {
            return;
        }
        if (user) {
            //get citations
            citationsService.getCitationsForObject(objectId, isStudy, doUpdate).pipe(first()).subscribe({
                next: setCitations,
                error: getCitationsError,
            });
        } else {
            //anonymous access allowed without logged in user
            citationsService.getCitationsForObjectAnonymous(objectId, isStudy, doUpdate).pipe(first()).subscribe({
                next: setCitations,
                error: getCitationsError,
            });
        }
    }, [objectId]);

    useEffect(() => {
        if (!citation) {
            setDefaultCitation();
        } else {
            setCitation(citation);
        }
    }, []);

    const provider = {
        citation,
        isLoading,
        isStudy,
        citations,
        setCitation,
        setCitations,
        addCitation,
        canDeleteOrEdit,
        removeCitation,
        updateCitation,
        defaultCitation,
    };

    return <CitationsContext.Provider value={provider}>{children}</CitationsContext.Provider>;
};
