import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useParams } from 'react-router';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { first } from 'rxjs/operators';
import {
    ButtonComponent,
    FieldHeaderComponent,
    LoadIndicatorCenteredComponent,
    ResearchMemberRowComponent,
} from '@vivli/shared/components';
import { useAdminCtStudyForm } from '@vivli/features/studies/infrastructure/hook';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { Color, Size } from '@vivli/shared/theme';
import { outerTabStyle, tabContentContainerStyle } from '@vivli/features/listing-request/components';
import { IUser } from '@vivli/shared/infrastructure/interface';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { useDefaultApiOptions, useQueryParamsHook } from '@vivli/shared/infrastructure/hook';
import { OrcidApiService } from '@vivli/features/studies/infrastructure/service';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const updateButtonStyle: CSSProperties = {
    width: '25%',
    marginLeft: '40px',
    fontSize: 'larger',
};
const rowStyle: CSSProperties = {
    flex: 'auto',
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 10,
    marginTop: 30,
};
const headerTitleContainerStyle: CSSProperties = {
    padding: '0px',
    color: Color.DARK_GRAY,
    fontWeight: Size.FontWeight.Bold,
    width: '70%',
};
const infoTextStyle: CSSProperties = {
    marginLeft: '40px',
    fontWeight: Size.FontWeight.Regular,
    color: Color.DARK_GRAY,
    fontSize: '15px',
};

const errorTextStyle: CSSProperties = {
    marginLeft: '40px',
    fontWeight: Size.FontWeight.Regular,
    color: Color.ERROR_RED,
    fontSize: '15px',
};
const headerTitleStyle: CSSProperties = {
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Regular,
    color: Color.DARK_GRAY,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-word',
    height: 100,
};
const messageStyle: CSSProperties = {
    marginLeft: '40px',
    fontSize: Size.FontSize.Larger,
    fontWeight: Size.FontWeight.Regular,
    color: Color.VIVLI_DARK_BLUE,
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    wordBreak: 'break-word',
};
export const ResearchTeamMemberOrcidAcceptComponent = () => {
    const [study, setStudy] = useState<IStudy>();
    const [owner, setOwner] = useState<IUser>();
    const [studyOwnerDetermined, setStudyOwnerDetermined] = useState<boolean>(false);
    const [updateMessage, setUpdateMessage] = useState<string>('');
    const orcidCallDone = useRef<boolean>(false);
    const studiesService = useStudiesService();
    const { studyId, orcidId } = useParams();
    const arrayName = 'researchTeam';
    const formApi = useAdminCtStudyForm(study);
    const { fields, append } = useFieldArray({
        name: arrayName,
        control: formApi.control,
    });
    const studyNctId = study?.nctId ? study?.nctId : study?.sponsorProtocolId;
    const modalService = useModalService();
    const config = useConfigService();
    const params = useQueryParamsHook();
    const redirect = encodeURIComponent(`${window.location.protocol}//${window.location.host}/orcid/${studyId}/${orcidId}`);
    const apiOptions = useDefaultApiOptions();
    const api = new OrcidApiService(apiOptions);

    function handleError(error) {
        modalService.error(`An error occurred with your operation. Please try again or contact Vivli support. Message: ${error}`);
        setStudyOwnerDetermined(true);
    }

    useEffect(() => {
        //anonymous access allowed without logged in user
        if (!orcidCallDone.current) {
            orcidCallDone.current = true;
            studiesService.getStudyAnonymous(studyId).pipe(first()).subscribe(setStudyData, handleError);
            //get the code and request auth token
            var code = params['code'];
            if (code) {
                //get the token and update credentials
                updateCredentials(code, studyId, orcidId);
            }
        }
    }, [studyId]);

    const updateCredentials = (code: string, studyId: string, orcidId: string) => {
        //get the token and update credentials
        api.getOrcidAuthToken(code, studyId, orcidId)
            .pipe(first())
            .subscribe((token) => {
                if (token) {
                    setUpdateMessage(token);
                } else {
                    setUpdateMessage('The update was successful. You can close this window.');
                }
                studiesService.getStudyAnonymous(studyId).pipe(first()).subscribe(setStudyData, handleError);
            }, handleError);
    };

    useEffect(() => {
        formApi.reset(study /*, {keepErrors: true, keepIsValid: false, keepDirty: false}*/);
    }, [study]);

    const setStudyData = (studyIn: IStudy) => {
        if (studyIn.researchTeam && studyIn.researchTeam.length > 0) {
            studyIn.researchTeam = studyIn.researchTeam.filter((tm) => tm.orcidId == orcidId);
            setStudy(studyIn);

            if (studyIn.ownerIds?.length > 0) {
                studiesService
                    .getStudyOwner(studyIn.ownerIds[0])
                    .pipe(first())
                    .subscribe(setOwnerData, (error) => {
                        setStudyOwnerDetermined(true);
                    });
            } else {
                setStudyOwnerDetermined(true);
            }
        }
    };

    /* this function is left here in case we will fetch the token on the client side and cannot use axios
    const getAuthToken = (code: string) => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        // myHeaders.append("Cookie", "AWSELB=43655BD9169005A277CE30781A8B0A8EC55EDF15FA2AA99E4CA4CA74063E5CC88CD41A744A214269B310AABB09334AB147CEE82589FCEC70F7FC34893BACF19F25FB503711; AWSELBCORS=43655BD9169005A277CE30781A8B0A8EC55EDF15FA2AA99E4CA4CA74063E5CC88CD41A744A214269B310AABB09334AB147CEE82589FCEC70F7FC34893BACF19F25FB503711");

        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", "APP-0HEV5MZW4XAPQBIX");
        urlencoded.append("client_secret", "d6b67ef8-a50e-4cf3-817c-bd3d6b9f3ac5");
        urlencoded.append("grant_type", "authorization_code");
        urlencoded.append("code", code);
        urlencoded.append("scope", "/activities/update");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded
            // redirect: 'follow'
        };

        fetch("https://sandbox.orcid.org/oauth/token", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }*/

    const setOwnerData = (user: IUser) => {
        setOwner(user);
        setStudyOwnerDetermined(true);
    };

    const handleUpdate = () => {
        if (study.researchTeam[0].orcidAuthCode) {
            //update credetials
            updateCredentials('NA', studyId, study.researchTeam[0].orcidId);
        } else {
            //redirect to orcid to authenticate
            window.location.href = `${config.studyOrcidConfig.authUrl}${redirect}`;
        }
    };

    if (!study || !studyOwnerDetermined) {
        return <LoadIndicatorCenteredComponent />;
    }
    return (
        <div>
            <FormProvider {...formApi}>
                <div style={outerTabStyle} className={'scrolly'}>
                    <div style={tabContentContainerStyle}>
                        <div style={headerTitleContainerStyle}>
                            <div>
                                <FieldHeaderComponent title={study?.studyTitle} style={headerTitleStyle} />
                            </div>
                        </div>
                        <p />
                        <div style={infoTextStyle}>
                            <div style={{ marginBottom: 5 }}>
                                The Study with the title above and ID <strong>{studyNctId}</strong> was registered in the Vivli Platform{' '}
                                {owner ? ` by ${owner.displayName} with e-mail: ${owner.email}` : ''}.
                            </div>
                            <div style={{ marginBottom: 5 }}>
                                You have been asked by the study owner to view this page so that you can authorize Vivli to add the study
                                and your roles in preparing the study into your ORCID® record.
                            </div>
                            <div style={{ marginBottom: 5 }}>
                                If this information is correct, click the Authorize button to login to ORCID and confirm your authorization.
                            </div>
                            <div>
                                If the information is incorrect, please contact the Study owner{' '}
                                {owner ? `${owner.displayName} at ${owner.email}` : ''} to make corrections.
                            </div>
                        </div>

                        <p />
                        {fields.length > 0 && (
                            <ResearchMemberRowComponent
                                key={fields[0]?.id}
                                index={0}
                                isReadOnly={true}
                                isOrcidUpdated={false}
                                arrayName={arrayName}
                                handleRemoveRow={(rowIndex) => {}}
                            />
                        )}
                    </div>
                </div>
            </FormProvider>

            {study.researchTeam && study.researchTeam.length > 0 && !updateMessage && (
                <div style={rowStyle}>
                    <ButtonComponent
                        style={updateButtonStyle}
                        onClick={handleUpdate}
                        dataId={DTICommonConst.HeaderApprovalButton('AuthorizeORCID')}
                    >
                        <img
                            alt="ORCID logo"
                            src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_24x24.png"
                            width="24"
                            height="24"
                            style={{ marginRight: 6 }}
                        />
                        Authorize update to ORCID
                    </ButtonComponent>
                </div>
            )}
            {updateMessage && (
                <div style={rowStyle}>
                    <div style={messageStyle}>{updateMessage}</div>
                </div>
            )}
            {(!study.researchTeam || study.researchTeam.length == 0) && (
                <div style={errorTextStyle}>
                    An error occurred with your operation. Please try again or contact Vivli support. Message: ORCID iD {orcidId} doesn't
                    exist in the Study.
                </div>
            )}
        </div>
    );
};
