﻿import React, { CSSProperties } from 'react';
import { TextAreaFormFieldComponent, TextFormFieldComponent } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';

const marginBottomStyle: CSSProperties = {
    marginBottom: Size.FIELD_SEPARATION,
};
const realMarginBottomStyle: CSSProperties = {
    marginBottom: Size.FIELD_SEPARATION,
    marginRight: Size.PADDING,
};
const halfWidthStyle: CSSProperties = {
    flex: '0 1 50%',
    maxWidth: '49%',
    ...marginBottomStyle,
};
const endHalfWidthStyle: CSSProperties = {
    flex: '0 1 50%',
    maxWidth: '49%',
    marginRight: Size.PADDING,
    ...marginBottomStyle,
};
const quarterWidthStyle: CSSProperties = {
    width: '24%',
    ...marginBottomStyle,
    marginRight: Size.FIELD_SEPARATION,
};
const endQuarterWidthStyle: CSSProperties = {
    width: '24%',
    ...marginBottomStyle,
    marginRight: Size.PADDING,
};
const quarterRowStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
};

export const StudyDetailsComponent = () => {
    return (
        <>
            <div style={Styles.FORM_ROW}>
                <TextAreaFormFieldComponent name={'studyTitle'} label={'Study Title'} style={realMarginBottomStyle} rows={3} />
            </div>
            <div style={Styles.FORM_ROW}>
                <TextFormFieldComponent name={'sponsorProtocolId'} label={'Sponsor Protocol ID'} style={halfWidthStyle} />
                <TextFormFieldComponent name={'nctId'} label={'NCT ID (e.g. NCT0001234)'} style={endHalfWidthStyle} />
            </div>
            <div style={quarterRowStyle}>
                <TextFormFieldComponent
                    name={'principalInvestigator.firstName'}
                    label={'Principal Investigator First Name'}
                    style={quarterWidthStyle}
                />
                <TextFormFieldComponent
                    name={'principalInvestigator.lastName'}
                    label={'Principal Investigator Last Name'}
                    style={quarterWidthStyle}
                />
                <TextFormFieldComponent
                    name={'principalInvestigator.emailAddress'}
                    label={'Principal Investigator Email'}
                    style={quarterWidthStyle}
                />
                <TextFormFieldComponent
                    name={'principalInvestigator.orcidId'}
                    label={'Principal Investigator ORCID iD'}
                    style={endQuarterWidthStyle}
                />
            </div>
        </>
    );
};
