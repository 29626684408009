import React, { CSSProperties } from 'react';
import { useAppEnvironment, useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useConfigService } from '@vivli/core/infrastructure/context';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '40px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
};

const textStyle: CSSProperties = {
    color: 'white',
    fontSize: '15px',
    fontWeight: 'bold',
};

//this component is for displaying a red banner indicating the mode
//(Clinical Trials/Amr) for certain development environments
export const EnvironmentBannerComponent = () => {
    const assignedAppType = useAssignedAppType();
    const appEnvironment = useAppEnvironment();
    const showBanner = appEnvironment.activeEnv === 'local';
    const config = useConfigService();
    const activeEnv = config.queryUri.split('-')[1];

    // assign friendly names to the two modes
    const mode = assignedAppType === AssignedAppTypeEnum.Default ? 'Clinical Trials' : 'AMR';

    if (showBanner) {
        return (
            <div style={containerStyle}>
                <div style={textStyle}>
                    Environment: {activeEnv} - {mode}
                </div>
            </div>
        );
    }

    return null;
};
