import { IDataInfoRequest, IEnquiry } from '@vivli/features/enquiry/infastructure/interface';
import { useForm } from 'react-hook-form';
import joi, { ObjectSchema } from 'joi';
import { joiResolver } from '@hookform/resolvers/joi/dist/joi';
import { JoiValidationOptionsConstant } from '@vivli/shared/infrastructure/constants';

const formDirSchema: ObjectSchema<IDataInfoRequest> = joi.object({
    nctId: joi
        .string()
        .regex(/^NCT\d{8}$/)
        .when('sponsorProtocolId', {
            is: joi.string().min(1),
            then: joi.string().allow(''),
        })
        .label('NCT ID')
        .messages({
            'string.pattern.base': '{{#label}} must be entered in the format NCT12345678.',
        }),
    studyTitle: joi.string().required().label('Data Requested').messages({
        'string.pattern.base': '{{#label}} is required',
    }),
    dataType: joi.array().min(1).required().label('Data Type'),
    orgId: joi.string().required().label('Data Contributor'),
});

const formEnquirySchema: ObjectSchema<IEnquiry> = joi.object({
    enquiryPurpose: joi.string().required().label('Enquiry Purpose'),
    requesterCountry: joi.string().required().label('Requester Country'),
    requesterOrganization: joi.string().required().label('Requester Organization'),
    dataInfoRequests: joi.array().items(formDirSchema).default([]),
});

export function useEnquiryFormHook(enquiry?: IEnquiry, mode: 'onBlur' | 'onChange' | 'onTouched' | 'all' = 'all') {
    return useForm({
        mode,
        resolver: joiResolver(formEnquirySchema.options(JoiValidationOptionsConstant)),
        defaultValues: enquiry,
        reValidateMode: 'onChange',
    });
}
