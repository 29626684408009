﻿import React, { CSSProperties } from 'react';
import moment from 'moment/moment';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { isEmpty } from 'lodash';

const MomentDateFormat = 'DD MMM YYYY';
const MomentTimeFormat = 'hh:mm a';
const isNullOrWhitespace = (input) => {
    return isEmpty(input) || input === null || input === undefined;
};

const headerStyle: CSSProperties = {
    color: MetricsConstants.Colors.DARK_BLUE_TEXT,
    fontSize: MetricsConstants.Sizing.REPORT_HEADER_FONT_SIZE,
};

export const MetricsReportHeaderComponent = ({ details }) => {
    const formattedCreationDate = moment(details?.creationDate).format(`${MomentDateFormat} ${MomentTimeFormat}`);
    let timeWindowText: string;
    let formattedTimeWindow = {
        startDate: '',
        endDate: moment(Date.now()).format(MomentDateFormat),
    };
    if (details?.startDate) {
        formattedTimeWindow.startDate = moment(details.startDate).format(MomentDateFormat);
    }
    if (details?.endDate) {
        formattedTimeWindow.endDate = moment(details.endDate).format(MomentDateFormat);
    }

    if (isNullOrWhitespace(formattedTimeWindow.startDate)) {
        timeWindowText = `until ${formattedTimeWindow.endDate}`;
    } else {
        timeWindowText = `for time period: ${formattedTimeWindow.startDate} to ${formattedTimeWindow.endDate}`;
    }

    const orgNameDisplay = details?.orgName ?? 'All Contributors';
    const timeWidowTextDisplay = timeWindowText ? (
        <span>
            &nbsp;<b>{timeWindowText}</b>
        </span>
    ) : (
        <b>all reports</b>
    );

    return (
        <div>
            <h1 style={headerStyle}>
                Metrics Report for:{' '}
                <span className={'orgName'}>
                    <b>{orgNameDisplay}</b>
                </span>{' '}
                generated {formattedCreationDate}
                {timeWidowTextDisplay}
            </h1>
        </div>
    );
};
