import React from 'react';
import { Size } from '@vivli/shared/theme';

interface AdminHeaderProps {
    style?: React.CSSProperties;
    children?: any;
}

export const AdminHeaderComponent = ({ style, children }: AdminHeaderProps) => (
    <div
        style={
            {
                marginBottom: Size.SMALL_INSET_SIZE,
                marginLeft: Size.INSET_SIZE,
                marginRight: Size.INSET_SIZE,
                marginTop: Size.TOP_INSET_SIZE,
                display: 'flex',
                wordBreak: 'break-all',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                '@media print': {
                    display: 'none',
                },
                ...style,
            } as React.CSSProperties
        }
    >
        {children}
    </div>
);
