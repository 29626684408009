﻿import React from 'react';
import { AmrDataRequestTabsComponent } from './amr-data-request-tabs.component';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';

export const AmrDataRequestRequesterTabsComponent = () => {
    const { dataRequest, organizations } = useDataRequestContext();
    const { shouldHideReviewers } = useDataRequestPermissions();
    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft;
    const hideReviewers = shouldHideReviewers(dataRequest, organizations);
    const showPrintTab = !hideReviewers && !isDraft;

    return (
        <AmrDataRequestTabsComponent
            showRequestTab={isDraft}
            showPrintTab={showPrintTab}
            showRequestPrintTab={false} //admin only
            showStatusUpdateTab={false} //admin only
        />
    );
};
