import React, { CSSProperties, useEffect, useState } from 'react';
import { VmAdminApprovalStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IDataRequest, IResearchTeamMemberDetail } from '@vivli/features/data-requests/infrastructure/interface';
import { Color, Size } from '@vivli/shared/theme';
import { EllipsisDropdownMenuComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { IDropdownMenuItem, IUser } from '@vivli/shared/infrastructure/interface';
import { AdminApprovalConstant, AssetsConstant, DTITeamMemberRow } from '@vivli/shared/infrastructure/constants';
import { useResearcherSubtitle, useResearchTeamPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useUsersService } from '@vivli/features/users/infrastructure/context';
import { first } from 'rxjs/operators';
import { IUserEmailParam } from '@vivli/features/users/infrastructure/interface';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';

const rowContentStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
};

const gridOptionsStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
};

const vmAccessStyle: CSSProperties = {
    fontSize: '0.8em',
    fontWeight: 'bold',
};

const vmGrantedStyle: CSSProperties = {
    ...vmAccessStyle,
    color: Color.VIVLI_GREEN,
};

const vmPendingStyle: CSSProperties = {
    ...vmAccessStyle,
    color: Color.VIVLI_GREY,
};

const adminDeniedStyle: CSSProperties = {
    ...vmAccessStyle,
    color: Color.ERROR_RED,
};

const rowStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    backgroundColor: Color.WHITE,
    height: Size.HEIGHT_FOR_ROW,
    marginBottom: Size.FIELD_SEPARATION,
    cursor: 'pointer',
};

const nameContainerStyle: CSSProperties = {
    position: 'relative',
    paddingLeft: 40,
};

const titleStyle: CSSProperties = {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
};

const nameAvatarStyle: CSSProperties = {
    position: 'absolute',
    top: -3,
    left: 0,
};

interface TeamMemberRowComponentProps {
    onRowClick?: (teamMember: IResearchTeamMemberDetail) => void;
    isLoading: boolean;
    itemHasError?: boolean;
    dataRequest: IDataRequest;
    teamMember: IResearchTeamMemberDetail;
    onRemove?: (teamMember: IResearchTeamMemberDetail) => void;
    onGrantDuaApproval?: (teamMember: IResearchTeamMemberDetail) => void;
    onRevokeDuaApproval?: (teamMember: IResearchTeamMemberDetail) => void;
    onRevokeVmAccess?: (teamMember: IResearchTeamMemberDetail) => void;
    onInviteToVm?: (teamMember: IResearchTeamMemberDetail) => void;
    onMakeOwner?: (teamMember: IResearchTeamMemberDetail) => void;
    onCancelInvite?: (teamMember: IResearchTeamMemberDetail) => void;
}

export const TeamMemberRowComponent = ({
    onRowClick,
    isLoading,
    dataRequest,
    teamMember,
    onRemove,
    itemHasError,
    onGrantDuaApproval,
    onRevokeDuaApproval,
    onRevokeVmAccess,
    onInviteToVm,
    onMakeOwner,
    onCancelInvite,
}: TeamMemberRowComponentProps) => {
    const [menuItems, setMenuItems] = useState<IDropdownMenuItem[]>();
    const [subTitle, setSubTitle] = useState<string>(null);
    const [userAccountStatus, setUserAccountStatus] = useState<string>();
    const { isAdminEditing } = useDataRequestContext();
    const { userCanEdit, userCanManage } = useResearchTeamPermissions(dataRequest, isAdminEditing);
    const user = useActiveUser();
    const getSubtitle = useResearcherSubtitle();
    const useService = useUsersService();

    const getVmAccessStyle = () => {
        if (teamMember.isAdmin || teamMember.hasAccessToVirtualMachineResource) {
            return vmGrantedStyle;
        } else if (teamMember.hasBeenInvitedToVMAccess || teamMember.inviteToVirtualMachine) {
            return vmPendingStyle;
        }

        return vmGrantedStyle;
    };

    const getVmAccessText = () => {
        if (teamMember.hasAccessToVirtualMachineResource) {
            return 'Access Granted';
        } else if (teamMember.hasBeenInvitedToVMAccess) {
            return 'Access Pending';
        } else if (teamMember.inviteToVirtualMachine) {
            return 'Save to send Member Invite';
        } else if (teamMember.isAdmin) {
            return 'Access Provided for Admin';
        }

        return null;
    };

    const getAdminAccessStyle = () => {
        switch (teamMember.adminApprovalStatus) {
            case VmAdminApprovalStatusEnum.NeedsApproval:
                return vmPendingStyle;
            case VmAdminApprovalStatusEnum.Disapproved:
                return adminDeniedStyle;
            case VmAdminApprovalStatusEnum.Approved:
            case VmAdminApprovalStatusEnum.Automatic:
                return vmGrantedStyle;
        }
    };

    const getAdminAccessText = () => {
        return AdminApprovalConstant.menuVMAdminApprovalLabels[teamMember.adminApprovalStatus];
    };

    const getUserAccountStatus = (userId, userEmail) => {
        const emailParam: IUserEmailParam = {
            emailAddress: userEmail,
        };
        useService
            .getUserByEmail(emailParam)
            .pipe(first())
            .subscribe((teamMemberUser: IUser) => {
                if (!teamMemberUser) {
                    //there really is no Vivli user
                    setUserAccountStatus('No Account');
                } else {
                    if (teamMemberUser.disabled) {
                        setUserAccountStatus('Account Disabled');
                    } else {
                        setUserAccountStatus('Account Enabled');
                    }
                    if (!userId || userId === '') {
                        //TODO - possibly later add the user id to the team member
                        //but it's not necessary - it gets added later anyway at
                        //DUA approval etc.
                    }
                }
            });
    };

    const getMenuItems = () => {
        const resultMenuItems: IDropdownMenuItem[] = [];

        if (onRemove && !teamMember.isLeadResearcher && userCanEdit) {
            resultMenuItems.push({
                title: 'Remove Team Member',
                onClick: () => onRemove(teamMember),
                dataIdPrefix: 'remove-team-member',
            });
        }

        if (onGrantDuaApproval && user.isVivliAdmin) {
            resultMenuItems.push({
                title: 'Grant DUA Approval (DUA approved)',
                onClick: () => onGrantDuaApproval(teamMember),
                dataIdPrefix: 'grant-dua-approval',
            });
        }

        if (onRevokeDuaApproval && user.isVivliAdmin) {
            resultMenuItems.push({
                title: 'Deny DUA Approval (DUA not approved)',
                onClick: () => onRevokeDuaApproval(teamMember),
                dataIdPrefix: 'deny-dua-approval',
            });
        }

        if (onRevokeVmAccess && teamMember.hasAccessToVirtualMachineResource) {
            resultMenuItems.push({
                title: 'Revoke Access',
                onClick: () => onRevokeVmAccess(teamMember),
                dataIdPrefix: 'revoke-access',
            });
        }

        if (onInviteToVm && !teamMember.hasAccessToVirtualMachineResource && !teamMember.hasBeenInvitedToVMAccess) {
            resultMenuItems.push({
                title: 'Activate Member for Access to Data Request',
                onClick: () => onInviteToVm(teamMember),
                dataIdPrefix: 'invite-member',
            });
        }

        if (onMakeOwner && !teamMember.isAdmin && teamMember.hasAccessToVirtualMachineResource) {
            resultMenuItems.push({
                title: 'Transfer Administrator Role to Member',
                onClick: () => onMakeOwner(teamMember),
                dataIdPrefix: 'transfer-administrator',
            });
        }

        if (teamMember.hasBeenInvitedToVMAccess && !teamMember.hasAccessToVirtualMachineResource) {
            resultMenuItems.push({
                title: 'Cancel Invite',
                onClick: () => onCancelInvite(teamMember),
                dataIdPrefix: 'cancel-invite',
            });
        }

        return resultMenuItems;
    };

    const getTeamMemberName = `${teamMember.firstName}_${teamMember.lastName}`;
    const teamMemberFormattedName = getTeamMemberName.replace(' ', '_');

    useEffect(() => {
        getUserAccountStatus(teamMember.userId, teamMember.email);
    }, []);

    useEffect(() => {
        getUserAccountStatus(teamMember.userId, teamMember.email);
        setMenuItems(getMenuItems());
        setSubTitle(getSubtitle(teamMember));
    }, [teamMember, userCanEdit]);

    return (
        <div onClick={() => onRowClick(teamMember)} style={rowStyle}>
            <div style={rowContentStyle}>
                <div style={nameContainerStyle} data-test-id={DTITeamMemberRow.AdditionalResearcherAccount}>
                    <img src={AssetsConstant.AVATAR_ICON} style={nameAvatarStyle} />

                    <div style={titleStyle}>
                        {itemHasError && <div style={{ width: 'fit-content', height: 'fit-content' }}>❗</div>}
                        {teamMember.firstName} {teamMember.lastName}
                        {subTitle && <span style={{ fontSize: '0.8em', marginLeft: '5px' }}>({subTitle})</span>}
                        <span>&nbsp;-&nbsp;{userAccountStatus}</span>
                    </div>
                </div>
                <div style={gridOptionsStyle}>
                    <div style={{ marginRight: '20px' }}>
                        <span style={getVmAccessStyle()}>{getVmAccessText()}</span>
                    </div>
                    <div style={{ marginRight: '20px' }}>
                        <span style={getAdminAccessStyle()}>{getAdminAccessText()}</span>
                    </div>
                    {isLoading && <LoadIndicatorComponent style={{ marginRight: '10px' }} />}
                    {userCanManage && !isLoading && menuItems && (
                        <EllipsisDropdownMenuComponent dataIdPrefix={teamMemberFormattedName} position={'top-end'} items={menuItems} />
                    )}
                </div>
            </div>
        </div>
    );
};
