import React from 'react';
import { ModalContextWrapper, ToastContextWrapper } from '@vivli/shared/infrastructure/wrappers';
import { IContextWrapper } from '@vivli/shared/infrastructure/interface';

export const ModulesWrapper = ({ children }: IContextWrapper) => {
    return (
        <ToastContextWrapper>
            <ModalContextWrapper>{children}</ModalContextWrapper>
        </ToastContextWrapper>
    );
};
