﻿import React, { CSSProperties } from 'react';
import { DownloadOnlyDataPackageFeature, StudyIpdDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { FieldHeaderComponent } from '@vivli/shared/components';
import { Size, Styles } from '@vivli/shared/theme';
import { useUserPermissions } from '@vivli/features/users/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';

const containerStyle: CSSProperties = {
    marginLeft: Size.INSET_SIZE,
    marginRight: Size.INSET_SIZE,
};
const innerContainerStyle: CSSProperties = {
    flex: 'auto',
    marginBottom: 20,
    marginLeft: Size.INSET_SIZE,
    marginRight: Size.INSET_SIZE,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
};

interface AdminStudyDatasetDatapackageComponentProps {
    ipdDataPackageId: string;
    userCanDownload: boolean;
    userCanUpload: boolean;
    studyId: string;
    studyIpdContentType: any;
    studyOrgId: string;
}

export const AdminStudyDatasetDatapackageComponent = ({
    ipdDataPackageId,
    userCanDownload,
    userCanUpload,
    studyId,
    studyIpdContentType,
}: AdminStudyDatasetDatapackageComponentProps) => {
    const { study } = useStudyContext();
    const user = useActiveUser();
    const showUpload = userCanUpload;
    const showDownloadAdmin = user.isVivliAdmin;
    const showDownload = userCanDownload && !showUpload && !useActiveUser().isVivliAdmin;
    const downloadMessage = showDownload
        ? 'SUBMITTED DATA PACKAGE CONTENTS - PRESS DOWNLOAD BUTTON TO DOWNLOAD EACH FILE'
        : showDownloadAdmin
        ? 'VIEW FILES IN DATA PACKAGE'
        : 'SUBMIT DATA PACKAGE';
    const showPubliclyAvailable = user.isVivliAdmin || /*org admin or data provider*/ useUserPermissions().canUploadIpd(study?.orgId);
    return (
        <div style={containerStyle}>
            <FieldHeaderComponent title={downloadMessage} style={Styles.FORM_ROW} />
            <div style={Styles.FORM_ROW}>
                {(showDownload || showDownloadAdmin) && (
                    <div style={innerContainerStyle}>
                        <DownloadOnlyDataPackageFeature
                            dataPackageId={ipdDataPackageId}
                            disallowDownload={showDownloadAdmin}
                            showPubliclyAvailable={showPubliclyAvailable}
                        />
                    </div>
                )}
                {showUpload && (
                    <div style={innerContainerStyle}>
                        <StudyIpdDataPackageFeature
                            dataPackageId={ipdDataPackageId}
                            ipdContentType={studyIpdContentType}
                            studyId={studyId}
                            showExternalLinks={false}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
