import { useEffect, useState } from 'react';
import { IAmrFilterValues, IAzureFiltersSearchService, ICtFilterValues } from '@vivli/features/search/infrastructure/interface';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { Subscription } from 'rxjs';

export function useFacetsHook(filtersSearch: IAzureFiltersSearchService) {
    const [amrFilterValues, setAmrFilterValues] = useState<IAmrFilterValues>();
    const [ctFilterValues, setCtFilterValues] = useState<ICtFilterValues>();

    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const handleFilterValues = (): Subscription => {
        if (isAmr) {
            return filtersSearch.getAmrFilterValues().subscribe(setAmrFilterValues);
        } else {
            return filtersSearch.getCtFilterValues().subscribe(SetAndSortCTFilterValues);
        }
    };

    const updateCtFilterValues = (updatedCtFilterValues: ICtFilterValues, ignoreSampleSize = false) => {
        if (ignoreSampleSize) {
            setCtFilterValues({ ...updatedCtFilterValues, actualEnrollment: ctFilterValues.actualEnrollment });
        } else {
            setCtFilterValues({ ...updatedCtFilterValues });
        }
    };

    const SetAndSortCTFilterValues = (filterValues: ICtFilterValues) => {
        //sort funders and contributors
        filterValues?.funder?.sort((a, b) => a.value.localeCompare(b.value));
        filterValues?.orgName?.sort((a, b) => a.value.localeCompare(b.value));
        setCtFilterValues(filterValues);
    };

    useEffect(() => {
        const filterSub = handleFilterValues();
        return () => {
            filterSub.unsubscribe();
        };
    }, []);

    return {
        ctFilterValues,
        amrFilterValues,
        updateCtFilterValues,
        setAmrFilterValues,
    };
}
