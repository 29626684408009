import React, { CSSProperties, useState } from 'react';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const imageContainerStyle: CSSProperties = {
    paddingLeft: '10px',
    display: 'flex',
};

interface TermBoxComponent {
    isActive?: boolean;
    isDisabled?: boolean;
    term: string;
    showCheckmark?: boolean;
    onClick?: () => void;
}

export const TermBoxComponent = ({ isActive, isDisabled, term, showCheckmark, onClick }: TermBoxComponent) => {
    const [isHovered, setIsHovered] = useState(false);

    const containerStyle: CSSProperties = {
        display: 'inline-grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.3)',
        color: 'rgb(255, 255, 255)',
        padding: '10px',
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
        borderRadius: '6px',
        transition: 'opacity 0.3s ease 0s',
        fontWeight: '300',
        opacity: isDisabled ? '0.6' : isHovered ? '0.8' : 1,
        pointerEvents: isDisabled ? 'none' : 'initial',
        height: '38px',
        width: 'fit-content',
        marginRight: '10px',
        marginBottom: '10px',
    };

    return (
        <div style={containerStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onClick}>
            <div>{term}</div>
            {!isActive && !isDisabled && (
                <div style={imageContainerStyle}>
                    <img src={AssetsConstant.PLUS_SYMBOL} />
                </div>
            )}

            {showCheckmark && (
                <div style={imageContainerStyle}>
                    <img src={AssetsConstant.PICO_CHECKMARK} />
                </div>
            )}
        </div>
    );
};
