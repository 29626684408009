﻿import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { FieldHeaderComponent, LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import React, { CSSProperties } from 'react';
import { Size } from '@vivli/shared/theme';

const containerStyle: CSSProperties = {
    backgroundColor: 'white',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    borderRadius: Size.BORDER_RADIUS,
    marginBottom: 20,
    display: 'grid',
};
const rowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '10% 1fr',
    alignItems: 'center',
};

interface ListingRequestStudySampleComponentProps {
    sampleStudy: IStudy;
}

export const ListingRequestStudySampleComponent = ({ sampleStudy }: ListingRequestStudySampleComponentProps) => {
    if (!sampleStudy) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={containerStyle}>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Title" />
                <div>{sampleStudy?.studyTitle}</div>
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Conditions" />
                <div>{sampleStudy?.extractedConditions?.join(', ')}</div>
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Interventions" />
                <div>{sampleStudy?.extractedInterventions?.join(', ')}</div>
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Phase" />
                <div>{sampleStudy?.phase}</div>
            </div>
        </div>
    );
};
