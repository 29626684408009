import React, { CSSProperties, ReactNode, useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

const listStyle: CSSProperties = {
    whiteSpace: 'normal',
    padding: '10px 0 10px 17px',
    margin: 0,
    lineHeight: 'normal',
};
const plainTextStyle: CSSProperties = {
    whiteSpace: 'normal',
    lineHeight: 'normal',
};
const itemStyle: CSSProperties = { padding: '2.5px 0', wordBreak: 'break-word' };
const containerStyle: CSSProperties = { height: '100%', overflowX: 'hidden', overflowY: 'auto' };

interface GridCellListComponentProps extends ICellRendererParams {
    emptyMessage?: string;
    plainTextList?: boolean;
}

export const GridCellListComponent = ({ node, emptyMessage, value, api, plainTextList }: GridCellListComponentProps): ReactNode => {
    useEffect(() => {
        const listLength = value?.length | 1;
        const listHeight = 20 * listLength + 30;
        const initialHeight = listHeight > node.rowHeight ? listHeight : node.rowHeight;

        setRowHeight(initialHeight);
    }, []);

    const setRowHeight = (height: number) => {
        if (height !== node.rowHeight) {
            if (height >= 200) {
                height = 200;
            }

            node.setRowHeight(height);
            api.onRowHeightChanged();
        }
    };

    if (!value || value.length <= 0 || (value.length === 1 && value[0].length <= 0)) {
        return emptyMessage || '';
    }

    if (value.length === 1) {
        return (
            <ul style={listStyle}>
                <li style={itemStyle}>{value[0]}</li>
            </ul>
        );
    }

    return (
        <div style={containerStyle} className="scrolly">
            {plainTextList ? (
                <div style={plainTextStyle}>
                    {value.map((value, i) => (
                        <p key={i}>{value}</p>
                    ))}
                </div>
            ) : value && value.map ? (
                <ul style={listStyle}>
                    {value.map((value, i) => (
                        <li key={i} style={itemStyle}>
                            {value}
                        </li>
                    ))}
                </ul>
            ) : (
                <ul>
                    <li key="0" style={itemStyle}>
                        {value ?? ''}
                    </li>
                </ul>
            )}
        </div>
    );
};
