﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { UsersApiService } from '@vivli/features/users/infrastructure/service';
import { IUsersService } from '@vivli/features/users/infrastructure/interface';
import React from 'react';
import { UsersServiceContext } from '@vivli/features/users/infrastructure/context';

export const UsersServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const usersApi = new UsersApiService(apiOptions);

    const provider: IUsersService = {
        ...usersApi,
    };

    return <UsersServiceContext.Provider value={provider}>{children}</UsersServiceContext.Provider>;
};
