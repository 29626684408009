import React, { CSSProperties, useEffect, useState } from 'react';
import { PicoTabComponent } from './pico-tab.component';
import { TermsSelectorComponent } from './terms-selector.component';
import { usePicoSearchOptionsContext } from '@vivli/features/search/infrastructure/context';
import { PicoTypeEnum } from '@vivli/shared/infrastructure/enum';
import { advancedGridStyle, searchFiltersContainerStyle } from '../search-filters.styles';
import { CtFilterGroupComponent } from '../ct/ct-filter-group.component';

const tabButtonsContainerStyle: CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'fit-content(0) 1fr',
    gridTemplateColumns: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
};

const tabHeadersStyle = (isTabActive: boolean): CSSProperties => ({
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    width: '100%',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    overflow: !isTabActive ? 'hidden' : 'visible',
    minHeight: '70px',
});

const tabsContainerStyle: CSSProperties = {
    overflow: 'hidden',
    height: '100%',
};

enum BackgroundColorEnum {
    P = '81, 87, 166',
    I = '0, 151, 198',
    C = '237, 139, 0',
    O = '132, 189, 0',
    A = '146, 172, 187',
}

const advancedTabStyle: CSSProperties = {
    ...advancedGridStyle,
    backgroundColor: `rgb(${BackgroundColorEnum.A})`,
    overflowY: 'auto',
    height: '100%',
};

export const PicoTabsComponent = () => {
    const [activeTab, setActiveTab] = useState<PicoTypeEnum>(null);
    const { picoTerms, activeTerm } = usePicoSearchOptionsContext();

    const getPicoCount = (type: PicoTypeEnum) => {
        return picoTerms.filter((t) => t.type === type).length;
    };

    useEffect(() => {
        if (activeTerm && activeTerm.type !== PicoTypeEnum.None) {
            setActiveTab(activeTerm.type);
        }
    }, [activeTerm]);

    return (
        <div style={searchFiltersContainerStyle}>
            <div style={tabButtonsContainerStyle}>
                <div style={tabHeadersStyle(activeTab !== null)}>
                    <PicoTabComponent
                        title={'Population'}
                        count={getPicoCount(PicoTypeEnum.P)}
                        onClick={() => setActiveTab(PicoTypeEnum.P)}
                        isActive={activeTab === PicoTypeEnum.P}
                        backgroundColor={BackgroundColorEnum.P}
                    />
                    <PicoTabComponent
                        title={'Intervention'}
                        count={getPicoCount(PicoTypeEnum.I)}
                        onClick={() => setActiveTab(PicoTypeEnum.I)}
                        isActive={activeTab === PicoTypeEnum.I}
                        backgroundColor={BackgroundColorEnum.I}
                    />
                    <PicoTabComponent
                        title={'Comparison'}
                        count={getPicoCount(PicoTypeEnum.C)}
                        onClick={() => setActiveTab(PicoTypeEnum.C)}
                        isActive={activeTab === PicoTypeEnum.C}
                        backgroundColor={BackgroundColorEnum.C}
                    />
                    <PicoTabComponent
                        title={'Outcome'}
                        count={getPicoCount(PicoTypeEnum.O)}
                        onClick={() => setActiveTab(PicoTypeEnum.O)}
                        isActive={activeTab === PicoTypeEnum.O}
                        backgroundColor={BackgroundColorEnum.O}
                    />
                    <PicoTabComponent
                        title={'Advanced'}
                        onClick={() => setActiveTab(PicoTypeEnum.None)}
                        isActive={activeTab === PicoTypeEnum.None}
                        backgroundColor={BackgroundColorEnum.A}
                    />
                </div>
                <div style={tabsContainerStyle}>
                    {activeTab === PicoTypeEnum.P && (
                        <TermsSelectorComponent backgroundColor={BackgroundColorEnum.P} picoType={PicoTypeEnum.P} />
                    )}

                    {activeTab === PicoTypeEnum.I && (
                        <TermsSelectorComponent backgroundColor={BackgroundColorEnum.I} picoType={PicoTypeEnum.I} />
                    )}

                    {activeTab === PicoTypeEnum.C && (
                        <TermsSelectorComponent backgroundColor={BackgroundColorEnum.C} picoType={PicoTypeEnum.C} />
                    )}

                    {activeTab === PicoTypeEnum.O && (
                        <TermsSelectorComponent backgroundColor={BackgroundColorEnum.O} picoType={PicoTypeEnum.O} />
                    )}

                    {activeTab === PicoTypeEnum.None && (
                        <div style={advancedTabStyle}>
                            <CtFilterGroupComponent />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
