﻿interface Response {
    title: string;
    description: string;
}

const responses_section_one: Response[] = [
    {
        title: 'New',
        description: 'The initial value - no one has commented on the enquiry for this particular study.',
    },
    {
        title: 'Response from Vivli',
        description: 'This will be set automatically when a Vivli Admin has added anything to the discussion.',
    },
    {
        title: 'Response from requester',
        description: 'This will be set automatically when the requester has added anything to the discussion.',
    },
    {
        title: 'Response from data contributor',
        description: 'This will be set automatically when a data requester has added anything to the discussion.',
    },
];

const responses_section_two: Response[] = [
    {
        title: 'Eligible for Request as an unlisted study',
        description:
            'This indicates that the researcher can proceed to request this study as an unlisted study. Note that this does not make a commitment that the request will be approved, only that it will be considered.',
    },
    {
        title: 'Study is Listed',
        description: 'This indicates that the study is now listed and can be requested as a listed study.',
    },
    {
        title: 'Not Available',
        description:
            "Indicates that the study data is not available for sharing - additional explanation will be in the field 'Reason' and if appropriate more details may be in the discussion field.",
    },
];
export const ResponseHint = () => {
    return (
        <>
            The response field is used to identify the progress of questions and answers during a discussion, and to capture the final
            response from the data contributor.
            <br />
            <br />
            The following responses are set automatically by the system when the user clicks Save, before and during any discussion to help
            participants notice any changes they may not have yet seen.
            <ul>
                {responses_section_one.map((response) => (
                    <li key={response.title} style={{ marginTop: '10px' }}>
                        <b>{response.title}</b> – {response.description}
                    </li>
                ))}
            </ul>
            One of the following responses will be selected by the data contributor to indicate whether the data can be requested.
            <ul>
                {responses_section_two.map((response) => (
                    <li key={response.title} style={{ marginTop: '10px' }}>
                        <b>{response.title}</b> – {response.description}
                    </li>
                ))}
            </ul>
        </>
    );
};
