﻿import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { StudiesApiService } from '@vivli/features/studies/infrastructure/service';
import { IStudiesService } from '@vivli/features/studies/infrastructure/interface';
import React from 'react';
import { StudiesServiceContext } from '@vivli/features/studies/infrastructure/context';

export const StudiesServiceContextWrapper = ({ children }: IContextWrapper) => {
    const apiOptions = useDefaultApiOptions();
    const api = new StudiesApiService(apiOptions);

    const provider: IStudiesService = {
        ...api,
    };

    return <StudiesServiceContext.Provider value={provider}>{children}</StudiesServiceContext.Provider>;
};
