import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useChatService } from '@vivli/features/chat/infrastructure/context';
import { useChannel } from '@vivli/features/chat/infrastructure/hook';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IChannelApi, IChannelUser, IChannelWithBlock, IChatApiMessage, IChatMessage } from '@vivli/features/chat/infrastructure/interface';
import { ButtonComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { Color, Size } from '@vivli/shared/theme';
import { ChatMessageComponent } from '@vivli/features/chat/components';
import { first } from 'rxjs/operators';
import { useCleanupHook } from '@vivli/shared/infrastructure/hook';
import { Subscription } from 'rxjs';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { LoadIndicatorSizeEnum } from '@vivli/shared/infrastructure/enum';
import { DTIChatFeature, DTICommonConst } from '@vivli/shared/infrastructure/constants';

interface ChatFeatureProps {
    channelId: string;
    onStart?: (channel: IChannelApi) => any;
    onMessageReceived?: (message: IChatApiMessage) => any;
    connectOnFirstSend?: boolean; //if true - connect on first send, if not - on activation
}

const chatRestrictedStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
};

const loadingIndicatorStyle: CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
};

const chatContainerStyle: CSSProperties = {
    paddingTop: Size.PADDING,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
};

const scrollContainerStyle: CSSProperties = {
    height: 350,
    width: '100%',
    borderWidth: 1,
    borderColor: Color.LIGHTER_GRAY,
    borderStyle: 'solid',
    borderRadius: 2,
    marginBottom: 5,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: 'white',
    position: 'relative',
};

const messageInnerContainerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    minHeight: '100%',
};

const textAreaContainerStyle: CSSProperties = {
    marginBottom: '20px',
    width: '100%',
};

const textAreaStyle: CSSProperties = {
    width: '100%',
    resize: 'vertical',
};

export const ChatFeature = ({ channelId, onStart, onMessageReceived, connectOnFirstSend }: ChatFeatureProps) => {
    useCleanupHook();

    const chatService = useChatService();
    const activeUser = useActiveUser();

    const [isChannelConnected, setIsChannelConnected] = useState(false);
    const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [channelError, setChannelError] = useState<string>(null);
    const [messages, setMessages] = useState<IChatMessage[]>(null);

    const channelBlockRef = useRef<IChannelWithBlock>();
    const messagesRef = useRef<IChatMessage[]>([]);
    const activeChannelRef = useRef<IChannelApi>();
    const scrollInitializedRef = useRef(false);
    const activeChannelSub = useRef<Subscription>(null);

    const textAreaRef = useRef<HTMLTextAreaElement>();
    const messageContainerRef = useRef<HTMLDivElement>();
    const messageContainerOnChange = useCallback((node: HTMLDivElement) => {
        if (!node) {
            return;
        }

        messageContainerRef.current = node;
        handleInitialChatScroll();
    }, []);
    const scrollContainerRef = useRef<HTMLDivElement>();
    const scrollContainerOnChange = useCallback((node: HTMLDivElement) => {
        if (!node) {
            return;
        }

        scrollContainerRef.current = node;
        handleInitialChatScroll();
    }, []);

    const handleOnMessage = (message: IChatApiMessage) => {
        // local user messages will be added locally instead of waiting on signalR
        if (!message || (message.userId === activeUser.userId && !message.systemMessage)) {
            return;
        }

        onMessageReceived && onMessageReceived(message);
        addMessage(message);
    };

    const addMessageToState = (message: IChatMessage, user: IChannelUser) => {
        const messageWithUser = {
            ...message,
            displayName: user.displayName,
            organizationNames: user.organizationNames,
        };
        const updatedMessages = [...messagesRef.current, messageWithUser];

        messagesRef.current = updatedMessages;
        setMessages(updatedMessages);
    };

    const addMessage = (message: IChatMessage) => {
        const messageUser = channelBlockRef.current.channel.users.find((u) => u.userId === message.userId);

        if (messageUser) {
            addMessageToState(message, messageUser);
        } else {
            chatService
                .getChannelUser(message.userId)
                .pipe(first())
                .subscribe((channelUser) => {
                    addMessageToState(message, channelUser);
                });
        }
    };

    const handleInitialChatScroll = () => {
        if (scrollContainerRef.current && messageContainerRef.current) {
            scrollToBottom(true);
            scrollInitializedRef.current = true;
        }
    };

    const getActiveChannel = (onBlockReceived?: () => void) => {
        activeChannelSub.current?.unsubscribe();

        const channelSub = chatService
            .getChatChannel(channelBlockRef.current?.channel?.id || channelId)
            .pipe(first())
            .subscribe(
                (channelWithBlock) => {
                    if (channelWithBlock) {
                        // only update block messages if we haven't already retrieved them
                        if (!channelBlockRef.current && channelWithBlock.channel.lastBlockId) {
                            getBlockMessages(channelWithBlock.channel.lastBlockId, channelWithBlock.channel.id);
                        }

                        channelBlockRef.current = channelWithBlock;
                        onBlockReceived && onBlockReceived();
                    } else {
                        setChannelError('Unable to connect to chat service at this time.');
                    }
                },
                () => {
                    setChannelError('Unable to connect to chat service at this time.');
                }
            );

        activeChannelSub.current = channelSub;

        return channelSub;
    };

    const scrollToBottom = (bypassAnimation = false) => {
        const scrollHeight = messageContainerRef.current.scrollHeight;
        const parentHeight = scrollContainerRef.current.clientHeight;
        const top = scrollHeight - parentHeight;

        const scrollOptions: ScrollToOptions = {
            behavior: bypassAnimation ? 'auto' : 'smooth',
            top,
        };

        scrollContainerRef.current.scrollTo(scrollOptions);
    };

    const isActiveUser = (userId: string) => {
        return userId === activeUser.id;
    };

    const sendMessage = (text: string) => {
        setIsSendingMessage(true);

        const message: IChatMessage = {
            text,
            userId: activeUser.id,
            sent: new Date().toString(),
        };

        // add the message to the local state
        addMessage(message);

        // send it up to signal R
        activeChannelRef.current
            .send(message)
            .pipe(first())
            .subscribe(() => {
                setIsSendingMessage(false);
                textAreaRef.current.value = '';
            });
    };

    const sendCurrentMessage = () => {
        if (isChannelConnected) {
            sendMessage(textAreaRef.current.value);
        } else {
            setIsFirstMessageSent(true);
        }
    };

    const getBlockMessages = (blockId: string, channelId: string) => {
        // NOTE: new blocks are created every 50k characters as of 10/21/2021

        // get the block messages
        chatService
            .getChatChannelBlock(channelId, blockId)
            .pipe(first())
            .subscribe((channelWithBlock) => {
                channelBlockRef.current = channelWithBlock;

                // add to the overall list of messages, remove any that are coming back down as dups
                const filteredMessages = messagesRef.current.filter(
                    (o) => !channelWithBlock.block.messages.some((n) => n.sent === o.sent && n.userId === o.userId && n.text === o.text)
                );

                const updatedMessages = [...filteredMessages, ...channelWithBlock.block.messages];

                const messagesWithDetails = updatedMessages.map((fm) => {
                    const messageUser = channelWithBlock.channel.users.find((u) => u.userId === fm.userId);

                    return {
                        ...fm,
                        displayName: messageUser?.displayName,
                        organizationNames: messageUser?.organizationNames,
                    };
                });

                messagesRef.current = messagesWithDetails;

                if (channelWithBlock.block.previousBlockId) {
                    getBlockMessages(channelWithBlock.block.previousBlockId, channelId);
                } else {
                    setMessages(
                        messagesRef.current.sort((a, b) =>
                            new Date(b.sent) === new Date(a.sent) ? 0 : new Date(b.sent) > new Date(a.sent) ? -1 : 1
                        )
                    );
                    LoggerService.info(`Messages are retrieved for the channel ${channelId}.`);
                }
            });
    };

    useChannel({
        channelId: isFirstMessageSent || !connectOnFirstSend ? channelId : null,
        onStart: (channel) => {
            activeChannelRef.current = channel;
            setIsChannelConnected(true);
            if (connectOnFirstSend) {
                sendMessage(textAreaRef.current.value); //connection is happenning on the sending of the first message, so we need to send it after connecting
            }
            onStart && onStart(channel);
        },
        onMessage: handleOnMessage,
        onError: () => setChannelError,
    });

    useEffect(() => {
        if (!channelId) {
            return;
        }
        //retrieve data only when chat is active
        getActiveChannel();

        return () => {
            activeChannelSub.current?.unsubscribe();
        };
    }, [channelId]);

    useEffect(() => {
        if (!messages || messages.length <= 0 || !scrollInitializedRef.current) {
            return;
        }

        scrollToBottom();
    }, [messages]);

    if (!channelId || channelError) {
        return (
            <div style={chatRestrictedStyle}>
                {!channelId && <span>This chat channel is restricted or is unavailable.</span>}
                {channelError && <span>{channelError}</span>}
            </div>
        );
    }

    if ((!isChannelConnected && !connectOnFirstSend) || !messages) {
        return (
            <div style={loadingIndicatorStyle}>
                <LoadIndicatorComponent style={{ margin: 'auto' }} size={LoadIndicatorSizeEnum.Medium} message={'Connecting...'} />
            </div>
        );
    }

    return (
        <div style={chatContainerStyle}>
            <div
                style={scrollContainerStyle}
                className="scrolly"
                id={'chatScrollContainer'}
                data-test-id={DTIChatFeature.Chat('area')}
                ref={scrollContainerOnChange}
            >
                <div style={messageInnerContainerStyle} ref={messageContainerOnChange}>
                    {messages?.map((message, i) => (
                        <ChatMessageComponent
                            displayName={message.displayName}
                            organizationNames={message.organizationNames}
                            message={message}
                            isSelf={isActiveUser(message.userId)}
                            key={i}
                        />
                    ))}
                </div>
            </div>
            <div style={textAreaContainerStyle}>
                <div data-test-id={DTIChatFeature.Chat('input')}>
                    <textarea style={textAreaStyle} rows={3} ref={textAreaRef} disabled={isSendingMessage} />
                </div>
                <div data-test-id={DTIChatFeature.Chat('send')}>
                    <ButtonComponent
                        isLoading={isSendingMessage}
                        onClick={sendCurrentMessage}
                        className="chat_send"
                        dataId={DTICommonConst.OkButton}
                    >
                        Send
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
