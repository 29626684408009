import { IStudy } from '@vivli/features/studies/infrastructure/interface';

export function useStudyPrimaryRegistryUrlHook() {
    const registryUrl = (study: IStudy) => {
        if (study?.extractedSponsorClinicalRegistryUrl) {
            return study.extractedSponsorClinicalRegistryUrl;
        }

        if (study?.nctId) {
            return 'https://clinicaltrials.gov/show/' + study.nctId;
        }

        return 'N/A';
    };

    return {
        registryUrl,
    };
}
