import { useEffect, useState } from 'react';
import { IVirtualMachine } from '@vivli/features/virtual-machine/infrastructure/interface';
import { useVirtualMachineService } from '@vivli/features/virtual-machine/infrastructure/context';

export function useDataRequestVirtualMachine(dataRequestId: string) {
    const [virtualMachine, setVirtualMachine] = useState<IVirtualMachine>();

    const virtualMachineService = useVirtualMachineService();

    useEffect(() => {
        if (!dataRequestId) {
            return;
        }

        const sub = virtualMachineService.getDataRequestVm(dataRequestId).subscribe(setVirtualMachine);

        return () => {
            sub.unsubscribe();
        };
    }, [dataRequestId]);

    return virtualMachine;
}
