﻿import React, { CSSProperties } from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { AssetsConstant, DTILogoComponent } from '@vivli/shared/infrastructure/constants';

const logoStyle: CSSProperties = {
    cursor: 'pointer',
    float: 'left',
    marginTop: '7px',
    marginLeft: '50px',
};

export const LogoComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    //These links could be moved into the config if necessary in the future
    const url = isAmr ? 'https://amr.vivli.org' : 'https://vivli.org';
    return (
        <img
            src={isAmr ? AssetsConstant.VIVLI_AMR_LOGO : AssetsConstant.VIVLI_LOGO2}
            width={isAmr ? 310 : 220}
            height={95}
            style={logoStyle}
            data-test-id={DTILogoComponent.HomePageLogo}
            onClick={() => window.open(url, '_blank')}
            className="vivliLogo_homeLink"
        />
    );
};
