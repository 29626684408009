import { useEffect } from 'react';
import { ISelectOption } from '@vivli/features/search/infrastructure/interface';

export function useDropdownFilterHook({
    options,
    onChange,
    useAnySyntax,
    key,
}: {
    key: string;
    options: ISelectOption[];
    onChange: (key: string, options: ISelectOption[], useAnySyntax: boolean) => void;
    useAnySyntax?: boolean;
}) {
    useEffect(() => {
        onChange(key, options, useAnySyntax);
    }, [options]);
}
