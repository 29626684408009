import {
    AccountInfo,
    AuthenticationResult,
    EventType,
    InteractionRequiredAuthError,
    PopupRequest,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';
import {
    B2cAuthorityTypeEnum,
    FailedToInitialize,
    IMsal2AuthService,
    IMsal2ProviderOptions,
    IMsalLoginOptions,
    LoggingInEvent,
    LoginEvent,
    LogoutEvent,
    Msal2EventType,
    Msal2EventTypeEnum,
    RefreshAuthExpirationEvent,
    SignedUpEvent,
    SignUpEvent,
    SilentLoggingInEvent,
    SilentLoginEvent,
    UnauthorizedEvent,
    UpdatedPasswordEvent,
    UpdatedProfileEvent,
    UpdatingPasswordEvent,
    UpdatingProfileEvent,
    UserCancelledLogin,
} from './interface';
import { Subject } from 'rxjs';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { EndSessionPopupRequest } from '@azure/msal-browser/dist/request/EndSessionPopupRequest';
import { PopupEvent } from '@azure/msal-browser/dist/event/EventMessage';

const loggerKey = 'authService';

export class Msal2AuthService implements IMsal2AuthService {
    private msalApp: PublicClientApplication;
    private account: AccountInfo;
    private loggedIn = false;
    private apiToken: string = null;
    private popupWindow = null;
    public readonly options: IMsal2ProviderOptions;
    public authEvents: Subject<Msal2EventType> = new Subject<Msal2EventType>();

    constructor(options: IMsal2ProviderOptions) {
        this.msalApp = new PublicClientApplication(options.config);
        this.options = options;

        this.handleLoginPopupFocus();
    }

    private handleLoginPopupFocus = () => {
        this.msalApp.addEventCallback((message) => {
            if (message.eventType === EventType.POPUP_OPENED) {
                // Save the popup window for focusing later
                const payload = message.payload as PopupEvent;
                this.popupWindow = payload.popupWindow as Window;
            }
            return message;
        });
    };

    public getAccount = (): AccountInfo | null => {
        const activeAccount = this.msalApp.getActiveAccount();
        return activeAccount;
    };

    public triggerEvent = (event: Msal2EventType): void => {
        this.authEvents.next(event);
    };

    public showLoginWindow = () => {
        this.popupWindow?.focus();
    };

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response
     * @param successEvent
     */
    private handleResponse(response: AuthenticationResult = null, successEvent?: Msal2EventType) {
        let account;
        if (response !== null) {
            account = response.account;
        } else {
            account = this.getAccount();
        }

        if (account) {
            if (successEvent) {
                switch (successEvent.type) {
                    case Msal2EventTypeEnum.UpdatedProfileEvent:
                    case Msal2EventTypeEnum.UpdatedPasswordEvent:
                    case Msal2EventTypeEnum.SignedUpEvent:
                        successEvent.idToken = response.idToken;
                        break;
                }
            }

            this.apiToken = response.idToken;

            this.triggerEvent(successEvent || new LoginEvent(account, response.idToken));
            this.setLoggedIn(account);
            LoggerService.debug('renewed token for ' + account.localAccountId + ' after redirect', loggerKey);
        }
    }

    private handleLoginError(error: any) {
        this.setLoggedOut();

        this.handleUserCancelled(error);
        this.handlePasswordReset(error);
    }

    private handleUserCancelled(error: any) {
        if (error.errorCode === 'user_cancelled' || error.errorMessage.indexOf('AADB2C90091') !== -1) {
            this.triggerEvent(new UserCancelledLogin());
        }
    }

    private handlePasswordReset(error: any) {
        // password reset flow redirection for b2c
        if (!this.options.b2cOptions || !this.options.b2cOptions.authorities) return;

        if (error.errorMessage && error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.updatePassword();
        }
    }

    private handleUnauthorizedLogin() {
        this.setLoggedOut();
        this.triggerEvent(new UnauthorizedEvent());
    }

    private getSilentRequest(account?: AccountInfo, authorityType?: B2cAuthorityTypeEnum): SilentRequest {
        const request: SilentRequest = {
            account: account || this.getAccount(),
            scopes: this.getScopes(),
        };

        return this.getRequestWithOptions<SilentRequest>(request, authorityType);
    }

    private getSsoSilentRequest(authorityType?: B2cAuthorityTypeEnum): SsoSilentRequest {
        const request: SsoSilentRequest = {
            loginHint: this.getAccount()?.idTokenClaims['email'] as any,
            scopes: this.getScopes(),
        };

        return this.getRequestWithOptions<SsoSilentRequest>(request, authorityType);
    }

    private getRedirectUri(redirectUriPath?: string) {
        let path = `${window.location.protocol}//${window.location.hostname}`;
        if (window.location.port?.length > 0) {
            let portDelimAndNumber = `:${window.location.port}`;
            portDelimAndNumber = portDelimAndNumber === ':' ? '' : portDelimAndNumber;
            path += portDelimAndNumber;
        }

        if (redirectUriPath) {
            return `${path}${redirectUriPath}`;
        }

        return path;
    }

    private getLoginRedirectRequest(authorityType?: B2cAuthorityTypeEnum): RedirectRequest {
        const redirectUri = this.getRedirectUri();
        const request: RedirectRequest = {
            scopes: this.getScopes(),
            redirectStartPage: this.options.b2cOptions.redirectUri || redirectUri,
        };

        return this.getRequestWithOptions<RedirectRequest>(request, authorityType);
    }

    private getLoginPopupRequest(authorityType?: B2cAuthorityTypeEnum): PopupRequest {
        const request: PopupRequest = {
            scopes: this.getScopes(),
        };

        return this.getRequestWithOptions<PopupRequest>(request, authorityType);
    }

    private getRequestWithOptions<T>(request: T, authorityType?: B2cAuthorityTypeEnum) {
        let requestWithOptions: T = request;

        const authority = this.getAuthority(authorityType);
        if (authority) requestWithOptions = { ...requestWithOptions, authority: authority };

        const scopes = this.getScopes();
        if (scopes) requestWithOptions = { ...requestWithOptions, scopes };

        return requestWithOptions;
    }

    private getScopes(): string[] {
        return this.options.b2cOptions?.scopes || [];
    }

    private getAuthority(authorityType: B2cAuthorityTypeEnum): string {
        if (!this.options.b2cOptions || !this.options.b2cOptions.authorities) return null;

        const authPolicyName = this.options.b2cOptions?.authorities[authorityType] || this.options.b2cOptions?.authorities.default;
        const b2cTenant = this.options.b2cOptions.tenant;
        const b2cName = b2cTenant.split('.')[0];
        return this.getAuthorityUrl(authPolicyName, b2cTenant, b2cName);
    }

    private getAuthorityUrl(authPolicyName: string, b2cTenant: string, b2cName: string) {
        return `https://${b2cName}.b2clogin.com/${b2cTenant}/${authPolicyName}`;
    }

    private setLoggedOut = () => {
        this.loggedIn = false;
        this.msalApp.setActiveAccount(null);
    };

    private setLoggedIn = (account: AccountInfo) => {
        this.loggedIn = true;
        this.account = account;
        this.msalApp.setActiveAccount(account);
    };

    public getApiToken = () => {
        return this.apiToken;
    };

    public updatePassword = () => {
        this.login({
            authorityType: 'forgotPassword',
            successEvent: new UpdatedPasswordEvent(),
            progressEvent: new UpdatingPasswordEvent(),
        });
    };

    public updateProfile = () => {
        this.login({
            authorityType: 'editProfile',
            successEvent: new UpdatedProfileEvent(),
            progressEvent: new UpdatingProfileEvent(),
        });
    };

    public signUp = () => {
        return this.login({
            authorityType: 'signUpOnly',
            successEvent: new SignedUpEvent(),
            progressEvent: new SignUpEvent(),
        });
    };

    public isSsoUser = () => {
        const idpClaim = this.getAccount().idTokenClaims['idp'] as any;
        return idpClaim !== -1 && idpClaim !== 'localAuthority';
    };

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    public loadAuthModule = (): Promise<any> => {
        // if a hash is present at this time that means we are about to handle a login redirect
        // aka we are in the final process of logging in, likely originating from the /partner-login route
        if (window.location.hash) {
            this.triggerEvent(new LoggingInEvent());
        }

        return this.msalApp
            .handleRedirectPromise()
            .then((resp: AuthenticationResult | null) => {
                if (resp) {
                    this.handleResponse(resp);
                }

                return resp;
            })
            .catch(() => {
                this.triggerEvent(new FailedToInitialize());
            });
    };

    /**
     * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error,
     * it will prompt the user to login using popup if forceLoginOnFail is true
     * @param forceLoginOnFail
     */
    public silentLogin = async (forceLoginOnFail?: boolean) => {
        // only attempt silent if we have a user context saved
        if (!this.getAccount()) {
            this.triggerEvent(new UnauthorizedEvent());
            return;
        }

        this.triggerEvent(new SilentLoggingInEvent());
        const silentRequest = this.getSsoSilentRequest();
        this.msalApp
            .ssoSilent(silentRequest)
            .then((resp) => {
                const account = this.getAccount();
                if (resp.account) {
                    this.setLoggedIn(resp.account);
                    this.triggerEvent(new SilentLoginEvent(account, resp.idToken));
                } else {
                    this.setLoggedOut();
                    this.triggerEvent(new UnauthorizedEvent());
                }
            })
            .catch((error) => {
                // console.error('Silent Error: ' + error);
                if (error instanceof InteractionRequiredAuthError) {
                    this.triggerEvent(new UnauthorizedEvent(error));
                    if (forceLoginOnFail) {
                        this.setLoggedOut();
                        this.login();
                    }
                }
            });
    };

    /**
     * Calls loginPopup or loginRedirect based on given signInType.
     * Optional authority name
     * @param options
     */
    public login = (options?: IMsalLoginOptions): Promise<any | void> => {
        const { quiet, authorityType, signInType, progressEvent, successEvent }: IMsalLoginOptions = {
            quiet: false,
            authorityType: 'signInOnly',
            signInType: 'loginPopup',
            ...options,
        };

        if (!quiet) {
            this.triggerEvent(progressEvent || new LoggingInEvent());
        }

        if (signInType === 'loginPopup') {
            const request = this.getLoginPopupRequest(authorityType);
            return this.msalApp
                .loginPopup(request)
                .then((resp: AuthenticationResult) => {
                    return this.handleResponse(resp, successEvent);
                })
                .catch((error) => {
                    this.handleLoginError(error);
                });
        } else if (signInType === 'loginRedirect') {
            const request = this.getLoginRedirectRequest(authorityType);
            this.msalApp.loginRedirect(request);
        }
    };

    /**
     * Logs out of current account.
     */
    public logout = (redirectUriPath?: string): void => {
        let account: AccountInfo | undefined;
        if (this.getAccount()) {
            account = this.getAccount();
        }

        const redirectUri = this.getRedirectUri(redirectUriPath);
        const logOutRequest: EndSessionPopupRequest = {
            account,
            postLogoutRedirectUri: redirectUri,
        };

        this.setLoggedOut();
        this.triggerEvent(new LogoutEvent());
        this.msalApp.logoutRedirect(logOutRequest);
    };

    /**
     * Gets a token silently, or falls back to interactive popup.
     */
    public getTokenPopup = async (): Promise<string | null> => {
        try {
            const silentRequest = this.getSilentRequest();
            const response: AuthenticationResult = await this.msalApp.acquireTokenSilent(silentRequest);
            this.handleResponse(response, new RefreshAuthExpirationEvent(response.idToken));
            return response.idToken;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                return this.msalApp
                    .acquireTokenPopup(this.getLoginPopupRequest())
                    .then((resp) => {
                        this.handleResponse(resp);
                        return resp.idToken;
                    })
                    .catch(() => {
                        this.handleUnauthorizedLogin();
                        return null;
                    });
            } else {
                this.handleUnauthorizedLogin();
            }
        }

        return null;
    };

    /**
     * Gets a token silently, or falls back to interactive redirect.
     */
    public async getTokenRedirect(): Promise<string | null> {
        try {
            const silentRequest = this.getSilentRequest();
            const response = await this.msalApp.acquireTokenSilent(silentRequest);
            this.triggerEvent(new RefreshAuthExpirationEvent(response.idToken));
            return response.idToken;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                this.msalApp.acquireTokenRedirect({ scopes: [] }).catch(() => {
                    this.handleUnauthorizedLogin();
                });
            } else {
                this.handleUnauthorizedLogin();
            }
        }

        return null;
    }
}
