﻿import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import {
    DropdownFormFieldComponent,
    FieldHeaderComponent,
    LoadIndicatorCenteredComponent,
    TextFormFieldComponent,
} from '@vivli/shared/components';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { PhaseEnum } from '@vivli/features/studies/infrastructure/enum';
import { IDropdownMenuItem } from '@vivli/shared/infrastructure/interface';
import { useFormContext } from 'react-hook-form';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';

const containerStyle: CSSProperties = {
    backgroundColor: 'white',
    paddingBottom: Size.PADDING,
    paddingLeft: Size.PADDING,
    paddingRight: Size.PADDING,
    paddingTop: Size.PADDING,
    borderRadius: Size.BORDER_RADIUS,
    marginBottom: 20,
    display: 'grid',
};
const rowStyle: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: '10% 1fr',
    alignItems: 'center',
};

interface ListingRequestStudyNonNctComponentProps {
    sampleStudy: IStudy;
    hasNonNctValue?: boolean;
    isReadOnly?: boolean;
}

export const ListingRequestStudyNonNctComponent = ({
    sampleStudy = { id: '' },
    hasNonNctValue,
    isReadOnly,
}: ListingRequestStudyNonNctComponentProps) => {
    const [phaseItems, setPhaseItems] = useState<IDropdownMenuItem[]>();
    const [currentPhase, setCurrentPhase] = useState<PhaseEnum>(sampleStudy?.phase);
    const formApi = useFormContext<IListingRequest>();

    if (!sampleStudy) {
        return <LoadIndicatorCenteredComponent />;
    }

    useEffect(() => {
        const phaseVals = Object.values(PhaseEnum);
        const phaseKeys = Object.keys(PhaseEnum);
        const menuItems = Object.keys(phaseVals).map<IDropdownMenuItem>((key) => {
            return {
                title: phaseVals[key],
                value: phaseKeys[key],
            };
        });

        setPhaseItems(menuItems);
        setCurrentPhase(sampleStudy?.phase);
    }, [sampleStudy?.phase]);

    const handleChange = (name, event) => {
        const arr = event.target.value.split(',');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formApi.setValue(name, arr, { shouldDirty: true });
    };

    return (
        <div style={containerStyle}>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Title" />
                <TextFormFieldComponent name={'datasets[0].studyTitle'} readonly={!hasNonNctValue || isReadOnly} />
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Conditions" />
                <TextFormFieldComponent
                    name={'datasets[0].extractedConditions'}
                    readonly={!hasNonNctValue || isReadOnly}
                    onChange={handleChange.bind(this, 'datasets[0].extractedConditions')}
                />
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Interventions" />

                <TextFormFieldComponent
                    name={'datasets[0].extractedInterventions'}
                    readonly={!hasNonNctValue || isReadOnly}
                    onChange={handleChange.bind(this, 'datasets[0].extractedInterventions')}
                />
            </div>
            <div style={rowStyle}>
                <FieldHeaderComponent title="Phase" />
                <DropdownFormFieldComponent
                    name={'datasets[0].phase'}
                    style={{ width: '25%' }}
                    items={phaseItems}
                    readonly={!hasNonNctValue || isReadOnly}
                    value={currentPhase}
                />
            </div>
        </div>
    );
};
