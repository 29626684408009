﻿import { ICitation } from '@vivli/shared/features/citations/infrastructure/interface';
import { useCitation } from '@vivli/shared/features/citations/infrastructure/hook';
import { Color, Size } from '@vivli/shared/theme';
import React, { CSSProperties } from 'react';
import Tippy from '@tippyjs/react';

const citationStyleGeneric: CSSProperties = {
    fontSize: Size.FontSize.Medium,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    flexWrap: 'wrap',
    color: Color.DARK_GRAY,
    marginRight: '10px',
    backgroundColor: Color.TRANSPARENT,
    marginBottom: '-20px',
};

const citationBodyStyle: CSSProperties = {
    ...citationStyleGeneric,
    marginTop: '-5px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
};

const citationJournalStyle: CSSProperties = {
    ...citationStyleGeneric,
    fontStyle: 'italic',
    marginRight: '0px',
};

const citationTitleStyle: CSSProperties = {
    ...citationStyleGeneric,
    marginRight: '0px',
    minWidth: '0px',
    maxWidth: '100%',
    whiteSpace: 'normal',
    lineHeight: '13px',
    marginTop: '10px',
    marginBottom: '10px',
};

interface CitationComponentProps {
    citation: ICitation;
}

export const CitationComponent = ({ citation }: CitationComponentProps) => {
    const { formattedAuthors, remainderFormatted } = useCitation(citation);
    const formattedAuthorsString = formattedAuthors(false);
    const remainderFormattedString = remainderFormatted();
    const titleText = citation ? `"${citation?.title}".` : '-';
    const journalText = citation?.journal ? `${citation?.journal},` : '-';

    return (
        <div style={{ width: '100%' }}>
            <Tippy content={formattedAuthorsString}>
                <div style={citationBodyStyle}>{formattedAuthorsString}</div>
            </Tippy>
            <div style={citationTitleStyle}>{titleText}</div>
            <div style={{ marginTop: '-20px' }}>
                <span style={citationJournalStyle}>{journalText}</span>
                <span style={citationStyleGeneric}>{remainderFormattedString}</span>
            </div>
        </div>
    );
};
