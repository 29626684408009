﻿import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ISearchStudy } from '@vivli/features/dashboard/infrastructure/interface';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { QuickSearchContext, useAzureSearchService } from '@vivli/features/search/infrastructure/context';
import { first } from 'rxjs/operators';
import { IFilterSet, IQuickSearchContext } from '@vivli/features/search/infrastructure/interface';
import { NoSearchResultsFoundModalComponent } from '@vivli/shared/components';

interface QuickSearchContextWrapperProps {
    children?: ReactNode;
}

export const QuickSearchContextWrapper = ({ children }: QuickSearchContextWrapperProps) => {
    const [isShowingResults, setIsShowingResults] = useState(false);
    const [searchResults, setSearchResults] = useState<ISearchStudy[]>([]);
    const [isGettingStudies, setIsGettingStudies] = useState(false);
    const [searchText, setSearchText] = useState('');

    const modalService = useModalService();
    const assignedAppType = useAssignedAppType();
    const { studiesSearch } = useAzureSearchService();

    const filterSetsRef = useRef<IFilterSet[]>([]);

    const addFilterSet = (filterSet: IFilterSet) => {
        /* clear out old failed queries or queries for a different id type */
        filterSetsRef.current = [];
        filterSetsRef.current = [...filterSetsRef.current, filterSet];
        updateStudyData();
    };

    const updateStudyData = () => {
        setIsGettingStudies(true);
        studiesSearch
            .search<ISearchStudy, any>({
                filterSets: filterSetsRef.current,
                assignedAppType,
            })
            .pipe(first())
            .subscribe(({ result }) => {
                setSearchResults(result);
                setIsGettingStudies(false);
                if (result.length < 1) {
                    const modalId = modalService.custom(
                        <NoSearchResultsFoundModalComponent
                            onClose={() => modalService.dismiss(modalId)}
                            noneFoundMessage={'No quick lookup results found.'}
                        />,
                        {
                            showBackground: true,
                        }
                    );
                }
            });
    };

    const closeResults = () => {
        setIsShowingResults(false);
        setSearchText('');
        setSearchResults([]);
    };

    useEffect(() => {
        setIsGettingStudies(true);
        setIsShowingResults(searchResults?.length > 0);
    }, [searchResults]);

    const provider: IQuickSearchContext = {
        isGettingStudies,
        isShowingResults,
        searchResults,
        addFilterSet,
        searchText,
        setSearchText,
        closeResults,
    };

    return <QuickSearchContext.Provider value={provider}>{children}</QuickSearchContext.Provider>;
};
