import React from 'react';
import { useDatasetContext } from '@vivli/features/datasets/infrastructure/context';
import { DownloadOnlyDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Size, Styles } from '@vivli/shared/theme';
import { FieldHeaderComponent } from '@vivli/shared/components';

export const DatasetDownloadComponent = () => {
    const { study, requestedStudyDownloadableIpdPackageId } = useDatasetContext();
    const ipdToUse = requestedStudyDownloadableIpdPackageId ?? study.ipdDataPackageId;
    return (
        <div>
            {
                <>
                    <FieldHeaderComponent style={Styles.FORM_ROW} title="DOWNLOADABLE DATA PACKAGE - PRESS DOWNLOAD BUTTON FOR EACH FILE" />
                    <div style={Styles.FORM_ROW}>
                        <div style={{ flex: 'auto', marginBottom: 20, paddingLeft: Size.PADDING }}>
                            <DownloadOnlyDataPackageFeature
                                dataPackageId={ipdToUse}
                                datasetMetaDataDoi={study.studyIPDDataPackageDoi}
                                studyOrgId={study.orgId}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
