import React from 'react';
import { searchBarContainerStyle } from '../search-filters.styles';
import { SearchSelectComponent } from '../search-select.component';
import { PicoTabsComponent } from './pico-tabs.component';

export const PicoSearchFiltersComponent = () => {
    return (
        <>
            <div style={searchBarContainerStyle}>
                <SearchSelectComponent />
            </div>
            <PicoTabsComponent />
        </>
    );
};
