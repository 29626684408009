import { RestApi } from '@vivli/core/infrastructure/rest';
import { Observable } from 'rxjs';
import {
    IAdminConsoleService,
    ILoggingAreas,
    IProvisionAdminOptions,
    ITemplateStudyLookUp,
    IVirtualMachineLookup,
} from '@vivli/features/admin-console/infrastructure/interface';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';

export class AdminConsoleApiService extends RestApi implements IAdminConsoleService {
    getTemplateStudy(nctOrSponsorID: string): Observable<ITemplateStudyLookUp> {
        const encodedNctOrSponsorId = encodeURIComponent(nctOrSponsorID);
        return this.handleGet<ITemplateStudyLookUp>(`/studies/adminStudy?id=${encodedNctOrSponsorId}`);
    }

    getRequestData(dataRequestDoi: string): Observable<IDataRequest> {
        return this.handleGet<any>(`/datarequests/${dataRequestDoi}/ops`);
    }

    performAction(operation: string, id: string, data: string, comment: string): Observable<any> {
        let url = `/admin/${operation}/${id}`;
        if (data) {
            url = url + `/${data}`;
        }
        return this.handlePut<any>(url, `${comment}`);
    }

    getVMData(VMDoi: string): Observable<IVirtualMachineLookup> {
        return this.handleGet<any>(`/admin/VM/${VMDoi}`);
    }

    provisionVm(id: string, options: IProvisionAdminOptions): Observable<any> {
        return this.handlePost<any>(`/admin/${id}/requestToProvision`, options);
    }

    downloadAudit(startDate: Date, endDate: Date, type: string, id: string, comment: string): Observable<string> {
        const url1 = `/admin/audit?startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}`;
        const urlType = type ? `&type=${type}` : '';
        const urlId = id ? `&id=${id}` : '';
        const urlCom = comment ? `&commentPart=${comment}` : '';

        const url2 = `${urlType}${urlId}${urlCom}`;
        return this.handleGet<any>(`${url1}${url2}`);
    }

    getAuditPresence(type: string, id: string): Observable<boolean> {
        return this.handleGet<boolean>(`/admin/auditPresence?type=${type}&id=${id}`);
    }

    getLoggingDetails(): Observable<ILoggingAreas[]> {
        return this.handleGet<ILoggingAreas[]>('/admin/LoggingAreas');
    }

    updateLoggingDetails(updatedLoggingAreaDetails: ILoggingAreas[]): Observable<void> {
        return this.handlePost<void>('/admin/LoggingAreas', updatedLoggingAreaDetails);
    }
}
