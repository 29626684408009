export const DTIAMRCommonConst = {
    OkButton: 'OkButton',
    CancelButton: 'CancelButton',
    EditButton: 'EditButton',
    ApproveButton: 'ApproveButton',
    SoftRejectButton: 'SoftRejectButton',
    ArchiveButton: 'ArchiveButton',
    DraftButton: 'DraftButton',
    SaveButton: 'SaveButton',
    SubmitButton: 'SubmitButton',
    ResetButton: 'ResetButton',
    ExportCsvButton: 'ExportCsvButton',
    ModalClose: 'ModalClose',
    NavigationPanel: 'NavigationPanel',
    AddButton: 'AddButton',
    DeleteButton: 'DeleteButton',
    DownloadButton: 'DownloadButton',
    VerifyUpload: 'VerifyUpload',
    RequestStudyButton: 'RequestStudyButton',
    ViewDetailsButton: 'ViewDetailsButton',
    LoginButton: 'LoginButton',
    EnquiryFormButton: 'EnquiryFormButton',
    AddCitationButton: (CitationButton: any) => `${CitationButton}Button`,
};
export const DTIFormFields = {
    FormTitle: 'FormTitle',
    FormURL: 'FormURL',
    FormPhase: 'FormPhase',
    FormCondition: 'FormCondition',
    FormIntervention: 'FormIntervention',
    FormSummary: 'FormSummary',
    FormAgeAbility: 'FormAgeAbility',
    FormSexEligibility: 'FormSexEligibility',
    FormHealthyVolunteer: 'FormHealthyVolunteer',
    FormEnrollment: 'FormEnrollment',
    FormLocation: 'FormLocation',
    FormLink: 'FormLink',
    FormStartDate: 'FormStartDate',
    FormCompletionDate: 'FormCompletionDate',
    FormTotalEnrollment: 'FormTotalEnrollment',
    FormStatus: 'FormStatus',
    FormStudyDoi: 'FromStudyDoi',
    FormAmrId: 'FromAmrId',
    FormSponsorId: 'FromSponsorId',
    FormStudyIpdDoi: 'FromStudyIpdDoi',
    FormOrgName: 'FromOrgName',
    FormVivliId: 'FromVivliId',
    FormSecondaryId: 'FromSecondaryId',
    FormLeadAgency: 'FromLeadAgency',
    FormAgencyClass: 'FromAgencyClass',
    FormAlternateDocumentUri: 'FromAlternateDocumentUri',
    FormExternalStudyUri: 'FromExternalStudyUri',
    FormDataAvailability: 'FromDataAvailability',
    FormDataAccessibility: 'FromDataAccessibility',
    FormAcronym: 'FromAcronym',
    UploadedByUserName: 'UploadedByUserName',
    Size: 'Size',
    Date: 'Date',
    FileName: 'FileName',
    DataCollectedFrom: 'DataCollectedFrom',
    LastUpdate: 'LastUpdate',
    NumberOfIsolates: 'NumberOfIsolates',
    PrimaryObjective: 'PrimaryObjective',
    AdditionalInformation: 'AdditionalInformation',
    Antimicrobials: 'Antimicrobials',
    Organisms: 'Organisms',
    Country: 'Country',
    SourceOfSample: 'SourceOfSample',
    TimingOfSample: 'TimingOfSample',
    ResistanceGroup: 'ResistanceGroup',
    PediatricsCheckBox: 'PediatricsCheckBox',
    GenotypeCheckBox: 'GenotypeCheckBox',
    DownloadCheckBox: 'DownLoadCheckBox',
    FormJournal: 'FormJournal',
    FormPages: 'FormPages',
    FormYear: 'FormYear',
    FormORCIDId: 'FormORCIDId',
    FormViews: 'FormViews',
    FormFileDownloads: 'FormFileDownloads',
    FormPackageDownloads: 'FormPackageDownloads',
    FormUsageData: 'FormUsageData',
    CollaboratorTitle: (title: any, index: number) => `${title}${index}`,
    FormLinkNct: (NctIndex: any) => `LinkNct${NctIndex}`,
    AuthorsName: (name: any) => `${name}Name`,
    FormLinkName: (LinkIndex: any) => `Link${LinkIndex}`,
};
export const DTIAMRAgGrid = {
    AboutDataGrid: 'AboutDataGrid',
    StudyDesignGrid: 'StudyDesignGrid',
    CitationsGrid: 'CitationsGrid',
};
export const DTIAMRDropDown = {
    DropdownMenu: (item: any) => `${item}DPValue`,
};
export const DTIAMRTab = {
    Tab: (tab: any) => `${tab}Value`,
};
export const DTIAMRSearchRowResult = {
    SearchRowResult: (index: any) => `SearchRowResult${index}`,
};
