import React, { ReactElement } from 'react';
import { BlockMessageComponent } from '../block-message.component';
import { RetryingComponent } from '../retrying.component';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { VmStatusEnum } from '@vivli/features/virtual-machine/infrastructure/enum';

interface VmAccessGuardComponent {
    children?: ReactElement;
}

export const VmAccessGuardComponent = ({ children }: VmAccessGuardComponent) => {
    const { isAttaching, isAuthorizedUser, provisionStatus } = useResearchEnvironmentContext();

    if (!isAuthorizedUser) {
        if (provisionStatus === VmStatusEnum.Stopped) {
            const stoppedMessage =
                'You cannot be added to the Research Environment right now because it is stopped; please contact your admin to start it.';
            return (
                <BlockMessageComponent message={stoppedMessage}>
                    <RetryingComponent />
                </BlockMessageComponent>
            );
        }

        if (provisionStatus === VmStatusEnum.Provisioned || provisionStatus === VmStatusEnum.Running) {
            if (isAttaching) {
                const message =
                    'You do not have rights to access this research environment, this may take 10 minutes after you accept the invitation. Contact Vivli support if this problem persists.';
                return (
                    <BlockMessageComponent message={message}>
                        <RetryingComponent />
                    </BlockMessageComponent>
                );
            }

            const message = 'You are being added to the research environment, which may take some time. Please return in a while.';
            return (
                <BlockMessageComponent message={message}>
                    <RetryingComponent message={'Adding to research environment...'} />
                </BlockMessageComponent>
            );
        }
    }

    return children;
};
