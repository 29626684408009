export enum VMSizeEnum {
    None = '',
    Basic = 'Basic',
    Standard = 'Standard',
    Large = 'Large',
    Optimized = 'Optimized',
    ComputeOptimized = 'ComputeOptimized',
    ComputeOptimizedLarge = 'ComputeOptimizedLarge',
    MemoryOptimized = 'MemoryOptimized',
}
