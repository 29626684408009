﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { ButtonComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { NotificationFeature } from '@vivli/shared/features/notification/feature';
import { Size } from '@vivli/shared/theme';
import { useNotificationContext } from '@vivli/shared/features/notification/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { ListingRequestStatusUpdateForm } from '../listing-request-status-update.form';
import { useFormContext } from 'react-hook-form';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { NotificationTopicEnum } from '@vivli/shared/features/notification/infrastructure/enum';

const containerStyle: CSSProperties = {
    margin: ` ${Size.INSET_SIZE}px`,
    height: '100%',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
};

const tabButtonsStyle: CSSProperties = {
    display: 'flex',
    gap: '10px',
    height: '100%',
    position: 'absolute',
    right: '30px',
};

interface IStatusUpdateTab {
    name: string;
    sortOrder: number;
    title: string;
}

export const ListingStatusUpdateComponent = () => {
    const { listingRequest, isEditable, handleFormSave, isLoading } = useListingRequestContext();
    const { resetNotificationForm, handleSubmit, notifications, isSaving, showSaveButton } = useNotificationContext();

    const activeUser = useActiveUser();
    const [tabs, setTabs] = useState<IStatusUpdateTab[]>();
    const [activeTab, setActiveTab] = useState('');
    const formApi = useFormContext<IListingRequest>();

    enum TabTitleEnum {
        statusUpdate = 'Status Update',
        notification = 'Notifications',
    }

    enum TabRouteEnum {
        statusUpdate = 'Status Update',
        notification = 'Notifications',
    }

    const getTabs = () => {
        const defaultTabs: IStatusUpdateTab[] = [
            {
                name: TabRouteEnum.statusUpdate,
                sortOrder: 0,
                title: TabTitleEnum.statusUpdate,
            },
            {
                name: TabRouteEnum.notification,
                sortOrder: 1,
                title: TabTitleEnum.notification,
            },
        ];
        return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
    };

    useEffect(() => {
        if (activeUser.isVivliAdmin === true) {
            setTabs(getTabs());
        } else {
            setTabs([getTabs()[0]]);
        }
    }, []);

    useEffect(() => {
        if (tabs !== undefined) setActiveTab(tabs[0].name);
    }, [tabs]);

    const buttonsDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Status Update':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            <ButtonComponent
                                style={{ maxWidth: '200px' }}
                                isLoading={isLoading}
                                disabled={isLoading}
                                onClick={() => handleFormSave(formApi, false)}
                            >
                                {'Save Status Update'}
                            </ButtonComponent>
                        </div>
                    </>
                );
            case 'Notifications':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            {notifications[0] && (
                                <ButtonComponent style={{ maxWidth: '200px' }} onClick={resetNotificationForm}>
                                    {'Create New Notification'}
                                </ButtonComponent>
                            )}
                            <ButtonComponent
                                style={{ maxWidth: '200px' }}
                                disabled={!showSaveButton}
                                isLoading={isSaving}
                                onClick={() => handleSubmit(NotificationTopicEnum.ListingRequest)}
                            >
                                {'Save Notification'}
                            </ButtonComponent>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    const handleTabClick = (tab: IStatusUpdateTab) => {
        setActiveTab(tab.name);
    };

    const tabDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Status Update':
                return (
                    <div>
                        <ListingRequestStatusUpdateForm />
                    </div>
                );
            case 'Notifications':
                return <NotificationFeature topicId={listingRequest.id} />;
            default:
                return null;
        }
    };

    return (
        <div style={containerStyle}>
            <div style={containerStyle}>
                <TabsComponent defaultTab={0}>
                    {tabs?.map((tab, i) => {
                        const { title } = tab;
                        return (
                            <TabComponent
                                key={i}
                                title={title}
                                onTabClick={() => handleTabClick(tab)}
                                rightHeaderContent={<div style={tabButtonsStyle}>{buttonsDisplayed(activeTab)}</div>}
                            >
                                {tabDisplayed(activeTab)}
                            </TabComponent>
                        );
                    })}
                </TabsComponent>
            </div>
        </div>
    );
};
