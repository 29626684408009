const typeOfLables = {
    typeOfLable: [
        { value: 'VM', title: 'Virtual Machine' },
        { value: 'DataRequest', title: 'Data Request' },
        { value: 'Study', title: 'Study' },
    ],
};

export const AdminConsoleConstant = {
    typeOfLables,
};
