import React from 'react';
import { filterGroupContainerStyle } from '../search-filters.styles';
import { StudyDesignFilterGroupComponent } from './ct-filter-groups/study-design-filter-group.component';
import { StudyPhaseFilterGroupComponent } from './ct-filter-groups/study-phase-filter-group.component';
import { SponsorInfoFilterGroupComponent } from './ct-filter-groups/sponsor-info-filter-group.component';
import { SampleSizeFilterGroupComponent } from './ct-filter-groups/sample-size-filter-group.component';
import { LocationFilterGroupComponent } from './ct-filter-groups/location-filter-group.component';
import { DatesFilterGroupComponent } from './ct-filter-groups/dates-filter-group.component';
import { FunderInfoFilterGroupComponent } from '@vivli/features/search/components';

export const CtFilterGroupComponent = () => {
    return (
        <>
            <div style={filterGroupContainerStyle}>
                <StudyDesignFilterGroupComponent />
                <StudyPhaseFilterGroupComponent />
            </div>

            <div style={filterGroupContainerStyle}>
                <FunderInfoFilterGroupComponent />
                <SampleSizeFilterGroupComponent />
            </div>

            <div style={filterGroupContainerStyle}>
                <LocationFilterGroupComponent />
            </div>

            <div style={filterGroupContainerStyle}>
                <DatesFilterGroupComponent />
            </div>
        </>
    );
};
