import React, { CSSProperties, Fragment } from 'react';
import { HistoryListItemComponent } from './history-list-item.component';
import { IHistory } from '@vivli/shared/infrastructure/interface';

const containerStyle = (historyStyle): CSSProperties => ({
    marginLeft: 51,
    height: '100%',
    width: '100%',
    ...historyStyle,
});

const headerStyle = (historyItemStyle): CSSProperties => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 25,
    fontSize: '.8em',
    flexGrow: 1,
    fontWeight: 'bold',
    maxHeight: '50px',
    alignItems: 'center',
    width: '100%',
    ...historyItemStyle,
});

interface HistoryListComponentProps {
    historyEntries: IHistory[];
    hideReviewers: boolean;
    historyStyle?: CSSProperties;
    historyItemStyle?: CSSProperties;
    excludeComments?: boolean;
}

export const HistoryListComponent = ({
    historyEntries,
    hideReviewers,
    historyStyle,
    historyItemStyle,
    excludeComments,
}: HistoryListComponentProps) => {
    return (
        <div style={containerStyle(historyStyle)}>
            <div style={headerStyle(historyItemStyle)}>
                <span style={{ width: '15%' }}>Date and Time</span>
                <span style={{ width: '45%' }}>Action</span>

                {!hideReviewers && (
                    <Fragment>
                        <span style={{ width: '20%' }}>Performed By</span>
                        {!excludeComments && <span style={{ width: '20%' }}>Comments</span>}
                    </Fragment>
                )}
            </div>

            {historyEntries?.map((historyEntry, i) => (
                <HistoryListItemComponent
                    key={i}
                    hideReviewers={hideReviewers}
                    historyEntry={historyEntry}
                    historyItemStyle={historyItemStyle}
                />
            ))}
        </div>
    );
};
