﻿import { AdminHeaderComponent, AdminHeaderTitleComponent } from '@vivli/shared/components';
import { OrganizationMembershipListComponent } from '@vivli/features/dashboard/components';
import { Color, Size } from '@vivli/shared/theme';
import React, { CSSProperties } from 'react';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useNavigate } from 'react-router';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';

const textContainerStyle: CSSProperties = {
    marginLeft: Size.INSET_SIZE,
    fontSize: Size.FontSize.Larger,
    color: Color.DARK_GRAY,
};
const linkStyle: CSSProperties = {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
};

const textStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '5%',
    paddingLeft: '5%',
    marginRight: '5%',
};

const CtLinks = [
    {
        text: 'For an introduction to how to request studies in the Vivli Platform, click here.',
        url: 'https://vivli.org/resources/requestdata/',
        navigate: false,
        urlText: 'How to request studies',
    },

    {
        text: 'For an introduction to the Vivli Platform in general and guides for using the platform, click here.',
        navigate: false,
        url: 'https://vivli.org/resources/resources/',
        urlText: 'How-to guides',
    },

    {
        text: 'To search for clinical studies and create a new data request, click here.',
        navigate: true,
        url: '/',
        urlText: 'Search',
    },

    {
        text: 'To complete and submit a request for data that you have already started, click on My Data Requests.',
        navigate: true,
        url: '/myDataRequests/active',
        urlText: 'My Data Requests',
    },

    {
        text: 'If you cannot find a specific study you need, click on Enquiry to ask about the availability of that clinical study from a Vivli member.',
        navigate: true,
        url: '/admin/enquiries/',
        urlText: 'Enquiry',
    },

    {
        text: 'If you are an academic researcher and want to submit your study to Vivli for archive and subsequent sharing, click here to submit your study. ',
        navigate: false,
        url: 'https://vivli.org/resources/sharedata/',
        urlText: 'Share Data',
    },

    {
        text: (
            <>
                If you have any questions, click here or email Vivli Support at <a href="mailto:support@vivli.org">support@vivli.org</a>.
            </>
        ),
        navigate: false,
        url: 'mailto:support@vivli.org',
        urlText: 'Contact Support',
    },
];

const AmrLinks = [
    {
        text: 'For an introduction to request AMR datasets in the Vivli AMR Platform, click here.',
        url: 'https://amr.vivli.org/resources/data-request-process-overview/',
        navigate: false,
        urlText: 'How to request datasets',
    },

    {
        text: 'For an introduction to the Vivli AMR Platform and guides for using the platform, click here.',
        url: 'https://amr.vivli.org/resources/how-to-guides/',
        navigate: false,
        urlText: 'How-to guides',
    },

    {
        text: 'To search for datasets, click Search.',
        navigate: true,
        url: '/',
        urlText: 'Search',
    },

    {
        text: 'To complete and submit a request for data, click on My Data Requests.',
        navigate: true,
        url: '/myDataRequests/',
        urlText: 'My Data Requests',
    },

    {
        text: (
            <>
                If you have any questions, click here or contact Vivli Support at <a href="mailto:amr@vivli.org">amr@vivli.org</a>.
            </>
        ),
        url: 'mailto:amr@vivli.org',
        urlText: 'Contact Support',
    },
];
export const DashboardResearcherFeature = () => {
    const user = useActiveUser();
    const navigate = useNavigate();
    const hasOrgs = user.orgMemberships.length > 0;

    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const heading = isAmr ? 'This is your view of AMR Register at a glance' : 'This is your view of Vivli at a glance.';

    return (
        <div style={{ height: '100%', overflowX: 'auto', marginBottom: '50px' }} className="scrolly">
            <div style={{ display: 'flex', flexFlow: 'row' }} className={'do-not-print'}>
                <div style={{ width: '50%', display: 'flex', flexFlow: 'column' }}>
                    <AdminHeaderComponent>
                        <AdminHeaderTitleComponent title={`Welcome, ${user.name}!`} />
                    </AdminHeaderComponent>
                    <div style={textContainerStyle}>
                        {heading}
                        <div style={textStyle}>
                            {(isAmr ? AmrLinks : CtLinks).map((item) => (
                                <div style={{ display: 'flex', gap: '50px', marginTop: '10px' }}>
                                    <div style={{ width: '75%' }}>{item.text}</div>
                                    <div style={{ width: '25%' }}>
                                        {!item.navigate ? (
                                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                {item.urlText}
                                            </a>
                                        ) : (
                                            <div style={linkStyle} onClick={() => navigate(item.url)}>
                                                {item.urlText}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {hasOrgs && <OrganizationMembershipListComponent />}
            </div>
        </div>
    );
};
