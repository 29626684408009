export const fileExtensionsIntercept = [
    {
        Extension: '.doc',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xls',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.csv',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.txt',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.pdf',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.rar',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.zip',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.7z',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.cab',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.tar',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.gz',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.bz2',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.zipx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.docx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.docm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.dotx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.dotm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xlsx',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xlsm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xltx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xltm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xlsb',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xlam',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.pptx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.pptm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.potx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.potm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.ppam',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.ppsx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.ppsm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sldx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sldm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.thmx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vsdx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vsdm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vssx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vssm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vstx',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.vstm',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.jpg',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.jpeg',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.gif',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.png',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.tif',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.tiff',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sasbat',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sas7bdat',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.r',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.py',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.pyw',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sas',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xpt',
        WarnForDocumentUpload: true,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.dta',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.sav',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.rtf',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.xml',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.eps',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.rmd',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.rds',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.rdata',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
    {
        Extension: '.pkl',
        WarnForDocumentUpload: false,
        AllowDocumentUpload: true,
    },
];
