import React, { CSSProperties, useEffect, useRef } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ChatRollupFeature } from '@vivli/features/chat/feature';
import { LoadIndicatorCenteredComponent } from '@vivli/shared/components';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { DataRequestStatusUpdateComponent } from '../data-request/data-request-status-update.component';
import { DataRequestHistoryComponent } from '../data-request/data-request-routes/data-request-history/data-request-history.component';
import { AmrDataRequestPrintViewComponent } from './amr-data-request-print-view.component';
import { AmrDataRequestDatasetsComponent } from './amr-data-request-datasets.component';
import { AmrRequestFormComponent } from './amr-request-form.component';
import { AmrDataRequestViewComponent } from './amr-data-request-view.component';
import { useDataRequestAdminPath, useDataRequestRoutes } from '@vivli/features/data-requests/infrastructure/hook';

const routesContainerStyle: CSSProperties = {
    marginTop: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexGrow: 1,
    paddingRight: '5px',
    position: 'relative',
    flexDirection: 'column',
};

export const AmrDataRequestRoutesComponent = () => {
    const { dataRequest, ownsDataRequest } = useDataRequestContext();
    const isDataRequestsView = useDataRequestAdminPath();
    const activeUser = useActiveUser();
    const modalService = useModalService();
    const messageShownRef = useRef(false);
    const isDraft = dataRequest.status === DataRequestStatusEnum.Draft;
    const isOrgAdmin = !activeUser.isVivliAdmin && activeUser?.orgMemberships?.find((om) => om.isOrgAdmin) !== undefined;
    const showStatusUpdate = activeUser.isVivliAdmin || isOrgAdmin ? <DataRequestStatusUpdateComponent /> : null;
    const { isFromEmailLink } = useDataRequestRoutes(dataRequest.id);

    useEffect(() => {
        if (!dataRequest || !activeUser || messageShownRef.current) {
            return;
        }

        if (ownsDataRequest() && activeUser.isVivliAdmin && isDraft && isDataRequestsView && !isFromEmailLink) {
            modalService.message(
                'You are viewing your own draft data request as an administrator.  To edit this request, view your request through the link on the site header.'
            );
            messageShownRef.current = true;
        }
    }, [dataRequest, activeUser]);

    if (!dataRequest) {
        return <LoadIndicatorCenteredComponent />;
    }

    return (
        <div style={routesContainerStyle} className="scrolly do-not-print">
            <Routes>
                <Route path="Datasets" element={<AmrDataRequestDatasetsComponent />} />
                <Route path="RequestHistory" element={<DataRequestHistoryComponent />} />
                <Route path="Chat" element={<ChatRollupFeature dataRequestId={dataRequest.id} channelId={dataRequest.chatChannelId} />} />
                <Route path="Request" element={<AmrRequestFormComponent dataRequest={dataRequest} />} />
                <Route path="StatusUpdate" element={showStatusUpdate} />
                <Route path="PrintView" element={<AmrDataRequestPrintViewComponent />} />
                <Route path="RequestPrintView" element={<AmrDataRequestViewComponent />} />
                //default, for use from email links only
                <Route path="" element={<ChatRollupFeature dataRequestId={dataRequest.id} channelId={dataRequest.chatChannelId} />} />
            </Routes>
            <Outlet />
        </div>
    );
};
