import { useEffect } from 'react';
import { IDateRange } from '@vivli/shared/infrastructure/interface';

export function useDateRangeFilterHook({
    key,
    dateRange,
    isYearRange,
    onChange,
}: {
    key: string;
    dateRange: IDateRange;
    isYearRange?: boolean;
    onChange: (key: string, dateRange: IDateRange, isYearRange: boolean) => void;
}) {
    useEffect(() => {
        onChange(key, dateRange, isYearRange);
    }, [dateRange, dateRange?.from, dateRange?.to]);
}
