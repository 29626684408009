import joi from 'joi';
import { OrcidRegex } from '@vivli/shared/infrastructure/constants';
import { validateOrcidChecksum } from '@vivli/shared/infrastructure/hook';

export function orcidInputSchema(label: string, required = true) {
    const joiValidator = joi
        .string()
        .regex(OrcidRegex)
        .label(label)
        .messages({
            'string.pattern.base': '{{#label}} must be entered in the format 0000-0000-0000-0000.',
        })
        .custom((value, helper) => {
            const result = validateOrcidChecksum(value);
            if (result === '') {
                return value;
            } else {
                return helper.message({
                    custom: result,
                });
            }
        });

    if (required) {
        return joiValidator.required();
    } else {
        return joiValidator.allow('', null);
    }
}
