import { RestApi } from '@vivli/core/infrastructure/rest';
import { map } from 'rxjs/operators';
import { DataTransformationService } from '@vivli/shared/infrastructure/service';
import { Observable } from 'rxjs';
import { ICodeItem, IReportItem, IStatusUpdate } from '@vivli/shared/infrastructure/interface';
import {
    IDataProvider,
    IDataRequest,
    IDataRequestInvitationResponse,
    IDataRequestsApiService,
    IDataRequestSummary,
    IInProcessDataRequest,
    IProvisionOptions,
    IRequestedStudyModification,
    IResearchTeamMemberDetail,
    ISafetyConcern,
    ISafetyConcernSubmission,
    ITeamMemberRequestResult,
    IVirtualMachineAccessInvitation,
} from '@vivli/features/data-requests/infrastructure/interface';
import { ApprovalStatusEnum, DataRequestStatusEnum, StudyRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { DataRequestFilterService } from './data-request-filter.service';
import { IAcceptInviteResponse } from '@vivli/features/organizations/infrastructure/interface';
import { IDataPackage } from '@vivli/shared/features/data-package/infrastructure/interface';
import { IVirtualMachine } from '@vivli/features/virtual-machine/infrastructure/interface';
import { ReportDataTypeEnum } from '@vivli/shared/infrastructure/enum';

export class DataRequestsApiService extends RestApi implements IDataRequestsApiService {
    getDataRequests = (): Observable<IDataRequest[]> => {
        return this.handleGet<IDataRequest[]>('/dataRequests').pipe(map(DataRequestFilterService.filterAllRequestsByAssignedAppType));
    };

    getDataRequestSummaries = (assignedAppType: string): Observable<IDataRequestSummary[]> => {
        return this.handleGet<IDataRequestSummary[]>(`/dataRequests/assignedAppType/${assignedAppType}/statuses`);
    };

    getMyDataRequests = (): Observable<IDataRequest[]> => {
        return this.handleGet<IDataRequest[]>('/dataRequests/myDataRequests').pipe(
            map(DataRequestFilterService.filterAllRequestsByAssignedAppType)
        );
    };

    getDataRequest = (dataRequestId: string): Observable<IDataRequest> => {
        return this.handleGet<IDataRequest>(`/dataRequests/${dataRequestId}`);
    };

    getDataRequestAnonymouslyByDoi = (dataRequestDoi: string): Observable<IDataRequest> => {
        return this.handleGet<IDataRequest>(`/dataRequests/${dataRequestDoi}/metadata`, { bypassAuth: true });
    };

    getReport = (
        dataType: ReportDataTypeEnum,
        dateString: string,
        code: string,
        contributor?: string,
        queryString?: string
    ): Observable<IReportItem[]> => {
        return this.handleGet<IReportItem[]>(`/reports/${dataType}/${dateString}/${code}/${contributor}?${queryString}`).pipe(
            map(DataTransformationService.formatReportItems)
        );
    };

    updateDataRequestStatus = (dataRequestId: string, statusUpdate: IStatusUpdate): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/DataRequests/${dataRequestId}/statusupdate`, statusUpdate);
    };

    createDataPackageForStudy = (dataRequestId: string, studyId: string): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/${dataRequestId}/studies/${studyId}/createDataPackage`);
    };

    setDataRequestStudyStatus = (
        dataRequestId: string,
        studyId: string,
        requestedStudyStatus: StudyRequestStatusEnum,
        comment: string
    ): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/${dataRequestId}/studies/${studyId}/status`, {
            requestedStudyStatus,
            comment,
        });
    };

    removeDataRequestStudy = (dataRequestId: string, studyId: string): Observable<IDataRequest> => {
        return this.handleDelete<IDataRequest>(`/dataRequests/${dataRequestId}/studies/${studyId}`);
    };

    deleteDataRequest = (dataRequestId: string): Observable<void> => {
        return this.handleDelete<void>(`/dataRequests/${dataRequestId}`);
    };

    setTeamMemberAdminApproval = (
        dataRequestId: string,
        teamMember: IResearchTeamMemberDetail,
        isApprove: string
    ): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(
            `/DataRequests/${dataRequestId}/researchteam/adminapproval?memberId=${teamMember.teamMemberId}&isApprove=${isApprove}`,
            teamMember
        );
    };

    changeDataRequestOwner = (dataRequestId: string, teamMember: IResearchTeamMemberDetail): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/DataRequests/${dataRequestId}/owner`, teamMember);
    };

    acceptDataRequestInvite = (dataRequestId: string, teamMemberId: string): Observable<IAcceptInviteResponse> => {
        return this.handlePost<IAcceptInviteResponse>(`/DataRequests/${dataRequestId}/invitations/accept?teamMemberId=${teamMemberId}`, {});
    };

    revokeVmAccess = (dataRequestId: string, memberId: string): Observable<IDataRequest> => {
        return this.handleDelete<IDataRequest>(`/DataRequests/${dataRequestId}/activations/revoke?memberId=${memberId}`);
    };

    inviteToVm = (invite: IVirtualMachineAccessInvitation): Observable<IDataRequestInvitationResponse> => {
        return this.handlePost<IDataRequestInvitationResponse>(`/DataRequests/${invite.dataRequestId}/invitations`, invite);
    };

    removeResearchTeamMember = (dataRequestId: string, teamMemberId: string): Observable<IDataRequest> => {
        return this.handleDelete<IDataRequest>(`/DataRequests/${dataRequestId}/researchteam?memberId=${teamMemberId}`);
    };

    editResearchTeamMember = (dataRequestId: string, teamMember: IResearchTeamMemberDetail): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/DataRequests/${dataRequestId}/researchteam?memberId=${teamMember.teamMemberId}`, teamMember);
    };

    addResearchTeamMember = (dataRequestId: string, teamMember: IResearchTeamMemberDetail): Observable<ITeamMemberRequestResult> => {
        return this.handlePost<ITeamMemberRequestResult>(`/DataRequests/${dataRequestId}/researchteam`, teamMember);
    };

    startHeuristics = (dataRequestId: string, resultRequestId: string, dataPackage: IDataPackage): Observable<IResultRequest> => {
        return this.handlePost<IResultRequest>(
            `/dataRequests/${dataRequestId}/researchEnvironment/${resultRequestId}/StartHeuristics/true`,
            dataPackage
        );
    };

    notifyOfDuaUpload = (dataRequestId: string): Observable<void> => {
        return this.handleGet(`/dataRequests/${dataRequestId}/notify/vivliAdmin/duaUpload`);
    };

    updateDataRequest = (dataRequest: IDataRequest): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>('/dataRequests', dataRequest);
    };

    bulkInviteTeamMembers = (
        dataRequestId: string,
        invitations: IVirtualMachineAccessInvitation[]
    ): Observable<{ dataRequest: IDataRequest }> => {
        return this.handlePost<{
            dataRequest: IDataRequest;
        }>(`/DataRequests/${dataRequestId}/invitations/bulk`, invitations);
    };

    submitDataRequest = (dataRequest: IDataRequest): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>('/dataRequests/submit', dataRequest);
    };

    createDataPackageForRequest = (dataRequestId: string): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/${dataRequestId}/createDataPackage`, {});
    };

    getDataProviders = (dataRequestId: string, isInProcess: boolean): Observable<IDataProvider[]> => {
        return this.handleGet<IDataProvider[]>(`/dataRequests/${dataRequestId}/unlistedstudies/dataproviders/${isInProcess}`);
    };

    addUnlistedStudy = (
        dataRequestId: string,
        unlistedStudy: { dataProviderOrgId: string; studyTitle: string; nctId: string; sponsorProtocolId: string }
    ): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/dataRequests/${dataRequestId}/unlistedstudies/addToDataRequest`, unlistedStudy);
    };

    addUnlistedStudyToInProcessRequest = (
        dataRequestId: string,
        unlistedStudy: { dataProviderOrgId: string; studyTitle: string; nctId: string; sponsorProtocolId: string }
    ): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/dataRequests/${dataRequestId}/studies/addUnlistedInProcess`, unlistedStudy);
    };

    postStatusUpdate = (dataRequestId: string, statusUpdate: IStatusUpdate): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/DataRequests/${dataRequestId}/statusupdate`, statusUpdate);
    };

    setTracking = (dataRequestId: string, tracking: boolean): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/DataRequests/${dataRequestId}/tracking/${tracking}`);
    };

    setDataRequestStatus = (dataRequestId: string, status: DataRequestStatusEnum, comment: string): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/${dataRequestId}/status`, { status, comment });
    };

    getSafetyConcerns = (dataRequestId: string): Observable<ISafetyConcern[]> => {
        return this.handleGet<ISafetyConcern[]>(`/DataRequests/${dataRequestId}/safetyconcerns`);
    };

    postSafetyConcern = (dataRequestId: string, safetyConcernSubmission: ISafetyConcernSubmission): Observable<void> => {
        return this.handlePost<void>(`/DataRequests/${dataRequestId}/safetyconcerns`, safetyConcernSubmission);
    };

    getApprovalOrgs = (dataRequestId: string): Observable<string[]> => {
        return this.handleGet<string[]>(`/datarequests/${dataRequestId}/approve/approvableOrgs`);
    };

    approveRequest = (
        dataRequestId: string,
        comment: string,
        orgId: string,
        rejectionReasonItems: ICodeItem[],
        status: ApprovalStatusEnum
    ): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/${dataRequestId}/approve/${status}`, {
            comment,
            orgId,
            rejectionReasonItems,
        });
    };

    addStudyToDataRequest = (options: { studyId: string; targetDataRequestTitle: string }): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>('/dataRequests/studies/addToDataRequest', options);
    };

    addStudyToDataRequestInProcess = (options: {
        studySponsorIdOrNctId: string;
        dataRequestId: string;
    }): Observable<IInProcessDataRequest> => {
        return this.handlePost<IInProcessDataRequest>('/dataRequests/studies/addToDataRequest/inprocesslisted', options);
    };

    updateStudyDownloadable = (dataRequestId: string, studyId: string, turnDownloadableOn: boolean): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/DataRequests/${dataRequestId}/${studyId}/downloadableSetting/${turnDownloadableOn}`);
    };

    attachSignatureToRequest = (requestId: string, signatureId: string): Observable<IDataRequest> => {
        return this.handlePost<IDataRequest>(`/dataRequests/${requestId}/signature`, signatureId);
    };

    getVirtualMachine = (dataRequestId: string): Observable<IVirtualMachine> => {
        return this.handleGet(`/dataRequests/${dataRequestId}/researchEnvironment`);
    };

    provisionVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToProvision`, options);
    };

    retryProvisionVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/retryRequestToProvision`, options);
    };

    deProvisionVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToDeprovision`, options);
    };

    startVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToStart`, options);
    };

    stopVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToStop`, options);
    };

    addDataPackagesToVirtualMachine = (dataRequestId: string, options: IProvisionOptions): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToAddDataPackages`, options);
    };

    getVmPasswordForActiveUser = (dataRequestId: string): Observable<IVirtualMachine> => {
        return this.handleGet(`/dataRequests/${dataRequestId}/researchEnvironment/authorizedUserPassword`);
    };

    getNumberOfStudiesProvided = (dataRequestId: string): Observable<number> => {
        return this.handleGet(`/dataRequests/${dataRequestId}/providedStudies`);
    };

    /*For modifying requested study settings during data request workkflow via Advanced Options*/
    modifyRequestedStudySettings = (params: IRequestedStudyModification): Observable<IDataRequest> => {
        return this.handlePut<IDataRequest>(`/dataRequests/requestedStudyModification`, params);
    };
}
