import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
    AmrDataRequestFeature,
    DataRequestDraftFeature,
    DataRequestFeature,
    MyDataRequestsFeature,
} from '@vivli/features/data-requests/feature';
import { useAssignedAppType, useGAPageTracking } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { DatasetFeature } from '@vivli/features/datasets/feature';
import { SearchFeature } from '@vivli/features/search/feature';
import { PortalFeature } from '@vivli/features/portal/feature';
import { NotFoundComponent } from '@vivli/shared/components';
import { ViewSecondaryAnalysisComponent } from '@vivli/features/data-requests/components';
import { ResearchTeamMemberOrcidAcceptComponent, ViewDataPackageComponent } from '@vivli/features/studies/components';
import { ListingRequestFeature, ListingRequestsFeature } from '@vivli/features/listing-request/feature';
import { ProtectedRoute } from './protected.route';
import { ListingLandingViewComponent } from '@vivli/features/listing-request/components';
import { VirtualMachinePortalFeature } from '@vivli/features/virtual-machine/feature';
import { EnquiryLandingViewComponent, EnquiryPortalComponent } from '@vivli/features/enquiry/components';

export const AppRoutesWrapper = () => {
    const appType = useAssignedAppType();
    const isAmr = appType === AssignedAppTypeEnum.Amr;
    const dataRequestFeatureToUse = isAmr ? <AmrDataRequestFeature /> : <DataRequestFeature />;
    const dataRequestDraftToUse = isAmr ? <AmrDataRequestFeature /> : <DataRequestDraftFeature />;

    useGAPageTracking();

    return (
        <Routes>
            <Route index path="/" element={<SearchFeature />} />
            <Route path="/portal/:customPortal" element={<SearchFeature />} />
            <Route path="/partner-login" element={<SearchFeature />} />
            <Route path="/mode/amr" element={<SearchFeature />} />
            <Route path="/mode/default" element={<SearchFeature />} />

            <Route path="/acceptInvite/:orgId" />
            <Route path="/acceptInviteForDataRequest/:dataRequestId/:teamMemberId" />
            <Route path="/orcid/:studyId/:orcidId" element={<ResearchTeamMemberOrcidAcceptComponent />} />

            <Route path="/doiLanding">
                <Route path="studies/:studyId/:isLanding" element={<DatasetFeature />} />
                <Route path="datasets/:studyId/:isLanding" element={<DatasetFeature />} />

                <Route path="dataRequests/:dataRequestDoi" element={<ViewSecondaryAnalysisComponent />} />
                <Route path="dataPackages/:dataPackageDoi" element={<ViewDataPackageComponent />} />
            </Route>

            <Route path="/studyDetails">
                <Route path="fromSearch/:studyId" element={<DatasetFeature />} />

                <Route path=":studyId" element={<DatasetFeature />} />
            </Route>

            <Route path="/datasetDetails">
                <Route path="fromSearch/:studyId" element={<DatasetFeature />} />

                <Route path=":studyId" element={<DatasetFeature />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/admin/*" element={<PortalFeature />} />

                <Route path="/datasetDetails">
                    <Route path=":studyId/:requestedStudyIpdDataPackageId/:dataRequestId/:reqStudyDl" element={<DatasetFeature />} />

                    <Route path=":studyId/:requestedStudyIpdDataPackageId" element={<DatasetFeature />} />
                </Route>

                <Route path="/studyDetails">
                    <Route path=":studyId/:requestedStudyIpdDataPackageId/:dataRequestId/:reqStudyDl" element={<DatasetFeature />} />

                    <Route path=":studyId/:requestedStudyIpdDataPackageId/:dataRequestId" element={<DatasetFeature />} />

                    <Route path=":studyId/:requestedStudyIpdDataPackageId" element={<DatasetFeature />} />
                </Route>

                <Route path="/adminDataRequests/:requestId/*" element={dataRequestFeatureToUse} />

                <Route path="/adminDataRequests/:requestStatus?/:requestId/*" element={dataRequestFeatureToUse} />

                <Route path="/myDataRequestDetails/:requestStatus?/:requestId?/*" element={dataRequestDraftToUse} />

                <Route path="/myDataRequestDetailsRO/:requestStatus?/:requestId?/*" element={dataRequestFeatureToUse} />

                <Route path="/myDataRequests/:tab?" element={<MyDataRequestsFeature />} />

                <Route path="/study-submissions/*" element={<ListingRequestsFeature />} />

                <Route path="/study-submission/:id?/*" element={<ListingRequestFeature />} />

                <Route path="/virtual-machine/:virtualMachineId/:dataRequestDoi" element={<VirtualMachinePortalFeature />} />
            </Route>

            <Route element={<ProtectedRoute element={<ListingLandingViewComponent />} />}>
                <Route path="/study-submission" element={<ListingRequestFeature />} />
            </Route>

            <Route element={<ProtectedRoute element={<EnquiryLandingViewComponent />} />}>
                <Route path="/enquiries" element={<EnquiryPortalComponent />} />
            </Route>

            <Route element={<NotFoundComponent />} />
        </Routes>
    );
};
