﻿import React, { CSSProperties } from 'react';
import { useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { AdminHeaderTitleComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { useConfigService } from '@vivli/core/infrastructure/context';
import { StudyDatasetChatComponent } from '../study-dataset-chat.component';
import { StudyDatasetHistoryComponent } from '../study-dataset-history.component';
import { AnnotateStudyJsonTabComponent } from './annotate-study-json-tab.component';
import { DocumentsDataPackageFeature } from '@vivli/shared/features/data-package/feature';
import { Color, Size } from '@vivli/shared/theme';
import { DataTransformationService } from '@vivli/shared/infrastructure/service';

const containerStyle: CSSProperties = {
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: '0 40px',
};
const headerTitleDivStyle: CSSProperties = {
    marginBottom: Size.INSET_SIZE,
};
const statusStyle: CSSProperties = {
    fontWeight: Size.FontWeight.XLight,
    color: Color.DARK_GRAY,
};
const historyStyle: CSSProperties = {
    marginLeft: 0,
};
const historyItemStyle: CSSProperties = {
    width: '100%',
    fontSize: '12.8px',
};

const metadataPackageFiles = ['Study Protocol', 'CSR', 'Statistical Analysis Plan', 'Data Dictionary', 'Other'];

export const AnnotateStudyTabsComponent = () => {
    const { study } = useStudyContext();
    const config = useConfigService();
    const statusMessage = DataTransformationService.mapStatusToMessage(study.status);
    const metadataDocumentsText = config?.enhancedMetadata ? 'Study Documents' : 'Attachments';
    const shouldShowChat = config?.chatEnabled && config?.chatUri?.length > 0 && study.chatChannelId;
    const availableTypes = config.enhancedMetadata ? metadataPackageFiles : [];

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <div style={containerStyle}>
                <div style={headerTitleDivStyle}>
                    <AdminHeaderTitleComponent title={study.studyTitle + ' (' + study.nctId + ')'} />
                    <div style={statusStyle}>Current Status: {statusMessage}</div>
                </div>
                <TabsComponent defaultTab={0}>
                    <TabComponent title={'JSON'}>
                        <AnnotateStudyJsonTabComponent />
                    </TabComponent>
                    <TabComponent title={metadataDocumentsText}>
                        <DocumentsDataPackageFeature dataPackageId={study.metaDataDataPackageId} availableTypes={availableTypes} />
                    </TabComponent>
                    <TabComponent title={'History'}>
                        <StudyDatasetHistoryComponent studyId={study.id} historyStyle={historyStyle} historyItemStyle={historyItemStyle} />
                    </TabComponent>
                    {shouldShowChat && (
                        <TabComponent title={'Chat'}>
                            <StudyDatasetChatComponent chatChannelId={study.chatChannelId} />
                        </TabComponent>
                    )}
                </TabsComponent>
            </div>
        </div>
    );
};
