import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { VMSizeEnum } from '@vivli/features/virtual-machine/infrastructure/enum';
import { VmTableHeaderComponent } from './components/vm-table-header.component';
import { Size } from '@vivli/shared/theme';
import { VmTableColumnComponent } from './components/vm-table-column.component';
import { FieldHeaderComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { Trans, useTranslation } from 'react-i18next';
import { useResearchEnvironmentContext } from '@vivli/features/data-requests/infrastructure/context';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';

const containerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE + Size.PADDING,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginBottom: '30px',
};

const headerStyle: CSSProperties = {
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.Bold,
    minHeight: Size.HEADER_HEIGHT,
};

const tableContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: '30px',
    width: '100%',
    justifyContent: 'center',
};

const tableHeaderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '80px',
    paddingRight: '20px',
    width: '350px',
};

const gridContainerStyle: CSSProperties = {
    display: 'flex',
    maxWidth: '75%',
    height: '100%',
    overflow: 'auto hidden',
};

const additionalOptionsStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: 'rgb(5, 61, 88)',
    minHeight: '50px',
    justifyContent: 'center',
};

const scrollContainerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
};

const headerStyle1: CSSProperties = {
    fontSize: Size.FontSize.Large,
    fontWeight: Size.FontWeight.Regular,
    minHeight: Size.HEADER_HEIGHT,
};

export const ProvisionViewComponent = () => {
    const [researchVmTypes, setResearchVmTypes] = useState(null);
    const [advancedResearchVmTypes, setAdvancedResearchVmTypes] = useState(null);
    const [viewAdvancedTypes, setViewAdvancedTypes] = useState(false);
    const [selectedVmSize, setSelectedVmSize] = useState<VMSizeEnum>();
    const { provisionVm, isWorking } = useResearchEnvironmentContext();

    const advancedOptionsRef = useRef<HTMLInputElement>();
    const vivliConfig = useVivliConfig();
    const { t } = useTranslation();
    const getVmTranslation = (
        <div style={headerStyle1}>
            <Trans components={[<a href="mailto:support@vivli.org" />]}>vmResearchEnvironmentConfig</Trans>
        </div>
    );

    const handleProvisionVm = (size: VMSizeEnum, licenseSAS: boolean, licenseSTATA: boolean) => {
        setSelectedVmSize(size);
        provisionVm({ size, licenseSAS, licenseSTATA });
    };

    useEffect(() => {
        if (!vivliConfig) {
            return;
        }

        const standardTypes = vivliConfig.virtualMachineTypes.filter((t) => !t.advanced);
        const advancedTypes = vivliConfig.virtualMachineTypes.filter((t) => t.advanced);

        setResearchVmTypes(standardTypes);
        setAdvancedResearchVmTypes(advancedTypes);
    }, [vivliConfig]);

    return (
        <div style={containerStyle}>
            <FieldHeaderComponent style={headerStyle} title={'RESEARCH ENVIRONMENT DETAILS'} />

            <div style={tableContainerStyle}>
                <div style={tableHeaderStyle}>
                    <div style={additionalOptionsStyle}>
                        <div style={{ cursor: 'pointer' }} onClick={() => advancedOptionsRef.current.click()}>
                            Advanced Options:
                        </div>
                        <div>
                            <input
                                ref={advancedOptionsRef}
                                type="checkbox"
                                checked={viewAdvancedTypes}
                                onChange={(value) => {
                                    setViewAdvancedTypes(value.target.checked);
                                }}
                            />
                        </div>
                    </div>
                    <VmTableHeaderComponent title="Initial Cost" />
                    <VmTableHeaderComponent title="After Initial Period" />
                    <VmTableHeaderComponent title="Machine Size" />
                    <VmTableHeaderComponent title="Jupyter Notebook" />
                    <VmTableHeaderComponent title="Python, R" />
                    <VmTableHeaderComponent title="STATA" />
                    <VmTableHeaderComponent title={getVmTranslation} />
                </div>
                <div style={gridContainerStyle} className="scrolly">
                    <div style={scrollContainerStyle}>
                        {!researchVmTypes && <LoadIndicatorComponent />}
                        {researchVmTypes?.map((vmType, i) => (
                            <VmTableColumnComponent
                                key={`standard_${i}`}
                                researchVmType={vmType}
                                isWorking={isWorking}
                                selectedVmSize={selectedVmSize}
                                provisionVm={handleProvisionVm}
                            />
                        ))}
                        {viewAdvancedTypes &&
                            advancedResearchVmTypes?.map((vmType, i) => (
                                <VmTableColumnComponent
                                    key={`advanced_${i}`}
                                    researchVmType={vmType}
                                    isWorking={isWorking}
                                    selectedVmSize={selectedVmSize}
                                    provisionVm={handleProvisionVm}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
