import { useActiveUser } from '@vivli/core/infrastructure/context';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';
import { useEffect, useRef, useState } from 'react';

export function useExternalLinkPermissionsHook(study: IStudy) {
    const [canEditLinks, setCanEditLinks] = useState(false);
    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
    const permissionsLoadedRef = useRef(false);
    const user = useActiveUser();

    useEffect(() => {
        if (!study) {
            return;
        }

        const userHasOrgAdminOrProvider = user.orgMemberships?.some(
            (om) => study.orgId === om.orgId && (om.isOrgAdmin || om.isDataProvider)
        );

        const _canEditLinks = user.isVivliAdmin || user.isOperationsAdmin || userHasOrgAdminOrProvider;
        setCanEditLinks(_canEditLinks);
    }, [study]);

    useEffect(() => {
        if (!permissionsLoadedRef.current) {
            permissionsLoadedRef.current = true;
            setIsLoadingPermissions(false);
        }
    }, [canEditLinks]);

    return {
        canEditLinks,
        isLoadingPermissions,
    };
}
