import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css';
import '../styles.scss';
import '../GlobalCSSOverrides.css';
import { ErrorBoundaryWrapper, InitializationWrapper } from '@vivli/core/wrapper';
import { AppInitComponent } from './app-init.component';
import { IntegrationContextWrapper } from '@vivli/shared/infrastructure/wrappers';
import { BrowserRouter } from 'react-router-dom';
import { LayoutWrapper } from '@vivli/core/layout';
import { ContextInjectorComponent, CookieWarningComponent } from '@vivli/shared/components';

const contextWrappers = [
    <BrowserRouter />,
    <ErrorBoundaryWrapper />,
    <InitializationWrapper />,
    <IntegrationContextWrapper />,
    <LayoutWrapper />,
];

export function App() {
    if (!navigator.cookieEnabled) {
        return <CookieWarningComponent />;
    }

    return (
        <ContextInjectorComponent contextWrappers={contextWrappers}>
            <AppInitComponent />
        </ContextInjectorComponent>
    );
}

export default App;
