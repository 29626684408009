import { Observable } from 'rxjs';
import { RestApi } from '@vivli/core/infrastructure/rest';
import { IVirtualMachine, IVirtualMachineApiService } from '@vivli/features/virtual-machine/infrastructure/interface';

export class VirtualMachineApiService extends RestApi implements IVirtualMachineApiService {
    getUserVms = (userId: string): Observable<IVirtualMachine[]> => {
        return this.handleGet<IVirtualMachine[]>(`/researchEnvironments/runningVms/${userId}`);
    };

    getVms = (includeDeleted: boolean): Observable<IVirtualMachine[]> => {
        return this.handleGet<IVirtualMachine[]>(`/ResearchEnvironments?includeDeleted=${includeDeleted}`);
    };

    getDataRequestVm = (dataRequestId: string): Observable<IVirtualMachine> => {
        return this.handleGet<IVirtualMachine>(`/dataRequests/${dataRequestId}/researchEnvironment`);
    };

    getVm = (researchEnvironmentId: string): Observable<IVirtualMachine> => {
        return this.handleGet<IVirtualMachine>(`/researchEnvironments/${researchEnvironmentId}`);
    };

    startResearchEnvironment = (dataRequestId: string): Observable<void> => {
        return this.handlePost(`/dataRequests/${dataRequestId}/researchEnvironment/requestToStart`);
    };

    deprovisionResearchEnvironment = (researchEnvironmentId: string): Observable<IVirtualMachine> => {
        return this.handlePost(`/researchEnvironments/${researchEnvironmentId}/requestToDeprovision`);
    };

    stopResearchEnvironment = (researchEnvironmentId: string): Observable<IVirtualMachine> => {
        return this.handlePost(`/researchEnvironments/${researchEnvironmentId}/requestToStop`);
    };

    getGuacToken = (researchEnvironmentId: string): Observable<string> => {
        return this.handleGet(`/researchEnvironments/${researchEnvironmentId}/guactoken`);
    };

    getAdminGuacToken = (researchEnvironmentId: string): Observable<string> => {
        return this.handleGet(`/researchEnvironments/${researchEnvironmentId}/adminguactoken`);
    };

    getAdminPassword = (researchEnvironmentId: string): Observable<string> => {
        return this.handleGet(`/researchEnvironments/${researchEnvironmentId}/adminpass`);
    };
}
