import { useEffect, useState } from 'react';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { first } from 'rxjs/operators';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useInitializeResearchTeamHook } from './use-initialize-research-team.hook';

export function useDataRequest(dataRequestId: string) {
    const dataRequestService = useDataRequestsService();
    const { transformDataRequest } = useInitializeResearchTeamHook();

    const [dataRequest, setDataRequest] = useState<IDataRequest>();
    const handleDataRequest = (dataRequest: IDataRequest) => {
        if (dataRequest.status !== DataRequestStatusEnum.Draft) {
            setDataRequest(dataRequest);
            return;
        }

        const transformedDataRequest = transformDataRequest(dataRequest);
        setDataRequest(transformedDataRequest);
    };

    useEffect(() => {
        if (!dataRequestId) {
            return;
        }

        const sub = dataRequestService.getDataRequest(dataRequestId).pipe(first()).subscribe(handleDataRequest);

        return () => {
            sub.unsubscribe();
        };
    }, [dataRequestId]);

    return {
        dataRequest,
        updateDataRequest: setDataRequest,
    };
}
