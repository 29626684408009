﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { DropdownFormFieldComponent, FieldHeaderComponent } from '@vivli/shared/components';
import { Styles } from '@vivli/shared/theme';
import {
    assignmentToInterventionOptions,
    interventionalStudyModelOptions,
    observationalStudyModelOptions,
    studyTypeOptions,
} from '@vivli/features/studies/infrastructure/constants';
import { useFormContext } from 'react-hook-form';
import { DTIDropdownMenu } from '@vivli/shared/infrastructure/constants';

const dropdownStyle: CSSProperties = {
    flex: '1 1 33%',
    maxWidth: '28%',
};

export const AnnotateStudyDesignComponent = () => {
    const formApi = useFormContext();
    const [interventionDropdownItems, setInterventionDropdownItems] = useState([]);
    const studyType = formApi.watch('studyType');

    useEffect(() => {
        //If 1st dropdown is observational, set state to change options for 2nd dropdown
        if (studyType === 'Observational') {
            setInterventionDropdownItems(observationalStudyModelOptions);
        } else {
            setInterventionDropdownItems(interventionalStudyModelOptions);
        }
    }, [studyType]);

    return (
        <>
            <FieldHeaderComponent title="STUDY DESIGN" />
            <div style={Styles.FORM_ROW}>
                <DropdownFormFieldComponent
                    name={'studyType'}
                    label={'Study Type'}
                    items={studyTypeOptions}
                    style={dropdownStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('StudyType')}
                />
                <DropdownFormFieldComponent
                    name={'studyDesign.interventionModel'}
                    label={'Intervention Model'}
                    items={interventionDropdownItems}
                    style={dropdownStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('InterventionModel')}
                />
                <DropdownFormFieldComponent
                    name={'studyDesign.assignmentToIntervention'}
                    label={'Assignment to Intervention'}
                    items={assignmentToInterventionOptions}
                    style={dropdownStyle}
                    dataId={DTIDropdownMenu.DropdownMenuItem('AssignmentToIntervention')}
                />
            </div>
        </>
    );
};
