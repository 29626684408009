import React, { CSSProperties, useState } from 'react';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const picoColorBlockStyle = (color: string, selectedOrHovered: boolean): CSSProperties => ({
    height: '50px',
    width: '30px',
    backgroundColor: selectedOrHovered ? `rgb(${color})` : `rgba(${color}, 0.5)`,
    display: 'grid',
    gridTemplateRows: '50% 50%',
    justifyContent: 'center',
    padding: '5px 0',
    cursor: 'pointer',
});

const textStyle: CSSProperties = {
    textAlign: 'center',
};

interface PicoColorBlockComponent {
    color: string;
    title: string;
    selected: boolean;
    onClick: () => void;
}

export const PicoColorBlockComponent = ({ color, title, selected, onClick }: PicoColorBlockComponent) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            onClick={onClick}
            style={picoColorBlockStyle(color, selected || isHovered)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div>{selected && <img src={AssetsConstant.PICO_CHECKMARK} />}</div>
            <div style={textStyle}>{title}</div>
        </div>
    );
};
