﻿import React, { CSSProperties, useEffect, useState } from 'react';
import { nextButtonStyle, outerTabStyle, tabContentContainerStyle } from '../shared/styles';
import {
    ButtonComponent,
    DatePickerFormFieldComponent,
    FieldHeaderComponent,
    HintPopoverComponent,
    YesNoRadioFormFieldComponent,
} from '@vivli/shared/components';
import { useListingRequestContext } from '@vivli/features/listing-request/infrastructure/context';
import { useFormContext, useWatch } from 'react-hook-form';
import { Color, Size } from '@vivli/shared/theme';
import { IListingRequest } from '@vivli/features/listing-request/infrastructure/interface';
import { anonymizedHint, contactHint, dataPackageHint, embargoHint } from '@vivli/features/listing-request/infrastructure/constants';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';

const textSpanStyle: CSSProperties = {
    backgroundColor: 'white',
    padding: 10,
    boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -3px',
    marginBottom: 10,
    fontSize: 15,
};
const marginStyle: CSSProperties = {
    marginBottom: 10,
};

export const DataSharingSettingsComponent = () => {
    const { isReadOnly, handleNext } = useListingRequestContext();
    const formApi = useFormContext<IListingRequest>();

    const isDataEmbargoedWatch = useWatch({ name: 'isDataEmbargoed' });
    const willNeedHelpAnonymizingWatch = useWatch({
        name: 'willNeedHelpAnonymizing',
    });
    const isRequesterContactStudyOwnerWatch = useWatch({
        name: 'allowRequestersToContactStudyOwner',
    });

    const [showEmbargoDate, setShowEmbargoDate] = useState(isDataEmbargoedWatch);
    const [showHelpText, setShowHelpText] = useState(willNeedHelpAnonymizingWatch);
    const vivliConfig = useVivliConfig();

    useEffect(() => {
        if (isDataEmbargoedWatch === undefined) {
            return;
        }
        if (!isDataEmbargoedWatch) {
            formApi.setValue('datasets[0].embargoDate', null, {
                shouldValidate: true,
            });
        }
        setShowEmbargoDate(isDataEmbargoedWatch);
        formApi.trigger();
    }, [isDataEmbargoedWatch]);

    useEffect(() => {
        if (willNeedHelpAnonymizingWatch === undefined) {
            return;
        }
        setShowHelpText(willNeedHelpAnonymizingWatch);
    }, [willNeedHelpAnonymizingWatch]);

    useEffect(() => {
        if (isRequesterContactStudyOwnerWatch === undefined) {
            return;
        }
        formApi.setValue('datasets[0].allowRequestersToContactStudyOwner', 'canBeContacted', {
            shouldValidate: true,
        });

        formApi.trigger();
    }, [isRequesterContactStudyOwnerWatch]);

    return (
        <div style={outerTabStyle} className={'scrolly'}>
            <div style={tabContentContainerStyle}>
                <FieldHeaderComponent title={'DATA SHARING SETTINGS'} />
                <b>Review process for requests for data:</b>
                <p>
                    When a research team requests your study, an Accelerated Research Proposal Review will be conducted within 3 business
                    days. Vivli will manage the execution of the Data Use Agreement. Once these steps are completed, the Vivli team will
                    work with the researcher to access the data and support them until they have published their results. If you have any
                    questions about this process, please reach out to the Vivli team in chat.
                </p>

                <YesNoRadioFormFieldComponent
                    name={'isDataEmbargoed'}
                    label={'Does your data need to be embargoed?'}
                    style={marginStyle}
                    hint={embargoHint}
                    readonly={isReadOnly}
                />
                {showEmbargoDate && (
                    <DatePickerFormFieldComponent
                        name={'datasets[0].embargoDate'}
                        label={'Embargo data until'}
                        style={marginStyle}
                        readonly={isReadOnly}
                    />
                )}

                <YesNoRadioFormFieldComponent
                    name={'canBeContacted'}
                    label={
                        'Data requesters may want to contact you for questions and/or collaboration. Are you willing to be available by email to requesters?'
                    }
                    style={marginStyle}
                    hint={contactHint}
                    readonly={isReadOnly}
                />

                <YesNoRadioFormFieldComponent
                    name={'willNeedHelpAnonymizing'}
                    label={'All data provided to Vivli must be anonymized. Will you need help anonymizing your data?'}
                    style={marginStyle}
                    hint={anonymizedHint}
                    readonly={isReadOnly}
                />
                {showHelpText && (
                    <span style={textSpanStyle}>
                        Vivli will connect you with a vendor who will help with your data anonymization. Please note that a third-party
                        agreement with the anonymization vendor and additional anonymization charges will apply.
                    </span>
                )}

                <p>
                    Study data packages must include at least 4 file types - click here for more information.
                    <HintPopoverComponent
                        position={'top' as any}
                        hintText={dataPackageHint(vivliConfig)}
                        maxWidth={Size.POPOVER_MAX_WIDTH}
                        popoverColor={Color.TEXT_LIGHT_BLUE}
                        popoverArrowColor={Color.TEXT_LIGHT_BLUE}
                        icon={AssetsConstant.INFO_ICON}
                    />
                </p>

                <ButtonComponent style={nextButtonStyle} onClick={() => handleNext(formApi, 'Agreements')}>
                    Next Page
                </ButtonComponent>
            </div>
        </div>
    );
};
