import React from 'react';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { BaseControlledFormFieldComponent } from './base-controlled-form-field.component';
import { DatePickerComponent } from '../input/date-picker.component';

interface DatePickerFormFieldComponent extends IFormField {
    name: string;
    optClassName?: string;
    maxDate?: Date;
    minDate?: Date;
    placeholderText?: string;
    isClearable?: boolean;
}

export const DatePickerFormFieldComponent = (props: DatePickerFormFieldComponent) => {
    return (
        <BaseControlledFormFieldComponent {...props}>
            <DatePickerComponent {...props} />
        </BaseControlledFormFieldComponent>
    );
};
