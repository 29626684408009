import React from 'react';
import { IExternalLink, IExternalLinkOptions } from '@vivli/shared/infrastructure/interface';
import { ExternalLinksComponent } from './external-links.component';
import { useToastService } from '@vivli/shared/infrastructure/context';
import { useStudiesService } from '@vivli/features/studies/infrastructure/context';
import { first } from 'rxjs/operators';
import { IStudy } from '@vivli/features/studies/infrastructure/interface';

export const StudyExternalLinksComponent = ({ editable, externalLinks, documentId, onLinksUpdated }: IExternalLinkOptions) => {
    const toastService = useToastService();
    const studiesService = useStudiesService();

    const handleLinksUpdated = (externalLinks: IExternalLink[], updateType: 'added' | 'edited' | 'deleted') => {
        // placeholder for PR, will call api here
        return new Promise<IStudy>((res, rej) => {
            studiesService
                .updateExternalLinks(documentId, externalLinks)
                .pipe(first())
                .subscribe(
                    (study) => {
                        onLinksUpdated && onLinksUpdated(study);
                        toastService.success(`External study link has been ${updateType} successfully!`);
                        res(study);
                    },
                    () => {
                        toastService.error('External study link failed to update, please try again.');
                        rej();
                    }
                );
        });
    };

    return <ExternalLinksComponent editable={editable} externalLinks={externalLinks} onLinksUpdated={handleLinksUpdated} />;
};
