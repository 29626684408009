import { useEffect, useState } from 'react';
import { IOrganizationMember } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';

export function useCurrentMembersHook(organizationId: string) {
    const [currentMembers, setCurrentMembers] = useState<IOrganizationMember[]>();
    const { getOrganizationMembers } = useOrganizationsService();

    const getCurrentMembers = () => getOrganizationMembers(organizationId, true).subscribe(setCurrentMembers);

    useEffect(() => {
        const organizationSub = getCurrentMembers();

        return () => {
            organizationSub.unsubscribe();
        };
    }, []);

    return {
        currentMembers,
        setCurrentMembers,
        getCurrentMembers,
    };
}
