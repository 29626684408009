import { useActiveUser } from '@vivli/core/infrastructure/context';

export function useUserPermissions() {
    const user = useActiveUser();

    const isDatasetOwner = user?.orgMemberships?.find((om) => om.isDatasetOwner) !== undefined;
    const isOrgAdmin = user?.isVivliAdmin || user?.orgMemberships?.find((om) => om.isOrgAdmin) !== undefined;
    const isDataProvider = isOrgAdmin || user?.orgMemberships?.find((om) => om.isDataProvider) !== undefined;
    const isAnnotator = isDataProvider || user?.orgMemberships?.find((om) => om.isAnnotator) !== undefined;
    const isQaReviewer = isAnnotator || user?.orgMemberships?.find((om) => om.isQaReviewer) !== undefined;
    const isIRPApprover = isAnnotator || isQaReviewer || user?.orgMemberships.find((om) => om.isIRPApprover) !== undefined;

    const canUploadIpd = (organizationId: string) => {
        return user.orgMemberships.some((org) => org.orgId === organizationId && (org.isOrgAdmin || org.isDataProvider));
    };

    return {
        canUploadIpd,
        isDatasetOwner,
        isOrgAdmin,
        isDataProvider,
        isAnnotator,
        isQaReviewer,
        isIRPApprover,
    };
}
