import React from 'react';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { ResultsRequestStatusEnum } from '@vivli/features/results-request/infrastructure/enum';
import { FieldHeaderComponent, LoadIndicatorComponent } from '@vivli/shared/components';
import { ResultRequestFormatService, ResultRequestPermissionService } from '@vivli/features/results-request/infrastructure/service';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { Size } from '@vivli/shared/theme';
import { useActiveUser, useConfigService } from '@vivli/core/infrastructure/context';
import { ResultRequestApprovalComponent } from './result-request-approval.component';
import { DownloadOnlyDataPackageFeature } from '@vivli/shared/features/data-package/feature';

interface ResultRequestComponentProps {
    resultRequest: IResultRequest;
    dataRequest: IDataRequest;
    onRequestChanged?: (request: IResultRequest) => void;
}

export const ResultRequestComponent = ({ resultRequest, dataRequest, onRequestChanged }: ResultRequestComponentProps) => {
    const user = useActiveUser();
    const config = useConfigService();

    if (!resultRequest) {
        return <LoadIndicatorComponent />;
    }

    if (resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.Disapproved && !user.isVivliAdmin) {
        return (
            <div style={{ marginTop: '40px', width: '470px' }}>
                <FieldHeaderComponent title="Results Request has failed review with the following comment:" />
                <div style={{ marginLeft: 25, marginTop: 10 }}>{resultRequest.reviewComments}</div>
            </div>
        );
    } else if (resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.RetrievingResultsFailed) {
        return (
            <div style={{ marginTop: '40px', width: 500 }}>
                <FieldHeaderComponent title="Results retrieval failed.  Please contact administrator." />
            </div>
        );
    } else if (resultRequest.resultsRequestStatus === ResultsRequestStatusEnum.Approved) {
        return (
            <div style={{ marginTop: Size.INSET_SIZE }} className={'scrolly'}>
                <span style={{ marginBottom: '15px' }}>
                    Result Request Status: {ResultRequestFormatService.formatStatus(resultRequest.resultsRequestStatus, user)}
                </span>

                {(ResultRequestPermissionService.canDownloadFiles(resultRequest, dataRequest, user) || user.isVivliAdmin) && (
                    <DownloadOnlyDataPackageFeature dataPackageId={resultRequest.resultsDataPackageId} />
                )}
            </div>
        );
    } else if (ResultRequestPermissionService.canApproveDeny(resultRequest, user) && !config.viewRequestResultsOnly) {
        return (
            <div style={{ marginTop: Size.INSET_SIZE }} className={'scrolly'}>
                <ResultRequestApprovalComponent
                    resultRequest={resultRequest}
                    dataRequestId={dataRequest.id}
                    onResultApproved={onRequestChanged}
                    onResultDisapproved={onRequestChanged}
                />
            </div>
        );
    }

    return (
        <div style={{ marginTop: '40px', width: '294px' }}>
            <FieldHeaderComponent title="Results not yet available for this request." />
        </div>
    );
};
