import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from 'react';
import { IFormField } from '@vivli/shared/infrastructure/interface';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { BaseInputFieldComponent } from './base-input-field.component';
import { FormFieldTypeEnum } from '@vivli/shared/infrastructure/enum';
import mergeRefs from 'react-merge-refs';
import Select, { StylesConfig } from 'react-select';
import { ISelectOption } from '@vivli/features/search/infrastructure/interface';

const selectStyles: StylesConfig<ISelectOption, false> = {
    control: (provided) => ({
        ...provided,
        ...selectStyle,
        paddingLeft: Size.PADDING,
        ':hover': {
            borderColor: Color.WHITE,
            boxShadow: 'none',
        },
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: Color.WHITE,
        borderColor: Color.WHITE,
        boxShadow: 'none',
    }),
    option: (styles) => ({
        ...styles,
        backgroundColor: Color.WHITE,
        color: Color.DARK_GRAY,
        ':hover': {
            backgroundColor: Color.HOVER_GRAY,
            color: Color.DARK_GRAY,
        },
        ':active': {
            backgroundColor: Color.HOVER_GRAY,
            color: Color.DARK_GRAY,
        },
    }),
};
const selectStyle: CSSProperties = {
    position: 'relative',
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    border: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    cursor: 'pointer',
    ...Styles.borderRadius(Size.BORDER_RADIUS),
    ...Styles.NO_SELECT,
};

const displayStyle: CSSProperties = {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
    fontSize: Size.FontSize.Large,
    color: Color.DARK_GRAY,
    ...Styles.borderRadius(Size.BORDER_RADIUS),
    ...Styles.NO_SELECT,
};

interface MultiSelectDropdownFieldComponentProps extends IFormField {
    items: any[];
    onChange?: (value: string | object) => void;
    className?: string;
    isDisabled?: boolean;
    objectKey: string;
    labelKey: string;
    arrayKey?: any;
}

export const MultiSelectDropdownFieldComponent = forwardRef((props: MultiSelectDropdownFieldComponentProps, ref) => {
    const dropdownRef = useRef<HTMLDivElement>();
    const { items, value, onChange, label, isDisabled, objectKey, labelKey, arrayKey } = props;

    const getValue = () => {
        if (arrayKey) {
            return items?.filter((x) => value?.some((v) => v === x[arrayKey])) || [];
        }

        return value;
    };

    const [_value, _setValue] = useState([]);

    const getOptionLabel = (option) => option[labelKey];
    const getOptionValue = (option) => option[objectKey];

    const handleOnChange = (values) => {
        if (arrayKey) {
            const optionValue = values.map((value) => {
                return value[arrayKey];
            });
            onChange && onChange(optionValue);
        } else {
            onChange && onChange(values);
        }
    };

    useEffect(() => {
        if (value) {
            _setValue(getValue());
        }
    }, [value]);

    useEffect(() => {
        if (items) {
            const currentValues = getValue();
            _setValue(currentValues);
        }
    }, [items]);

    const buildInput = () => (
        <Select
            value={_value}
            options={items}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isSearchable={false}
            isMulti={true as any}
            isClearable={true}
            placeholder={'- Select Multiple -'}
            onChange={handleOnChange}
            backspaceRemovesValue
            isDisabled={isDisabled}
            closeMenuOnSelect={false}
            styles={selectStyles}
        />
    );

    const buildDisplayOnly = () => (
        <div style={displayStyle}>
            <ul>
                {value.map((item) => (
                    <li key={item[objectKey]}>{item[labelKey]}</li>
                ))}
            </ul>
        </div>
    );

    return (
        <BaseInputFieldComponent {...props} type={FormFieldTypeEnum.DropDown} inputRef={dropdownRef} label={label || ''}>
            <div ref={mergeRefs([dropdownRef, ref])}>{isDisabled ? buildDisplayOnly() : buildInput()}</div>
        </BaseInputFieldComponent>
    );
});
