﻿import { ButtonComponent, LoadIndicatorComponent, TextFieldComponent } from '@vivli/shared/components';
import React, { CSSProperties, useState } from 'react';
import { useRorSearchHook } from '@vivli/features/organizations/infrastructure/hook';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { IRorResponseItem } from '@vivli/features/organizations/infrastructure/interface';

const institutionContainerStyle: CSSProperties = {
    width: '100%',
    height: '250px',
    backgroundColor: 'rgb(255, 246, 217)',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '30px',
};

const institutionDivStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    gap: '10px',
};
export const EnterInstitutionComponent = ({ onCancel, index, handleUpdateRow, field }) => {
    const rorSearch = useRorSearchHook();
    const modalService = useModalService();
    const [suggestedOrganization, setSuggestedOrganization] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [institutionNameField, setInstitutionNameField] = useState('');

    const handleError = (e, toast?: string) => {
        setIsLoading(false);
        const message = toast ? toast + e : e;
        modalService.error(message);
    };

    const handleResult = (selections: string, matches: IRorResponseItem[]) => {
        let selected = matches.find((item) => selections == item.id);
        selected && setInstitutionNameField(selected.name);
        setIsLoading(false);
    };

    const handleRequestedRorSearch = () => {
        setIsLoading(true);
        rorSearch({
            searchTerm: suggestedOrganization,
            onError: handleError,
            onConfirm: handleResult,
            setIsLoading: setIsLoading,
        });
    };

    return isLoading ? (
        <div style={{ ...institutionContainerStyle, justifyContent: 'center', alignItems: 'center' }}>
            <LoadIndicatorComponent />
        </div>
    ) : (
        <div style={institutionContainerStyle}>
            <div style={institutionDivStyle}>
                <div>Search ROR for name of institution or enter it directly</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ width: '60%' }}>
                        <TextFieldComponent value={suggestedOrganization} onChange={(e) => setSuggestedOrganization(e.target.value)} />
                    </div>

                    <ButtonComponent onClick={handleRequestedRorSearch} disabled={suggestedOrganization.length === 0}>
                        Search ROR
                    </ButtonComponent>
                </div>
            </div>

            <div style={institutionDivStyle}>
                The following institution name will be used
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ width: '60%' }}>
                        <TextFieldComponent
                            value={institutionNameField}
                            onChange={(e) => setInstitutionNameField(e.target.value)}
                            innerStyle={{ width: '94%' }}
                        />
                    </div>
                    <ButtonComponent
                        onClick={() => {
                            setIsLoading(true);
                            onCancel();
                            handleUpdateRow(index, field, institutionNameField);
                            setIsLoading(false);
                        }}
                    >
                        Save Name
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
};
