import { StudyRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IDataRequestSummary, IRequestedStudy } from '@vivli/features/data-requests/infrastructure/interface';

export function useRequestedStudyFilters() {
    const filterIsNewStudy = (study: IRequestedStudy) => {
        return study.studyRequestStatus === StudyRequestStatusEnum.New;
    };

    const filterWithRequestId = (dataRequest: IDataRequestSummary) => {
        return dataRequest.requestedStudies.map((rs) => ({ ...rs, requestId: dataRequest.id }));
    };

    return {
        filterIsNewStudy,
        filterWithRequestId,
    };
}
