import React, { CSSProperties, useEffect } from 'react';
import { useDataRequestContext } from '@vivli/features/data-requests/infrastructure/context';
import { Size } from '@vivli/shared/theme';
import { DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { IDataRequest } from '@vivli/features/data-requests/infrastructure/interface';
import { AmrPublicationTextComponent } from './amr-publication-text.component';
import { VivliAdminCommentsComponent } from '../data-request/data-request-view/vivli-admin-comments.component';

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
};

const subContainerStyle: CSSProperties = {
    borderLeft: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    marginLeft: '10px',
    whiteSpace: 'pre-wrap',
};

const requestDoiStyle: CSSProperties = {
    display: 'flex',
};

const doiTitleStyle: CSSProperties = {
    marginRight: '10px',
};

const doiStyle: CSSProperties = {
    fontSize: '16px',
    paddingTop: '25px',
};

const listStyle: CSSProperties = {
    padding: 0,
    margin: 0,
    listStyle: 'none',
};

const marginBottomStyle: CSSProperties = {
    marginBottom: '5px',
};

const emailStyle: CSSProperties = {
    marginBottom: '25px',
};

const studyTitleStyle: CSSProperties = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
    marginTop: '15px',
};

const checkedUnicode = <span>&#9745;</span>;
const boxUnicode = <span>&#9744;</span>;

interface AmrDataRequestPrintViewComponentProps {
    dataRequest?: IDataRequest;
    onReady?: () => void;
}

export const AmrDataRequestPrintViewComponent = (props: AmrDataRequestPrintViewComponentProps) => {
    const requestContext = useDataRequestContext();

    // grab from the context for regular use, from props for use as a modal
    const dataRequest = requestContext?.dataRequest || props.dataRequest;

    const { requestTitle, dataRequestDoi, secondaryAnalysisDoi, antimicrobialDetail, requestedStudies } = dataRequest;

    const { agreesToPublicationAcknowledgment, agreesToTermsOfUse, amrAnalysisDetails, amrResearcherDetails } = antimicrobialDetail;

    const { summary, purposeList, purposeListOtherDescription } = amrAnalysisDetails;

    const { amrResearcherAffiliation, amrResearcherLocation, researcherName, researcherEmail, orcId } = amrResearcherDetails;

    const showDoi =
        dataRequest.status === DataRequestStatusEnum.Fulfilled || dataRequest.status === DataRequestStatusEnum.PartiallyFulfilled;

    const getPurposeList = () => {
        return (
            <>
                {purposeList?.length > 0 && (
                    <ul style={listStyle}>
                        {purposeList?.map((purpose, i) => (
                            <li key={i} style={getListItemStyle(i)}>
                                {purpose === 'Other' ? 'Other: ' + purposeListOtherDescription : purpose}
                            </li>
                        ))}
                    </ul>
                )}
            </>
        );
    };

    const getListItemStyle = (i: number): CSSProperties => {
        if (i === purposeList?.length - 1) {
            return {};
        }

        return {
            ...marginBottomStyle,
        };
    };

    useEffect(() => {
        props.onReady && props.onReady();
    }, []);

    return (
        <div style={containerStyle}>
            <h2>{'Research Data Request: ' + requestTitle}</h2>
            <h2>AMR ID: {dataRequestDoi}</h2>
            <VivliAdminCommentsComponent history={dataRequest.dataRequestHistoryEntries} isPrintModal={true} />
            {showDoi && (
                <div style={requestDoiStyle}>
                    <h2 style={doiTitleStyle}>Data Request DOI: </h2>
                    <div style={doiStyle}>
                        <a href={secondaryAnalysisDoi}>{secondaryAnalysisDoi || 'N/A'}</a>
                    </div>
                </div>
            )}
            <div>
                <h2>Lead Researcher:</h2>
                <div style={subContainerStyle}>
                    <div style={marginBottomStyle}>Name: {researcherName}</div>
                    <div style={emailStyle}>Email: {researcherEmail}</div>
                    <div style={marginBottomStyle}>Affiliation: {amrResearcherAffiliation}</div>
                    <div style={marginBottomStyle}>ORCID iD: {orcId}</div>
                    <div>Location: {amrResearcherLocation?.name}</div>
                </div>
            </div>
            <div>
                <h2>Purpose of Analysis</h2>
                <div style={subContainerStyle}>{getPurposeList()}</div>
            </div>
            <div>
                <h2>Terms of Use: {agreesToTermsOfUse ? checkedUnicode : boxUnicode}</h2>
            </div>
            <div>
                <h2>Publication Acknowledgement: {agreesToPublicationAcknowledgment ? checkedUnicode : boxUnicode}</h2>
                <AmrPublicationTextComponent dataRequest={dataRequest} />
            </div>
            <div>
                <h2>Summary of Research:</h2>
                <div style={subContainerStyle}>{summary}</div>
            </div>
            <div>
                <h2>Requested Datasets</h2>
                <div>
                    {requestedStudies?.map((study, i) => (
                        <div key={i} style={subContainerStyle}>
                            <div style={studyTitleStyle}>{study.studyTitle}</div>
                            <div style={marginBottomStyle}>Data Request ID: {dataRequest.idString?.replace('Vivli ID: ', '')}</div>
                            <div style={marginBottomStyle}>Dataset ID: {study.sponsorProtocolId}</div>
                            <div style={marginBottomStyle}>Data Contributor: {study.sponsorName}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
