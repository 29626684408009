import React from 'react';
import { useAssignedAppType } from '@vivli/core/infrastructure/hook';
import { AssignedAppTypeEnum } from '@vivli/shared/infrastructure/enum';
import { useConfigService } from '@vivli/core/infrastructure/context';

export const OrgResourcesLinkComponent = () => {
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;
    const config = useConfigService();
    const orgAdminLink = isAmr ? config.amrOrganizationAdminResourcesLink : config.vivliOrganizationAdminResourcesLink;

    return (
        <div
            style={{
                textAlign: 'right',
                marginRight: '40px',
            }}
        >
            <a href={orgAdminLink} target="_blank">
                Organization Administrator Resources
            </a>
        </div>
    );
};
