enum FontSize {
    Small = 10,
    Medium = 12,
    Large = 15,
    Larger = 20,
    XLarge = 36,
    XXLarge = 45,
}

enum FontWeight {
    XLight = 100,
    Light = 300,
    Regular = 400,
    SemiBold = 600,
    Bold = 700,
}

enum Key {
    Enter = 'Enter',
    Escape = 'Escape',
    Space = ' ',
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    Backspace = 'Backspace',
    Tab = 'Tab',
    Shift = 'Shift',
    Alt = 'Alt',
    Meta = 'Meta',
    ContextMenu = 'ContextMenu',
    Control = 'Control',
    Dead = 'Dead',
    Delete = 'Delete',
}

export const Size = {
    FontWeight,
    FontSize,
    Key,
    MIN_QUERY_LENGTH: 3,
    HOVER_OPACITY: 0.4,
    TOP_INSET_SIZE: 20,
    INSET_SIZE: 40,
    SMALL_INSET_SIZE: 20,
    HOVER_TRANSITION_TIME: 0.3,
    HEADER_HEIGHT: 50,
    CONTROL_HEIGHT: 52,
    HEIGHT_FOR_ROW: 60,
    ADMIN_LEFT_PANEL_WIDTH: 230,
    ADMIN_RIGHT_PANEL_MIN_WIDTH: 1049,
    ADMIN_LEFT_PANEL_ITEM_HEIGHT: 70,
    PADDING: 15,
    HALF_PADDING: 8,
    SEARCH_HEIGHT: 70,
    BORDER_RADIUS: 6,
    SEARCH_BORDER_RADIUS_SIZE: 10,
    ROW_BOTTOM_MARGIN: 4,
    MENU_BOX_SHADOW: 'rgba(0, 0, 0, 0.2) 0px 10px 10px -3px',
    BOX_SHADOW: 'rgba(0, 0, 0, 0.2) 0px 1px 20px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    BOX_SHADOW_LIGHT: 'rgba(0, 0, 0, 0.1) 0px 1px 15px, rgba(0, 0, 0, 0.2) 0px 1px 2px',
    BOX_SHADOW_EXTRA_LIGHT: 'rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    FIELD_HEADER_PADDING: 5,
    POPOVER_MAX_WIDTH: 400,
    POPOVER_ZINDEX: 3,
    POPOVER_PADDING: 2,
    TEXTFIELD_ROWS: 2,
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
    FIELD_SEPARATION: 10,
    ADMIN_CHROME_HEIGHT: 228,
    FOOTER_HEIGHT: 50,
    NavigationRoute: {
        AdminDataRequestDetails: {
            Studies: 'Studies',
            RequestHistory: 'Request History',
            Attachments: 'Attachments',
            RequestDetailsPrintView: 'Request Details/Print View',
            ResearchEnvironment: 'Research Environment',
            SignedAgreements: 'Signed Agreements',
            Chat: 'Chat',
            SafetyConcerns: 'Safety Concerns',
            PublicDisclosures: 'Public Disclosures',
        },
    },
};
