import { IContextWrapper } from '@vivli/shared/infrastructure/interface';
import { AdminConsoleApiService } from '@vivli/features/admin-console/infrastructure/service';
import React from 'react';
import { AdminConsoleServiceContext } from '@vivli/features/admin-console/infrastructure/context';
import { useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';

export const AdminConsoleServiceContextWrappers = ({ children }: IContextWrapper) => {
    const defaultApiOptions = useDefaultApiOptions();
    const adminConsoleApiService = new AdminConsoleApiService(defaultApiOptions);

    return <AdminConsoleServiceContext.Provider value={adminConsoleApiService}>{children}</AdminConsoleServiceContext.Provider>;
};
