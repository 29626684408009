import React, { CSSProperties } from 'react';
import { AppRoutesWrapper } from '@vivli/core/wrapper';

const containerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
};

export const AppDefaultModule = () => {
    return (
        <div style={containerStyle}>
            <AppRoutesWrapper />
        </div>
    );
};
