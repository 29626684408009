﻿import React from 'react';
import { AnnotateStudyDesignComponent } from './annotate-study-design.component';
import { AnnotateStudyBlindingComponent } from './annotate-study-blinding.component';
import { AnnotateStudyAnnotationComponent } from './annotate-study-annotation.component';

export const AnnotateStudyJsonTabComponent = () => {
    return (
        <>
            <AnnotateStudyDesignComponent />
            <AnnotateStudyBlindingComponent />
            <AnnotateStudyAnnotationComponent />
        </>
    );
};
