﻿import React, { CSSProperties } from 'react';
import { Color, Size, Styles } from '@vivli/shared/theme';
import { AssetsConstant } from '@vivli/shared/infrastructure/constants';

const PADDING = 5;
const divStyle = (style): CSSProperties => ({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: Color.TEXT_GRAY,
    color: Color.WHITE,
    ...Styles.borderRadius(Size.BORDER_RADIUS),
    paddingBottom: PADDING,
    paddingRight: PADDING,
    paddingTop: PADDING,
    paddingLeft: PADDING * 2,
    cursor: 'pointer',
    boxShadow: Size.BOX_SHADOW_EXTRA_LIGHT,
    ...Styles.Transition.HOVER_OPACITY_TRANSITION,
    ...style,
});
const imgStyle: CSSProperties = {
    paddingBottom: PADDING,
    paddingLeft: PADDING,
    paddingRight: PADDING,
    paddingTop: PADDING,
};

interface MultiEntryBubbleComponentProps {
    style?: React.CSSProperties;
    value: string;
    onRemove?: (value: string) => void;
    stopProp?: boolean;
}

export const MultiEntryBubbleComponent = ({ value, onRemove, style, stopProp }: MultiEntryBubbleComponentProps) => {
    return (
        <div
            style={divStyle(style)}
            onClick={(event) => {
                {
                    stopProp && event.stopPropagation();
                }
                onRemove(value);
            }}
        >
            {value}
            <img src={AssetsConstant.CLOSE_X_WHITE} style={imgStyle} />
        </div>
    );
};
