import React, { CSSProperties, useEffect, useState } from 'react';
import moment from 'moment';
import { ButtonComponent, CheckboxComponent, DropdownFieldComponent, TextFieldComponent } from '@vivli/shared/components';
import { useDataPackageFileService, useDataPackageService } from '@vivli/shared/features/data-package/infrastructure/context';
import { IDataPackage, IDataPackageFileDescriptor } from '@vivli/shared/features/data-package/infrastructure/interface';
import { finalize, first } from 'rxjs/operators';
import { Color } from '@vivli/shared/theme';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useStudiesService, useStudyContext } from '@vivli/features/studies/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { LoggerService } from '@vivli/shared/infrastructure/service';
import { AssetsConstant, DTIAMRCommonConst, DTIAMRDropDown, DTIFormFields } from '@vivli/shared/infrastructure/constants';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useVivliConfig } from '@vivli/core/infrastructure/hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';

const containerStyle: CSSProperties = {
    marginBottom: 10,
    backgroundColor: Color.WHITE,
};

const formStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '5px',
};
const readonlyStyle: CSSProperties = {
    padding: '10px 15px',
    fontSize: '15px',
    color: 'rgb(96, 104, 111)',
    overflowWrap: 'anywhere',
};

const failedMessageStyle: CSSProperties = {
    marginLeft: 0,
    color: Color.WHITE,
    width: 110,
    height: 30,
    marginRight: 10,
    backgroundColor: Color.ERROR_RED,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const uploadingContainerStyle: CSSProperties = {
    color: Color.WHITE,
    width: 100,
    height: 30,
    marginRight: 10,
    backgroundColor: Color.VIVLI_LIGHT_BLUE,
    position: 'relative',
};

const uploadingStyle = (progressPercent: number): CSSProperties => ({
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'inline-block',
    width: progressPercent,
    height: 30,
    transition: 'width 8s',
    backgroundColor: Color.VIVLI_BLUE,
});

const buttonStyle = (minature?: boolean): CSSProperties => ({
    maxWidth: 100,
    minWidth: minature ? 'initial' : '100px',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'space-between',
});

const deleteButtonStyle: CSSProperties = {
    '@media print': {
        display: 'none',
    },
    width: '43px',
    justifyContent: 'center',
    display: 'inline-flex',
    backgroundColor: 'white',
    color: Color.ERROR_RED,
    border: `1px solid ${Color.ERROR_RED}`,
    minWidth: 'initial',
} as React.CSSProperties;

const downloadButtonStyle: CSSProperties = {
    marginRight: '10px',
    '@media print': {
        display: 'none',
    },
    justifyContent: 'center',
    display: 'inline-flex',
    color: 'white',
    minWidth: 'initial',
} as CSSProperties;

const uploadingTextStyle: CSSProperties = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
};

const buttonImgStyle: CSSProperties = {
    width: '15px',
};

const buttonTxtStyle: CSSProperties = {
    marginRight: '10px',
};

interface DataPackageFileProps {
    dataPackageId: string;
    file: IDataPackageFileDescriptor;
    availableTypes?: string[];
    readOnly: boolean;
    allowDelete?: boolean;
    hideFileType?: boolean;
    showPubliclyAvailable?: boolean;
    showDateTime?: boolean;
    allowDownload?: boolean;
    recordDownloadHistory?: boolean;
    hasRequiredTypes?: boolean;
    requiredTypes?: string[];
    miniature?: boolean;
    useSecureStorage?: boolean;
    datasetMetaDataDoi?: string;
    studyOrgId?: string;
    expandedFilenameText?: boolean;
    onFileTypeChanged: (file: IDataPackageFileDescriptor) => void;
    onFileDeleted: (file: IDataPackageFileDescriptor, dataPackage: IDataPackage) => void;
    organization?: IOrganization;
    showDeleteDialog?: boolean;
}

export const DataPackageFileComponent = ({
    file,
    showDateTime,
    hideFileType,
    showPubliclyAvailable,
    availableTypes,
    readOnly,
    onFileTypeChanged,
    allowDelete,
    allowDownload,
    recordDownloadHistory,
    dataPackageId,
    onFileDeleted,
    hasRequiredTypes,
    requiredTypes,
    miniature,
    useSecureStorage,
    datasetMetaDataDoi,
    studyOrgId,
    expandedFilenameText,
    organization,
    showDeleteDialog = false,
}: DataPackageFileProps) => {
    const [activeFile, setActiveFile] = useState<IDataPackageFileDescriptor>(file);
    const [isLoading, setIsLoading] = useState(false);
    const studyContext = useStudyContext();

    const dataPackageFileService = useDataPackageFileService();
    const dataPackageService = useDataPackageService();
    const modalService = useModalService();
    const studiesService = useStudiesService();
    const user = useActiveUser();
    const config = useVivliConfig();
    const filenameText = expandedFilenameText
        ? activeFile.name +
          ' - Uploaded: ' +
          moment(activeFile.updatedDateTime).format('M-D-YY h:mm a') +
          ', Uploaded by: ' +
          activeFile.uploadedByUserEmail
        : activeFile.name;
    const isLinkedSharedFile: boolean = activeFile.publicAccessGranted && !showPubliclyAvailable;

    const getDisplaySize = () => {
        if (!activeFile) {
            return '';
        }

        let { sizeInKb } = activeFile;
        let displaySize = '';

        // 0 is equal to false so specifically checking for null or undefined
        if (sizeInKb === undefined || sizeInKb === null) {
            return displaySize;
        }

        if (sizeInKb > 1024) {
            sizeInKb = sizeInKb / 1024;
            displaySize = `${parseFloat(sizeInKb.toString()).toFixed(2)}MB`;
        } else if (sizeInKb <= 0) {
            displaySize = '< 1 kB';
        } else {
            displaySize = `${parseFloat(sizeInKb.toString()).toFixed(2)}kB`;
        }

        return displaySize;
    };
    const canUserSendDataCount = (studyOrgId: string) => {
        // when data contributor downloads its own data , it doesn't count
        if (studyOrgId) {
            const orgs = user.orgMemberships.filter((org) => {
                return org.orgId === studyOrgId && org.isDataProvider;
            });
            return !(orgs && orgs.length > 0);
        }
        return false;
    };

    const handleError = (customError: string, error: any) => {
        modalService.message(`${customError} ${error ? error : ''}`, {
            showBackground: false,
            showContinueButton: true,
            title: 'Error',
        });
    };
    const handleFileDownload = () => {
        setIsLoading(true);
        //send data count

        if (datasetMetaDataDoi && datasetMetaDataDoi != 'DOINotCreated' && canUserSendDataCount(studyOrgId)) {
            const fileId = activeFile.name;
            const fileIdentity = activeFile.datapackageDoiFileNumber ? activeFile.datapackageDoiFileNumber : fileId;
            studiesService.sendDataCountCollectionData(datasetMetaDataDoi, fileIdentity).subscribe(
                (sent) => {
                    LoggerService.log(`Download count is ${sent}`);
                },
                (error) => {
                    LoggerService.log(`Download count is errored:  ${error}`);
                }
            );
        }

        dataPackageFileService
            .downloadFileUri(dataPackageId, activeFile.name, recordDownloadHistory || false, useSecureStorage)
            .pipe(first())
            .subscribe(
                (downloadLink) => {
                    //link is created and inserted into the DOM
                    //to avoid flickering happening if we just use window.open...
                    const a = document.createElement('a');

                    a.download = file.name;
                    a.href = downloadLink;

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    setIsLoading(false);
                },
                (error) => {
                    setIsLoading(false);
                    handleError('Unable to download file.', error);
                }
            );
    };

    const handleFileDeleteWarning = () => {
        const isUploadedByCurrentUser = activeFile.uploadedByUserId === user.userId;
        const modalMessage = isUploadedByCurrentUser
            ? `Are you sure you want to delete this file:  ${activeFile.name}`
            : `This file was added by another user - are you sure you want to delete this file: ${activeFile.name}`;

        modalService.confirm(modalMessage, {
            confirmText: 'Ok',
            cancelText: 'Cancel',
            onConfirm: handleFileDelete,
        });
    };

    const handleFileDelete = () => {
        setIsLoading(true);
        if (activeFile.publicAccessGranted) {
            //remove from Public access
            const updatedFile = {
                ...activeFile,
                publicAccessGranted: false,
            };
            dataPackageService
                .addUpdateFile(dataPackageId, updatedFile)
                .pipe(
                    first(),
                    finalize(() => setIsLoading(false))
                )
                .subscribe(
                    () => {
                        onFileDeleted(activeFile, null);
                        studyContext?.setIpdRefresh(studyContext?.ipdRefresh + 1);
                    },
                    (error) => {
                        handleError('Unable to remove file from public access', error);
                    }
                );
        } else {
            //delete document
            dataPackageFileService
                .deleteFile(dataPackageId, activeFile.name, useSecureStorage)
                .pipe(
                    first(),
                    finalize(() => setIsLoading(false))
                )
                .subscribe(
                    (dataPackage) => {
                        onFileDeleted(activeFile, dataPackage);
                    },
                    (error) => {
                        handleError('Unable to delete file.', error);
                    }
                );
        }
    };

    const handleFileTypeChanged = (value: string) => {
        setIsLoading(true);
        const updatedFile = {
            ...activeFile,
            type: value,
        };
        handleFileTypeChangeforPubliclyAvailable(value, updatedFile, true);
        dataPackageService
            .addUpdateFile(dataPackageId, updatedFile)
            .pipe(
                first(),
                finalize(() => setIsLoading(false))
            )
            .subscribe(
                () => {
                    onFileTypeChanged(updatedFile);
                },
                (error) => {
                    handleError('Unable to change file type.', error);
                }
            );
    };

    const handleFileTypeChangeforPubliclyAvailable = (value: string, file: IDataPackageFileDescriptor, fromUser: boolean = false) => {
        if (showPubliclyAvailable) {
            if (value == 'Unknown') {
                file.publicAccessDisabled = true;
                file.publicAccessGranted = false;
            }
            if (config?.ipdFileTypes) {
                //clear and disable checkbox if not shareable
                file.publicAccessDisabled = !config.ipdFileTypes.find((t) => t.fileType === value)?.publicAccessAllowable;
                if (file.publicAccessDisabled) {
                    file.publicAccessGranted = false;
                }
            }
            //if file type is shared by default by organization, set it to true - only on user's action
            if (
                fromUser &&
                !file.publicAccessDisabled &&
                organization?.publicAccessByDefaultFileTypes?.length > 0 &&
                !file.publicAccessGranted
            ) {
                file.publicAccessGranted = !!organization.publicAccessByDefaultFileTypes.find((t) => t === value);
            }
        }
    };
    const handlePubliclyAvailableChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (activeFile.publicAccessDisabled) {
            return;
        } //just in case, if we still have timing issues

        setIsLoading(true);
        const updatedFile = {
            ...activeFile,
            publicAccessGranted: event.target.checked,
        };

        dataPackageService
            .addUpdateFile(dataPackageId, updatedFile)
            .pipe(
                first(),
                finalize(() => setIsLoading(false))
            )
            .subscribe(
                () => {
                    onFileTypeChanged(updatedFile); //updates the display and files in the parent component
                    if (!allowDelete) {
                        //if the file is submitted (allowDelete = false) refresh the documents tab if public access changed
                        studyContext?.setDocumentsRefresh(studyContext?.documentsRefresh + 1);
                    }
                },
                (error) => {
                    handleError('Unable to change Public Availability.', error);
                }
            );
    };

    const fieldHasError = () => {
        return !hasRequiredTypes && !requiredTypes?.some((rt) => activeFile.type === rt) && 'Missing Required File Types';
    };

    useEffect(() => {
        if (!file) {
            return;
        }
        if (config) {
            handleFileTypeChangeforPubliclyAvailable(file.type, file, false);
        }
        setActiveFile({ ...file }); //clone the file to initiate re-render
    }, [file, config]);

    return (
        <div style={containerStyle}>
            <div style={formStyle}>
                <TextFieldComponent
                    label="Filename"
                    style={{
                        width: miniature ? '30%' : '15%',
                        boxShadow: 'none',
                        flexDirection: 'column',
                        maxWidth: miniature ? '300px' : '200px',
                    }}
                    defaultValue={filenameText}
                    readonly={true}
                    dataId={DTIFormFields.FileName}
                    widthPerCharacter={5}
                />

                {activeFile.updatedDateTime && showDateTime && (
                    <TextFieldComponent
                        label="Date"
                        style={{
                            width: '30%',
                            boxShadow: 'none',
                            flexDirection: 'column',
                            maxWidth: '150px',
                        }}
                        defaultValue={moment(activeFile.updatedDateTime).format('M-D-YY h:mm a')}
                        readonly={true}
                        dataId={DTIFormFields.Date}
                        widthPerCharacter={4}
                    />
                )}

                <TextFieldComponent
                    label="Size"
                    style={{
                        width: '5%',
                        boxShadow: 'none',
                        flexDirection: 'column',
                        maxWidth: '120px',
                        minWidth: '120px',
                    }}
                    defaultValue={getDisplaySize()}
                    readonly={true}
                    dataId={DTIFormFields.Size}
                    widthPerCharacter={4}
                />
                {
                    <div>
                        <TextFieldComponent
                            label="Uploaded By"
                            style={{
                                width: hideFileType ? '15%' : '8%',
                                boxShadow: 'none',
                                wordBreak: 'break-all',
                                textAlign: 'left',
                                minWidth: miniature ? '100px' : '150px',
                            }}
                            defaultValue={activeFile.uploadedByUserName}
                            readonly={true}
                            dataId={DTIFormFields.UploadedByUserName}
                            widthPerCharacter={5}
                        />
                    </div>
                }
                {!hideFileType && availableTypes && !readOnly && (
                    <div>
                        <DropdownFieldComponent
                            label={'File Type'}
                            placement="auto"
                            style={{
                                width: '25%',
                                boxShadow: 'none',
                                marginRight: '10px',
                                minWidth: '160px',
                                paddingTop: '0px',
                                marginTop: '0px',
                            }}
                            readonly={readOnly || isLoading || isLinkedSharedFile}
                            defaultValue={activeFile.type}
                            items={availableTypes.map((t) => ({
                                title: t,
                                value: t,
                            }))}
                            onChange={(value) => handleFileTypeChanged(value as string)}
                            error={fieldHasError()}
                            hidePopoverError={true}
                            dataId={DTIAMRDropDown.DropdownMenu('value')}
                        />
                    </div>
                )}
                {!hideFileType && readOnly && (
                    <div
                        style={{
                            ...readonlyStyle,
                            width: '20%',
                            minWidth: '100px',
                            flexDirection: 'row',
                        }}
                    >
                        File Type: {activeFile.type}
                    </div>
                )}
                {!hideFileType && showPubliclyAvailable && !activeFile.publicAccessDisabled && (
                    <div style={{ ...readonlyStyle, paddingRight: 15, width: '15%', paddingTop: 20 }}>
                        Publicly Available
                        <CheckboxComponent
                            label={''}
                            checked={activeFile.publicAccessGranted}
                            onChange={(event) => handlePubliclyAvailableChanged(event)}
                            disabled={activeFile.publicAccessDisabled}
                        />
                    </div>
                )}
                {!hideFileType && showPubliclyAvailable && activeFile.publicAccessDisabled && (
                    /*this is to format grid decently*/ <div
                        style={{
                            ...readonlyStyle,
                            paddingRight: 15,
                            width: '15%',
                            paddingTop: 20,
                            color: 'transparent',
                        }}
                    >
                        Publicly Available
                    </div>
                )}

                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    {activeFile.isError && <div style={failedMessageStyle}>Upload Failed</div>}

                    {!activeFile.isError && (
                        <div
                            style={{
                                minWidth: miniature ? 'initial' : '120px',
                            }}
                        >
                            {!activeFile.isComplete && activeFile.percentUploaded !== undefined && (
                                <div style={uploadingContainerStyle}>
                                    <div style={uploadingStyle(activeFile.percentUploaded)} />
                                    <div style={uploadingTextStyle}>Uploading</div>
                                </div>
                            )}

                            {/*we have to check for percentUploaded here as well since old data did not correctly save*/}
                            {/*the isComplete property because of the old component's async issue */}
                            {(activeFile.isComplete || activeFile.percentUploaded === undefined) && (
                                <div style={buttonContainerStyle}>
                                    {allowDownload && (
                                        <ButtonComponent
                                            style={downloadButtonStyle}
                                            onClick={handleFileDownload}
                                            isLoading={isLoading}
                                            className="do-not-print"
                                            loaderColor={LoadIndicatorColorEnum.White}
                                            dataId={DTIAMRCommonConst.DownloadButton}
                                        >
                                            <div style={buttonStyle(miniature)}>
                                                {!miniature && <span style={buttonTxtStyle}>Download</span>}
                                                <img src={AssetsConstant.DOWNLOAD_ARROW_WHITE} style={buttonImgStyle} />
                                            </div>
                                        </ButtonComponent>
                                    )}

                                    {allowDelete && (
                                        <ButtonComponent
                                            style={deleteButtonStyle}
                                            onClick={showDeleteDialog ? handleFileDeleteWarning : handleFileDelete}
                                            isLoading={isLoading}
                                            className="do-not-print"
                                            dataId={DTIAMRCommonConst.DeleteButton}
                                        >
                                            <div style={buttonStyle(true)}>
                                                <FontAwesomeIcon
                                                    icon={!isLinkedSharedFile ? faTrash : faEyeSlash}
                                                    aria-hidden="true"
                                                    title={!isLinkedSharedFile ? 'Delete this File' : 'Remove from Public Access'}
                                                />
                                            </div>
                                        </ButtonComponent>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
