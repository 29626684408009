import { Observable } from 'rxjs';
import { IChannelUser, IChannelWithBlock, IChatApi } from '@vivli/features/chat/infrastructure/interface';
import { RestApi } from '@vivli/core/infrastructure/rest';

export class ChatApiService extends RestApi implements IChatApi {
    getChatChannel = (channelId: string): Observable<IChannelWithBlock> => {
        return this.handleGet<IChannelWithBlock>(`/chat/channel/${channelId}`);
    };

    getChatChannelBlock = (channelId: string, blockId: string): Observable<IChannelWithBlock> => {
        return this.handleGet<IChannelWithBlock>(`/chat/channel/${channelId}/block/${blockId}`);
    };

    postChatMessage = (channelId: string, message: string): Observable<void> => {
        return this.handlePost<void>(`/chat/channel/${channelId}/message`, message);
    };

    getChannelUser = (userId: string): Observable<IChannelUser> => {
        return this.handleGet<IChannelUser>(`/chat/channel/user/${userId}`);
    };
}
