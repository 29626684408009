import React from 'react';
import { GridComponent } from '@vivli/shared/components';
import { IResultRequest } from '@vivli/features/results-request/infrastructure/interface';
import { ColDef, FirstDataRenderedEvent } from 'ag-grid-community';
import moment from 'moment';
import { ResultsRequestStatusEnum } from '@vivli/features/results-request/infrastructure/enum';
import { ResultRequestFormatService } from '@vivli/features/results-request/infrastructure/service';
import { useActiveUser } from '@vivli/core/infrastructure/context';

interface ResultRequestsGridComponentProps {
    resultRequests: IResultRequest[];
    onRequestClick: (resultRequest: IResultRequest) => void;
    showDraftRequests?: boolean;
    onGridReady?: (resultRequest: IResultRequest) => void;
}

export const ResultRequestsGridComponent = ({
    resultRequests,
    onRequestClick,
    showDraftRequests,
    onGridReady,
}: ResultRequestsGridComponentProps) => {
    const activeUser = useActiveUser();

    const handleFirstDataRendered = ({ api, firstRow }: FirstDataRenderedEvent) => {
        const firstResultRequestNode = api.getDisplayedRowAtIndex(firstRow);
        api.selectNode(firstResultRequestNode);
        onGridReady && onGridReady(firstResultRequestNode.data);
    };

    const columns: ColDef[] = [
        {
            field: 'requestedDateTime',
            headerName: 'Date Requested',
            initialSort: 'desc',
            valueFormatter: (params) => moment(params.data.requestedDateTime).format('YYYY-MM-DD'),
        },
        {
            field: 'requestedByDisplayName',
            headerName: 'Requested By',
            cellStyle: { textAlign: 'center' },
        },
        {
            field: 'status',
            headerName: 'Request Status',
            valueGetter: (params) => ResultRequestFormatService.formatStatus(params.data.resultsRequestStatus, activeUser),
            cellStyle: { textAlign: 'center' },
        },
    ];

    const filteredRequests = resultRequests.filter(
        (r) => r.resultsRequestStatus !== ResultsRequestStatusEnum.Draft && r.resultsRequestStatus !== ResultsRequestStatusEnum.NotStarted
    );

    return (
        <GridComponent
            rowData={showDraftRequests ? resultRequests : filteredRequests}
            columns={columns}
            hideHeader={true}
            hideFloatingFilter={true}
            fullWidthColumns={true}
            emptyMessage={'No Result Requests Found'}
            pagination={true}
            pageSize={4}
            onRowClick={onRequestClick}
            autoHeight={true}
            style={{ height: 'initial' }}
            rowSelection="single"
            highlightSelectedRow={true}
            onFirstDataRendered={handleFirstDataRendered}
        />
    );
};
