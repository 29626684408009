import React, { CSSProperties } from 'react';
import { Styles } from '@vivli/shared/theme';
import { WhiteBackgroundLogoComponent } from '@vivli/shared/components';

const logoContainerStyle: CSSProperties = {
    margin: 25,
    marginBottom: 50,
    textAlign: 'center',
    display: 'block',
};

const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
};

export const LoginPromptComponent = ({ children = null, login }) => {
    const handleLogin = () => {
        login();
    };

    return (
        <div style={containerStyle}>
            <div style={logoContainerStyle}>
                <div
                    style={{
                        margin: 25,
                        marginBottom: 50,
                        textAlign: 'center',
                        display: 'block',
                    }}
                >
                    <WhiteBackgroundLogoComponent />
                </div>
            </div>

            {children && (
                <div style={{ ...Styles.CENTERED_FLEX, marginBottom: 50 }}>
                    <h2>Please log in to continue...</h2>
                </div>
            )}

            <div style={{ ...Styles.CENTERED_FLEX }}>
                <button style={Styles.Button.BUTTON_VIVLI_BLUE} onClick={handleLogin}>
                    Login &gt;
                </button>
            </div>
        </div>
    );
};
