import React, { CSSProperties, useEffect, useState } from 'react';
import { AdminConsoleApiService } from '@vivli/features/admin-console/infrastructure/service';
import { downloadFile, useDefaultApiOptions } from '@vivli/shared/infrastructure/hook';
import { first } from 'rxjs/operators';
import { ButtonComponent } from '@vivli/shared/components';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { DTICommonConst } from '@vivli/shared/infrastructure/constants';

const buttonStyle: CSSProperties = {
    marginLeft: 0,
};

interface AuditTrailContextualProps {
    type: string;
    id: string;
    internalId: string;
}

export const AuditTrailContextualComponent = ({
    type,
    id, //protocolId, datar request DOI, etc.
    internalId, //id
}: AuditTrailContextualProps) => {
    const defaultApiOptions = useDefaultApiOptions();
    const adminConsoleApiService = new AdminConsoleApiService(defaultApiOptions);
    const [auditExists, setAuditExists] = useState<boolean>(false);
    const modalService = useModalService();
    const user = useActiveUser();
    const isAuditAvailable = user.isVivliAdmin || user.isOperationsAdmin;

    const handleError = (error: string) => {
        modalService.error(error);
    };

    const onDownload = () => {
        var fromDateRange = new Date(2023, 2, 1);
        var toDateRange = new Date();

        adminConsoleApiService
            .downloadAudit(fromDateRange, toDateRange, type, id, null)
            .pipe(first())
            .subscribe((data) => {
                downloadFile(
                    'data:application/json;charset=utf-8,' + encodeURIComponent(data.substr(1, data.length - 2)),
                    `auditTrail${Date.now().toString()}.json`
                );
            }, handleError);
    };

    useEffect(() => {
        if (isAuditAvailable) {
            adminConsoleApiService
                .getAuditPresence(type, internalId)
                .pipe(first())
                .subscribe((data) => {
                    setAuditExists(data);
                }, handleError);
        }
    }, [internalId]);

    return (
        <div>
            {auditExists && (
                <ButtonComponent style={buttonStyle} onClick={onDownload} dataId={DTICommonConst.HeaderApprovalButton('DownloadAudit')}>
                    Download Audit Trail
                </ButtonComponent>
            )}
        </div>
    );
};
