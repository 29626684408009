import React, { CSSProperties } from 'react';
import { LoadIndicatorComponent } from '@vivli/shared/components';

const containerStyle: CSSProperties = { marginTop: 20, display: 'flex', justifyContent: 'center' };
const textStyle: CSSProperties = { marginRight: '20px' };

interface RetryingComponent {
    message?: string;
}

export const RetryingComponent = ({ message }: RetryingComponent = { message: 'Retrying...' }) => {
    return (
        <div style={containerStyle}>
            <div style={textStyle}>{message}</div>
            <LoadIndicatorComponent />
        </div>
    );
};
