﻿import { AssignmentToInterventionEnum } from '@vivli/features/studies/infrastructure/enum';

export const studyTypeOptions = [
    { value: 'Interventional', title: 'Interventional' },
    { value: 'Observational', title: 'Observational' },
];

export const interventionalStudyModelOptions = [
    { title: 'Single Group', value: 'SingleGroup' },
    { title: 'Parallel Group', value: 'ParallelGroup' },
    { title: 'Crossover', value: 'Crossover' },
];

export const observationalStudyModelOptions = [
    { title: 'Case Crossover', value: 'CaseCrossover' },
    { title: 'Case Control', value: 'CaseControl' },
    { title: 'Cohort', value: 'Cohort' },
    { title: 'Cross-Sectional', value: 'Cross-sectional' },
];

export const assignmentToInterventionOptions = [
    { value: AssignmentToInterventionEnum.NotApplicable, title: '' },
    { value: AssignmentToInterventionEnum.Randomized, title: 'Randomized' },
    { value: AssignmentToInterventionEnum.NonRandomized, title: 'Non-Randomized' },
];
