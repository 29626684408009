import React, { CSSProperties, useEffect, useState } from 'react';
import { Size } from '@vivli/shared/theme';
import { useStatusUpdate, useStatusUpdateForm } from '@vivli/features/data-requests/infrastructure/hook';
import { StatusUpdateFormComponent } from '../forms/status-update-form/status-update-form.component';
import { ButtonComponent, TabComponent, TabsComponent } from '@vivli/shared/components';
import { useDataRequestContext, useStatusUpdateContext } from '@vivli/features/data-requests/infrastructure/context';
import { useActiveUser } from '@vivli/core/infrastructure/context';
import { FormProvider } from 'react-hook-form';
import { NotificationFeature } from '@vivli/shared/features/notification/feature';
import { useNotificationContext } from '@vivli/shared/features/notification/infrastructure/context';
import { NotificationTopicEnum } from '@vivli/shared/features/notification/infrastructure/enum';
import { DuaComponent } from './data-request-header/components/dua.component';

const containerStyle: CSSProperties = {
    margin: `0 ${Size.INSET_SIZE}px`,
    height: '100%',
};

const formContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(1fr)',
    rowGap: '20px',
    columnGap: '20px',
};

const buttonContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
};

const tabButtonsStyle: CSSProperties = {
    display: 'flex',
    gap: '10px',
    height: '100%',
    position: 'absolute',
    right: '30px',
};

interface IStatusUpdateTab {
    name: string;
    sortOrder: number;
    title: string;
}

export const DataRequestStatusUpdateComponent = () => {
    const { dataRequest } = useDataRequestContext();
    const { isSubmittingForm, submitStatusUpdate, setFormApi } = useStatusUpdateContext();
    const { handelDuaSave, isSavingDataRequest } = useDataRequestContext();
    const { resetNotificationForm, handleSubmit, notifications, isSaving, showSaveButton } = useNotificationContext();
    const activeUser = useActiveUser();
    const isOrgAdmin = !activeUser.isVivliAdmin && activeUser?.orgMemberships?.find((om) => om.isOrgAdmin) !== undefined;
    const buttonText = isOrgAdmin ? 'Save Feedback Update' : 'Save Status Update';
    const update = useStatusUpdate();
    const formApi = useStatusUpdateForm(dataRequest.statusUpdate);

    const daysInCurrentStep = () => {
        const days = update.getCurrentDays(dataRequest, null);
        return days;
    };

    useEffect(() => {
        setFormApi(formApi);
    }, []);

    enum TabTitleEnum {
        statusUpdate = 'Status Update',
        notification = 'Notifications',
        duaDetails = 'DUA Details',
    }

    enum TabRouteEnum {
        statusUpdate = 'Status Update',
        notification = 'Notifications',
        duaDetails = 'DUA Details',
    }

    const getTabs = () => {
        const defaultTabs: IStatusUpdateTab[] = [
            {
                name: TabRouteEnum.statusUpdate,
                sortOrder: 0,
                title: TabTitleEnum.statusUpdate,
            },
            {
                name: TabRouteEnum.duaDetails,
                sortOrder: 1,
                title: TabTitleEnum.duaDetails,
            },
            {
                name: TabRouteEnum.notification,
                sortOrder: 2,
                title: TabTitleEnum.notification,
            },
        ];
        return defaultTabs.sort((a, b) => a.sortOrder - b.sortOrder);
    };

    const [tabs, setTabs] = useState<IStatusUpdateTab[]>();
    const [activeTab, setActiveTab] = useState('');
    const handleTabClick = (tab: IStatusUpdateTab) => {
        setActiveTab(tab.name);
    };

    useEffect(() => {
        if (activeUser.isVivliAdmin || isOrgAdmin === true) {
            if (isOrgAdmin) {
                setTabs(getTabs().slice(0, 2));
            } else {
                setTabs(getTabs());
            }
        } else {
            setTabs([getTabs()[0]]);
        }
    }, []);

    useEffect(() => {
        if (tabs !== undefined) setActiveTab(tabs[0].name);
    }, [tabs]);

    const buttonsDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Status Update':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            <ButtonComponent
                                style={{ maxWidth: '200px' }}
                                isLoading={isSubmittingForm}
                                disabled={isSubmittingForm}
                                onClick={submitStatusUpdate}
                            >
                                {buttonText}
                            </ButtonComponent>
                        </div>
                    </>
                );
            case 'DUA Details':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            {activeUser.isVivliAdmin && (
                                <ButtonComponent style={{ maxWidth: '200px' }} onClick={handelDuaSave} isLoading={isSavingDataRequest}>
                                    Save DUA Detail
                                </ButtonComponent>
                            )}
                        </div>
                    </>
                );
            case 'Notifications':
                return (
                    <>
                        <div style={buttonContainerStyle}>
                            {notifications[0] && (
                                <ButtonComponent style={{ maxWidth: '200px' }} onClick={resetNotificationForm}>
                                    {'Create New Notification'}
                                </ButtonComponent>
                            )}
                            <ButtonComponent
                                style={{ maxWidth: '200px' }}
                                disabled={!showSaveButton}
                                isLoading={isSaving}
                                onClick={() => handleSubmit(NotificationTopicEnum.DataRequest)}
                            >
                                {'Save Notification'}
                            </ButtonComponent>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    const tabDisplayed = (activeTab) => {
        switch (activeTab) {
            case 'Status Update':
                return (
                    <div style={formContainerStyle}>
                        <FormProvider {...formApi}>
                            <StatusUpdateFormComponent
                                daysInCurrentStep={daysInCurrentStep()}
                                isOrgAdmin={isOrgAdmin}
                                origWorkflowStepStartDate={dataRequest.statusUpdate.currentWorkflowStepStartDate}
                            />
                        </FormProvider>
                    </div>
                );
            case 'Notifications':
                return <NotificationFeature topicId={dataRequest.id} />;
            case 'DUA Details':
                return (
                    <div>
                        <DuaComponent isOrgAdmin={isOrgAdmin} />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={containerStyle}>
            <div style={containerStyle}>
                <TabsComponent defaultTab={0}>
                    {tabs?.map((tab, i) => {
                        const { title } = tab;
                        return (
                            <TabComponent
                                key={i}
                                title={title}
                                onTabClick={() => handleTabClick(tab)}
                                rightHeaderContent={<div style={tabButtonsStyle}>{buttonsDisplayed(activeTab)}</div>}
                            >
                                {tabDisplayed(activeTab)}
                            </TabComponent>
                        );
                    })}
                </TabsComponent>
            </div>
        </div>
    );
};
