import { useEffect, useState } from 'react';
import { IOrganization } from '@vivli/features/organizations/infrastructure/interface';
import { useOrganizationsService } from '@vivli/features/organizations/infrastructure/context';
import { finalize, first } from 'rxjs/operators';

export function useOrganizations() {
    const [organizations, setOrganizations] = useState<IOrganization[]>();
    const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
    const [irpApproverOrgs, setIrpApproverOrgs] = useState<IOrganization[]>(null);
    const [qaReviewerOrgs, setQaReviewerOrgs] = useState<IOrganization[]>(null);
    const [annotatorOrgs, setAnnotatorOrgs] = useState<IOrganization[]>(null);

    const organizationsService = useOrganizationsService();

    useEffect(() => {
        setIsLoadingOrganizations(true);
        const orgsSub = organizationsService
            .getOrganizations()
            .pipe(
                first((o) => o !== null),
                finalize(() => setIsLoadingOrganizations(false))
            )
            .subscribe(setOrganizations);

        return () => {
            orgsSub.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!organizations) {
            return;
        }
        const irpApprovers = organizations?.filter((a) => a.isIRPApprover);
        setIrpApproverOrgs(irpApprovers);

        const qaReviewers = organizations?.filter((a) => a.isQaReviewer);
        setQaReviewerOrgs(qaReviewers);

        const annotators = organizations?.filter((a) => a.isAnnotator);
        setAnnotatorOrgs(annotators);

        return () => {
            irpApproverOrgs;
            qaReviewerOrgs;
            annotatorOrgs;
        };
    }, [organizations]);

    return {
        organizations,
        isLoadingOrganizations,
        irpApproverOrgs,
        qaReviewerOrgs,
        annotatorOrgs,
    };
}
