import { IFacet } from '@vivli/shared/infrastructure/interface';

export const AmrFacetsConstant: IFacet[] = [
    {
        name: 'antimicrobials',
        arguments: {
            count: 200,
            sort: 'value',
        },
    },
    {
        name: 'groupingsOfResistancePatterns',
        arguments: {
            count: 50,
            sort: 'value',
        },
    },
    {
        name: 'organisms',
        arguments: {
            count: 1500,
            sort: 'value',
        },
    },
    {
        name: 'specimenSources',
        arguments: {
            count: 200,
            sort: 'value',
        },
    },
    {
        name: 'sampleTimes',
    },
    {
        name: 'countries',
        arguments: {
            count: 300,
            sort: 'value',
        },
    },
    { name: 'regions' },
    { name: 'yearsDataCollected' },
    {
        name: 'sponsorName',
        arguments: {
            count: 500,
            sort: 'value',
        },
    },
];
