﻿import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const EnquiryPortalComponent = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        const gridUrl = `/admin/enquiries/`;
        navigate(gridUrl);
    };

    useEffect(() => {
        handleNavigation();
    }, []);

    return null;
};
