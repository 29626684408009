import React, { CSSProperties, Fragment, useState } from 'react';
import { ButtonComponent } from '@vivli/shared/components';
import { Color } from '@vivli/shared/theme';
import { ApprovalStatusEnum, DataRequestStatusEnum } from '@vivli/features/data-requests/infrastructure/enum';
import { useDataRequestContext, useDataRequestsService } from '@vivli/features/data-requests/infrastructure/context';
import { useDataRequestPermissions } from '@vivli/features/data-requests/infrastructure/hook';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { finalize, first } from 'rxjs/operators';
import { useAssignedAppType, useVivliConfig } from '@vivli/core/infrastructure/hook';
import { GetOrgIdResult } from '@vivli/features/data-requests/infrastructure/interface';
import { AssignedAppTypeEnum, LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';
import { AssetsConstant, DTIRejectButton } from '@vivli/shared/infrastructure/constants';

const rejectButtonStyle: CSSProperties = {
    backgroundColor: 'white',
    backgroundImage: `url(${AssetsConstant.RED_X_ICON})`,
    backgroundPosition: 'center left 15px',
    backgroundRepeat: 'no-repeat',
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderTop: '1px solid',
    borderColor: Color.ERROR_RED,
    boxShadow: 'none',
    color: Color.ERROR_RED,
    marginRight: 10,
    maxHeight: 40,
    paddingLeft: 30,
};

interface RejectButtonComponentProps {
    getOrgId: (orgIds: string[]) => Promise<GetOrgIdResult>;
}

export const RejectButtonComponent = ({ getOrgId }: RejectButtonComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { dataRequest, isUpdatingStatus, setIsUpdatingStatus, updateDataRequest } = useDataRequestContext();
    const { getAllowedTransitions } = useDataRequestPermissions();
    const dataRequestsService = useDataRequestsService();
    const modalService = useModalService();
    const vivliConfig = useVivliConfig();
    const assignedAppType = useAssignedAppType();
    const isAmr = assignedAppType === AssignedAppTypeEnum.Amr;

    const handleFinally = () => setLoadingStatus(false);

    const handleError = (e) => {
        handleFinally();
        modalService.error(e);
    };

    const setLoadingStatus = (status: boolean) => {
        setIsLoading(status);
        setIsUpdatingStatus(status);
    };

    const rejectText = 'Cannot Fulfill';

    const handleResult = (comment: string, selections: string[], state: DataRequestStatusEnum, orgId: string) => {
        setLoadingStatus(true);
        if (
            dataRequest.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
            dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval ||
            dataRequest.status === DataRequestStatusEnum.SubmittedToVivli
        ) {
            const rejectReasons = vivliConfig.rejectionReasons.filter((rr) => selections?.some((s) => s === rr.code));
            dataRequestsService
                .approveRequest(dataRequest.id, comment, orgId, rejectReasons, ApprovalStatusEnum.Rejected)
                .pipe(first(), finalize(handleFinally))
                .subscribe(updateDataRequest, modalService.error);
        } else {
            dataRequestsService
                .setDataRequestStatus(dataRequest.id, state, comment)
                .pipe(first(), finalize(handleFinally))
                .subscribe(updateDataRequest, modalService.error);
        }
    };

    const getCheckboxes = () => {
        if (isAmr) {
            return [];
        }

        return vivliConfig.rejectionReasons.map((rr) => ({
            title: rr.description,
            value: rr.code,
            checked: false,
        }));
    };

    const handleRejectSubmission = (orgId: string, state: DataRequestStatusEnum) => {
        // Non-Approval = REJECTION
        let modalTitle =
            dataRequest.status === DataRequestStatusEnum.SubmittedToVivli ? 'Reason for Non-Fulfillment' : 'Reason for Non-Approval';
        let modalDescript =
            dataRequest.status === DataRequestStatusEnum.SubmittedToVivli
                ? 'Please provide the reason this request is being sent back to the sponsor'
                : 'Please provide a reason for your non-approval.';
        let modalText = dataRequest.status === DataRequestStatusEnum.SubmittedToVivli ? 'Non-Fulfillment Reason' : 'Non-Approval Reason';

        const message = (
            <Fragment>
                <p>{modalDescript}</p>
                {(dataRequest.status === DataRequestStatusEnum.AwaitingDataProviderApproval ||
                    dataRequest.status === DataRequestStatusEnum.AwaitingIRPApproval) && (
                    <p>Include any feedback for the researcher in the chat.</p>
                )}
            </Fragment>
        );

        setLoadingStatus(false);
        return modalService.confirm(message, {
            title: modalTitle,
            style: { textAlign: 'left', minWidth: '50em' },
            messageStyle: { textAlign: 'left' },
            multiSelectOptions: getCheckboxes(),
            showMultiSelect: isAmr ? false : true,
            requireSelectOption: isAmr ? false : true,
            showTextAreaPrompt: true,
            showPrompt: false,
            promptLabel: modalText,
            confirmText: 'OK',
            cancelText: 'Cancel',
            onConfirm: ({ comment, selections }) => {
                handleResult(comment, selections, state, orgId);
            },
        });
    };

    const handleReject = () => {
        const nextAllowedStatus: DataRequestStatusEnum = getAllowedTransitions(dataRequest).reject;
        if (!nextAllowedStatus) {
            return;
        }

        setLoadingStatus(true);

        //we only need to worry about approval orgs for DataproviderApproval &&
        //IRPApproval stages
        if (
            dataRequest.status === DataRequestStatusEnum.SubmittedToVivli ||
            dataRequest.status === DataRequestStatusEnum.Approved ||
            dataRequest.status === DataRequestStatusEnum.AwaitingDUAValidation
        ) {
            handleRejectSubmission(null, nextAllowedStatus);
        } else {
            dataRequestsService
                .getApprovalOrgs(dataRequest.id)
                .pipe(first())
                .subscribe((orgIds) => {
                    getOrgId(orgIds)
                        .then((result) => {
                            return handleRejectSubmission(result.orgId, nextAllowedStatus);
                        })
                        .finally(handleFinally);
                }, handleError);
        }
    };

    return (
        <ButtonComponent
            style={rejectButtonStyle}
            onClick={handleReject}
            isLoading={isLoading}
            disabled={isUpdatingStatus}
            className="approveReject_reject"
            dataId={DTIRejectButton.DataRequestRejectButton}
            loaderColor={LoadIndicatorColorEnum.ErrorRed}
        >
            {rejectText}
        </ButtonComponent>
    );
};
