﻿import React, { CSSProperties } from 'react';
import { Color, Size } from '@vivli/shared/theme';
import { MetricsConstants } from '@vivli/features/dashboard/infrastructure/constants';
import { IStatusDataPoint } from '@vivli/features/dashboard/infrastructure/interface';

const flexStyle: CSSProperties = {
    display: 'flex',
};
const columnStyle: CSSProperties = {
    ...flexStyle,
    flexWrap: 'wrap',
    width: MetricsConstants.Sizing.SPACING * 6,
    marginLeft: MetricsConstants.Sizing.SPACING,
    flexDirection: 'column',
    alignItems: 'center',
};
const boxStyle: CSSProperties = {
    ...flexStyle,
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    height: 50,
    marginLeft: '0 auto',
    fontSize: Size.FontSize.Larger * 1.1,
    fontWeight: Size.FontWeight.Bold,
    color: 'white',
};
const labelStyle: CSSProperties = {
    marginTop: '4px',
    color: MetricsConstants.Colors.DARK_BLUE_TEXT,
    fontSize: Size.FontSize.Large - 1,
    fontWeight: Size.FontWeight.SemiBold,
    textAlign: 'center',
};
const outerContainerStyle: CSSProperties = {
    backgroundColor: 'white',
    padding: `${MetricsConstants.Sizing.DOUBLE_SPACING}px 0`,
};
const containerStyle: CSSProperties = {
    ...flexStyle,
    flexDirection: 'row',
    justifyContent: 'center',
};
const orgTotalOuterStyle: CSSProperties = {
    ...flexStyle,
    ...columnStyle,
    marginLeft: 0,
};
const orgTotalInnerStyle = (color): CSSProperties => ({
    ...flexStyle,
    ...boxStyle,
    backgroundColor: color,
});
const allTotalOuterStyle = (isAggregateReport): CSSProperties => ({
    ...flexStyle,
    ...columnStyle,
    marginLeft: isAggregateReport ? 0 : MetricsConstants.Sizing.SPACING,
});
const allTotalInnerStyle: CSSProperties = {
    ...flexStyle,
    ...boxStyle,
    backgroundColor: Color.VIVLI_GREY,
};
const daysStyle = (color): CSSProperties => ({
    ...flexStyle,
    ...boxStyle,
    fontSize: Size.FontSize.Larger - 2,
    color: MetricsConstants.Colors.DARK_BLUE_TEXT,
    border: `2px solid ${color}`,
    backgroundColor: 'white',
});

interface AveragesSummaryComponentProps {
    data: IStatusDataPoint;
    color: string;
    isAggregateReport?: boolean;
}

export const AveragesSummaryComponent = ({ data, color, isAggregateReport }: AveragesSummaryComponentProps) => {
    const orgTotal = isAggregateReport ? 0 : data?.orgAverage;
    const allTotal = data?.allDataContributorsAverage;
    const diff = Math.round(Math.abs(allTotal - orgTotal) * 100) / 100;
    const daysDisplay = `${diff >= 0 ? '+' : '-'}${diff} days`;

    return (
        <div style={outerContainerStyle}>
            <div style={containerStyle}>
                {!isAggregateReport && (
                    <div style={orgTotalOuterStyle}>
                        <div style={orgTotalInnerStyle(color)}>{orgTotal}</div>
                        <div style={labelStyle}>{MetricsConstants.Text.AVERAGE_SUMMARY_LABEL_1}</div>
                    </div>
                )}
                <div style={allTotalOuterStyle(isAggregateReport)}>
                    <div style={allTotalInnerStyle}>{allTotal}</div>
                    <div style={labelStyle}>{MetricsConstants.Text.AVERAGE_SUMMARY_LABEL_2}</div>
                </div>
                {!isAggregateReport && (
                    <div style={columnStyle}>
                        <div style={daysStyle(color)}>{daysDisplay}</div>
                        <div style={labelStyle}>{MetricsConstants.Text.AVERAGE_SUMMARY_LABEL_3}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
