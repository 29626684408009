﻿import React, { CSSProperties } from 'react';
import { TextAreaFormFieldComponent, TextFieldReadOnlyFormComponent, TextFormFieldComponent } from '@vivli/shared/components';

// This style is to clearly mark which fields are readonly for the Org Admins - 7295 pointerEvents disabled scrolling
const readonlyStyle: CSSProperties = {
    cursor: 'default',
    /*    pointerEvents: 'none',*/
    backgroundColor: 'rgb(210, 213, 217)',
};

export const SharedStatusUpdateFieldsComponent = ({ daysInCurrentStep, isOrgAdmin }) => {
    const inputStyle = isOrgAdmin ? readonlyStyle : null;

    return (
        <>
            <TextFieldReadOnlyFormComponent
                defaultValue={daysInCurrentStep}
                label={'Days in Current Step'}
                readonly={true}
                style={readonlyStyle}
            />
            <TextFormFieldComponent
                name={'currentWorkflowStepTargetDays'}
                label={'Target Time for Current Step (Days)'}
                readonly={isOrgAdmin}
                style={inputStyle}
            />

            <TextAreaFormFieldComponent name={'actionsRequired'} label={'Actions Required'} readonly={isOrgAdmin} style={inputStyle} />

            {/* Only one editable for org admins */}
            <TextAreaFormFieldComponent name={'feedback'} label={'Feedback'} />

            <TextAreaFormFieldComponent
                name={'requestReviewStatus'}
                label={'Request Review Status'}
                readonly={isOrgAdmin}
                style={inputStyle}
            />

            <TextAreaFormFieldComponent name={'dataUploadStatus'} label={'Data Upload Status'} readonly={isOrgAdmin} style={inputStyle} />
            <TextAreaFormFieldComponent
                name={'publicationProgress'}
                label={'Publication Progress'}
                readonly={isOrgAdmin}
                style={inputStyle}
            />

            <TextAreaFormFieldComponent name={'additionalNotes'} label={'Additional Notes'} readonly={isOrgAdmin} style={inputStyle} />
        </>
    );
};
