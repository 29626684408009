import { ButtonComponent } from '@vivli/shared/components';
import React, { CSSProperties, useState } from 'react';
import { Color } from '@vivli/shared/theme';
import { useModalService } from '@vivli/shared/infrastructure/context';
import { Observable } from 'rxjs';
import { LoadIndicatorColorEnum } from '@vivli/shared/infrastructure/enum';

export interface ConfirmButtonComponentProps {
    title?: string;
    children?: any;
    confirmMessage?: string;
    apiCall?: () => Observable<any>;
    callback?: (confirmed: boolean, apiResult?: any) => void;
}

const buttonStyle: CSSProperties = {
    margin: 'auto',
    height: 34,
    fontSize: 13,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 87,
    borderBottom: `1px solid ${Color.ERROR_RED}`,
    borderLeft: `1px solid ${Color.ERROR_RED}`,
    borderRight: `1px solid ${Color.ERROR_RED}`,
    borderTop: `1px solid ${Color.ERROR_RED}`,
    backgroundColor: Color.ERROR_RED,
    color: Color.WHITE,
};

export const ConfirmButtonComponent = ({ title, confirmMessage, apiCall, callback, children }: ConfirmButtonComponentProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const modalService = useModalService();

    const showConfirmModal = () => {
        modalService.confirm(confirmMessage, {
            onConfirm: () => {
                if (apiCall) {
                    setIsLoading(true);
                    apiCall().subscribe((apiResult) => {
                        setIsLoading(false);
                        callback && callback(true, apiResult);
                    });
                } else {
                    callback && callback(true);
                }
            },
        });
    };

    return (
        <ButtonComponent
            isLoading={isLoading}
            disabled={isLoading}
            title={title}
            style={buttonStyle}
            loaderColor={LoadIndicatorColorEnum.White}
            onClick={showConfirmModal}
        >
            {children}
        </ButtonComponent>
    );
};
